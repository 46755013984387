import { useEffect, useState } from "react";
import {useMutation, useQuery} from "@apollo/client";
import { Helmet } from "react-helmet";
import styled, { withTheme } from "styled-components";
import { withAppContext } from "../../../store/initAppContext";
import cookieUtil from "../../../util/cookieUtil";
import { ECookieName } from "../../../util/utilModel";
import config from "../../../config/config";
import { ifProp, theme, withProp } from "styled-tools";
import _isEmpty from "lodash/isEmpty";
import util from "../../../util/util";
import EventNotFound from "../EventNotFound";
import { GET_BALANCE_INFO, GET_PLAY_GAME } from "../../../../src/graphql/game";
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
import { Routes, Route, useParams } from 'react-router-dom';
import { BalanceAnimation } from "src/pages/loading";
import SEOContents from "src/components/SEOContents";
import VirtualSportsVciFun88 from "src/components/SEOComponents/VirtualSportsVciFun88";
import getSchemaData from "src/components/schema";

const _animationImg = '/static/mobile/brand/31/image/virtualsports-coins.gif'

const structuredData = (brandId: any, userId: any) => {
  const brandUrlConfig = {
    32: 'https://www.yolo247.club',
    33: 'https://www.iw247.com',
    34: 'https://www.betdaily.club',
    39: 'https://www.fomo7.com',
    40: 'https://www.fomo7.xyz',
    46: 'https://www.fun88.site',
    47: 'https://www.gofun88.in',
  };

  const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.site';

  // const schemaName: string = userId ? userId : 'virtualSports';
  const schemaName: string = userId || 'virtualSports';
  const otherschemaData = getSchemaData(schemaName);

  let listItem =[
    {
      "@type": "ListItem",
      "item": `${domainURL}`,
      "name": "Homepage",
      "position": 1
    },
    {
      "@type": "ListItem",
      "item": `${domainURL}/virtualSports/`,
      "name": "virtual Sports",
      "position": 2
    }
  ];

  if(userId) {
    listItem = [...listItem, {
      "@type": "ListItem",
      "item": `${domainURL}/virtualSports/${userId}`,
      "name": userId,
      "position": 3
    }]
  }

  const data = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": listItem,
      "name": "Breadcrumbs"
    },
    ...otherschemaData
  ]

  return JSON.stringify(data)
}

const dynamicGameCode = [
  {
    id: 1,
    gameCode: "vfwc",
    name: "worldCup",
  },
  {
    id: 2,
    gameCode: "vfel",
    name: "englishLeague",
  },
  {
    id: 3,
    gameCode: "vti",
    name: "tennis",
  },
  {
    id: 4,
    gameCode: "vbl",
    name: "basketBall",
  },
  {
    id: 5,
    gameCode: "vci",
    name: "cricket",
  },
  {
    id: 6,
    gameCode: "vhk",
    name: "horses",
  },
];



const VirtualSports = (props: any) => {
  const {
    app: { brandId },
    theme,
  } = props;

  let { userId } = useParams();


  const [isLoading, setLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState("");
  const [noGame, setNoGame] = useState(false);
  const [
    triggerOneClickRecycle,
    { recycleLoading, recycleError, recycleData },
  ]: any = useMutation(ONE_CLICK_RECYCLE);
  const [getIframeData, { loading, error }] = useMutation(GET_PLAY_GAME, {
    variables: {
      input: {
        platformCode: "VIRTUALSPORTS",
        gameCode: userId,
        homeURL: '',
      },
    },
  });

  const { data, startPolling, stopPolling } = useQuery(GET_BALANCE_INFO, {
		variables: {
			input: {
				platformCodes: ['VIRTUALSPORTS'],
				target: util.getTargetString({ memberId: props.app.memberId, type: props.app.type }),
			},
		}
	});

  const balance = util.getValue(data, ['game', 'balanceInfo', '0', 'balance'], '-',);

	useEffect(() => {
		startPolling(7000); // poll interval
   
		return () => {
			stopPolling();
		};
      
	}, [balance])



  useEffect(() => {
    getIframeData()
      .then(async (response) => {
        const payload = await util.getValue(
          response,
          ["data", "game", "playgame"],
          {}
        );
        setIframeUrl(payload.data.url);
      })
      .catch((error) => {
        setNoGame(true);
      });

    return () => {
        cookieUtil.get(ECookieName.COOKIE_TOKEN) &&
          triggerOneClickRecycle()
            .then((response) => {
              const result = util.getValue(
                response,
                ["data", "game", "recycleTransfer"],
                {}
              );
              const { success, message, data } = result;
              if (success) {
                return;
              }
            })
            .catch((error) => {
              console.error('Error in one click recycle')
            });
      
    };
  }, [userId]);

  if (loading) {
    return null;
  }
  if (error) {
    return <p>Error...</p>;
  }

  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <>
      {" "}
      <Helmet>
        <script type="application/ld+json">
          {
           structuredData(brandId, userId)
          }
        </script>
      </Helmet>
      <SEOContents pageName={
        !userId ? "virtualSports" 

          :userId === "vci" ? 'virtualSports' 
          : userId === "vbl" ? "virtualSportsBL" 
          : userId === "vfb" ? "virtualSportsFB" 
          : userId === "vfel" ? "virtualSportsFEL" 
          : userId === "vfec" ? "virtualSportsFEC"
          : userId === "vti" ? "virtualSportsVTI"
          : userId === "vfwc" ? "virtualSportsWC" 
          : userId
        } 
      />
      {noGame ? (
        <EventNotFound />
      ) : (
        <GameContainer>
        {isLoading ? (
					<LoadingWrap> <img src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.loaderYOLO247}`} alt="logonew" /></LoadingWrap>
				) : null}
          <BalanceBar>
            <CoinBalance>
              <span>Coin Balance : {balance}</span>
            </CoinBalance>
          </BalanceBar>
          <Main>
          {!isLoading  && <BalanceAnimation imgSrc={_animationImg}  style={{top:'51px'}}  />}
            {
              <iframe
                src={iframeUrl}
                width="100%"
                height="700"
                onLoad={hideSpinner}
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                title="CSVertual"
              />
            }
          </Main>
        </GameContainer>
      )}
      {(brandId == 47) && <VirtualSportsVciFun88 />}
    </>
  );
};

export default withTheme(withAppContext(VirtualSports));

const LoadingWrap = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
    align-items: center;
	/* background: ${theme('palette.secondary-background-color-linear-new')}; */
	& > img {
		width: 120px !important;
		/* height: 100px; */
	}
	height: 100vh;
`;

const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Main = styled.div`
  margin-top: 0rem;
  flex: 1;

  > iframe {
    height: 98vh!important
}
`;

export const BalanceBar = styled.div`
	padding: 10px;
    /* background: ${theme('palette.backButtonColor')}; */
    background: ${theme('palette.secondary-background-color-linear-new1')};
	color: white;
	flex: 0 0 40px;
	text-align: right;

`;

export const CoinBalance = styled.div`
	text-align: right;
`;
