import React, { Component, useEffect, useState } from "react";
import { HeadingTextWrapper } from '../../common/Headings';
import CasinoTabGames, { allIcon, netentIcon } from "./casino-tab-games";
// import CasinoSlider from "./casino-slider";
// import RecommendedGames, { RowFirst } from "./recommended-games";
// import Loading from 'src/pages/loading';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from './casino-tab';
import { useMutation } from "@apollo/client";
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
// import AllGames from "./all-games";
import PlayDemoGames from "./bingo-slotGames";
import styled, { createGlobalStyle, withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { motion } from 'framer-motion'
import config from "src/config/config";
import Intl from '../../common/Intl';
import SEOContents from "src/components/SEOContents";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import gameData from "src/static/game-data/slotgames.json";
import SlotGameSEO from "src/components/SEOComponents/slotgameSEO";

import { Helmet } from "react-helmet";
import AviatrixSEO from "src/components/SEOComponents/AviatrixSEO";
import JILIBLACKJACKSEO from "src/components/SEOComponents/JIliBlackjackSeo";
import JiliAndarBahar46 from 'src/components/SEOComponents/JiliAndarBahar'
import JiliRummy from "src/components/SEOComponents/JiliRummy";
import JiliFishingFun88 from "src/components/SEOComponents/JiliFishingFun88";
import JiliSlotFun88 from "src/components/SEOComponents/JiliSlotFun88";
import JiliCrazy777Fun88 from "src/components/SEOComponents/JiliCrazy777Fun88";
import JiliFortuneGemsFun88 from "src/components/SEOComponents/JiliFortuneGemsFun88";
import JiliMoneyComingFun88 from "src/components/SEOComponents/JiliMoneyComingFun88";
import NetentFun88 from "src/components/SEOComponents/NetentFun88";
import getSchemaData from "src/components/schema";

//Provider Icons =========================================
// const pragmaticplayIcon = `${'/static/media/pragmatic_icon.png'}`;
const KAgamingIcon = `${'/static/media/kagamingicon.png'}`;
// const macawicon = `${'/static/media/macawicon.png'}`;
const redTigerIcon = `${'/static/media/redTigerIcon.png'}`;
const mplayicon = `${'/static/media/mplayicon.png'}`;
const moj = `${'/static/media/7mojos.png'}`
const BTGIcon = `${'/static/media/btgicon.png'}`
const NLCIcon = `${'/static/media/nlc-icon.png'}`
// const redTigerIcon = `${'/static/media/redTigerIcon.png'}`
const evoPlayIcon = `${'/static/media/Evoplay_icon_desk.png'}`;
const jiliIcon = `${'/static/media/Jili_icon_desk.png'}`;
const slotopiaIcon = `${'/static/media/Slotopia.png'}`;

let ALLGAMES = <Intl langKey="GAME@ALL" />;
let NETENT = <Intl langKey="GAME@NETENT" />;
// let BETSOFT = <Intl langKey="GAME@BETSOFT" />;
// let PRAGMATICPLAY = <Intl langKey="GAME@PRAGMATICPLAY" />;
let KA_GAMING = <Intl langKey="GAME@KA-GAMES" />;
// let MACAW = <Intl langKey="GAME@MACAW" />;
// let REDTIGER =  <Intl langKey= "GAME@REDTIGER"/>
let MOJOS = <Intl langKey='GAME@7MOJO' />;
let MPLAY = <Intl langKey="GAME@MPLAY" />;
let BTGGAMES = <Intl langKey="GAME@BTG" />;
let NLC = <Intl langKey="GAME@NLCGAMES" />;
let REDTIGER = <Intl langKey="GAME@REDTIGERGAES" />;
let EVOPLAY = <Intl langKey="GAME@EVOPLAY" />;
let JILI = <Intl langKey="GAME@JILI" />;
let SLOTOPIA = <Intl langKey="GAME@SLOTOPIA" />;


// let SPINMATIC = <Intl langKey="GAME@SPINMATIC"/>;

const Language = cookieUtil.get(ECookieName.COOKIE_LANG);
const providerTab = [ALLGAMES, NETENT, MPLAY, MOJOS, KA_GAMING, BTGGAMES, NLC,REDTIGER, EVOPLAY, JILI, SLOTOPIA];
const providerList = ['all','netent', 'mplay', '7mojos', 'ka-gaming', 'btg', 'no-limit-city','red-tiger', 'evoplay', 'jili-slot', 'slotopia'];

  const structuredData = (domainURL: any, providerName: string, gameName: string) => {

    // const schemaName: string = providerName && gameName ? gameName : providerName ? providerName : 'slots';
    let schemaName: string = gameName || providerName || 'slots';

    if(providerName === 'jili-slot' && gameName === 'blackjack') {
        schemaName = 'jiliblackjack';
    }

    const otherschemaData = getSchemaData(schemaName);

    const itemLists = [
        {
            "@type": "ListItem",
            "item": domainURL,
            "name": "Homepage",
            "position": 1
        },
        {
            "@type": "ListItem",
            "item": `${domainURL}/slots`,
            "name": "Slotgames 🎰",
            "position": 2
        }
    ];

    if (providerName) {
        itemLists.push({
            "@type": "ListItem",
            "item": `${domainURL}/slots/${providerName}`,
            "name": providerName,
            "position": 3
        });

        if (gameName) {
            itemLists.push({
                "@type": "ListItem",
                "item": `${domainURL}/slots/${providerName}/${gameName}`,
                "name": `Play ${gameName}`,
                "position": 4
            });
        }
    }

    const data = [
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": itemLists,
            "name": "Breadcrumbs"
        },
        ...otherschemaData
    ];

    return JSON.stringify(data);
};

const providerIcons = [
    {
        id: 1,
        name: ALLGAMES,
        icon: allIcon
    },
    {
        id: 2,
        name: NETENT,
        icon: netentIcon
    },
    // {
    //     id: 4,
    //     name: PRAGMATICPLAY,
    //     icon: pragmaticplayIcon
    // },
    {
        id: 3,
        name: MPLAY,
        icon: mplayicon
    },
    {

        id: 4,
        name: MOJOS,
        icon: moj
    },
    {
        id: 5,
        name: KA_GAMING,
        icon: KAgamingIcon
    },
    {
        id: 6,
        name: BTGGAMES,
        icon: BTGIcon
    },
    {
        id: 7,
        name: NLC,
        icon: NLCIcon
    },
    {
        id: 8,
        name: REDTIGER,
        icon: redTigerIcon
    },
    {
        id: 9,
        name: EVOPLAY,
        icon: evoPlayIcon
    },
    {
        id: 10,
        name: JILI,
        icon: jiliIcon
    },
    {
        id: 11,
        name: SLOTOPIA,
        icon: slotopiaIcon
    },
    // {
    //     id: 12,
    //     name: MACAW,
    //     icon: macawicon
    // }
]

function SlotGames(props: any) {

    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE);
    //Added Hindi Images Also
    //const slotGame = gameData(Language);
    const [slotGame, setSlotGame] = useState(gameData);

    const [filteredData, setfilteredData] = useState(slotGame);
    const [search, setSearch] = useState('');
    const { id: gameName, provider: providerName } = useParams();
    const location = useLocation();
    const getLastqueryString = location.pathname.split("/").pop();


    useEffect(() => {

        // Now Lazy load game data 
        // import('src/static/game-data/slotgames.json').then(data => data.default).then(data => {
            // setSlotGame(data);
            if (!gameName) {
                setfilteredData(slotGame)
            }

        // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))

        window.scrollTo(0, 0);
    }, [])


    let history: any = useNavigate();

    const backButton = () => {

        window.location.reload();
        triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });
    }

    const searchData = (val) => {
        setSearch(val)
        if (val !== "") {
            const filteredItems = slotGame.filter((item) => {
                //MS - Modifying Search feature for better result
                let type = item.type.toLowerCase() && item.type.toLowerCase().replace(/[\_\-\s]/gi, '');
                let _val = val && val.replace(/[\_\-\s]/gi, '');
                let slug = item.slug ? item.slug.replace(/[\_\-\s]/gi, '') : '';
                
             // Check if either type or slug matches the search value
             if (type.toLowerCase().includes(_val.toLowerCase()) || slug.toLowerCase().includes(_val.toLowerCase())) {
                return item;
            } else {
                const { imgsrc, ...rest } = item;
                // Fallback: Search through all other fields in the object
                return Object.values(rest).join('').toLowerCase().includes(val.toLowerCase());
            }
                
            })
            setfilteredData(filteredItems)
        }
        else {
            setfilteredData(slotGame)
        }
    }

    const budgetData = () => {

    }

    const { app: { brandId }, theme: { config } } = props;
    const { showSEOfooterForYolo ,showSeofooterFun88} = config;

    const brandUrlConfig = {
        32: 'https://www.yolo247.club',
        33: 'https://www.iw247.com',
        34: 'https://www.betdaily.club',
        39: 'https://www.fomo7.com',
        40: 'https://www.fomo7.xyz',
        46: 'https://www.fun88.site',
        47: 'https://www.gofun88.in',
    };

    const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.site';

    const _id = providerName && gameName ? gameName : providerName;


    let SEOPAGE = _id ? _id : "slotGames";
    if(_id && _id === 'netent') {
        SEOPAGE = 'netent'
    }
    else if(_id && _id === 'no-limit-city') {
        SEOPAGE = 'nlc'
    }
    else if(_id && _id === 'pragmatic-play') {
        SEOPAGE = 'pragmatic'
    }
    else if(providerName === 'jili-slot' && _id === 'blackjack') {
        SEOPAGE = 'jiliblackjack'
    }
    else if(providerName === 'netent' && _id === 'baccarat') {
        SEOPAGE = 'netent-baccarat'
    }
    else if( _id === 'roulette') {
        SEOPAGE = 'slots-roulette'
    }
    else if( _id === 'evoplay') {
        SEOPAGE = 'slots-evoplay'
    }
    else if( _id === 'european-roulette') {
        SEOPAGE = 'slots-european-roulette'
    }
    else if( _id === 'atlantis') {
        SEOPAGE = 'slots-atlantis'
    }

    
    else if(_id){
        SEOPAGE = _id;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Helmet>
                <script type="application/ld+json">
                    {
                       structuredData(domainURL, providerName, gameName)
                    }
                </script>
            </Helmet>
             <>
                <SEOContents pageName={SEOPAGE} />
            </>
            
            
            {/* <SlotWrapper style={{width:"100%", height:"84vh"}} theme={props.app.theme} customStyle={props.theme.image} > */}
            <SlotWrapper theme={props.app.theme} customStyle={props.theme.image} >
                <TitleHead theme={props.app.theme} customStyle={props.theme.palette}><Intl langKey="TITLE@SLOTGAME" /> & <Intl langKey="TITLE@BINGO" /></TitleHead>
                {/* <BackButton onClick={backButton}> <img src={LeftArrow}/>
                    Back
                </BackButton> */}

                <TabListSection>
                    <Tabs
                        searchText={search}
                        providerIcons={providerIcons}
                        tabs={providerTab}
                        onSearch={searchData}
                        isTabsHidden={false}
                        budgetClick={budgetData}
                        minWidthInContent={'initial'}
                        providerList={providerList}
                        render={(index) => {
                            return (
                                <>
                                    {providerTab[index] === ALLGAMES && <PlayDemoGames data={filteredData} />}
                                    {providerTab[index] === NETENT && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'netent';
                                    })} />}

                                    {providerTab[index] === MPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'mplay';
                                    })} />}

                                    {providerTab[index] === MOJOS && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === '7mojos';
                                    })} />}


                                    {/* {providerTab[index] === PRAGMATICPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'PRAGMATICPLAY';
                                    })} />} */}
                                    {providerTab[index] === KA_GAMING && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'ka-gaming';
                                    })} />}
                                    {/* {providerTab[index] === MACAW && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'MACAW';
                                    })} />} */}

                                    {/* {providerTab[index] === REDTIGER && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'REDTIGER';
                                    })} />} */}

                                    {providerTab[index] === BTGGAMES && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'btg';
                                    })} />}

                                    {providerTab[index] === NLC && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'no-limit-city';
                                    })} />}

                                    {providerTab[index] === REDTIGER && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'red-tiger';
                                    })} />}

                                    {providerTab[index] === EVOPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'evoplay';
                                    })} />}

                                    {providerTab[index] === JILI && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'jili-slot';
                                    })} />}

                                    {providerTab[index] === SLOTOPIA && <PlayDemoGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'slotopia';
                                    })} />}
                                </>
                            );
                        }} />
                </TabListSection>
            </SlotWrapper>


            {
                ((getLastqueryString === "jili-slot") && showSeofooterFun88) ? <JiliSlotFun88 /> :
                ((getLastqueryString === "jili-fishing") && showSeofooterFun88) ? <JiliFishingFun88 /> :
                ((getLastqueryString === "netent") && showSeofooterFun88) ? <NetentFun88 /> :
                ((providerName === 'jili-slot' && getLastqueryString === 'blackjack') && showSeofooterFun88) ? <JILIBLACKJACKSEO /> :
                ((providerName === 'jili-slot' && getLastqueryString === 'rummy') && showSeofooterFun88) ? <JiliRummy /> :
                ((providerName === 'jili-slot' && getLastqueryString === "jili-fishing") && showSeofooterFun88) ? <JiliFishingFun88 /> :
                ((providerName === 'jili-slot' && getLastqueryString === "crazy777") && showSeofooterFun88) ? <JiliCrazy777Fun88 /> :
                ((providerName === 'jili-slot' && getLastqueryString === "fortune-gems") && showSeofooterFun88) ? <JiliFortuneGemsFun88 /> :
                ((providerName === 'jili-slot' && getLastqueryString === "money-coming") && showSeofooterFun88) ? <JiliMoneyComingFun88 /> :
                (showSEOfooterForYolo && ['lucky-fruits-lines', 'netent', 'no-limit-city', 'pragmatic-play'].includes(getLastqueryString)) ? <></> : (showSEOfooterForYolo && !_id) ? <SlotGameSEO /> : <></>
            }


            {}
        </motion.div>
    );

};

export default withTheme(withAppContext(SlotGames));


const SlotWrapper: any = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-top: -5px; */
    // height: 87vh;
    /* background-image: url('${(props: any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}'); */
    background-image: url('${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
    background-position: 100%;
    background-size: cover;
`;

const TabListSection = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;

const TitleHead: any = styled(HeadingTextWrapper)`
    font-size: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    /* color: ${(props: any) => (props.theme === 'Dark' ? '#FFF' : '#F4A322')}; */
    color: ${(props:any) => (props.theme === 'Dark' ? '#FFF' : props.customStyle['casinoTitleHeadTextColor'])};
    margin-left: 1em;
    padding-top: 10px;
    
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;

    :after {
        content: "";
        width: 500px;
        height: 2px;
        background: ${(props:any) => (props.customStyle['casinoTitleHeadBorderBtmLineColor'])};
        position: absolute;
        bottom: 0;
        left: 0;
    }
	
	@media only screen and (max-width: 992px){
		font-size: 30px;
	}	
`;

export const Col1: any = styled.div`
    width: 100%;
    height: 275px;
    display: flex;
    background-image: url(${(props: any) => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
`;
