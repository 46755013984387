const seoData = {
    "31": {
        "default": {
            "title": "Yolo247 Official India | Online Casino & Sports Betting Site",
            "description": "Join Yolo247, India’s top site for online betting 🎰⚽. Bet on sports & play casino games safely. Enjoy real money gaming on a trusted platform! 🏆",
            "canonical": "https://www.yolo247.site",
            "alternate": "https://m.yolo247.site",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "onlineCasinoGames": {
            "title": "Live Casino 🎰 | Play Real Money Online Games on Yolo247",
            "description": "Experience the thrill of real-time gaming at Yolo247’s Live Casino. Play popular casino games with live dealers and win real money!",
            "canonical": "https://www.yolo247.site/live-casino",
            "alternate": "https://m.yolo247.site/live-casino",
            "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
        },
        "live-baccarat": {
            "title": "Play Baccarat Online for Real Money | Yolo247",
            "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
            "canonical": "https://www.yolo247.site/live-casino/live-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/live-baccarat"
        },
        "live-blackjack": {
            "title": "Play Live Blackjack at Yolo247 India | Real Money Action",
            "description": "Experience Live Blackjack at Yolo247 India with real-time action and real money gameplay. Bet, strategize, and win big rewards today! 🃏💰",
            "canonical": "https://www.yolo247.site/live-casino/live-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/live-blackjack"
        },
        "live-roulette": {
            "title": "Play Roulette Game Online | Free Registration & Instant Payouts",
            "description": "Play Roulette Online for real money on Yolo247. Get all betting options, like inside & outside, with better payouts than ever before. Bet on Roulette online. ",
            "canonical": "https://www.yolo247.site/live-casino/live-roulette",
            "alternate": "https://m.yolo247.site/live-casino/live-roulette"
        },
        "lightningroulette": {
            "title": "Play lightning roulette Game online on Yolo247",
            "description": "Lightning roulette has become one of the best online casino games on Yolo247. Play Online lightning roulette for real money with higher payouts and all betting features.",
            "canonical": "https://www.yolo247.site/live-casino/lightning-roulette",
            "alternate": "https://m.yolo247.site/live-casino/lightning-roulette",
            "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "dragonTiger": {
            "title": "Online Dragon Tiger Game | Get Instant Wins on Yolo247",
            "description": "Play Live Dragon Tiger at Yolo247 India! Enjoy real-time action, place your bets, and win big with exciting rewards. 🐉🐯💰",
            "canonical": "https://www.yolo247.site/live-casino/dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/dragon-tiger",
            "keywords": "Yolo247 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
        },
        "baccarat": {
            "title": "Play Baccarat by Ezugi Live Casino | Yolo247 India",
            "description": "Play Baccarat by Ezugi Live Casino at Yolo247 🌟. Bet live, experience real-time action with professional dealers, and win big with real money gameplay.",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/baccarat",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/baccarat",
            "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
        },
        "teenpattiOnlinePlay": {
            "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
            "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on Yolo247 and earn real cash. Betting on teen patti game made easy!",
            "canonical": "https://www.yolo247.site/teenpatti-online-play",
            "alternate": "https://m.yolo247.site/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
        },
        "sportsBetting": {
            "title": "Bet on the Best Betting Exchange at Yolo247 India | Top Odds",
            "description": "Explore the best odds with Betting Exchange at Yolo247 India. Bet on a variety of events and enjoy high returns, enhanced excitement, and great opportunities!",
            "canonical": "https://www.yolo247.site/betting-exchange",
            "alternate": "https://m.yolo247.site/betting-exchange",
            "keywords": "betting exchange"
        },
        "onlineCricketSattaBazar": {
            "title": "Online Cricket Betting with Latest IPL Odds 2025 | Yolo247",
            "description": "Bet on your favorite cricket matches with Online Cricket Betting at Yolo247 India. Check out Live Odds in this IPL 2025 season with IPL Betting tips🏏💥",
            "keywords": "",
            "canonical": "https://www.yolo247.site/betting-exchange/online-cricket-betting",
            "alternate": "https://m.yolo247.site/betting-exchange/online-cricket-betting",
        },
        "footballBetting": {
            "title": "Football Betting at Yolo247 India | Bet & Win Big!",
            "description": "Bet on Football Betting at Yolo247 India! Enjoy top leagues, exciting matches, and big wins. Join now for thrilling football action! ⚽🏆",
            "canonical": "https://www.yolo247.site/betting-exchange/football-betting",
            "alternate": "https://m.yolo247.site/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
        },
        "tennisBetting": {
            "title": "Tennis Betting at Yolo247 India | Bet on Grand Slams",
            "description": "Bet on tennis tournaments and Grand Slams with Tennis Betting at Yolo247 India. Enjoy thrilling action, big wins, and exciting moments! 🎾🔥",
            "canonical": "https://www.yolo247.site/betting-exchange/tennis-betting",
            "alternate": "https://m.yolo247.site/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
        },
        "volleyballBetting": {
            "title": "Volleyball Betting at Yolo247 India | Bet & Score Big",
            "description": "Bet on exciting volleyball matches with Volleyball Betting at Yolo247 India. Place your bets, cheer for top teams, and win big with every point! 🏐💰",
            "canonical": "https://www.yolo247.site/betting-exchange/volleyball-betting",
            "alternate": "https://m.yolo247.site/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
        },
        "promotions": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.yolo247.site/promotions",
            "alternate": "https://m.yolo247.site/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "specialMarket": {
            "title": "Explore Special Market Betting at Yolo247 India | Win Big!",
            "description": "Discover thrilling Special Market betting options at Yolo247 India! Bet on unique events and enjoy exciting opportunities to win big. Place your bet now! 🎯💰",
            "canonical": "https://www.yolo247.site/specialMarket",
            "alternate": "https://m.yolo247.site/specialMarket"
        },
        "virtualSports": {
            "title": "Bet on Virtual Cricket In-Play at Yolo247 India",
            "description": "Bet on exciting virtual cricket matches in real-time with Virtual Cricket In-Play at Yolo247 India. Enjoy thrilling action and instant wins! 🏏💥",
            "canonical": "https://www.yolo247.site/virtualSports/vci",
            "alternate": "https://m.yolo247.site/virtualSports/vci"
        },
        "virtualSportsBL": {
            "title": "Virtual Basketball League Betting | Yolo247 India",
            "description": "Bet on Virtual Basketball League matches at Yolo247 India! Enjoy fast-paced basketball action and win big with every play. 🏀💥",
            "canonical": "https://www.yolo247.site/virtualSports/vbl",
            "alternate": "https://m.yolo247.site/virtualSports/vbl"
        },
        "virtualSportsFB": {
            "title": "Virtual Football Bundesliga Betting at Yolo247 India",
            "description": "Bet on virtual Bundesliga football matches with high-speed action at Yolo247 India! Experience top teams and instant betting results. ⚽🔥",
            "canonical": "https://www.yolo247.site/virtualSports/vfb",
            "alternate": "https://m.yolo247.site/virtualSports/vfb"
        },
        "virtualSportsFEL": {
            "title": "Virtual Football English League Betting | Yolo247 India",
            "description": "Bet on virtual football in the English League at Yolo247 India. Fast action, top teams, and instant rewards await you! ⚽🏅",
            "canonical": "https://www.yolo247.site/virtualSports/vfel",
            "alternate": "https://m.yolo247.site/virtualSports/vfel"
        },
        "virtualSportsFEC": {
            "title": "Bet on Virtual Football Euro Cup | Yolo247 India",
            "description": "Bet on Virtual Football Euro Cup at Yolo247 India. Watch fast-action football matches and predict the winner for quick wins! 🏆⚽",
            "canonical": "https://www.yolo247.site/virtualSports/vfec",
            "alternate": "https://m.yolo247.site/virtualSports/vfec"
        },
        "virtualSportsVTI": {
            "title": "Bet on Virtual Tennis In-Play | Yolo247 India",
            "description": "Bet on Virtual Tennis In-Play at Yolo247 India. Watch live tennis matches and place your bets for exciting wins and fast payouts! 🎾🔥",
            "canonical": "https://www.yolo247.site/virtualSports/vti",
            "alternate": "https://m.yolo247.site/virtualSports/vti"
        },
        "virtualSportsWC": {
            "title": "Bet on Virtual Football World Cup | Yolo247 India",
            "description": "Experience the thrill of virtual football World Cup matches at Yolo247 India. Bet on the best teams and win big in this fast-paced environment. 🌍⚽",
            "canonical": "https://www.yolo247.site/virtualSports/vfwc",
            "alternate": "https://m.yolo247.site/virtualSports/vfwc"
        },
        "slotGames": {
            "title": "Casino Slot Games | Play Slot Games for Real Money on Yolo247",
            "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
            "canonical": "https://www.yolo247.site/slots",
            "alternate": "https://m.yolo247.site/slots",
            "keywords": "slot games for real money, casino slot games"
        },
        "aviator": {
            "title": "Play Aviator Game Online | Win Real Money on Aviator",
            "description": "Aviator game online at its best on Yolo247! Play Aviator online & Bet with INR payments. Win real money at the best Aviator betting site in India.",
            "canonical": "https://www.yolo247.site/instant-games/spribe/aviator",
            "alternate": "https://m.yolo247.site/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game "
        },
        "cashshow": {
            "title": "Play Cash Show Instant Game by Galaxys | Yolo247 India",
            "description": "🎤 Play Cash Show Instant Game by Galaxys at Yolo247 India! Spin, guess, win cash prizes instantly! 💰🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/cash-show",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/cash-show"
        },
        "jetx": {
            "title": "Play JetX Instant Game by Smartsoft | Yolo247 India",
            "description": "🚀 Play JetX Instant Game by Smartsoft at Yolo247 India! Enjoy fast-paced action and big wins. Start your adventure today! 🎮💰",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/jetx",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/jetx"
        },
        "rockpaperscissors": {
            "title": "Play Rock Paper Scissors Instant Game by STP | Yolo247 India",
            "description": "Play the classic game of Rock Paper Scissors Instant Game by STP! Challenge your luck and win exciting rewards at Yolo247 India! ✊✋✌️",
            "canonical": "https://www.yolo247.site/instant-games/stp/rock-paper-scissors",
            "alternate": "https://m.yolo247.site/instant-games/stp/rock-paper-scissors"
        },
        "plinkox": {
            "title": "Play PlinkoX casino games | Top Instant Games on Yolo247",
            "description": "Play Plinkox Casino game online on Yolo247. PlinkoX online game provides higher payout and smoother gameplay. Play for real money online. Get the best experience.",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/plinkox",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/plinkox"
        },
        "footballx": {
            "title": "Play FootballX Crash Game Online | Play Instant Game",
            "description": "Footballx casino game offers quality gameplay among all instant games. Play online footballx game for real money. Get 24x7 customer support and faster withdrawal.",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/footballx",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/footballx"
        },
        "balloon": {
            "title": "Balloon crash games | Play for real money",
            "description": "Balloon casino game is one of the popular online instant games on Yolo247. Play Balloon for real money & win exciting rewards instantly.",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/balloon",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/balloon"
        },
        "mines": {
            "title": "Mines Game Online | Play Instant Game for real cash",
            "description": "Mines game online on Yolo247 is popular among online instant games. Simple rules and faster gameplay are the reason for its popularity. Play Mines casino game now!",
            "canonical": "https://www.yolo247.site/instant-games/spribe/mines",
            "alternate": "https://m.yolo247.site/instant-games/spribe/mines"
        },
        "macludo": {
            "title": "Macludo Casino Games Online - Play & Win 24/7",
            "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
            "canonical": "https://www.yolo247.site/live-casino/macludo",
            "alternate": "https://m.yolo247.site/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online"
        },
        "crazytime": {
            "title": "Play Crazy Time game online For Real money on yolo247",
            "description": "Crazy Time casino game is the best wheel-based casino game on Yolo247. Play Crazy time game online, go crazy with higher winnings and faster game process.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/crazy-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online"
        },
        "welcomeBonus": {
            "title": "Yolo247 Welcome Bonus 🎉 | 400% Bonus on Your First Deposit",
            "description": "We're excited to have you with us! Enjoy a 400% welcome bonus on your first deposit, with no maximum limit. Read below to learn how to claim your bonus! 😊",
            "canonical": "https://www.yolo247.site/promotions/welcome-bonus",
            "alternate": "https://m.yolo247.site/promotions/welcome-bonus",
            "keywords": "Welcome Bonus"
        },
        "premiumSportsbook": {
            "title": "Premium Sportsbook at Yolo247 India | Exclusive Betting",
            "description": "Experience exclusive sports betting with Yolo247 India’s Premium Sportsbook. Bet on top events and maximize your wins! 🏅💸",
            "canonical": "https://www.yolo247.site/premium-sportsbook",
            "alternate": "https://m.yolo247.site/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
        },
        "refillBonus": {
            "title": "Yolo247 Refill Bonus 🎁 | Get Up to 5% on Every Deposit",
            "description": "Enjoy a refill bonus of up to 5% on every deposit after your first one. Log in to your Yolo247 account and receive the bonus instantly in your main wallet!",
            "canonical": "https://www.yolo247.site/promotions/refill-bonus",
            "alternate": "https://m.yolo247.site/promotions/refill-bonus",
            "keywords": "Refill bonus"
        },
        "cashbackBonus": {
            "title": "Yolo247 5% Cashback Bonus | Weekly Losses Refunded Instantly",
            "description": "Yolo247 offers a 5% cashback bonus 🎉, instantly credited to your wallet on your weekly losses. Enjoy more play and rewards every week! 💰",
            "canonical": "https://www.yolo247.site/promotions/cashback-bonus",
            "alternate": "https://m.yolo247.site/promotions/cashback-bonus",
            "keywords": "Cashback Bonus"
        },
        "referralBonus": {
            "title": "Yolo247 Referral Bonus 🎁 | Refer-A-Friend & Earn Together",
            "description": "Introducing Yolo247's Refer-A-Friend program! Enjoy the best online betting & casino referral bonuses. Both new and existing users can benefit!",
            "canonical": "https://www.yolo247.site/promotions/referral-bonus",
            "alternate": "https://m.yolo247.site/promotions/referral-bonus",
            "keywords": "Referral Bonus"
        },
        "termsandconditions": {
            "title": "Yolo247 Terms & Conditions | Official Site India",
            "description": "Review the terms and conditions of using Yolo247 for sports betting, casino games, and real money gambling. Ensure a safe and enjoyable experience!",
            "canonical": "https://www.yolo247.site/terms-conditions",
            "alternate": "https://m.yolo247.site/about/terms-conditions"
        },
        "responsibleGambling": {
            "title": "Yolo247 Responsible Gambling | Play Safely & Within Limits",
            "description": "Learn about our commitment to responsible gambling practices, ensuring a safe and enjoyable experience for all users on Yolo247",
            "canonical": "https://www.yolo247.site/responsible-gambling",
            "alternate": "https://m.yolo247.site/about/responsible-gambling",
            "keywords": "Responsible Gambling"
        },
        "privacyPolicy": {
            "title": "Yolo247 Privacy Policy | Best Online Betting Site in India",
            "description": "Privacy Policy reading is always integral in the process of betting. Go through definitions and types of data collected. Click to know more!",
            "canonical": "https://www.yolo247.site/privacy-policy",
            "alternate": "https://m.yolo247.site/about/privacy-policy",
            "keywords": "privacy policy"
        },
        "kycPolicy": {
            "title": "Yolo247 KYC Policy | Verify Your Identity for Safe Betting",
            "description": "Understand Yolo247's KYC policy to ensure secure and responsible sports betting and casino gaming while protecting your account",
            "canonical": "https://www.yolo247.site/kyc-policy",
            "alternate": "https://m.yolo247.site/about/kyc-policy"
        },
        "exclusionPolicy": {
            "title": "Yolo247 Exclusion Policy | Ensuring Safe Betting Practices",
            "description": "Learn about Yolo247's Exclusion Policy, designed to promote responsible gambling by providing users with options to limit or exclude their betting activities",
            "canonical": "https://www.yolo247.site/exclusion-policy",
            "alternate": "https://m.yolo247.site/about/self-exclusion-policy "
        },
        "amlPolicy": {
            "title": "Anti-Money Laundering Policy | Yolo247 Official site India",
            "description": "Review Yolo247’s Anti-Money Laundering (AML) policy to understand how we prevent illegal financial activities & ensure a secure, transparent betting environment",
            "canonical": "https://www.yolo247.site/aml-policy",
            "alternate": "https://m.yolo247.site/about/aml-policy "
        },
        "aboutus": {
            "title": "About Yolo247 | Leading Sports Betting & Casino Platform",
            "description": "Discover Yolo247, your trusted platform for sports betting, casino games, & real money gambling. Learn about our mission, values, and commitment to safe gaming",
            "canonical": "https://www.yolo247.site/about-us",
            "alternate": "https://m.yolo247.site/about/about-us"
        },
        "SPRIBE": {
            "title": "Play Spribe Instant Games at Yolo247 India | Exciting Fun",
            "description": "Enjoy fast-paced gameplay with Spribe Instant Games at Yolo247 India! Spin, win, and experience fun-filled action in every game. Play now and win big! 🎰✨",
            "canonical": "https://www.yolo247.site/instant-games/spribe",
            "alternate": "https://m.yolo247.site/instant-games/spribe",
            "keywords": "spribe casino games"
        },
        "aviatrix": {
            "title": "Aviatrix Game | Play at Yolo247 & Win Real Money Online",
            "description": "Yolo247 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
            "canonical": "https://www.yolo247.site/instant-games/aviatrix",
            "alternate": "https://m.yolo247.site/instant-games/aviatrix",
            "keywords": "Aviatrix casino games"
        },
        "7MOJOS": {
            "title": "Play 7 Mojos Live Casino Games at Yolo247 India 🎲✨",
            "description": "Experience 7 Mojos Live Casino at Yolo247 India! Play exciting games like Andar Bahar, Teen Patti, and more with live dealers and big wins. 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/7mojos",
            "alternate": "https://m.yolo247.site/live-casino/7mojos",
            "keywords": "7Mojos Games"
        },
        "supernowa": {
            "title": "Play Supernowa Live Casino Games at Yolo247 India 🎮✨",
            "description": "Experience Supernowa Live Casino at Yolo247 India! Play exciting games like Andar Bahar, Teen Patti, and more with live dealers and big wins. 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/supernowa",
            "alternate": "https://m.yolo247.site/live-casino/supernowa"
        },
        "pragmatic": {
            "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
            "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
            "canonical": "https://www.yolo247.site/slots/pragmatic-play",
            "alternate": "https://m.yolo247.site/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games"
        },
        "superspade": {
            "title": "Play Super Spade Live Casino games at Yolo247 India! 🎲💰",
            "description": "Enjoy Super Spade Live Casino at Yolo247 India! Play exciting games like Andar Bahar, Roulette, and Teen Patti with real-time action and big wins. 🎲💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/super-spade",
            "alternate": "https://m.yolo247.site/live-casino/super-spade",
            "keywords": "super spade games,play super spade casino games, super spade casino"
        },
        "tvbet": {
            "title": "Play TV Bet Live Casino Games Online | Yolo247 India 🎲✨",
            "description": "Experience TV Bet Live Casino at Yolo247 India! Play Keno, Poker, Lucky 6 & Wheel Bet with real-time action and big rewards. Join the excitement! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/tvbet",
            "alternate": "https://m.yolo247.site/live-casino/tvbet"
        },
        "netent": {
            "title": "Play Netent Slots Online at Yolo247 India | Thrilling Wins!",
            "description": "Enjoy the excitement of NetEnt slots online at Yolo247 India. Amazing graphics, innovative gameplay, and big rewards await!",
            "canonical": "https://www.yolo247.site/slots/netent",
            "alternate": "https://m.yolo247.site/slots/netent"
        },
        "betgames": {
            "title": "Bet Games at Yolo247 | Real Money Games with Live Action",
            "description": "Experience the Thrilling Casino Games of Bet Games on Yolo247 🎯. Bet on live casino games for real money and enjoy the thrill of winning big with live dealers",
            "canonical": "https://www.yolo247.site/live-casino/betgames",
            "alternate": "https://m.yolo247.site/live-casino/betgames"
        },
        "sportsbook": {
            "title": "Bet on top sports with Online Sportsbook at Yolo247 India.",
            "description": "Bet on top sports with Online Sportsbook at Yolo247 India! Enjoy thrilling sports action and place your bets on your favorite teams for exciting wins. 🏅🎯",
            "canonical": "https://www.yolo247.site/sportsbook",
            "alternate": "https://m.yolo247.site/sportsbook",
            "keywords": "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
        },
        "appDownload": {
            "title": "Yolo247 App Download | Best Indian Betting App for Sports",
            "description": "Download the Yolo247 app today to enjoy seamless sports betting, casino games, and real money gambling, all in the palm of your hand! 📱🎰",
            "canonical": "https://www.yolo247.site/app-download",
            "alternate": "https://m.yolo247.site/app-download",
            "keywords": "Yolo App Download, Yolo Android App"
        },
        "signup": {
            "title": "Register Yolo247 | Join India's Top Sports Betting & Casino Site",
            "description": "Register on Yolo247 today to bet on sports, play casino games, earn bonuses, and enjoy real money gambling on a trusted platform. Register, play, bet & win big!",
            "canonical": "https://www.yolo247.site/signup",
            "alternate": "https://m.yolo247.site/signup"
        },
        "faq": {
            "title": "Yolo247 FAQ | Answers to Your Betting & Casino Questions",
            "description": "Find answers to common questions about sports betting, casino games, account management, deposits, withdrawals, and responsible gambling at Yolo247",
            "canonical": "https://www.yolo247.site/faq",
            "alternate": "https://m.yolo247.site/faq"
        },
        "affiliate": {
            "title": "Yolo247 Affiliate Program | Earn with Betting & Casino",
            "description": "Join the Yolo247 Affiliate Program to earn commissions by promoting our sports betting and casino platform. Start earning today with our trusted network!",
            "canonical": "https://www.yolo247.site/affiliate",
            "alternate": "https://m.yolo247.site/affiliate"
        },
        "loyalty": {
            "title": "Loyalty Program by Yolo247 India | Earn Rewards & Win Big",
            "description": "Join Yolo247's Loyalty Program to earn exclusive rewards, bonuses, and benefits for your continued play in sports betting and casino games",
            "canonical": "https://www.yolo247.site/loyalty",
            "alternate": "https://m.yolo247.site/loyalty"
        },
        "login": {
            "title": "Yolo247 Login | Access Online Casino & Sports Betting India",
            "description": "Log in to your Yolo247 account to bet on sports, play casino games, and enjoy real money gambling on a trusted platform. Your winning journey starts here! 🏆",
            "canonical": "https://www.yolo247.site/login",
            "alternate": "https://m.yolo247.site/login"
        },
        "instantGames": {
            "title": "Instant Games | Play Best Instant Casino Online on Yolo247",
            "description": "Instant Games on Yolo247 are always on demand. Play all your favourite instant casino games online on your favourite betting platform.",
            "canonical": "https://www.yolo247.site/instant-games",
            "alternate": "https://m.yolo247.site/instant-games",
            "keywords": ""
        },
        "majorLeagueCricketBetting": {
            "title": "MLC Betting in India | Major League Cricket Betting Online",
            "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
            "canonical": "https://www.yolo247.site/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.yolo247.site/sports/tournaments/major-league-cricket-betting",
            "keywords": "Major League Cricket Betting Online, MLC Betting in India"
        },
        "dailyDepositBonus": {
            "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
            "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
            "canonical": "https://www.yolo247.site/daily-deposit-bonus",
            "alternate": "https://m.yolo247.site/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "popular-games": {
            "title": "Best Casino Games | Online Casino Real Money on Yolo247",
            "description": "Best casino games on Yolo247 provide the best online gambling experience. Choose any of the top casino games & play online casino real money. Get free demo!",
            "canonical": "https://www.yolo247.site/popular-games",
            "alternate": "https://m.yolo247.site/popular-games",
            "keywords": "Best Casino Games Popular Casino Games, online casino real money"
        },
        "evolution": {
            "title": "Yolo247 Evolution Live Casino 🎰 | Play Real Money Games",
            "description": "Experience top-tier gaming with Evolution at Yolo247’s Live Casino. Play real money online casino games with live dealers for an immersive, thrilling experience!",
            "canonical": "https://www.yolo247.site/live-casino/evolution",
            "alternate": "https://m.yolo247.site/live-casino/evolution"
        },

        "spribe": {
            "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
            "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
            "canonical": "https://www.yolo247.site/instant-games/spribe",
            "alternate": "https://m.yolo247.site/instant-games/spribe",
            "keywords": "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
        },
        "cricketx": {
            "title": "Play CricketX Game Online | Play Instant Game for Real Cash",
            "description": "Cricketx casino game offers quality gameplay among all instant games. Play online Cricketx game for real money. Best combination of sports & casino!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/cricketx",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/cricketx"
        },
        "ezugi": {
            "title": "Play Ezugi Live Casino Games Online | Yolo247 India",
            "description": "Enjoy thrilling Ezugi Live Casino games at Yolo247 🌟. Play real money classics like Andar Bahar, Blackjack, and Teen Patti with live casino action!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi",
            "alternate": "https://m.yolo247.site/live-casino/ezugi",
            "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
        },
        "sexygaming": {
            "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
            "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
            "canonical": "https://www.yolo247.site/live-casino/sexy-gaming",
            "alternate": "https://m.yolo247.site/live-casino/sexy-gaming",
            "keywords": "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
        },
        "monopolylive": {
            "title": "Monopoly Game Online | Explore monoply live casino game on yolo247",
            "description": "Monopoly game online has recently gained popularity, as it has added both offline board game & wheel game features. Play Monopoly casino game now!",
            "canonical": "https://www.yolo247.site/live-casino/monopoly-live",
            "alternate": "https://m.yolo247.site/live-casino/monopoly-live",
            "keywords": "MONOPOLY LIVE"
        },
        "funkytime": {
            "title": "Play Funky time Casino Game By Evolution Gaming on Yolo247",
            "description": "Funky Time casino game takes us back to the retro gaming mode with the modern online casino touch on Yolo247. Play Funky time online now.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/funky-time",
            "keywords": "funkytime"
        },
        "lightningdice": {
            "title": "Play Lightning Dice | Play Instant Games for Real Cash",
            "description": "Lightning dice casino game online on Yolo247 is simple to learn & easy to master with Higher payout & faster gameplay, Play Lightning dice for real money.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/lightning-dice",
            "alternate": "https://m.yolo247.site/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice"
        },
        "macaw": {
            "title": "Play Macaw Live Casino Games at Yolo247 India 🎮✨",
            "description": "Experience Macaw Live Casino at Yolo247 India! Play exciting games like Mac Ludo, Hot Balloon, and more with live dealers and big rewards. 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/macaw",
            "alternate": "https://m.yolo247.site/live-casino/macaw"
        },
        "ag": {
            "title": "Amazing Gaming Live Casino | Real Money Games on Yolo247",
            "description": "Yolo247 brings you Amazing Gaming 🎲. Play live casino games, win real money, and enjoy premium gaming with trusted dealers and nonstop action!",
            "canonical": "https://www.yolo247.site/live-casino/ag",
            "alternate": "https://m.yolo247.site/live-casino/ag"
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.yolo247.site/asia-cup-bonanza",
            "alternate": "https://m.yolo247.site/asia-cup-bonanza"
        },

        "bingo": {
            "title": "Play Online Bingo Games on Yolo247 Casino with real money",
            "description": "Online Bingo games on Yolo247 allow you to play and place bets with INR payments. All types of Bingo games are available. Play now!",
            "canonical": "https://www.yolo247.site/live-casino/bingo",
            "alternate": "https://m.yolo247.site/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "Funkytime": {
            "title": "Play Funky Time Casino Online Game at Yolo247",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "evo": {
            "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
            "canonical": "https://www.yolo247.site/live-casino/evo",
            "alternate": "https://m.yolo247.site/live-casino/evo",
            "keywords": "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
        },
        "nlc": {
            "title": "Play No Limit City Slots at Yolo247 India | Win Real Money",
            "description": "Explore No Limit City slot games at Yolo247 India. Enjoy thrilling spins, innovative features, and win big rewards. Start your real money adventure today! 🎰💰",
            "canonical": "https://www.yolo247.site/slots/no-limit-city",
            "alternate": "https://m.yolo247.site/slots/no-limit-city",
            "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
        },
        "Funky Time": {
            "title": "Funky Time Online | Play for Free or Real Money",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "First-Person-Blackjack": {
            "title": "Play Blackjack Live Casino Games | Free or Real Money",
            "description": "Play blackjack online on the most safest online casino. Register now at Yolo247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
            "canonical": "https://www.yolo247.site/live-casino/first%20person%20blackjack",
            "alternate": "https://m.yolo247.site/live-casino/first%20person%20blackjack",
            "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
        },
        "teenpatti2020-2020": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti 2020 is a popular casino card game on Yolo247. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.yolo247.site/live-casino/teenpatti%2020%2020",
            "alternate": "https://m.yolo247.site/live-casino/teenpatti%2020%2020",
            "keywords": "Teenpatti 2020"
        },
        "teenpatti": {
            "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
            "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on Yolo247 and earn real cash. Betting on teen patti game made easy!",
            "canonical": "https://www.yolo247.site/live-casino/teenpatti",
            "alternate": "https://m.yolo247.site/live-casino/teenpatti",
            "keywords": "Teenpatti"
        },
        //member dashboard pages
        "memberDashboard": {
            "canonical": "https://www.yolo247.site/member/profile",
            "alternate": "https://m.yolo247.site/member/profile"
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.yolo247.site/member/profile/accountinfo",
            "alternate": "https://m.yolo247.site/member/profile/accountinfo"
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.yolo247.site/member/profile/password",
            "alternate": "https://m.yolo247.site/member/profile/password"
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.yolo247.site/member/profile/bankdetails",
            "alternate": "https://m.yolo247.site/member/profile/bankdetails"
        },
        "memberProfileKYC": {
            "canonical": "https://www.yolo247.site/member/profile/kycpage",
            "alternate": "https://m.yolo247.site/member/profile/kycpage"
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.yolo247.site/member/finance/deposit",
            "alternate": "https://m.yolo247.site/member/finance/deposit"
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.yolo247.site/member/finance/withdrawal",
            "alternate": "https://m.yolo247.site/member/finance/withdrawal"
        },
        "memberFinanceHistory": {
            "canonical": "https://www.yolo247.site/member/finance/history",
            "alternate": "https://m.yolo247.site/member/finance/history"
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.yolo247.site/member/finance/transfer",
            "alternate": "https://m.yolo247.site/member/finance/transfer"
        },
        "memberMyBets": {
            "canonical": "https://www.yolo247.site/member/betDetails/mybets",
            "alternate": "https://m.yolo247.site/member/betDetails/mybets"
        },
        "memberP&L": {
            "canonical": "https://www.yolo247.site/member/betDetails/profitandloss",
            "alternate": "https://m.yolo247.site/member/betDetails/profitandloss"
        },
        "memberCasinoStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/casinostatement",
            "alternate": "https://m.yolo247.site/member/betDetails/casinostatement"
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/sportbookstatement",
            "alternate": "https://m.yolo247.site/member/betDetails/sportbookstatement"
        },
        "memberSTPStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/splitThePotStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/splitThePotStatement"
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/galaxyStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/galaxyStatement"
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/aviatrixStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/aviatrixStatement"
        },
        "memberEvoPlayStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/evoplayStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/evoplayStatement"
        },
        "memberJiliStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/jiliStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/jiliStatement"
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/turbogamesStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/turbogamesStatement"
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/indiaLotteryStatement"
        },
        "memberAccountStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/accountStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/accountStatement"
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/virtualSportsStatement"
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.yolo247.site/member/betDetails/turnoverStatement",
            "alternate": "https://m.yolo247.site/member/betDetails/turnoverStatement"
        },
        "memberPrormotions": {
            "canonical": "https://www.yolo247.site/member/promotions",
            "alternate": "https://m.yolo247.site/member/promotions"
        },
        "memberMessages": {
            "canonical": "https://www.yolo247.site/member/message",
            "alternate": "https://m.yolo247.site/member/message"
        },
        "ninjacrash": {
            "title": "Play Ninja Crash Instant Game by Galaxys | Yolo247 India",
            "description": "🥷 Play Ninja Crash Instant Game by Galaxys at Yolo247 India! Crash through for big rewards! 💥🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/ninja-crash"
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.yolo247.site/promotions/big-bash",
            "alternate": "https://m.yolo247.site/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "lucky-fruits-lines": {
            "canonical": "https://www.yolo247.site/slots/lucky-fruits-lines",
            "alternate": "https://m.yolo247.site/slots/lucky-fruits-lines"
        },
        "andar-bahar": {
            "title": "Top Andar Bahar Games | Choose your Favourite on Yolo247",
            "description": "More than 16 Andar Bahar games to select. Choose your favourite version of Andar bahar and start winning. Play Now!",
            "canonical": "https://www.yolo247.site/live-casino/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/andar-bahar"
        },
        "dream-catcher": {
            "title": "Play Dream Catcher by Evolution Live Casino | Yolo247 India",
            "description": "Spin the wheel of fortune with Dream Catcher by Evolution at Yolo247 India. Win big with this exciting live casino game and real-time rewards!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/dream-catcher",
            "alternate": "https://m.yolo247.site/live-casino/evolution/dream-catcher"
        },
        "hi-lo": {
            "title": "Play Hilo Instant Game by Spribe | Yolo247 India 🎲",
            "description": "Guess high or low with Hilo Instant Game by Spribe! Test your luck, make the right call, and win big today at Yolo247 India! 🍀🎯 Try your luck now!",
            "canonical": "https://www.yolo247.site/instant-games/spribe/hi-lo",
            "alternate": "https://m.yolo247.site/instant-games/spribe/hi-lo"
        },
        "rocketon": {
            "title": "Play Rocketon Game Online at Yolo247 | Win Real Money Online",
            "description": "Rocketon Online Game for Real Money is now available on Yolo247! Play Rocketon Game now & win exciting rewards. Instant withdrawal & all the betting options.",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/rocketon",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/rocketon"
        },
        "burning-ice": {
            "title": "Play Burning Ice Instant Game By Smartsoft | Yolo247 India",
            "description": "Enjoy the thrilling Burning Ice Instant Game by Smartsoft at Yolo247. Play now for fast-paced action and win real money with every spin!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/burning-ice",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/burning-ice"
        },
        "cappa-docia": {
            "title": "Play Cappadocia Instant Game by Smartsoft | Yolo247 India",
            "description": "🏞️ Play Cappadocia Instant Game by Smartsoft at Yolo247 India! Explore stunning landscapes and win rewards in this thrilling adventure. Play now! 🌄🎮",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/cappadocia"
        },
        "genies-bonanza": {
            "title": "Play Genies Bonanza Instant Game By Smartsoft | Yolo247 India",
            "description": "Unlock magical wins with Genies Bonanza Instant Game by Smartsoft on Yolo247. Play now for instant rewards and thrilling gameplay!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/genies-bonanza",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/genies-bonanza"
        },
        "goal": {
            "title": "Play Goal Instant Game by Spribe | Yolo247 India ⚽",
            "description": "Score big with Goal Instant Game by Spribe! Take your shot and aim for the net to win massive rewards. Play now at Yolo247 India and score your victory! 🥅🎯",
            "canonical": "https://www.yolo247.site/instant-games/spribe/goal",
            "alternate": "https://m.yolo247.site/instant-games/spribe/goal"
        },
        "hunter-x": {
            "title": "Play HunterX Instant Game By Smartsoft | Yolo247 India",
            "description": "Hunt for big wins in HunterX Instant Game by Smartsoft at Yolo247. Fast, exciting gameplay with real money rewards at every turn!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/hunter-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/hunter-x"
        },
        "jungles": {
            "title": "Play Jungles Instant Game By Smartsoft | Yolo247 India",
            "description": "Venture into the wild with Jungles Instant Game by Smartsoft at Yolo247. Play now for exciting gameplay and quick cash rewards!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/jungles",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/jungles"
        },
        "magic-garden": {
            "canonical": "https://www.yolo247.site/instant-games/magic-garden",
            "alternate": "https://m.yolo247.site/instant-games/magic-garden"
        },
        "multi-hot-ways": {
            "title": "Play Multi Hot Ways Instant Game By Smartsoft | Yolo247 India",
            "description": "Feel the heat with Multi Hot Ways Instant Game by Smartsoft at Yolo247. Play for real money rewards in this fast-paced, exciting game!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/multi-hot-ways",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/multi-hot-ways"
        },
        "paper-plane": {
            "title": "Play Paper Plane Instant Game by STP Gaming | Yolo247 India",
            "description": "Fly high with Paper Plane Instant Game by STP! Soar through the skies and win amazing rewards at Yolo247 India today! ✈️📈",
            "canonical": "https://www.yolo247.site/instant-games/stp/paper-plane",
            "alternate": "https://m.yolo247.site/instant-games/stp/paper-plane"
        },
        "stockmarket": {
            "title": "Play Stock Market Instant Game by Evolution | Yolo247 India",
            "description": "Trade your way to victory with Stock Market Instant Game by Evolution at Yolo247 India! Buy, sell, and win big in this exciting game. 📈💰",
            "canonical": "https://www.yolo247.site/instant-games/evolution/stock-market",
            "alternate": "https://m.yolo247.site/instant-games/evolution/stock-market"
        },
        "rulesRgulations": {
            "canonical": "https://www.yolo247.site/rules-regulations",
            "alternate": "https://m.yolo247.site/about/rules-regulations"
        },
        "teen-patti": {
            "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
            "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on Yolo247 and earn real cash. Betting on teen patti game made easy!",
            "canonical": "https://www.yolo247.site/live-casino/teen-patti",
            "alternate": "https://m.yolo247.site/live-casino/teen-patti"
        },
        "bingo-roll": {
            "title": "Play Bingo Roll by Kingmaker Online Casino | Yolo247 India",
            "description": "Enjoy real-time action with Bingo Roll by Kingmaker and win exciting prizes. Experience the thrill of the game at Yolo247 India. Bet now and start winning!",
            "canonical": "https://www.yolo247.site/live-casino/kingmaker/bingo-roll",
            "alternate": "https://m.yolo247.site/live-casino/kingmaker/bingo-roll",
            "keywords": ""
        },
        "dota-hi-lo": {
            "title": "Enjoy Dota Hi Lo by Kingmaker Live Casino | Yolo247 India",
            "description": "Dive into the action with Dota Hi Lo by Kingmaker at Yolo247 India. Bet your luck and win big with thrilling gameplay and real-time action. Play now and win!",
            "canonical": "https://www.yolo247.site/live-casino/kingmaker/dota-hi-lo",
            "alternate": "https://m.yolo247.site/live-casino/kingmaker/dota-hi-lo",
            "keywords": ""
        },
        "cappadocia": {
            "title": "Play Cappadocia Instant Game by Smartsoft | Yolo247 India",
            "description": "🏞️ Play Cappadocia Instant Game by Smartsoft at Yolo247 India! Explore stunning landscapes and win rewards in this thrilling adventure. Play now! 🌄🎮",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/cappadocia",
            "keywords": ""
        },
        "stock-market": {
            "title": "Play Stock Market Instant Game by Evolution | Yolo247 India",
            "description": "Trade your way to victory with Stock Market Instant Game by Evolution at Yolo247 India! Buy, sell, and win big in this exciting game. 📈💰",
            "canonical": "https://www.yolo247.site/instant-games/evolution/stock-market",
            "alternate": "https://m.yolo247.site/instant-games/evolution/stock-market"
        },
        "sponsorships": {
            "title": "Yolo247 Sponsorships",
            "description": "Yolo247 Sponsorships",
            "canonical": "https://www.yolo247.site/sponsors",
            "alternate": "https://m.yolo247.site/sponsors",
            "keywords": ""
        },
        "play-aviatrix": {
            "title": "Play Aviatrix Game at Yolo247 | Win Real Money Online Now",
            "description": "Play Aviatrix game at Yolo247 and enjoy fast-paced, high-reward action. Win real money online with every strategic decision. Join now for instant thrills!",
            "canonical": "https://www.yolo247.site/instant-games/aviatrix/play-aviatrix",
            "alternate": "https://m.yolo247.site/instant-games/aviatrix/play-aviatrix",
            "keywords": ""
        },
        "turbo-mines": {
            "title": "Play Turbo Mines Instant Game by Galaxys | Yolo247 India",
            "description": "⛏️ Play Turbo Mines Instant Game by Galaxys at Yolo247 India! Dig fast, win big rewards! 💎🎉",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/turbo-mines",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/turbo-mines",
            "keywords": ""
        },
        "turbo-mines-turbo": {
            "title": "Play Turbo Mines Instant Game by Turbo Games  | Yolo247 India",
            "description": "Explore the world of Turbo Mines on Yolo247 India, where each choice can lead to thrilling rewards",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/turbo-mines",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/turbo-mines",
            "keywords": ""
        },
        "turbo-games": {
            "title": "Play Instant Turbo Games at Yolo247 India | Thrilling Fun",
            "description": "Explore exciting Turbo Games Instant Games at Yolo247 India! Enjoy immersive gameplay and win big with various fun-filled options. 🎮💥 Play now and win instantly!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games",
            "keywords": ""
        },
        "aero": {
            "title": "Play Aero Instant Game by Turbo Games  | Yolo247 India",
            "description": "Soar to new heights in Aero by Turbo Games on Yolo247 India. Fast-paced flying action and big wins!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/aero",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/aero",
            "keywords": ""
        },
        "dice-thrice": {
            "title": "Play Dice Thrice Instant Game by Turbo Games  | Yolo247 India",
            "description": "Roll the dice three times in Dice Thrice by Turbo Games on Yolo247 India. Fast, rewarding action!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/dice-thrice",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/dice-thrice",
            "keywords": ""
        },
        "mines-turbo": {
            "title": "Play Mines Instant Game by Turbo Games  | Yolo247 India",
            "description": "Test your luck with Mines by Turbo Games on Yolo247 India. Find treasures while avoiding hidden mines!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/mines",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/mines",
            "keywords": ""
        },
        "vortex": {
            "title": "Play Vortex Instant Game by Turbo Games  | Yolo247 India",
            "description": "Dive into the vortex for thrilling wins in Vortex by Turbo Games on Yolo247 India. Quick gameplay and rewards await!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/vortex",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/vortex",
            "keywords": ""
        },
        "blackjack": {
            "title": "Play Live Blackjack Online | Yolo247 Live Casino",
            "description": "Test your strategy with live blackjack at Yolo247. Enjoy interactive gameplay with live dealers in real-time. Join now for your chance to win big!",
            "canonical": "https://www.yolo247.site/live-casino/blackjack",
            "alternate": "https://m.yolo247.site/live-casino/blackjack",
            "keywords": ""
        },
        "ezugiBlackjack": {
            "title": "Play Black Jack by Ezugi Live Casino | Yolo247 India",
            "description": "Join Black Jack by Ezugi Live Casino at Yolo247 🃏. Play against live dealers, use your skills, and win real money in this exciting live casino game.",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/blackjack",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/blackjack",
            "keywords": ""
        },
        "dice-games": {
            "title": "Play Instant Dice Games | Yolo247 India",
            "description": "Roll the dice and win instantly with Yolo247's instant dice games. Test your luck and win real money today!",
            "canonical": "https://www.yolo247.site/instant-games/dice-games",
            "alternate": "https://m.yolo247.site/instant-games/dice-games"
        },
        "32-cards": {
            "title": "Play 32 Cards by Ezugi Live Casino | Yolo247 India",
            "description": "Experience the thrill of 32 Cards by Ezugi Live Casino 🃏. Play this unique live card game at Yolo247 and win real money with every bet!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/32-cards",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/32-cards",
            "keywords": ""
        },
        "ezugi-andarbahar": {
            "title": "Play Andar Bahar by Ezugi Live Casino | Yolo247 India",
            "description": "Bet on Andar Bahar by Ezugi Live Casino at Yolo247 🎲. Enjoy this classic Indian card game with live dealers and win real money instantly.",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/andar-bahar",
            "keywords": ""
        },
        "bet-on-number": {
            "title": "Play Bet on Number by Ezugi Live Casino | Yolo247 India",
            "description": "Play Bet on Number by Ezugi Live Casino 🎲. Place your bets, enjoy live dealer gameplay, and win real money at Yolo247—India’s trusted gaming platform!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/bet-on-number",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/bet-on-number",
            "keywords": ""
        },
        "dragon-tiger-table": {
            "title": "Play Dragon Tiger Table by Ezugi Live Casino | Yolo247 India",
            "description": "Experience the excitement of Dragon Tiger with live dealers at Yolo247. Bet on the Dragon or Tiger for fast-paced action and big wins in this classic Ezugi game.",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/dragon-tiger-table",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/dragon-tiger-table",
            "keywords": ""
        },
        "roulette": {
            "title": "Play Live Roulette Online | Yolo247 Live Casino",
            "description": "Experience the excitement of live roulette at Yolo247. Spin the wheel and enjoy real-time gameplay with professional dealers. Join now and play today!",
            "canonical": "https://www.yolo247.site/live-casino/roulette",
            "alternate": "https://m.yolo247.site/live-casino/roulette",
            "keywords": ""
        },
        "ezugi-roulette": {
            "title": "Play Roulette by Ezugi Live Casino | Yolo247 India",
            "description": "Play Roulette by Ezugi Live Casino at Yolo247 🎰. Enjoy real-time live dealer action and bet on your favorite numbers. Win big with real money gaming!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/roulette",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/roulette",
            "keywords": ""
        },
        "ezugi-teenpatti": {
            "title": "Play Teen Patti by Ezugi Live Casino | Yolo247 India",
            "description": "Enjoy the classic Indian card game Teen Patti with live dealers at Yolo247. Bet, bluff, and win big in this exciting, fast-paced live casino experience!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/teen-patti",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/teen-patti",
            "keywords": ""
        },
        "saba-sports": {
            "title": "Saba Sports Betting | Online Sports Betting on Yolo247",
            "description": "Bet on Saba Sports at Yolo247! Enjoy top sports betting, secure transactions, and exciting promotions. Start winning today!",
            "canonical": "https://www.yolo247.site/saba-sports",
            "alternate": "https://m.yolo247.site/saba-sports",
            "keywords": ""
        },
        "instbetgames": {
            "title": "Play Betgames Instant Games at Yolo247 India",
            "description": "Experience thrilling instant games with Betgames at Yolo247 India! Enjoy high-stakes action and win big in games like Dice, Baccarat, and more! 🎲💰",
            "canonical": "https://www.yolo247.site/instant-games/betgames",
            "alternate": "https://m.yolo247.site/instant-games/betgames"
        },
        "instantskyward": {
            "title": "Play Skyward Instant Game by Betgames | Yolo247 India",
            "description": "Play Skyward Instant Game by Betgames Gaming at Yolo247 India! Enjoy thrilling gameplay and win big with every spin. Start your adventure today! ✨🎮",
            "canonical": "https://www.yolo247.site/instant-games/betgames/skyward",
            "alternate": "https://m.yolo247.site/instant-games/betgames/skyward"
        },
        "crash-games": {
            "title": "Play Instant Crash Games | Yolo247 India",
            "description": "Enjoy thrilling instant crash games at Yolo247. Play now and win real money with fast-paced, high-reward action!",
            "canonical": "https://www.yolo247.site/instant-games/crash-games",
            "alternate": "https://m.yolo247.site/instant-games/crash-games"
        },
        "instantevolution": {
            "title": "Play Evolution Instant Games at Yolo247 India | Ultimate Fun",
            "description": "Discover the thrill of Evolution Instant Games at Yolo247 India! Dive into action-packed games for non-stop excitement and big wins. Play now! 🎮💥",
            "canonical": "https://www.yolo247.site/instant-games/evolution",
            "alternate": "https://m.yolo247.site/instant-games/evolution"
        },
        "evoplay": {
            "title": "Play Evoplay Instant Games at Yolo247 India | Thrilling Fun",
            "description": "Explore exciting Evoplay Instant Games at Yolo247 India! Enjoy immersive gameplay and win big with various fun-filled options. 🎮💥 Play now and win instantly!",
            "canonical": "https://www.yolo247.site/instant-games/evoplay",
            "alternate": "https://m.yolo247.site/instant-games/evoplay"
        },
        "european-roulette": {
            "title": "European Roulette Instant Game by Evoplay | Yolo247 India",
            "description": "Spin the wheel and experience the excitement of European Roulette by Evoplay! Play now at Yolo247 India for a chance to win big! 🎡💸",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/european-roulette",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/european-roulette"
        },
        "football-bet": {
            "title": "Play Football Bet Instant Game by Evoplay | Yolo247 India",
            "description": "Place your bets and score big with the Football Bet instant game by Evoplay! Play now at Yolo247 India and win amazing rewards! ⚽💵",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/football-bet",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/football-bet"
        },
        "football-manager": {
            "title": "Football Manager Instant Game by Evoplay | Yolo247 India",
            "description": "Step into the shoes of a football manager! Make strategic decisions and lead your team to victory in this instant game by Evoplay at Yolo247 India! ⚽🏆",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/football-manager",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/football-manager"
        },
        "high-striker": {
            "title": "Play High Striker Instant Game by Evoplay | Yolo247 India",
            "description": "Test your strength and aim for the top in the High Striker instant game by Evoplay! Play now at Yolo247 India and hit the jackpot! 💪🔔",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/high-striker",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/high-striker"
        },
        "inner-fire-bonus-buy": {
            "title": "Inner Fire Bonus Buy Instant Game by Evoplay | Yolo247 India",
            "description": "Ignite your passion and win big with the Inner Fire Bonus Buy instant game by Evoplay. Play now at Yolo247 India for thrilling rewards! 🔥💰",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/inner-fire-bonus-buy",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/inner-fire-bonus-buy"
        },
        "penalty-shoot-out": {
            "title": "Play Penalty Shoot-out Street by Evoplay | Yolo247 India",
            "description": "Experience the thrill of penalty shootouts in this exciting instant game by Evoplay! Test your skills and score big at Yolo247 India today! ⚽🎯",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/penalty-shoot-out",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/penalty-shoot-out"
        },
        "roll-the-dice": {
            "title": "Play Roll the Dice Instant Game by Evoplay | Yolo247 India",
            "description": "Roll the dice and watch your fortune unfold with this thrilling instant game by Evoplay. Play now at Yolo247 India for exciting rewards! 🎲💥",
            "canonical": "https://www.yolo247.site/instant-games/evoplay/roll-the-dice",
            "alternate": "https://m.yolo247.site/instant-games/evoplay/roll-the-dice"
        },




        "galaxsys": {
            "title": "Galaxsys Instant Games at Yolo247 India | Fast Fun",
            "description": "Play thrilling instant games from Galaxsys at Yolo247 India. Enjoy crash games, blackjack, and more with exciting gameplay and instant wins! Start playing now!",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys"
        },
        "atlantis": {
            "title": "Play Atlantis Instant Game by Galaxys | Yolo247 India",
            "description": "🏖️ Play Atlantis Instant Game by Galaxys at Yolo247 India! Dive for underwater treasures! 💎✨",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/atlantis",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/atlantis"
        },
        "bingo-star": {
            "title": "Play Bingo Star Instant Game by Galaxys | Yolo247 India",
            "description": "🏆 Play Bingo Star Instant Game by Galaxys at Yolo247 India! Mark your cards and win big prizes instantly! 🎉🍀",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/bingo-star",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/bingo-star"
        },
        "instantblackjack": {
            "title": "Play Blackjack Instant Game by Galaxys | Yolo247 India",
            "description": "🃏 Play Blackjack Instant Game by Galaxys at Yolo247 India! Beat the dealer, win instant rewards. Spin now and win big! 🎉💰",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/blackjack",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/blackjack"
        },
        "coin-flip": {
            "title": "Play Coin Flip Instant Game by Galaxys | Yolo247 India",
            "description": "💰 Play Coin Flip Instant Game by Galaxys at Yolo247 India! Flip and win big instantly! 🪙🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/coin-flip",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/coin-flip"
        },
        "crash": {
            "title": "Play Crash Instant Game by Galaxys | Yolo247 India",
            "description": "🚀 Play Crash Instant Game by Galaxys at Yolo247 India! Fast-paced action and big wins await. Spin now for instant rewards! 💥🎰",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/crash",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/crash"
        },
        "crasher": {
            "title": "Play Crasher Instant Game by Galaxys | Yolo247 India",
            "description": "💥 Play Crasher Instant Game by Galaxys at Yolo247 India! Break barriers, win huge rewards! 🏆🎰",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/crasher",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/crasher"
        },
        "dr-shocker": {
            "title": "Play DR. Shocker Instant Game by Galaxys | Yolo247 India",
            "description": "⚡ Play DR. Shocker Instant Game by Galaxys at Yolo247 India! Get shocked by massive wins instantly! 💥🎉",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/dr-shocker",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/dr-shocker"
        },
        "f-mines": {
            "title": "Play F Mines Instant Game by Galaxys | Yolo247 India",
            "description": "💎 Play F Mines Instant Game by Galaxys at Yolo247 India! Dig for treasure, win rewards! ⚒️🎉",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/f-mines",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/f-mines"
        },
        "golden-ra": {
            "title": "Play Golden Ra Instant Game by Galaxys | Yolo247 India",
            "description": "🏺 Play Golden Ra Instant Game by Galaxys at Yolo247 India! Uncover hidden treasures and win big! 💰✨",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/golden-ra",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/golden-ra"
        },
        "hamster-mania": {
            "title": "Play Hamster Mania Instant Game by Galaxys | Yolo247 India",
            "description": "🐹 Play Hamster Mania Instant Game by Galaxys at Yolo247 India! Spin and win wild rewards instantly! 💥🎰",
            "canonical": "https://www.yolo247.site/instant-games/red-tiger/hamster-mania",
            "alternate": "https://m.yolo247.site/instant-games/red-tiger/hamster-mania"
        },
        "galaxsys-hilo": {
            "title": "Play Hilo Instant Game by Galaxys | Yolo247 India",
            "description": "♠️ Play Hilo Instant Game by Galaxys at Yolo247 India! Bet high or low, win big with thrilling gameplay. Spin now! 🃏🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/hi-lo",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/hi-lo"
        },
        "jungle-wheel": {
            "title": "Play Jungle Wheel Instant Game by Galaxys | Yolo247 India",
            "description": "🌿 Play Jungle Wheel Instant Game by Galaxys at Yolo247 India! Spin for wild rewards! 🎉🍀",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/jungle-wheel",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/jungle-wheel"
        },
        "keno-10-1-minute": {
            "title": "Play Keno 10(1 Min) Instant Game by Galaxys | Yolo247 India",
            "description": "⏱️ Play Keno 10 (1 Min) Instant Game by Galaxys at Yolo247 India! Quick gameplay, fast wins! 💥🎉",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/keno-10-1-minute",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/keno-10-1-minute"
        },
        "keno-10-2-minute": {
            "title": "Play Keno 10(2 Min) Instant Game by Galaxys | Yolo247 India",
            "description": "⏱️ Play Keno 10 (2 Min) Instant Game by Galaxys at Yolo247 India! Quick spins, huge rewards! 🎰💥",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/keno-10-2-minute",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/keno-10-2-minute"
        },
        "keno-8-1-minute": {
            "title": "Play Keno 8(1 Min) Instant Game by Galaxys | Yolo247 India",
            "description": "⏱️ Play Keno 8 (1 Min) Instant Game by Galaxys at Yolo247 India! Fast-paced and instant rewards await! 🏆💸",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/keno-8-1-minute",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/keno-8-1-minute"
        },
        "keno-8-2-minute": {
            "title": "Play Keno 8(2 Min) Instant Game by Galaxys | Yolo247 India",
            "description": "⏳ Play Keno 8 (2 Min) Instant Game by Galaxys at Yolo247 India! Win fast with every spin. Try now! 🏆💸",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/keno-8-2-minute",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/keno-8-2-minute"
        },
        "keno-express": {
            "title": "Play Keno Express Instant Game by Galaxys | Yolo247 India",
            "description": "🔢 Play Keno Express Instant Game by Galaxys at Yolo247 India! Mark your numbers, win fast! 💸💥",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/keno-express",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/keno-express"
        },
        "limbo-crash": {
            "title": "Play Limbo Crash Instant Game by Galaxys | Yolo247 India",
            "description": "🚀 Play Limbo Crash Instant Game by Galaxys at Yolo247 India! Hold your breath, avoid crashes, and win! 💥🎰",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/limbo-crash",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/limbo-crash"
        },
        "maestro": {
            "title": "Play Maestro Instant Game by Galaxys | Yolo247 India",
            "description": "🎶 Play Maestro Instant Game by Galaxys at Yolo247 India! Conduct your symphony of rewards. Spin and win big! 💥🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/maestro",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/maestro"
        },
        "magic-dice": {
            "title": "Play Magic Dice Instant Game by Galaxys | Yolo247 India",
            "description": "🎲 Play Magic Dice Instant Game by Galaxys at Yolo247 India! Roll and unlock huge rewards! 💰✨",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/magic-dice",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/magic-dice"
        },
        "mr-thimble": {
            "title": "Play Mr. Thimble Instant Game by Galaxys | Yolo247 India",
            "description": "🎩 Play Mr. Thimble Instant Game by Galaxys at Yolo247 India! Find the hidden thimble and win big rewards. Spin now! 🏆✨",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/mr-thimble",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/mr-thimble"
        },
        "penalty": {
            "title": "Play Penalty Instant Game by Galaxys | Yolo247 India",
            "description": "⚽ Play Penalty Instant Game by Galaxys at Yolo247 India! Score big with each kick and win amazing rewards. 🥅🎯",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/penalty",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/penalty"
        },
        "plinkoman": {
            "title": "Play Plinkoman Instant Game by Galaxys | Yolo247 India",
            "description": "🌀 Play Plinkoman Instant Game by Galaxys at Yolo247 India! Spin and win big rewards instantly! 🎉💥",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/plinkoman",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/plinkoman"
        },
        "roulette-x": {
            "title": "Play Roulette X Instant Game by Galaxys | Yolo247 India",
            "description": "🎡 Play Roulette X Instant Game by Galaxys at Yolo247 India! Spin the wheel and win exciting rewards instantly! 💥🍀",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/roulette-x",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/roulette-x"
        },
        "scratch-map": {
            "title": "Play Scratch Map Instant Game by Galaxys | Yolo247 India",
            "description": "🗺️ Play Scratch Map Instant Game by Galaxys at Yolo247 India! Scratch and win big! 💥🏆",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/scratch-map",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/scratch-map"
        },
        "sicbo": {
            "title": "Play Sicbo Instant Game by Galaxys | Yolo247 India",
            "description": "🎲 Play Sicbo Instant Game by Galaxys at Yolo247 India! Roll the dice and win instantly! 💥🍀",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/sicbo",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/sicbo"
        },
        "totem": {
            "title": "Play Totem Instant Game by Galaxys | Yolo247 India",
            "description": "🪶 Play Totem Instant Game by Galaxys at Yolo247 India! Spin for ancient powers and rewards! 💥🎰",
            "canonical": "https://www.yolo247.site/instant-games/galaxsys/totem",
            "alternate": "https://m.yolo247.site/instant-games/galaxsys/totem"
        },
        "instantroulette": {
            "title": "Play Instant Roulette Games | Yolo247 India",
            "description": "Experience the thrill of instant roulette games at Yolo247. Play now and spin for real money rewards in every round!",
            "canonical": "https://www.yolo247.site/instant-games/roulette",
            "alternate": "https://m.yolo247.site/instant-games/roulette"
        },
        "smartsoft": {
            "title": "Play SmartSoft Instant Games at Yolo247 India",
            "description": "Explore thrilling SmartSoft Instant Games at Yolo247 India! Enjoy fast-paced fun with unique titles and exciting rewards. Start playing now!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft"
        },
        "burning-ice-10": {
            "title": "Play Burning Ice 10 Instant Game By Smartsoft | Yolo247 India",
            "description": "Try Burning Ice 10 Instant Game by Smartsoft on Yolo247. Enjoy exciting gameplay and quick rewards with every turn. Play now for real money prizes!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/burning-ice-10",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/burning-ice-10"
        },
        "burning-ice-4": {
            "title": "Play Burning Ice 40 Instant Game By Smartsoft | Yolo247 India",
            "description": "Experience the excitement of Burning Ice 40 Instant Game by Smartsoft at Yolo247. Fast gameplay and big rewards await - play and win real money today!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/burning-ice-40",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/burning-ice-40"
        },
        "crash-duel-x": {
            "title": "Play Crash Duel X Instant Game By Smartsoft | Yolo247 India",
            "description": "Face off in Crash Duel X Instant Game by Smartsoft at Yolo247. Quick results, intense gameplay, and real money prizes - play now!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/crash-duel-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/crash-duel-x"
        },
        "crazy-hunt-x": {
            "title": "Play CrazyHuntX Instant Game By Smartsoft | Yolo247 India",
            "description": "Go on a wild hunt in CrazyHuntX Instant Game by Smartsoft at Yolo247. Enjoy fast-paced action and win real money with every play!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/crazy-hunt-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/crazy-hunt-x"
        },
        "helicopter-x": {
            "title": "Play HelicopterX Instant Game By Smartsoft | Yolo247 India",
            "description": "Soar to new heights with HelicopterX Instant Game by Smartsoft at Yolo247. Play for real money rewards in this thrilling, fast-paced game!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/helicopter-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/helicopter-x"
        },
        "jetx-3": {
            "title": "Play JetX3 Instant Game by Smartsoft Gaming | Yolo247 India",
            "description": "🚀 Play JetX3 Instant Game by Smartsoft at Yolo247 India! Take off for massive rewards in this exciting aviation game. Play and win now! ✈️💸",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/jetx-3",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/jetx-3"
        },
        "joker-buy-bonus": {
            "title": "Play Joker Buy Bonus X Instant Game By Smartsoft | Yolo247 India",
            "description": "Play Joker Buy Bonus X Instant Game by Smartsoft at Yolo247. Experience exciting instant wins and the thrill of bonus buys - play now!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/joker-buy-bonus",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/joker-buy-bonus"
        },
        "mine-islan": {
            "title": "Play Mine Island Instant Game By Smartsoft | Yolo247 India",
            "description": "Discover treasure on Mine Island Instant Game by Smartsoft at Yolo247. Enjoy instant rewards and exciting gameplay with every move!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/mine-island",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/mine-island"
        },
        "slicer-x": {
            "title": "Play SlicerX Instant Game By Smartsoft | Yolo247 India",
            "description": "Slice through the competition with SlicerX Instant Game by Smartsoft at Yolo247. Play now for quick, rewarding gameplay and real money wins!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/slicer-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/slicer-x"
        },
        "tower-x": {
            "title": "Play TowerX Instant Game By Smartsoft | Yolo247 India",
            "description": "Reach new heights with TowerX Instant Game by Smartsoft at Yolo247. Play for fast rewards and exciting wins today!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/tower-x",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/tower-x"
        },
        "virtual-burning-roulette": {
            "title": "Play Virtual Burning Roulette Instant Game By Smartsoft | Yolo247 India",
            "description": "Turn up the heat with Virtual Burning Roulette Instant Game by Smartsoft at Yolo247. Play for real money with exciting spins and big rewards!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/virtual-burning-roulette",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/virtual-burning-roulette"
        },
        "virtual-classic-roulette": {
            "title": "Play Virtual Classic Roulette Instant Game By Smartsoft | Yolo247 India",
            "description": "Enjoy the classic thrill of Virtual Classic Roulette Instant Game by Smartsoft at Yolo247. Spin and win real money in this fast-paced game!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/virtual-classic-roulette",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/virtual-classic-roulette"
        },
        "virtual-roulette": {
            "title": "Play Virtual Roulette Instant Game By Smartsoft | Yolo247 India",
            "description": "Spin the wheel in Virtual Roulette Instant Game by Smartsoft at Yolo247. Play now for real money rewards in every spin of the wheel!",
            "canonical": "https://www.yolo247.site/instant-games/smartsoft/virtual-roulette",
            "alternate": "https://m.yolo247.site/instant-games/smartsoft/virtual-roulette"
        },
        "dice": {
            "title": "Play Dice Instant Game by Spribe | Yolo247 India 🎲",
            "description": "Roll the dice and win big with Dice Instant Game by Spribe. Play now and see where luck takes you at Yolo247 India! 🎰🎲",
            "canonical": "https://www.yolo247.site/instant-games/spribe/dice",
            "alternate": "https://m.yolo247.site/instant-games/spribe/dice"
        },
        "keno": {
            "title": "Play Keno Instant Game by Spribe | Yolo247 India 🏆",
            "description": "Try your luck with Keno Instant Game by Spribe! Pick your numbers, spin, and win instantly at Yolo247 India! 💥🎟️ Play now for huge rewards!",
            "canonical": "https://www.yolo247.site/instant-games/spribe/keno",
            "alternate": "https://m.yolo247.site/instant-games/spribe/keno"
        },
        "mini-roulette": {
            "title": "Play Mini Roulette Instant Game by Spribe | Yolo247 India 🎡",
            "description": "Enjoy fast-paced Mini Roulette Instant Game by Spribe! Spin the wheel and win big at Yolo247 India now! 🎉🔄 Test your luck and claim your rewards!",
            "canonical": "https://www.yolo247.site/instant-games/spribe/mini-roulette",
            "alternate": "https://m.yolo247.site/instant-games/spribe/mini-roulette"
        },
        "plinko": {
            "title": "Play Plinko Instant Game by STP Gaming | Yolo247 India",
            "description": "Drop the puck and win big with Plinko Instant Game by STP! Play now for incredible prizes at Yolo247 India! 🟠💰",
            "canonical": "https://www.yolo247.site/instant-games/stp/plinko",
            "alternate": "https://m.yolo247.site/instant-games/stp/plinko"
        },
        "stp": {
            "title": "Play STP Instant Games at Yolo247 India | Great Wins Await",
            "description": "Enjoy thrilling instant games from STP Game Provider at Yolo247 India! Spin, play, and win big with exciting games today. 🕹️🎉 Start winning now!",
            "canonical": "https://www.yolo247.site/instant-games/stp",
            "alternate": "https://m.yolo247.site/instant-games/stp"
        },
        "archeo": {
            "title": "Play Archeo Instant Game by STP Gaming | Yolo247 India",
            "description": "Explore ancient treasures and uncover hidden riches with Archeo Instant Game by STP! Start your adventure today at Yolo247 India! 🏺💎",
            "canonical": "https://www.yolo247.site/instant-games/stp/archeo",
            "alternate": "https://m.yolo247.site/instant-games/stp/archeo"
        },
        "comet-crash": {
            "title": "Play Comet Crash Instant Game by STP Gaming | Yolo247 India",
            "description": "Blast off and win big with Comet Crash Instant Game by STP! Play now at Yolo247 India for exciting rewards and endless fun! 🚀💥",
            "canonical": "https://www.yolo247.site/instant-games/stp/comet-crash",
            "alternate": "https://m.yolo247.site/instant-games/stp/comet-crash"
        },
        "dare-devil": {
            "title": "Play Dare Devil Instant Game by STP Gaming | Yolo247 India",
            "description": "Take a leap of faith with Dare Devil Instant Game by STP! Experience the thrill and play to win big at Yolo247 India! 🏍️💨",
            "canonical": "https://www.yolo247.site/instant-games/stp/dare-devil",
            "alternate": "https://m.yolo247.site/instant-games/stp/dare-devil"
        },
        "gold-rush": {
            "title": "Play Gold Rush Instant Game by STP Gaming | Yolo247 India",
            "description": "Strike it rich with Gold Rush Instant Game by STP! Dig for treasures and play for huge wins at Yolo247 India! 🏆⛏️",
            "canonical": "https://www.yolo247.site/instant-games/stp/gold-rush",
            "alternate": "https://m.yolo247.site/instant-games/stp/gold-rush"
        },
        "jungle-gems": {
            "title": "Play Jungle Gems Instant Game by STP Gaming | Yolo247 India",
            "description": "Discover precious gems hidden in the jungle with Jungle Gems Instant Game by STP! Play now for big rewards at Yolo247 India! 🌿💎",
            "canonical": "https://www.yolo247.site/instant-games/stp/jungle-gems",
            "alternate": "https://m.yolo247.site/instant-games/stp/jungle-gems"
        },
        "magic-numbers": {
            "title": "Play Magic Numbers Instant Game by STP | Yolo247 India",
            "description": "Unlock the power of magical numbers with Magic Numbers Instant Game by STP! Test your luck and win big today at Yolo247 India! 🔮✨",
            "canonical": "https://www.yolo247.site/instant-games/stp/magic-numbers",
            "alternate": "https://m.yolo247.site/instant-games/stp/magic-numbers"
        },
        "monkey-bizniz": {
            "title": "Play Monkey Bizniz Instant Game by STP | Yolo247 India",
            "description": "Swing into action with Monkey Bizniz Instant Game by STP! Play now and collect exciting rewards at Yolo247 India! 🐒🍌",
            "canonical": "https://www.yolo247.site/instant-games/stp/monkey-bizniz",
            "alternate": "https://m.yolo247.site/instant-games/stp/monkey-bizniz"
        },
        "soccer-mania": {
            "title": "Play Soccer Mania Instant Game by STP Gaming | Yolo247 India",
            "description": "Score big in Soccer Mania Instant Game by STP! Play now and win exciting rewards at Yolo247 India with every goal you score! ⚽🥅",
            "canonical": "https://www.yolo247.site/instant-games/stp/soccer-mania",
            "alternate": "https://m.yolo247.site/instant-games/stp/soccer-mania"
        },
        "street-dice": {
            "title": "Play Street Dice Instant Game by STP Gaming | Yolo247 India",
            "description": "Roll the dice and make your luck with Street Dice Instant Game by STP! Play now and win exciting prizes at Yolo247 India! 🎲💰",
            "canonical": "https://www.yolo247.site/instant-games/stp/street-dice",
            "alternate": "https://m.yolo247.site/instant-games/stp/street-dice"
        },
        "taxi-ride": {
            "title": "Play Taxi Ride Instant Game by STP Gaming | Yolo247 India",
            "description": "Hop in for a thrilling ride with Taxi Ride Instant Game by STP! Play for huge wins and experience non-stop action at Yolo247 India! 🚖💨",
            "canonical": "https://www.yolo247.site/instant-games/stp/taxi-ride",
            "alternate": "https://m.yolo247.site/instant-games/stp/taxi-ride"
        },
        "treasure-games": {
            "title": "Play Instant Treasure Games | Yolo247 India",
            "description": "Dive into exciting instant treasure games at Yolo247! Play now to uncover hidden rewards and win big in minutes",
            "canonical": "https://www.yolo247.site/instant-games/treasure-games",
            "alternate": "https://m.yolo247.site/instant-games/treasure-games"
        },
        "1-tap-mines": {
            "title": "Play 1Tap Mines Instant Game by Turbo Games  | Yolo247 India",
            "description": "Tap your way to treasures in 1Tap Mines by Turbo Games on Yolo247 India. Fast, easy, and rewarding gameplay!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/1-tap-mines",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/1-tap-mines"
        },
        "ball-and-ball": {
            "title": "Play Ball & Ball Instant Game by Turbo Games  | Yolo247 India",
            "description": "Bounce your way to exciting wins with Ball & Ball by Turbo Games on Yolo247 India. Fun and fast-paced!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/ball-and-ball",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/ball-and-ball"
        },
        "bayraktar": {
            "title": "Play Bayraktar Instant Game by Turbo Games  | Yolo247 India",
            "description": "Fly high with Bayraktar by Turbo Games on Yolo247 India. Test your skills for rewarding gameplay!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/bayraktar",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/bayraktar"
        },
        "book-of-mines": {
            "title": "Play Book Of Mines Instant Game by Turbo Games  | Yolo247 India",
            "description": "Explore hidden treasures in Book Of Mines by Turbo Games on Yolo247 India. Quick, exciting, and rewarding!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/book-of-mines",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/book-of-mines"
        },
        "bubbles": {
            "title": "Play Bubbles Instant Game by Turbo Games  | Yolo247 India",
            "description": "Pop your way to rewards in Bubbles by Turbo Games on Yolo247 India. Fun and fast with instant payouts!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/bubbles",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/bubbles"
        },
        "crash-x": {
            "title": "Play Crash X Instant Game by Turbo Games  | Yolo247 India",
            "description": "Enjoy the thrilling Crash X instant game by Turbo Games on Yolo247 India for fast-paced, high-reward action",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/crash-x",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/crash-x"
        },
        "crash-x-football-edition": {
            "title": "Play Crash X Football Instant Game by Turbo Games  | Yolo247 India",
            "description": "Play the ultimate football crash game with Crash X Football by Turbo Games on Yolo247 India for big thrills!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/crash-x-football-edition",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/crash-x-football-edition"
        },
        "crystal-poker": {
            "title": "Play Crystal Poker Instant Game by Turbo Games  | Yolo247 India",
            "description": "Play Crystal Poker by Turbo Games on Yolo247 India. Fast-paced poker action with instant rewards!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/crystal-poker",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/crystal-poker"
        },
        "dice-twice": {
            "title": "Play Dice Twice Instant Game by Turbo Games  | Yolo247 India",
            "description": "Roll the dice in Dice Twice by Turbo Games on Yolo247 India and experience instant fun and big rewards",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/dice-twice",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/dice-twice"
        },
        "dogs-street": {
            "title": "Play Dogs Street Instant Game by Turbo Games  | Yolo247 India",
            "description": "Play Dogs Street by Turbo Games on Yolo247 India. Exciting instant wins and thrilling gameplay!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/dogs-street",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/dogs-street"
        },
        "donny-king": {
            "title": "Play Donny King Instant Game by Turbo Games  | Yolo247 India",
            "description": "Rule the ring in Donny King by Turbo Games on Yolo247 India. Fast gameplay with thrilling payouts",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/donny-king",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/donny-king"
        },
        "double-roll": {
            "title": "Play Double Roll Instant Game by Turbo Games  | Yolo247 India",
            "description": "Roll the dice twice for double the rewards in Double Roll by Turbo Games on Yolo247 India",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/double-roll",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/double-roll"
        },
        "fast-fielder": {
            "title": "Play Fast Fielder Instant Game by Turbo Games  | Yolo247 India",
            "description": "Test your reflexes with Fast Fielder by Turbo Games on Yolo247 India. Quick, fun, and rewarding gameplay",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/fast-fielder",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/fast-fielder"
        },
        "fruit-towers": {
            "title": "Play Fruit Towers Instant Game by Turbo Games  | Yolo247 India",
            "description": "Stack your fruit for big wins in Fruit Towers by Turbo Games on Yolo247 India. Fast, fun, and rewarding!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/fruit-towers",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/fruit-towers"
        },
        "fury-stairs": {
            "title": "Play Fury Stairs Instant Game by Turbo Games  | Yolo247 India",
            "description": "Conquer the Fury Stairs instant game by Turbo Games on Yolo247 India. Climb higher for big rewards!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/fury-stairs",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/fury-stairs"
        },
        "hamsta": {
            "title": "Play Hamsta Instant Game by Turbo Games  | Yolo247 India",
            "description": "Dive into the Hamsta instant game by Turbo Games on Yolo247 India for exciting, fast-paced gameplay",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/hamsta",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/hamsta"
        },
        "turbo-hi-lo": {
            "title": "Play Hi-Lo Instant Game by Turbo Games  | Yolo247 India",
            "description": "Guess high or low in Hi-Lo by Turbo Games on Yolo247 India. Quick predictions lead to exciting rewards",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/hi-lo",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/hi-lo"
        },
        "javelinx": {
            "title": "Play JavelinX Instant Game by Turbo Games  | Yolo247 India",
            "description": "Launch your javelin to new heights in JavelinX by Turbo Games on Yolo247 India. Quick and rewarding gameplay",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/javelinx",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/javelinx"
        },
        "jewel-clicker": {
            "title": "Play Jewel Clicker Instant Game by Turbo Games  | Yolo247 India",
            "description": "Click your way to jewels and rewards in Jewel Clicker by Turbo Games on Yolo247 India. Quick and fun!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/jewel-clicker",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/jewel-clicker"
        },
        "limbo-rider": {
            "title": "Play Limbo Ride Instant Game by Turbo Games  | Yolo247 India",
            "description": "Experience the thrill of Limbo Ride by Turbo Games on Yolo247 India. Ride to rewards with every turn!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/limbo-rider",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/limbo-rider"
        },
        "magic-keno": {
            "title": "Play Magic Keno Instant Game by Turbo Games  | Yolo247 India",
            "description": "Try your luck with Magic Keno by Turbo Games on Yolo247 India. Fast-paced and rewarding gameplay",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/magic-keno",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/magic-keno"
        },
        "multiplayer-mines": {
            "title": "Play Multiplayer Mines Instant Game by Turbo Games  | Yolo247 India",
            "description": "Compete with others in Multiplayer Mines by Turbo Games on Yolo247 India. Quick fun and thrilling rewards!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/multiplayer-mines",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/multiplayer-mines"
        },
        "neko": {
            "title": "Play Neko Instant Game by Turbo Games  | Yolo247 India",
            "description": "Play Neko by Turbo Games on Yolo247 India and uncover exciting rewards with every move",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/neko",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/neko"
        },
        "pumped-x": {
            "title": "Play PumpedX Instant Game by Turbo Games  | Yolo247 India",
            "description": "Get pumped for rewards in PumpedX by Turbo Games on Yolo247 India. Quick and exciting gameplay!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/pumped-x",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/pumped-x"
        },
        "save-the-princess": {
            "title": "Play Save the Princes Instant Game by Turbo Games  | Yolo247 India",
            "description": "Help save the princesses in this action-packed Save the Princes game by Turbo Games on Yolo247 India",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/save-the-princess",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/save-the-princess"
        },
        "spin-strike": {
            "title": "Play Spin Strike Instant Game by Turbo Games  | Yolo247 India",
            "description": "Spin your way to rewards in Spin Strike by Turbo Games on Yolo247 India. Fast-paced, high-reward fun!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/spin-strike",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/spin-strike"
        },
        "take-my-plinko": {
            "title": "Play Take My Plinko Instant Game by Turbo Games  | Yolo247 India",
            "description": "Experience the excitement of Take My Plinko by Turbo Games on Yolo247 India. Drop the chip for big wins!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/take-my-plinko",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/take-my-plinko"
        },
        "towers": {
            "title": "Play Towers Instant Game by Turbo Games  | Yolo247 India",
            "description": "Build your fortune with Towers by Turbo Games on Yolo247 India. Test your skills for big wins!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/towers",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/towers"
        },
        "trading-dice": {
            "title": "Play Trading Dice Instant Game by Turbo Games  | Yolo247 India",
            "description": "Roll the dice for big rewards in Trading Dice by Turbo Games on Yolo247 India. Quick decisions, fast payouts!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/trading-dice",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/trading-dice"
        },
        "turbo-plinko": {
            "title": "Play Turbo Plinko Instant Game by Turbo Games  | Yolo247 India",
            "description": "Drop your chips and win big in Turbo Plinko by Turbo Games on Yolo247 India. Instant fun and rewards!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/turbo-plinko",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/turbo-plinko"
        },
        "wicket-blast": {
            "title": "Play Wicket Blast Instant Game by Turbo Games  | Yolo247 India",
            "description": "Hit your way to success in Wicket Blast by Turbo Games on Yolo247 India. Cricket-themed, fast rewards!",
            "canonical": "https://www.yolo247.site/instant-games/turbo-games/wicket-blast",
            "alternate": "https://m.yolo247.site/instant-games/turbo-games/wicket-blast"
        },


        "777": {
            "title": "Play 777 Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Feel the thrill of 777 Slot by JILI Gaming at Yolo247 India. Spin the classic reels for huge rewards today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/777",
            "alternate": "https://m.yolo247.site/slots/jili-slot/777"
        },

        "7mojos": {
            "title": "Discover 7 Mojos Games at Yolo247 India | Real Money Slots",
            "description": "Join Yolo247 and play 7 Mojos slots. Exciting real money games await with high-quality graphics and big win opportunities!",
            "canonical": "https://www.yolo247.site/slots/7mojos",
            "alternate": "https://m.yolo247.site/slots/7mojos"
        },
        "dragon-tiger": {
            "title": "Play Dragon Tiger by Super Spade Live Casino | Yolo247 India",
            "description": "Try your luck with Dragon Tiger by Super Spade at Yolo247 India. Bet on this fast-paced live casino game and enjoy big rewards! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/super-spade/dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/super-spade/dragon-tiger"
        },
        "teenpatti-face-off": {
            "title": "Play Teenpatti Face Off by 7 Mojos Live Casino at Yolo247!",
            "description": "Join the action with Teenpatti Face Off by 7 Mojos Live Casino at Yolo247 India. Play real-time against others and win big! 🃏💵",
            "canonical": "https://www.yolo247.site/live-casino/7mojos/teenpatti-face-off",
            "alternate": "https://m.yolo247.site/live-casino/7mojos/teenpatti-face-off"
        },
        "turkish-roulette": {
            "title": "Play Turkish Roulette by 7 Mojos Live Casino | Yolo247 India",
            "description": "Play Turkish Roulette by 7 Mojos Live Casino at Yolo247 India. Bet on your lucky numbers and spin for big rewards in this exciting game! 💥🔥",
            "canonical": "https://www.yolo247.site/live-casino/7mojos/turkish-roulette",
            "alternate": "https://m.yolo247.site/live-casino/7mojos/turkish-roulette"
        },
        "baccarat-2": {
            "title": "Play Baccarat 2 Online by Amazing Gaming | Yolo247 India",
            "description": "Play Baccarat 2 Live at Yolo247 🎲. Bet on Amazing Gaming’s live casino tables for real money fun with professional dealers.",
            "canonical": "https://www.yolo247.site/live-casino/ag/baccarat-2",
            "alternate": "https://m.yolo247.site/live-casino/ag/baccarat-2"
        },
        "baccarat-3": {
            "title": "Play Baccarat 3 Online by Amazing Gaming | Yolo247 India",
            "description": "Enjoy Baccarat 3 Live at Yolo247 🌟. Play Amazing Gaming’s real money Baccarat with live dealers for thrilling casino action!",
            "canonical": "https://www.yolo247.site/live-casino/ag/baccarat-3",
            "alternate": "https://m.yolo247.site/live-casino/ag/baccarat-3"
        },
        "black-jack-1": {
            "title": "Play Blackjack 1 Online by Amazing Gaming | Yolo247 India",
            "description": "Play Blackjack 1 Live by Amazing Gaming at Yolo247 🎮. Win big on real money tables with live dealers and premium gameplay.",
            "canonical": "https://www.yolo247.site/live-casino/ag/black-jack-1",
            "alternate": "https://m.yolo247.site/live-casino/ag/black-jack-1"
        },
        "black-jack-2": {
            "title": "Play Blackjack 2 Online by Amazing Gaming | Yolo247 India",
            "description": "Join Yolo247 for Blackjack 2 Live 🎲. Experience Amazing Gaming’s live action, bet real money, and enjoy the thrill of winning big!",
            "canonical": "https://www.yolo247.site/live-casino/ag/black-jack-2",
            "alternate": "https://m.yolo247.site/live-casino/ag/black-jack-2"
        },
        "arw": {
            "title": "Play ARW | Yolo247 Live Casino",
            "description": "Dive into ARW at Yolo247. Play this exciting and fast-paced live casino game for big wins and engaging gameplay. Join today and start your winning streak!",
            "canonical": "https://www.yolo247.site/live-casino/arw",
            "alternate": "https://m.yolo247.site/live-casino/arw"
        },
        "6-plus-poker": {
            "title": "Play 6+ Poker by Bet Games Live Casino | Yolo247 India",
            "description": "Play 6+ Poker by Bet Games at Yolo247 🎮. Bet real money on this fast-paced variant with live dealers and experience exciting gameplay!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/6-plus-poker",
            "alternate": "https://m.yolo247.site/live-casino/betgames/6-plus-poker"
        },
        "bet-on-poker": {
            "title": "Play Bet on Poker by Bet Games Live Casino | Yolo247 India",
            "description": "Play Bet on Poker at Yolo247 🎲. Bet real money on live Poker games by Bet Games and win big with professional dealers. Join now for exciting action!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/bet-on-poker",
            "alternate": "https://m.yolo247.site/live-casino/betgames/bet-on-poker"
        },
        "dice-duel": {
            "title": "Play Dice Duel by Bet Games Live Casino | Yolo247 India",
            "description": "Join Yolo247 🎰 and play Dice Duel by Bet Games. Bet real money on live dice games with professional dealers. Get ready for nonstop fun and big wins!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/dice-duel",
            "alternate": "https://m.yolo247.site/live-casino/betgames/dice-duel"
        },
        "lucky-5": {
            "title": "Play Lucky 5 by Bet Games Live Casino | Yolo247 India",
            "description": "Play Lucky 5 by Bet Games 🎮 at Yolo247. Bet real money and enjoy live casino action with this exciting game. Big wins and thrilling gameplay await!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/lucky-5",
            "alternate": "https://m.yolo247.site/live-casino/betgames/lucky-5"
        },
        "lucky-6": {
            "title": "Play Lucky 6 by TV Bet Live Casino | Yolo247 India 🌟🎲",
            "description": "Feel the luck with Lucky 6 at TV Bet Live Casino on Yolo247 India. Join the excitement and win big prizes today!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/lucky-6",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/lucky-6"
        },
        "lucky-7": {
            "title": "Play Lucky 7 by Ezugi Live Casino | Yolo247 India",
            "description": "Test your luck with Lucky 7 at Yolo247. Enjoy real-time gameplay with live dealers and a chance to win big in this thrilling and fast-paced live casino game!",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/lucky-7",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/lucky-7"
        },
        "war-of-bets": {
            "title": "Play War of Bets by Bet Games Live Casino | Yolo247 India",
            "description": "Play War of Bets by Bet Games 🎲 at Yolo247. Bet real money on this thrilling live casino game with fast-paced action and exciting rewards!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/war-of-bets",
            "alternate": "https://m.yolo247.site/live-casino/betgames/war-of-bets"
        },
        "wheel-of-fortune": {
            "title": "Play Wheel by Bet Games Live Casino | Yolo247 India",
            "description": "Spin the Wheel by Bet Games 🎰 at Yolo247. Bet real money on this exciting live casino game and win big with every spin. Join now for fun and rewards!",
            "canonical": "https://www.yolo247.site/live-casino/betgames/wheel-of-fortune",
            "alternate": "https://m.yolo247.site/live-casino/betgames/wheel-of-fortune"
        },
        "card-games": {
            "title": "Play Card Games | Yolo247 Live Casino",
            "description": "Explore a wide variety of card games at Yolo247 Live Casino. From Poker to Baccarat, enjoy real-time gameplay with professional dealers. Start playing today!",
            "canonical": "https://www.yolo247.site/live-casino/card-games",
            "alternate": "https://m.yolo247.site/live-casino/card-games"
        },
        "cash-or-crash": {
            "title": "Play Cash or Crash by Evolution Live Casino | Yolo247 India",
            "description": "Play Cash or Crash by Evolution Live Casino at Yolo247 India. Take a chance to win big in this fast-paced, high-stakes game of luck!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/cash-or-crash",
            "alternate": "https://m.yolo247.site/live-casino/evolution/cash-or-crash"
        },

        "american-roulette": {
            "title": "Play American Roulette Slot by Netent | Yolo247 India",
            "description": "Play American Roulette Slot by NetEnt at Yolo247 India. Spin the wheel and enjoy the thrilling action with exciting features and opportunities for big wins!",
            "canonical": "https://www.yolo247.site/slots/netent/american-roulette",
            "alternate": "https://m.yolo247.site/slots/netent/american-roulette"
        },
        "baccarat-squeeze": {
            "title": "Play Baccarat Squeeze by Evolution Live Casino | Yolo247 India",
            "description": "Enjoy Baccarat Squeeze by Evolution at Yolo247 India. Get a closer look at your cards and play with real dealers for an immersive experience!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/baccarat-squeeze",
            "alternate": "https://m.yolo247.site/live-casino/evolution/baccarat-squeeze"
        },
        "crazy-coin-flip": {
            "title": "Play Crazy Coin Flip by Evolution Live Casino | Yolo247 India",
            "description": "Play Crazy Coin Flip by Evolution at Yolo247 India. Flip the coin and win real money with big multipliers in this exciting live casino game!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/crazy-coin-flip",
            "alternate": "https://m.yolo247.site/live-casino/evolution/crazy-coin-flip"
        },
        "crazy-pachinko": {
            "title": "Play Crazy Pachinko by Evolution Live Casino | Yolo247 India",
            "description": "Play Crazy Pachinko by Evolution Live Casino at Yolo247 India. Enjoy an exciting and unique game with real-time action and big rewards!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/crazy-pachinko",
            "alternate": "https://m.yolo247.site/live-casino/evolution/crazy-pachinko"
        },
        "crazy-time": {
            "title": "Play Crazy Time game online For Real money on Yolo247",
            "description": "Crazy Time casino game is the best wheel-based casino game on Yolo247. Play Crazy time game online, go crazy with higher winnings and faster game process.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/crazy-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/crazy-time"
        },
        "fan-tan": {
            "title": "Play Fan Tan | Yolo247 Live Casino",
            "description": "Try your luck with the traditional game of Fan Tan at Yolo247. Enjoy live gameplay and interact with dealers in this fast-paced live casino experience.",
            "canonical": "https://www.yolo247.site/live-casino/fan-tan",
            "alternate": "https://m.yolo247.site/live-casino/fan-tan"
        },
        "first-person-baccarat": {
            "title": "Play First Person Baccarat by Evolution Live Casino | Yolo247 India",
            "description": "Play First Person Baccarat by Evolution at Yolo247 India. Enjoy the classic Baccarat game with a first-person perspective and win real money!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-baccarat"
        },
        "first-person-blackjack": {
            "title": "Play First Person Blackjack by Evolution Live Casino | Yolo247 India",
            "description": "Play First Person Blackjack by Evolution at Yolo247 India. Experience Blackjack from a first-person perspective and win big in real-time!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-blackjack"
        },
        "first-person-dragon-tiger": {
            "title": "Play First Person Dragon Tiger by Evolution Live Casino | Yolo247 India",
            "description": "Enjoy First Person Dragon Tiger by Evolution at Yolo247 India. Play the fast-paced card game in first-person mode and win big in real-time!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-dragon-tiger"
        },
        "first-person-lightning-roulette": {
            "title": "Play First Person Lightning Roulette by Evolution Live Casino | Yolo247 India",
            "description": "Play First Person Lightning Roulette by Evolution at Yolo247 India. Experience exciting multipliers and win big in this unique version of roulette!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-lightning-roulette",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-lightning-roulette"
        },
        "first-person-mega-ball": {
            "title": "Play First Person Mega Ball by Evolution Live Casino | Yolo247 India",
            "description": "Play First Person Mega Ball by Evolution at Yolo247 India. Enjoy a fun and thrilling lottery-style game with real-time wins and big jackpots!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-mega-ball",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-mega-ball"
        },
        "first-person-roulette": {
            "title": "Play First Person Roulette by Evolution Live Casino | Yolo247 India",
            "description": "Play First Person Roulette by Evolution at Yolo247 India. Spin the wheel in first-person mode and win real money in this immersive roulette game!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/first-person-roulette",
            "alternate": "https://m.yolo247.site/live-casino/evolution/first-person-roulette"
        },
        "football-studio": {
            "title": "Play Football Studio | Yolo247 Live Casino",
            "description": "Enjoy the excitement of Football Studio at Yolo247. Bet on your favorite team and experience live football action with professional dealers. Play today!",
            "canonical": "https://www.yolo247.site/live-casino/football-studio",
            "alternate": "https://m.yolo247.site/live-casino/football-studio"
        },
        "funky-time": {
            "title": "Play Funky time Casino Game By Evolution Gaming on Yolo247",
            "description": "Funky Time casino game takes us back to the retro gaming mode with the modern online casino touch on Yolo247. Play Funky time online now.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.site/live-casino/evolution/funky-time"
        },
        "hindi-lightning-roulette": {
            "title": "Play Hindi Lightning Roulette by Evolution Live Casino | Yolo247 India",
            "description": "Play Hindi Lightning Roulette by Evolution at Yolo247 India. Enjoy an electrifying roulette experience with Hindi language support and big wins!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/hindi-lightning-roulette",
            "alternate": "https://m.yolo247.site/live-casino/evolution/hindi-lightning-roulette"
        },
        "hindi-speed-baccarat": {
            "title": "Play Hindi Speed Baccarat by Evolution Live Casino | Yolo247 India",
            "description": "Play Hindi Speed Baccarat by Evolution at Yolo247 India. Experience fast-paced Baccarat with Hindi language support and real-time winnings!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/hindi-speed-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/evolution/hindi-speed-baccarat"
        },
        "lightning-baccarat": {
            "title": "Play Lightning Baccarat by Evolution Live Casino | Yolo247 India",
            "description": "Play XXX Lightning Roulette by Evolution at Yolo247 India. Experience exciting multipliers and win big with every spin in this thrilling live game!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/lightning-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/evolution/lightning-baccarat"
        },
        "lightning-dice": {
            "title": "Play Lightning Dice | Play Instant Games for Real Cash",
            "description": "Lightning dice casino game online on Yolo247 is simple to learn & easy to master with Higher payout & faster gameplay, Play Lightning dice for real money.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/lightning-dice",
            "alternate": "https://m.yolo247.site/live-casino/evolution/lightning-dice"
        },
        "lightning-dragon-tiger": {
            "title": "Play Lightning Dragon Tiger by Evolution Live Casino | Yolo247 India",
            "description": "Dive into the action with Lightning Dragon Tiger by Evolution at Yolo247 India. Bet on the fastest and most exciting card game with real-time wins!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/lightning-dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/evolution/lightning-dragon-tiger"
        },
        "lightning-roulette": {
            "title": "Play lightning roulette Game online on Yolo247",
            "description": "Lightning roulette has become one of the best online casino games on Yolo247. Play Online lightning roulette for real money with higher payouts and all betting features.",
            "canonical": "https://www.yolo247.site/live-casino/lightning-roulette",
            "alternate": "https://m.yolo247.site/live-casino/lightning-roulette"
        },
        "lightning-storm": {
            "title": "Play Lightning Storm by Evolution Live Casino | Yolo247 India",
            "description": "Play Lightning Storm by Evolution at Yolo247 India. Win big with multipliers and exciting real-time gameplay!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/lightning-storm",
            "alternate": "https://m.yolo247.site/live-casino/evolution/lightning-storm"
        },
        "monopoly-big-baller": {
            "title": "Play Monopoly Big Baller by Evolution Live Casino | Yolo247 India",
            "description": "Play Monopoly Big Baller by Evolution at Yolo247 India. Enjoy an exciting blend of Monopoly and Bingo for real-time fun and big rewards!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/monopoly-big-baller",
            "alternate": "https://m.yolo247.site/live-casino/evolution/monopoly-big-baller"
        },
        "monopoly-live": {
            "title": "Monopoly Game Online | Explore monoply live casino game on Yolo247",
            "description": "Monopoly game online has recently gained popularity, as it has added both offline board game & wheel game features. Play Monopoly casino game now!",
            "canonical": "https://www.yolo247.site/live-casino/monopoly-live",
            "alternate": "https://m.yolo247.site/live-casino/monopoly-live"
        },
        "red-door-roulette": {
            "title": "Play Red Door Roulette by Evolution Live Casino | Yolo247 India",
            "description": "Experience Red Door Roulette by Evolution Live Casino at Yolo247 India. Spin the wheel and win real money in this exciting variant of roulette.",
            "canonical": "https://www.yolo247.site/live-casino/evolution/red-door-roulette",
            "alternate": "https://m.yolo247.site/live-casino/evolution/red-door-roulette"
        },
        "sic-bo": {
            "title": "Play Sic Bo | Yolo247 Live Casino",
            "description": "Roll the dice and predict your fate in Sic Bo at Yolo247. Bet on the outcome of the dice and experience the excitement of this classic live casino game.",
            "canonical": "https://www.yolo247.site/live-casino/sic-bo",
            "alternate": "https://m.yolo247.site/live-casino/sic-bo"
        },
        "side-bet-city": {
            "title": "Play Side Bet City by Evolution Live Casino | Yolo247 India",
            "description": "Enjoy Side Bet City by Evolution Live Casino at Yolo247 India. Play this exciting poker-style game with side bets and win big in real-time!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/side-bet-city",
            "alternate": "https://m.yolo247.site/live-casino/evolution/side-bet-city"
        },
        "super-andar-bahar": {
            "title": "Play Super Andar Bahar by Evolution Live Casino | Yolo247 India",
            "description": "Play Super Andar Bahar by Evolution at Yolo247 India. Enjoy a fast-paced game with real dealers and win big with every round in this classic game!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/super-andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/evolution/super-andar-bahar"
        },
        "super-sic-bo": {
            "title": "Play Super Sic Bo by Evolution Live Casino | Yolo247 India",
            "description": "Enjoy Super Sic Bo by Evolution Live Casino at Yolo247 India. Play the thrilling dice game with big multipliers and instant wins in real time!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/super-sic-bo",
            "alternate": "https://m.yolo247.site/live-casino/evolution/super-sic-bo"
        },

        "xxx-lightning-roulette": {
            "title": "Play XXX Lightning Roulette by Evolution Live Casino | Yolo247 India",
            "description": "Play Lightning Baccarat by Evolution at Yolo247 India. Experience exciting multipliers and win big with every spin in this thrilling live game!",
            "canonical": "https://www.yolo247.site/live-casino/evolution/xxx-lightning-roulette",
            "alternate": "https://m.yolo247.site/live-casino/evolution/xxx-lightning-roulette"
        },
        "matka": {
            "title": "Play Matka by Ezugi Live Casino | Yolo247 India",
            "description": "Experience the excitement of Matka with live dealers at Yolo247. Bet on numbers and enjoy real-time action in this classic and popular live casino game.",
            "canonical": "https://www.yolo247.site/live-casino/ezugi/matka",
            "alternate": "https://m.yolo247.site/live-casino/ezugi/matka"
        },
        "game-shows": {
            "title": "Live Casino Game Shows | Yolo247",
            "description": "Dive into thrilling live game shows at Yolo247. Enjoy interactive experiences with hosts and big prizes. Play today for a chance to win in your favorite casino game show!",
            "canonical": "https://www.yolo247.site/live-casino/game-shows",
            "alternate": "https://m.yolo247.site/live-casino/game-shows"
        },
        "hi-lows": {
            "title": "Play Hi Lo | Yolo247 Live Casino",
            "description": "Test your prediction skills with Hi Lo at Yolo247. Guess whether the next card will be higher or lower and win big in this fast-paced live casino game!",
            "canonical": "https://www.yolo247.site/live-casino/hi-lows",
            "alternate": "https://m.yolo247.site/live-casino/hi-lows"
        },
        "jackpot-games": {
            "title": "Play Jackpot Games | Yolo247 Live Casino",
            "description": "Try your luck with Jackpot Games at Yolo247! Play exciting live games with big jackpot prizes. Join now for your chance to win huge rewards in real-time!",
            "canonical": "https://www.yolo247.site/live-casino/jackpot-games",
            "alternate": "https://m.yolo247.site/live-casino/jackpot-games"
        },
        "king-race": {
            "title": "Play King Race | Yolo247 Live Casino",
            "description": "Race to victory in King Race at Yolo247. Bet on your favorite horses and enjoy fast-paced, live casino action. Play today and see if you can win the race!",
            "canonical": "https://www.yolo247.site/live-casino/king-race",
            "alternate": "https://m.yolo247.site/live-casino/king-race"
        },

        "hot-balloon": {
            "title": "Play Hot Balloon by Macaw Live Casino | Yolo247 India",
            "description": "Experience Hot Balloon by Macaw Live Casino at Yolo247 India. Bet, spin, and enjoy thrilling gameplay with live action and big wins! 🎉💰",
            "canonical": "https://www.yolo247.site/live-casino/macaw/hot-balloon",
            "alternate": "https://m.yolo247.site/live-casino/macaw/hot-balloon"
        },
        "mac-ludo": {
            "title": "Play Mac Ludo by Macaw Live Casino | Yolo247 India",
            "description": "Enjoy Mac Ludo by Macaw Live Casino at Yolo247 India. Bet and play this fun, interactive game with live dealers and win exciting rewards! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/macaw/mac-ludo",
            "alternate": "https://m.yolo247.site/live-casino/macaw/mac-ludo"
        },
        "mega-ball": {
            "title": "Play Mega Ball | Yolo247 Live Casino",
            "description": "Experience the thrill of Mega Ball at Yolo247. With huge multipliers and fast action, this live casino game offers endless excitement. Join now to play!",
            "canonical": "https://www.yolo247.site/live-casino/mega-ball",
            "alternate": "https://m.yolo247.site/live-casino/mega-ball"
        },
        "monopoly": {
            "title": "Play Monopoly Live | Yolo247 Live Casino",
            "description": "Experience the iconic Monopoly game with a twist! Play Monopoly Live at Yolo247 and interact with live dealers for a chance to win big!",
            "canonical": "https://www.yolo247.site/live-casino/monopoly",
            "alternate": "https://m.yolo247.site/live-casino/monopoly"
        },
        "mplay": {
            "title": "Play Mplay Slot Games at Yolo247 India | Real Money Games",
            "description": "Explore exciting Mplay Slot games at Yolo247 🎮. Enjoy thrilling spins, bonus features, and win real money rewards. Start your adventure today! 💰✨",
            "canonical": "https://www.yolo247.site/slots/mplay",
            "alternate": "https://m.yolo247.site/slots/mplay"
        },
        "jhandi-munda": {
            "title": "Play Jhandi Munda by Mplay Live Casino | Yolo247 India",
            "description": "Try your luck with Jhandi Munda by Mplay Live Casino at Yolo247 India. Bet on your favorite symbols and win exciting rewards!",
            "canonical": "https://www.yolo247.site/live-casino/mplay/jhandi-munda",
            "alternate": "https://m.yolo247.site/live-casino/mplay/jhandi-munda"
        },
        "satta-matka-express": {
            "title": "Play Satta Matka Express by Mplay Live Casino at Yolo247!",
            "description": "Try Satta Matka Express by Mplay Live Casino at Yolo247 India. Bet live and enjoy fast-paced gameplay with big rewards! 💵🔥",
            "canonical": "https://www.yolo247.site/live-casino/mplay/satta-matka-express",
            "alternate": "https://m.yolo247.site/live-casino/mplay/satta-matka-express"
        },
        "teenpatti-champion": {
            "title": "Play Teenpatti Champion by Mplay Live Casino | Yolo247 India",
            "description": "Join the action with Teenpatti Champion by Mplay Live Casino at Yolo247 India. Win big in this fast-paced card game with live dealers!",
            "canonical": "https://www.yolo247.site/live-casino/mplay/teenpatti-champion",
            "alternate": "https://m.yolo247.site/live-casino/mplay/teenpatti-champion"
        },
        "teenpatti-express": {
            "title": "Play Teenpatti Express by Mplay Live Casino | Yolo247 India",
            "description": "Experience Teenpatti Express by Mplay Live Casino at Yolo247 India. Quick and exciting gameplay with real-time action and huge wins! 🎉💵",
            "canonical": "https://www.yolo247.site/live-casino/mplay/teenpatti-express",
            "alternate": "https://m.yolo247.site/live-casino/mplay/teenpatti-express"
        },
        "poker": {
            "title": "Play Poker by TV Bet Live Casino | Yolo247 India 🂡♠️",
            "description": "Play live Poker at TV Bet Casino on Yolo247 India. Test your skills, bluff smart, and win exciting rewards!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/poker",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/poker"
        },
        "sidebet-city": {
            "title": "Play Sidebet City | Yolo247 Live Casino",
            "description": "Step into Sidebet City at Yolo247 and enjoy unique betting options with live dealers. Play for fun and prizes in this fast-paced live casino game!",
            "canonical": "https://www.yolo247.site/live-casino/sidebet-city",
            "alternate": "https://m.yolo247.site/live-casino/sidebet-city"
        },
        "super-spade": {
            "title": "Play Super Spade Live Casino games at Yolo247 India! 🎲💰",
            "description": "Enjoy Super Spade Live Casino at Yolo247 India! Play exciting games like Andar Bahar, Roulette, and Teen Patti with real-time action and big wins. 🎲💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/super-spade",
            "alternate": "https://m.yolo247.site/live-casino/super-spade"
        },
        "teen-patti-20-20": {
            "title": "Play Teen Patti 20-20 by Super Spade Live Casino at Yolo247!",
            "description": "Experience Teen Patti 20-20 by Super Spade, the iconic Indian card game, live at Yolo247 India. Play now and win big with real-time action! 🃏💵",
            "canonical": "https://www.yolo247.site/live-casino/super-spade/teen-patti-20-20",
            "alternate": "https://m.yolo247.site/live-casino/super-spade/teen-patti-20-20"
        },
        "akbar-romeo-walter": {
            "title": "Play Akbar Romeo Walter by Supernowa Live Casino at Yolo247!",
            "description": "Join the action with Akbar Romeo Walter by Supernowa Live Casino at Yolo247 India. Play for real money and experience thrilling gameplay! 💵🔥",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/akbar-romeo-walter",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/akbar-romeo-walter"
        },
        "goas-andar-bahar": {
            "title": "Play Goa's Andar Bahar by Supernowa Live Casino at Yolo247!",
            "description": "Enjoy Goa's Andar Bahar by Supernowa Live Casino at Yolo247 India. Play with live dealers and win big with every round! 🎲🔥",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/goas-andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/goas-andar-bahar"
        },
        "kings-race": {
            "title": "Play Kings Race by Supernowa Live Casino | Yolo247 India",
            "description": "Join Kings Race by Supernowa Live Casino at Yolo247 India. Race your luck with live action and claim your royal rewards! 👑🎰",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/kings-race",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/kings-race"
        },
        "worli-matka": {
            "title": "Play Worli Matka | Yolo247 Live Casino",
            "description": "Join the excitement of Worli Matka at Yolo247. Bet on numbers and enjoy the thrilling experience of this traditional live casino game. Play now!",
            "canonical": "https://www.yolo247.site/live-casino/worli-matka",
            "alternate": "https://m.yolo247.site/live-casino/worli-matka"
        },
        "1-bet": {
            "title": "Play 1 Bet by TV Bet Live Casino | Yolo247 India 🎲🔥",
            "description": "Experience the thrill of 'Play 1 Bet' at TV Bet Live Casino on Yolo247 India. Join now for fast-paced gameplay and big wins!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/1-bet",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/1-bet"
        },
        "5-bet": {
            "title": "Play 5 Bet by TV Bet Live Casino | Yolo247 India 🃏🌟",
            "description": "Step into the action with 'Play 5 Bet' on TV Bet Live Casino at Yolo247 India. Enjoy live gaming and win big!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/5-bet",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/5-bet"
        },
        "7-bet": {
            "title": "Play 7 Bet by TV Bet Live Casino | Yolo247 India 🎰💸",
            "description": "Try your luck with 'Play 7 Bet' at TV Bet Live Casino on Yolo247 India. Big rewards and endless fun await!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/7-bet",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/7-bet"
        },
        "fruit-race": {
            "title": "Play Fruit Race by TV Bet Live Casino | Yolo247 India 🍒🏎️",
            "description": "Play Fruit Race at TV Bet Live Casino on Yolo247 India. A thrilling, fast-paced game with fruity rewards awaits!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/fruit-race",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/fruit-race"
        },
        "war-elements": {
            "title": "Play War Elements by TV Bet Live Casino | Yolo247 India ⚔️🔥",
            "description": "Dive into the intense action of War Elements on TV Bet Live Casino at Yolo247 India. Start playing now and win big!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/war-elements",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/war-elements"
        },
        "wheel-bet": {
            "title": "Play Wheel Bet by TV Bet Live Casino | Yolo247 India 🎡💥",
            "description": "Spin the Wheel Bet at TV Bet Live Casino with Yolo247 India. Fun, excitement, and big wins are just a spin away!",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/wheel-bet",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/wheel-bet"
        },
        "vivo": {
            "title": "Play Vivo Live Casino at Yolo247 India | Big Wins Awaits!",
            "description": "Experience Vivo Live Casino at Yolo247 India! Enjoy real-time action, immersive gameplay, and exciting rewards with professional live dealers",
            "canonical": "https://www.yolo247.site/live-casino/vivo",
            "alternate": "https://m.yolo247.site/live-casino/vivo"
        },
        "andar-bahar-double-up": {
            "title": "Enjoy Andar Bahar Double Up by Vivo | Yolo247 India",
            "description": "Bet big with Andar Bahar Double Up by Vivo at Yolo247 India and enjoy live-action gameplay with great rewards! 🎯💰",
            "canonical": "https://www.yolo247.site/live-casino/vivo/andar-bahar-double-up",
            "alternate": "https://m.yolo247.site/live-casino/vivo/andar-bahar-double-up"
        },
        "baccarat-dance": {
            "title": "Play Baccarat Dance by Vivo Live Casino | Yolo247 India",
            "description": "Experience Baccarat Dance by Vivo at Yolo247 India. Bet now for real-time action and big payouts! 💃💰",
            "canonical": "https://www.yolo247.site/live-casino/vivo/baccarat-dance",
            "alternate": "https://m.yolo247.site/live-casino/vivo/baccarat-dance"
        },
        "carribean-baccarat": {
            "title": "Play Caribbean Baccarat Online by Vivo | Yolo247 India",
            "description": "Play Caribbean Baccarat by Vivo at Yolo247 India and enjoy exciting gameplay with real dealers and big rewards. 💰🎯",
            "canonical": "https://www.yolo247.site/live-casino/vivo/carribean-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/carribean-baccarat"
        },
        "copa-cabana-baccarat": {
            "title": "Play Copa Cabana Baccarat by Vivo | Yolo247 India",
            "description": "Enjoy Copa Cabana Baccarat by Vivo at Yolo247 India for real-time action and big winnings! 🏝️💸",
            "canonical": "https://www.yolo247.site/live-casino/vivo/copa-cabana-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/copa-cabana-baccarat"
        },
        "dancing-baccarat": {
            "title": "Play Dancing Baccarat by Vivo Live Casino | Yolo247 India",
            "description": "Play Dancing Baccarat by Vivo at Yolo247 India for a fun, fast-paced casino experience and massive rewards! 💃🎯",
            "canonical": "https://www.yolo247.site/live-casino/vivo/dancing-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/dancing-baccarat"
        },
        "imperial-dragon-tiger": {
            "title": "Enjoy Imperial Dragon Tiger by Vivo | Yolo247 India",
            "description": "Play Imperial Dragon Tiger by Vivo at Yolo247 India and experience live action and massive rewards in this exciting game! 🐉🐯",
            "canonical": "https://www.yolo247.site/live-casino/vivo/imperial-dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/vivo/imperial-dragon-tiger"
        },
        "infinite-casino-hold": {
            "title": "Enjoy Infinite Casino Hold'em by Vivo | Yolo247 India",
            "description": "Enjoy Infinite Casino Hold'em by Vivo at Yolo247 India for thrilling poker action and exciting rewards! ♠️💸",
            "canonical": "https://www.yolo247.site/live-casino/vivo/infinite-casino-hold",
            "alternate": "https://m.yolo247.site/live-casino/vivo/infinite-casino-hold"
        },
        "las-vegas-blackjack": {
            "title": "Enjoy Las Vegas Blackjack by Vivo | Yolo247 India",
            "description": "Enjoy Las Vegas Blackjack by Vivo at Yolo247 India for exciting gameplay and chances to win big! 🃏🎰",
            "canonical": "https://www.yolo247.site/live-casino/vivo/las-vegas-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/vivo/las-vegas-blackjack"
        },
        "limitless-blackjack": {
            "title": "Play Limitless Blackjack by Vivo | Yolo247 India",
            "description": "Play Limitless Blackjack by Vivo at Yolo247 India and enjoy a premium blackjack experience with big winnings! ♠️💵",
            "canonical": "https://www.yolo247.site/live-casino/vivo/limitless-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/vivo/limitless-blackjack"
        },
        "marina-bay-baccarat": {
            "title": "Play Marina Bay Baccarat by Vivo Live Casino | Yolo247 India",
            "description": "Play Marina Bay Baccarat by Vivo at Yolo247 India for fast-paced action and exciting rewards. 🌆💰",
            "canonical": "https://www.yolo247.site/live-casino/vivo/marina-bay-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/marina-bay-baccarat"
        },
        "miami-beach-baccarat": {
            "title": "Play Miami Beach Baccarat Online by Vivo | Yolo247 India",
            "description": "Play Miami Beach Baccarat by Vivo at Yolo247 India for thrilling action and massive rewards. Bet now! 🏖️💵",
            "canonical": "https://www.yolo247.site/live-casino/vivo/miami-beach-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/miami-beach-baccarat"
        },
        "oceania-vip-blackjack": {
            "title": "Enjoy Oceania VIP Blackjack by Vivo | Yolo247 India",
            "description": "Play Oceania VIP Blackjack by Vivo at Yolo247 India for a high-end blackjack experience with real-time action and great rewards! 💥",
            "canonical": "https://www.yolo247.site/live-casino/vivo/oceania-vip-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/vivo/oceania-vip-blackjack"
        },
        "singapore-baccarat": {
            "title": "Play Singapore Baccarat by Vivo | Yolo247 India",
            "description": "Play Singapore Baccarat by Vivo at Yolo247 India and enjoy live-action gameplay and big wins! 🏙️💥",
            "canonical": "https://www.yolo247.site/live-casino/vivo/singapore-baccarat",
            "alternate": "https://m.yolo247.site/live-casino/vivo/singapore-baccarat"
        },
        "taj-mahal-teen-patti": {
            "title": "Enjoy Taj Mahal Teen Patti by Vivo | Yolo247 India",
            "description": "Enjoy Taj Mahal Teen Patti by Vivo at Yolo247 India for a thrilling Indian card game experience and big wins! 🏰♠️",
            "canonical": "https://www.yolo247.site/live-casino/vivo/taj-mahal-teen-patti",
            "alternate": "https://m.yolo247.site/live-casino/vivo/taj-mahal-teen-patti"
        },
        "velvet-vip-blackjack": {
            "title": "Play Velvet VIP Blackjack by Vivo | Yolo247 India",
            "description": "Play Velvet VIP Blackjack by Vivo at Yolo247 India for a luxurious blackjack experience with big rewards! 👑♠️",
            "canonical": "https://www.yolo247.site/live-casino/vivo/velvet-vip-blackjack",
            "alternate": "https://m.yolo247.site/live-casino/vivo/velvet-vip-blackjack"
        },
        "vivo-lobby": {
            "title": "Play Vivo Lobby by Vivo Live Casino | Yolo247 India",
            "description": "Play Vivo Lobby by Vivo at Yolo247 India for an immersive live casino experience with endless gaming options. 🎲🎮",
            "canonical": "https://www.yolo247.site/live-casino/vivo/vivo-lobby",
            "alternate": "https://m.yolo247.site/live-casino/vivo/vivo-lobby"
        },
        "privacy-policy": {
            "title": "Yolo247 Privacy Policy | Protecting Your Data & Privacy",
            "description": "Review how Yolo247 collects, uses, and protects your personal information while ensuring a safe and secure experience on our platform",
            "canonical": "https://www.yolo247.site/privacy-policy",
            "alternate": "https://m.yolo247.site/about/privacy-policy"
        },
        "restricted-territories": {
            "title": "Yolo247 Restricted Territories | Limited Access by Country",
            "description": "Check the list of restricted territories where Yolo247 services are unavailable due to legal or regulatory reasons",
            "canonical": "https://www.yolo247.site/restricted-territories",
            "alternate": "https://m.yolo247.site/about/restricted-territories"
        },
        "rules-regulations": {
            "title": "Yolo247 Rules & Regulations | Safe Betting & Gambling",
            "description": "Review Yolo247’s rules and regulations to ensure a safe, fair, and enjoyable experience for all users in sports betting, casino games, and real money gambling",
            "canonical": "https://www.yolo247.site/rules-regulations",
            "alternate": "https://m.yolo247.site/about/rules-regulations"
        },
        "20-hot-spin": {
            "title": "Play 20 Hot Spin Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Spin 20 Hot Spin for sizzling rewards and epic wins! Play today at Yolo247 India for thrilling gameplay and huge rewards. Don’t miss out! 💎",
            "canonical": "https://www.yolo247.site/slots/7mojos/20-hot-spin",
            "alternate": "https://m.yolo247.site/slots/7mojos/20-hot-spin"
        },
        "40-fruity-reels": {
            "title": "Play 40 Fruity Reels Slot by 7 Mojos | Yolo247 India",
            "description": "🍍 Spin 40 Fruity Reels slot for tasty rewards! Play now and get ready for big wins, juicy surprises, and exciting gameplay at Yolo247 India! 🍒",
            "canonical": "https://www.yolo247.site/slots/7mojos/40-fruity-reels",
            "alternate": "https://m.yolo247.site/slots/7mojos/40-fruity-reels"
        },
        "40-hot-twist": {
            "title": "Play 40 Hot Twist Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Enjoy a Hot Twist with 40 Hot Twist slot! Spin for exciting rewards, sizzling wins, and non-stop fun at Yolo247 India. Play now and win big! 🎰",
            "canonical": "https://www.yolo247.site/slots/7mojos/40-hot-twist",
            "alternate": "https://m.yolo247.site/slots/7mojos/40-hot-twist"
        },
        "60-ultra-classic-hot": {
            "title": "Play 60 Ultra Classic Hot Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Double the fun with 60 Ultra Classic Hot! Spin for huge rewards, exciting gameplay, and epic wins at Yolo247 India. Don’t miss the action! 💥",
            "canonical": "https://www.yolo247.site/slots/7mojos/60-ultra-classic-hot",
            "alternate": "https://m.yolo247.site/slots/7mojos/60-ultra-classic-hot"
        },
        "80-fruit-dash": {
            "title": "Play 80 Fruit Dash Slot by 7 Mojos | Yolo247 India",
            "description": "🍉 Double the fun with 80 Fruit Dash slot! Spin for big wins, juicy rewards, and thrilling gameplay. Play now at Yolo247 India and win big! 🎰",
            "canonical": "https://www.yolo247.site/slots/7mojos/80-fruit-dash",
            "alternate": "https://m.yolo247.site/slots/7mojos/80-fruit-dash"
        },
        "80-hot-fortune-wheel": {
            "title": "Play 80 Hot Fortune Wheel Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Turn up the heat with 80 Hot Fortune Wheel! Spin for huge rewards and exciting gameplay. Don’t miss out, play now at Yolo247 India and win big! 💰",
            "canonical": "https://www.yolo247.site/slots/7mojos/80-hot-fortune-wheel",
            "alternate": "https://m.yolo247.site/slots/7mojos/80-hot-fortune-wheel"
        },
        "adventure-saga": {
            "title": "Play Adventure Saga Slot by 7 Mojos | Yolo247 India",
            "description": "🌍 Embark on an exciting journey with Adventure Saga slot! Spin for incredible rewards and enjoy epic gameplay at Yolo247 India. 🏅",
            "canonical": "https://www.yolo247.site/slots/7mojos/adventure-saga",
            "alternate": "https://m.yolo247.site/slots/7mojos/adventure-saga"
        },
        "age-of-cleopatra": {
            "title": "Play Age of Cleopatra Slot by 7 Mojos | Yolo247 India",
            "description": "🏺 Discover treasures in Age of Cleopatra slot! Spin the reels and win luxurious rewards at Yolo247 India. 💎 Start your adventure now!",
            "canonical": "https://www.yolo247.site/slots/7mojos/age-of-cleopatra",
            "alternate": "https://m.yolo247.site/slots/7mojos/age-of-cleopatra"
        },
        "bandidos-showdown": {
            "title": "Play Bandidos Showdown Slot by 7 Mojos | Yolo247 India",
            "description": "🤠 Join the Bandidos Showdown slot for thrilling action and massive rewards. Spin now at Yolo247 India for your chance to win big! 🏆",
            "canonical": "https://www.yolo247.site/slots/7mojos/bandidos-showdown",
            "alternate": "https://m.yolo247.site/slots/7mojos/bandidos-showdown"
        },
        "big-5-africa": {
            "title": "Play Big 5 Africa Slot by 7 Mojos | Yolo247 India",
            "description": "🦁 Explore Africa’s Big 5 with this exciting slot! Spin now for amazing wins and epic rewards at Yolo247 India! 🌍",
            "canonical": "https://www.yolo247.site/slots/7mojos/big-5-africa",
            "alternate": "https://m.yolo247.site/slots/7mojos/big-5-africa"
        },
        "dragons-flower": {
            "title": "Play Dragons Flower Slot by 7 Mojos | Yolo247 India",
            "description": "🐉 Unveil dragon-powered rewards in Dragons Flower slot! Spin the reels for big wins and magical gameplay at Yolo247 India! ✨",
            "canonical": "https://www.yolo247.site/slots/7mojos/dragons-flower",
            "alternate": "https://m.yolo247.site/slots/7mojos/dragons-flower"
        },
        "fortune-genie": {
            "title": "Play Fortune Genie Slot by 7 Mojos | Yolo247 India",
            "description": "🧞‍♂️ Make your wishes come true with Fortune Genie slot! Spin and win big rewards at Yolo247 India. 🎉 Start spinning now!",
            "canonical": "https://www.yolo247.site/slots/7mojos/fortune-genie",
            "alternate": "https://m.yolo247.site/slots/7mojos/fortune-genie"
        },
        "fruit-dash": {
            "title": "Play Fruit Dash Slot by 7 Mojos | Yolo247 India",
            "description": "🍇 Enjoy fruity fun with Fruit Dash slot! Spin the reels for juicy rewards and exciting gameplay at Yolo247 India. 🍓",
            "canonical": "https://www.yolo247.site/slots/7mojos/fruit-dash",
            "alternate": "https://m.yolo247.site/slots/7mojos/fruit-dash"
        },
        "fruity-reels": {
            "title": "Play Fruity Reels Slot by 7 Mojos | Yolo247 India",
            "description": "🍊 Spin the Fruity Reels slot for sweet wins! Enjoy juicy rewards, exciting gameplay, and endless fun. Play now at Yolo247 India for your chance to win! 🍓",
            "canonical": "https://www.yolo247.site/slots/7mojos/fruity-reels",
            "alternate": "https://m.yolo247.site/slots/7mojos/fruity-reels"
        },
        "golden-vegas": {
            "title": "Play Golden Vegas Slot by 7 Mojos | Yolo247 India",
            "description": "🎰 Step into Golden Vegas and spin for dazzling rewards! Experience exciting gameplay and win big! Play now at Yolo247 India and strike it rich! 💎",
            "canonical": "https://www.yolo247.site/slots/7mojos/golden-vegas",
            "alternate": "https://m.yolo247.site/slots/7mojos/golden-vegas"
        },
        "hot-fortune-wheel": {
            "title": "Play Hot Fortune Wheel Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Spin the Hot Fortune Wheel for sizzling rewards! Enjoy thrilling gameplay and win big today! Play now at Yolo247 India and feel the heat! 🎉",
            "canonical": "https://www.yolo247.site/slots/7mojos/hot-fortune-wheel",
            "alternate": "https://m.yolo247.site/slots/7mojos/hot-fortune-wheel"
        },
        "infinity-wilds": {
            "title": "Play Infinity Wilds Slot by 7 Mojos | Yolo247 India",
            "description": "🌟 Spin the Infinity Wilds slot for endless rewards, thrilling gameplay, and exciting prizes at Yolo247 India. Play now and win big with every spin! 🎰",
            "canonical": "https://www.yolo247.site/slots/7mojos/infinity-wilds",
            "alternate": "https://m.yolo247.site/slots/7mojos/infinity-wilds"
        },
        "lucky-fruit-lines": {
            "title": "Play Lucky Fruit Lines Slot by 7 Mojos | Yolo247 India",
            "description": "🍒 Spin the Lucky Fruit Lines slot for sweet wins, juicy rewards, and fun gameplay. Play now at Yolo247 India and experience the luck of the fruit! 🍇",
            "canonical": "https://www.yolo247.site/slots/7mojos/lucky-fruit-lines",
            "alternate": "https://m.yolo247.site/slots/7mojos/lucky-fruit-lines"
        },
        "patrick-s-riches": {
            "title": "Play Patrick's Riches Slot by 7 Mojos | Yolo247 India",
            "description": "🍀 Unlock Patrick's Riches slot for lucky wins! Spin the reels now and get rewarded with amazing prizes at Yolo247 India. Your fortune awaits! 🍀",
            "canonical": "https://www.yolo247.site/slots/7mojos/patrick-s-riches",
            "alternate": "https://m.yolo247.site/slots/7mojos/patrick-s-riches"
        },
        "rune-wars": {
            "title": "Play Rune Wars Slot by 7 Mojos | Yolo247 India",
            "description": "⚔️ Enter the battlefield with Rune Wars slot! Spin the reels for big rewards, exciting gameplay, and epic wins. Play now at Yolo247 India! 🏅",
            "canonical": "https://www.yolo247.site/slots/7mojos/rune-wars",
            "alternate": "https://m.yolo247.site/slots/7mojos/rune-wars"
        },
        "ultra-classic-hot": {
            "title": "Play Ultra Classic Hot Slot by 7 Mojos | Yolo247 India",
            "description": "🔥 Spin Ultra Classic Hot slot for sizzling rewards! Feel the excitement, enjoy big wins, and have fun at Yolo247 India. Play now and win big! 🎰",
            "canonical": "https://www.yolo247.site/slots/7mojos/ultra-classic-hot",
            "alternate": "https://m.yolo247.site/slots/7mojos/ultra-classic-hot"
        },
        "underwater-adventure": {
            "title": "Play Underwater Adventure Slot by 7 Mojos | Yolo247 India",
            "description": "🌊 Dive into the underwater adventure with thrilling gameplay! Spin the reels and win big rewards at Yolo247 India. Play now and dive deep into fun! 🏝️",
            "canonical": "https://www.yolo247.site/slots/7mojos/underwater-adventure",
            "alternate": "https://m.yolo247.site/slots/7mojos/underwater-adventure"
        },
        "wild-bandidos": {
            "title": "Play Wild Bandidos Slot by 7 Mojos | Yolo247 India",
            "description": "🎰 Spin the Wild Bandidos slot for big wins! Join the adventure and win exciting rewards at Yolo247 India. Start playing today! 💰",
            "canonical": "https://www.yolo247.site/slots/7mojos/wild-bandidos",
            "alternate": "https://m.yolo247.site/slots/7mojos/wild-bandidos"
        },
        "btg": {
            "title": "Play BTG Slot Games at Yolo247 India | Real Money Games",
            "description": "Play thrilling BTG slot games at Yolo247 India. Enjoy exciting spins, innovative features like Megaways, and win real money rewards. Start playing today!",
            "canonical": "https://www.yolo247.site/slots/btg",
            "alternate": "https://m.yolo247.site/slots/btg"
        },
        "bonanza": {
            "title": "Play Bonanza Megaways Slot by BTG Gaming | Yolo247 India",
            "description": "Play Bonanza Megaways Slot by BTG Gaming at Yolo247 India. Enjoy thrilling Megaways spins, massive payouts 💰, and exciting bonus features. Start winning now!",
            "canonical": "https://www.yolo247.site/slots/btg/bonanza",
            "alternate": "https://m.yolo247.site/slots/btg/bonanza"
        },
        "christmas-bonanza": {
            "title": "Play Christmas Bonanza Slot by BTG Gaming | Yolo247 India",
            "description": "Celebrate Christmas 🎄 with Christmas Bonanza Slot by BTG Gaming at Yolo247. Enjoy festive spins 🎉, big wins 💰, and bonus features. Spin today!",
            "canonical": "https://www.yolo247.site/slots/btg/christmas-bonanza",
            "alternate": "https://m.yolo247.site/slots/btg/christmas-bonanza"
        },
        "danger-high-voltage": {
            "title": "Play Danger High Voltage Slot by BTG Gaming | Yolo247 India",
            "description": "Experience electrifying spins ⚡ in Danger High Voltage Slot by BTG Gaming at Yolo247. Win big 💸 with thrilling features! Play now for exciting rewards!",
            "canonical": "https://www.yolo247.site/slots/btg/danger-high-voltage",
            "alternate": "https://m.yolo247.site/slots/btg/danger-high-voltage"
        },
        "extra-chilli": {
            "title": "Play Extra Chilli Slot by BTG Gaming | Yolo247 India",
            "description": "Spice things up 🌶️ with Extra Chilli Slot by BTG Gaming at Yolo247. Enjoy thrilling Megaways 🔄, big wins 💰, and exciting features. Start spinning!",
            "canonical": "https://www.yolo247.site/slots/btg/extra-chilli",
            "alternate": "https://m.yolo247.site/slots/btg/extra-chilli"
        },
        "gift-of-fortune": {
            "title": "Play Gift of Fortune Slot by BTG Gaming | Yolo247 India",
            "description": "Unwrap big wins 🎁 with Gift of Fortune Slot by BTG Gaming at Yolo247. Enjoy exciting features 🌟, huge rewards 💸, and real money gameplay!",
            "canonical": "https://www.yolo247.site/slots/btg/gift-of-fortune",
            "alternate": "https://m.yolo247.site/slots/btg/gift-of-fortune"
        },
        "golden-catch": {
            "title": "Play Golden Catch Slot by BTG Gaming | Yolo247 India",
            "description": "Catch golden rewards 🏅 with Golden Catch Slot by BTG Gaming at Yolo247. Play now to enjoy bonus features 🎰, big wins 💰, and real money rewards!",
            "canonical": "https://www.yolo247.site/slots/btg/golden-catch",
            "alternate": "https://m.yolo247.site/slots/btg/golden-catch"
        },
        "millionaire-megaways": {
            "title": "Play Millionaire Megaways Slot by BTG Gaming | Yolo247 India",
            "description": "Play Millionaire Megaways 💎 Slot by BTG Gaming at Yolo247. Enjoy Megaways 🔄, huge payouts 💰, and the chance to become a millionaire today!",
            "canonical": "https://www.yolo247.site/slots/btg/millionaire-megaways",
            "alternate": "https://m.yolo247.site/slots/btg/millionaire-megaways"
        },
        "the-race-megaways": {
            "title": "Play The Race Megaways Slot by BTG Gaming | Yolo247 India",
            "description": "Race to big wins 🏁 with The Race Megaways Slot by BTG Gaming at Yolo247. Fast-paced action 🚗, massive rewards 🏆, and exciting features await!",
            "canonical": "https://www.yolo247.site/slots/btg/the-race-megaways",
            "alternate": "https://m.yolo247.site/slots/btg/the-race-megaways"
        },
        "white-rabbit": {
            "title": "Play White Rabbit Slot by BTG Gaming | Yolo247 India",
            "description": "Follow the White Rabbit 🐇 to magical wins ✨ in this exciting slot by BTG Gaming at Yolo247. Enjoy huge payouts 💸 and thrilling gameplay today!",
            "canonical": "https://www.yolo247.site/slots/btg/white-rabbit",
            "alternate": "https://m.yolo247.site/slots/btg/white-rabbit"
        },
        "baccarat-777": {
            "title": "Play Baccarat 777 Slot by Evoplay Gaming | Yolo247 India",
            "description": "Play Baccarat 777 Slot by Evoplay Gaming at Yolo247 India. Feel the thrill of baccarat with an exciting slot twist!",
            "canonical": "https://www.yolo247.site/slots/evoplay/baccarat-777",
            "alternate": "https://m.yolo247.site/slots/evoplay/baccarat-777"
        },
        "basket-ball": {
            "title": "Play BasketBall Slot by Evoplay Gaming | Yolo247 India",
            "description": "Score big wins with BasketBall Slot by Evoplay Gaming. Take your shot and play for exciting rewards today at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/basket-ball",
            "alternate": "https://m.yolo247.site/slots/evoplay/basket-ball"
        },
        "blackjack-licky-seven": {
            "title": "Blackjack Lucky Seven Slot by Evoplay | Yolo247 India",
            "description": "Play Blackjack: Lucky Seven Slot by Evoplay at Yolo247 India. Enjoy thrilling card game mechanics with slot action.",
            "canonical": "https://www.yolo247.site/slots/evoplay/blackjack-licky-seven",
            "alternate": "https://m.yolo247.site/slots/evoplay/blackjack-licky-seven"
        },
        "brutal-santa": {
            "title": "Play Brutal Santa Slot by Evoplay Gaming | Yolo247 India",
            "description": "Celebrate festive wins with Brutal Santa Slot by Evoplay Gaming. Spin for joyful holiday rewards now at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/brutal-santa",
            "alternate": "https://m.yolo247.site/slots/evoplay/brutal-santa"
        },
        "candy-dreams": {
            "title": "Play Candy Dreams Slot by Evoplay Gaming | Yolo247 India",
            "description": "Indulge in sweet wins with Candy Dreams Slot by Evoplay Gaming. Play now and satisfy your sweet tooth with wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/candy-dreams",
            "alternate": "https://m.yolo247.site/slots/evoplay/candy-dreams"
        },
        "elven-princesses": {
            "title": "Play Elven Princesses Slot by Evoplay Gaming | Yolo247 India",
            "description": "Play Elven Princesses Slot by Evoplay at Yolo247 India. Dive into a magical adventure with enchanting rewards.",
            "canonical": "https://www.yolo247.site/slots/evoplay/elven-princesses",
            "alternate": "https://m.yolo247.site/slots/evoplay/elven-princesses"
        },
        "epic-gladiators": {
            "title": "Play Epic Gladiators Slot by Evoplay Gaming | Yolo247 India",
            "description": "Enter the arena with Epic Gladiators Slot by Evoplay Gaming. Unleash your warrior spirit and enjoy thrilling gameplay at Yolo247 India. Spin to win today!",
            "canonical": "https://www.yolo247.site/slots/evoplay/epic-gladiators",
            "alternate": "https://m.yolo247.site/slots/evoplay/epic-gladiators"
        },
        "epic-legends": {
            "title": "Play Epic Legends Slot by Evoplay Gaming | Yolo247 India",
            "description": "Unleash legendary wins with Epic Legends Slot by Evoplay Gaming. Spin for glory and mythical rewards at Yolo247 India now!",
            "canonical": "https://www.yolo247.site/slots/evoplay/epic-legends",
            "alternate": "https://m.yolo247.site/slots/evoplay/epic-legends"
        },
        "fruit-burst": {
            "title": "Play Fruit Burst Slot by Evoplay Gaming | Yolo247 India",
            "description": "Dive into juicy gameplay with Fruit Burst Slot by Evoplay Gaming. Spin for fruity wins and vibrant rewards at Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/evoplay/fruit-burst",
            "alternate": "https://m.yolo247.site/slots/evoplay/fruit-burst"
        },
        "fruit-nova": {
            "title": "Play Fruit Nova Slot by Evoplay Gaming | Yolo247 India",
            "description": "Savor classic fruity fun with Fruit Nova Slot by Evoplay Gaming. Experience vibrant gameplay and spin for juicy wins at Yolo247 India. Start playing!",
            "canonical": "https://www.yolo247.site/slots/evoplay/fruit-nova",
            "alternate": "https://m.yolo247.site/slots/evoplay/fruit-nova"
        },
        "hot-mania": {
            "title": "Play Hot Mania Slot by Evoplay Gaming | Yolo247 India",
            "description": "Fuel your excitement with Hot Mania Slot by Evoplay Gaming. Spin for fiery rewards and adrenaline-pumping gameplay at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/hot-mania",
            "alternate": "https://m.yolo247.site/slots/evoplay/hot-mania"
        },
        "hot-triple-sevens": {
            "title": "Play Hot Triple Sevens Slot by Evoplay | Yolo247 India",
            "description": "Ignite your winning streak with Hot Triple Sevens Slot by Evoplay. Play now at Yolo247 India for blazing spins and big rewards!",
            "canonical": "https://www.yolo247.site/slots/evoplay/hot-triple-sevens",
            "alternate": "https://m.yolo247.site/slots/evoplay/hot-triple-sevens"
        },
        "hot-triple-sevens-special": {
            "title": "Hot Triple Sevens Special Slot by Evoplay | Yolo247 India",
            "description": "Heat up your gameplay with Hot Triple Sevens Special Slot by Evoplay. Play for fiery jackpots today at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/hot-triple-sevens-special",
            "alternate": "https://m.yolo247.site/slots/evoplay/hot-triple-sevens-special"
        },
        "jelly-boom": {
            "title": "Play Jelly Boom Slot by Evoplay Gaming | Yolo247 India",
            "description": "Dive into a sugary adventure with Jelly Boom Slot by Evoplay Gaming. Play now for sweet spins and delightful wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/jelly-boom",
            "alternate": "https://m.yolo247.site/slots/evoplay/jelly-boom"
        },
        "lucky-crumbling": {
            "title": "Play Lucky Crumbling Slot by Evoplay Gaming | Yolo247 India",
            "description": "Spin for good fortune with Lucky Crumbling Slot by Evoplay Gaming. Play now at Yolo247 India and grab your luck!",
            "canonical": "https://www.yolo247.site/slots/evoplay/lucky-crumbling",
            "alternate": "https://m.yolo247.site/slots/evoplay/lucky-crumbling"
        },
        "magic-wheel": {
            "title": "Play Magic Wheel Slot by Evoplay Gaming | Yolo247 India",
            "description": "Spin the Magic Wheel Slot by Evoplay Gaming at Yolo247 India for exciting wins and thrilling gameplay. Try your luck now!",
            "canonical": "https://www.yolo247.site/slots/evoplay/magic-wheel",
            "alternate": "https://m.yolo247.site/slots/evoplay/magic-wheel"
        },
        "mine-field": {
            "title": "Play Mine Field Slot by Evoplay Gaming | Yolo247 India",
            "description": "Test your strategy with Mine Field Slot by Evoplay Gaming. Enjoy unique mechanics and exciting gameplay at Yolo247 India. Play for rewards now!",
            "canonical": "https://www.yolo247.site/slots/evoplay/mine-field",
            "alternate": "https://m.yolo247.site/slots/evoplay/mine-field"
        },
        "oasis-poker": {
            "title": "Play Oasis Poker Slot by Evoplay Gaming | Yolo247 India",
            "description": "Experience the thrill of classic poker in slot form with Oasis Poker Slot by Evoplay. Play now at Yolo247 India for an exciting gaming adventure!",
            "canonical": "https://www.yolo247.site/slots/evoplay/oasis-poker",
            "alternate": "https://m.yolo247.site/slots/evoplay/oasis-poker"
        },
        "racoon-tales": {
            "title": "Play Racoon Tales Slot by Evoplay Gaming | Yolo247 India",
            "description": "Embark on an adventure with Racoon Tales Slot by Evoplay Gaming. Spin for thrilling stories and big wins at Yolo247 India now!",
            "canonical": "https://www.yolo247.site/slots/evoplay/racoon-tales",
            "alternate": "https://m.yolo247.site/slots/evoplay/racoon-tales"
        },
        "rise-of-horus": {
            "title": "Play Rise of Horus Slot by Evoplay Gaming | Yolo247 India",
            "description": "Explore ancient Egypt with Rise of Horus Slot by Evoplay Gaming. Play now at Yolo247 India and uncover timeless treasures!",
            "canonical": "https://www.yolo247.site/slots/evoplay/rise-of-horus",
            "alternate": "https://m.yolo247.site/slots/evoplay/rise-of-horus"
        },
        "the-emperor-s-tomb": {
            "title": "Play The Emperor's Tomb Slot by Evoplay | Yolo247 India",
            "description": "Unlock ancient treasures in The Emperor's Tomb Slot by Evoplay. Spin now at Yolo247 India and claim your royal rewards!",
            "canonical": "https://www.yolo247.site/slots/evoplay/the-emperor-s-tomb",
            "alternate": "https://m.yolo247.site/slots/evoplay/the-emperor-s-tomb"
        },
        "treasure-mania": {
            "title": "Play Treasure Mania Slot by Evoplay Gaming | Yolo247 India",
            "description": "Discover hidden treasures in Treasure Mania Slot by Evoplay Gaming. Spin the reels for exciting rewards and adventure at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/evoplay/treasure-mania",
            "alternate": "https://m.yolo247.site/slots/evoplay/treasure-mania"
        },
        "treasure-snipes": {
            "title": "Play Treasure Snipes Slot by Evoplay Gaming | Yolo247 India",
            "description": "Hunt for hidden treasures in Treasure Snipes Slot by Evoplay Gaming. Spin for riches and uncover bountiful wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/treasure-snipes",
            "alternate": "https://m.yolo247.site/slots/evoplay/treasure-snipes"
        },
        "tree-of-light": {
            "title": "Play Tree of Light Slot by Evoplay Gaming | Yolo247 India",
            "description": "Embark on a magical journey with Tree of Light Slot by Evoplay Gaming. Spin and win enchanting rewards today at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/tree-of-light",
            "alternate": "https://m.yolo247.site/slots/evoplay/tree-of-light"
        },
        "wild-bullets": {
            "title": "Play Wild Bullets Slot by Evoplay Gaming | Yolo247 India",
            "description": "Saddle up for action with Wild Bullets Slot by Evoplay Gaming. Spin the reels and aim for thrilling rewards at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/evoplay/wild-bullets",
            "alternate": "https://m.yolo247.site/slots/evoplay/wild-bullets"
        },
        "wild-overlords-bonus-buy": {
            "title": "Wild Overlords Bonus Buy Slot by Evoplay | Yolo247 India",
            "description": "Dominate the reels with Wild Overlords Bonus Buy Slot by Evoplay. Spin for royal rewards and ultimate power at Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/evoplay/wild-overlords-bonus-buy",
            "alternate": "https://m.yolo247.site/slots/evoplay/wild-overlords-bonus-buy"
        },
        "fighting-games": {
            "title": "Play Fighting Slots Games | Yolo247 India",
            "description": "Enter the world of combat with thrilling fighting-themed slot games at Yolo247. Spin the reels and join the action-packed battles for exciting wins!",
            "canonical": "https://www.yolo247.site/slots/fighting-games",
            "alternate": "https://m.yolo247.site/slots/fighting-games"
        },
        "fruit-games": {
            "title": "Play Fruit Slot Games | Yolo247 India",
            "description": "Enjoy the classic fun of fruit-themed slots at Yolo247. Spin the colorful reels for juicy rewards and exciting wins in these timeless slot games.",
            "canonical": "https://www.yolo247.site/slots/fruit-games",
            "alternate": "https://m.yolo247.site/slots/fruit-games"
        },
        "jili-slot": {
            "title": "Top JILI Slot Games to Play | Yolo247 India",
            "description": "Explore the best JILI Slot games at Yolo247 India. Enjoy engaging features, exciting bonuses, and thrilling gameplay. Play now for big rewards!",
            "canonical": "https://www.yolo247.site/slots/jili-slot",
            "alternate": "https://m.yolo247.site/slots/jili-slot"
        },
        "aztec-priestess": {
            "title": "Aztec Priestess Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Embark on an ancient adventure with Aztec Priestess Slot by JILI Gaming. Spin for treasures at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/aztec-priestess",
            "alternate": "https://m.yolo247.site/slots/jili-slot/aztec-priestess"
        },
        "bangla-beauty": {
            "title": "Play Bangla Beauty Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Experience the vibrant beauty of Bangla Beauty Slot by JILI Gaming. Spin for exciting wins at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/bangla-beauty",
            "alternate": "https://m.yolo247.site/slots/jili-slot/bangla-beauty"
        },
        "book-of-gold": {
            "title": "Play Book of Gold Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Unlock treasures with Book of Gold Slot by JILI Gaming. Spin for riches at Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/book-of-gold",
            "alternate": "https://m.yolo247.site/slots/jili-slot/book-of-gold"
        },
        "boxing-king": {
            "title": "Play Boxing King Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Step into the ring with Boxing King Slot by JILI Gaming at Yolo247 India. Spin for knockout rewards today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/boxing-king",
            "alternate": "https://m.yolo247.site/slots/jili-slot/boxing-king"
        },
        "bubble-beauty": {
            "title": "Play Bubble Beauty Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Experience the fun of Bubble Beauty Slot by JILI Gaming. Spin for bubbly rewards at Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/bubble-beauty",
            "alternate": "https://m.yolo247.site/slots/jili-slot/bubble-beauty"
        },
        "charge-buffalo": {
            "title": "Play Charge Buffalo Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Feel the power of Charge Buffalo Slot by JILI Gaming at Yolo247 India. Spin for epic rewards and win big today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/charge-buffalo",
            "alternate": "https://m.yolo247.site/slots/jili-slot/charge-buffalo"
        },
        "crazy-fafafa": {
            "title": "Play Crazy Fafafa Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Spin for insane wins with Crazy Fafafa Slot by JILI Gaming. Enjoy thrilling gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/crazy-fafafa",
            "alternate": "https://m.yolo247.site/slots/jili-slot/crazy-fafafa"
        },
        "crazy-hunter": {
            "title": "Play Crazy Hunter Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Hunt for big rewards with Crazy Hunter Slot by JILI Gaming. Spin for thrilling wins at Yolo247 India. Start playing now!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/crazy-hunter",
            "alternate": "https://m.yolo247.site/slots/jili-slot/crazy-hunter"
        },
        "crazy777": {
            "title": "Play Crazy 777 Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Dive into the thrill of Crazy 777 Slot by JILI Gaming. Spin for huge jackpots and exciting wins at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/crazy777",
            "alternate": "https://m.yolo247.site/slots/jili-slot/crazy777"
        },
        "fa-fa-fa": {
            "title": "Play Fa Fa Fa Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Experience luck with Fa Fa Fa Slot by JILI Gaming. Spin for exciting wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/fa-fa-fa",
            "alternate": "https://m.yolo247.site/slots/jili-slot/fa-fa-fa"
        },
        "fortune-gems": {
            "title": "Play Fortune Gems Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Unlock hidden treasures in Fortune Gems Slot by JILI Gaming. Spin the reels for big wins at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/fortune-gems",
            "alternate": "https://m.yolo247.site/slots/jili-slot/fortune-gems"
        },
        "fortune-gems-2": {
            "title": "Play Fortune Gems 2 Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Discover the magic of Fortune Gems 2 Slot by JILI Gaming at Yolo247 India. Spin for rewards and win big today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/fortune-gems-2",
            "alternate": "https://m.yolo247.site/slots/jili-slot/fortune-gems-2"
        },
        "fortune-pig": {
            "title": "Play Fortune Pig Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Spin for wealth with Fortune Pig Slot by JILI Gaming. Play now at Yolo247 India for exciting wins and rewards!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/fortune-pig",
            "alternate": "https://m.yolo247.site/slots/jili-slot/fortune-pig"
        },
        "fortune-tree": {
            "title": "Play Fortune Tree Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Discover the Fortune Tree Slot by JILI Gaming. Spin for mystical rewards and big wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/fortune-tree",
            "alternate": "https://m.yolo247.site/slots/jili-slot/fortune-tree"
        },
        "golden-bank": {
            "title": "Play Golden Bank Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Unlock riches with Golden Bank Slot by JILI Gaming at Yolo247 India. Spin for rewards and big payouts today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/golden-bank",
            "alternate": "https://m.yolo247.site/slots/jili-slot/golden-bank"
        },
        "golden-empire": {
            "title": "Play Golden Empire Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Step into the Golden Empire Slot by JILI Gaming. Spin for golden rewards at Yolo247 India. Play now for big wins!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/golden-empire",
            "alternate": "https://m.yolo247.site/slots/jili-slot/golden-empire"
        },
        "hot-chili": {
            "title": "Play Hot Chili Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Spice up your gameplay with Hot Chili Slot by JILI Gaming. Play now at Yolo247 India for sizzling rewards!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/hot-chili",
            "alternate": "https://m.yolo247.site/slots/jili-slot/hot-chili"
        },
        "jili-caishen": {
            "title": "Play Jili Caishen Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Seek fortune with Jili Caishen Slot by JILI Gaming. Spin the reels for big wins at Yolo247 India. Start playing today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/jili-caishen",
            "alternate": "https://m.yolo247.site/slots/jili-slot/jili-caishen"
        },
        "lucky-coming": {
            "title": "Play Lucky Coming Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Experience the excitement of Lucky Coming Slot by JILI Gaming. Spin for huge rewards and wins at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/lucky-coming",
            "alternate": "https://m.yolo247.site/slots/jili-slot/lucky-coming"
        },
        "lucky-goldbricks": {
            "title": "Lucky Goldbricks Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Find your fortune with Lucky Goldbricks Slot by JILI Gaming at Yolo247 India. Spin for golden wins today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/lucky-goldbricks",
            "alternate": "https://m.yolo247.site/slots/jili-slot/lucky-goldbricks"
        },
        "mayan-empire": {
            "title": "Play Mayan Empire Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Explore the Mayan Empire Slot by JILI Gaming at Yolo247 India. Spin for ancient treasures and epic rewards today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/mayan-empire",
            "alternate": "https://m.yolo247.site/slots/jili-slot/mayan-empire"
        },
        "mega-ace": {
            "title": "Play Mega Ace Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Aim for big wins with Mega Ace Slot by JILI Gaming at Yolo247 India. Spin the reels for thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/mega-ace",
            "alternate": "https://m.yolo247.site/slots/jili-slot/mega-ace"
        },
        "money-coming": {
            "title": "Play Money Coming Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Spin the reels of Money Coming Slot by JILI Gaming at Yolo247 India. Experience exciting gameplay and win big rewards today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/money-coming",
            "alternate": "https://m.yolo247.site/slots/jili-slot/money-coming"
        },
        "rummy": {
            "title": "Play Rummy by JILI Slot Games | Yolo247 India",
            "description": "Dive into the world of Rummy with JILI slots at Yolo247. Enjoy fast-paced card action and thrilling wins in this classic card game slot experience!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/rummy",
            "alternate": "https://m.yolo247.site/slots/jili-slot/rummy"
        },
        "secret-treasure": {
            "title": "Play Secret Treasure Slot by JILI Slot | Yolo247 India",
            "description": "Uncover hidden riches in Secret Treasure Slot by JILI Gaming. Spin the reels for big payouts at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/secret-treasure",
            "alternate": "https://m.yolo247.site/slots/jili-slot/secret-treasure"
        },
        "super-ace": {
            "title": "Play Super Ace Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Unleash the power of Super Ace Slot by JILI Gaming at Yolo247 India. Spin for big payouts and enjoy thrilling gameplay today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/super-ace",
            "alternate": "https://m.yolo247.site/slots/jili-slot/super-ace"
        },
        "super-rich": {
            "title": "Play Super Rich Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Spin for fortune with Super Rich Slot by JILI Gaming. Play now at Yolo247 India for big payouts and exciting wins!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/super-rich",
            "alternate": "https://m.yolo247.site/slots/jili-slot/super-rich"
        },
        "war-of-dragons": {
            "title": "Play War of Dragons Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Join the War of Dragons Slot by JILI Gaming at Yolo247 India. Spin for legendary rewards and thrilling wins today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/war-of-dragons",
            "alternate": "https://m.yolo247.site/slots/jili-slot/war-of-dragons"
        },
        "world-cup": {
            "title": "Play World Cup Slot by JILI Slot Gaming | Yolo247 India",
            "description": "Celebrate football with World Cup Slot by JILI Gaming at Yolo247 India. Spin for big victories today!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/world-cup",
            "alternate": "https://m.yolo247.site/slots/jili-slot/world-cup"
        },
        "ka-gaming": {
            "title": "KA Gaming Slots at Yolo247 India | Play Top Casino Games",
            "description": "Experience thrilling KA Gaming slots at Yolo247 India! Play top-rated casino games with exciting features, big wins, and endless fun. Start spinning today! 🎮",
            "canonical": "https://www.yolo247.site/slots/ka-gaming",
            "alternate": "https://m.yolo247.site/slots/ka-gaming"
        },
        "4-dragon-kings": {
            "title": "Play 4 Dragon Kings Slot by KA Gaming | Yolo247 India",
            "description": "Spin 4 Dragon Kings Slot at Yolo247 India! Embark on an epic adventure and win massive rewards. Play now for legendary prizes! 🎰🐉",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/4-dragon-kings",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/4-dragon-kings"
        },
        "agent-angels": {
            "title": "Play Agent Angels Slot by KA Gaming | Yolo247 India",
            "description": "Join Agent Angels Slot by KA Gaming at Yolo247 India! Enjoy thrilling spins and huge rewards. Play now for exciting action and big wins! 🕵️‍♀️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/agent-angels",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/agent-angels"
        },
        "american-burger": {
            "title": "Play American Burger Slot by KA Gaming | Yolo247 India",
            "description": "Craving tasty rewards? Play American Burger Slot by KA Gaming at Yolo247 India! Spin for juicy wins and satisfying gameplay! 🍔🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/american-burger",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/american-burger"
        },
        "apollo-artemis": {
            "title": "Play Apollo & Artemis Slot by KA Gaming | Yolo247 India",
            "description": "Discover the mythological world with Apollo & Artemis Slot at Yolo247 India! Spin for divine rewards and epic gameplay. Play now! 🏛️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/apollo-artemis",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/apollo-artemis"
        },
        "atlantide": {
            "title": "Play Atlantide Slot by KA Gaming | Yolo247 India",
            "description": "Dive into the mystery of Atlantis with Atlantide Slot by KA Gaming at Yolo247 India. Spin for treasure-filled rewards and big wins! 🌊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/atlantide",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/atlantide"
        },
        "bigfoot-yeti": {
            "title": "Play Bigfoot Yeti Slot by KA Gaming | Yolo247 India",
            "description": "Unleash Bigfoot and Yeti for wild spins and rewards in Bigfoot Yeti Slot at Yolo247 India. Play now and win legendary prizes! 🐾🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/bigfoot-yeti",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/bigfoot-yeti"
        },
        "bollywood-romance": {
            "title": "Play Bollywood Romance Slot by KA Gaming | Yolo247 India",
            "description": "Play Bollywood Romance Slot by KA Gaming at Yolo247 India! Spin for love-filled rewards and exciting features. Play now for big wins! 💃🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/bollywood-romance",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/bollywood-romance"
        },
        "bonus-vending": {
            "title": "Play Bonus Vending Slot by KA Gaming | Yolo247 India",
            "description": "Spin the Bonus Vending Slot for exciting rewards at Yolo247 India. Unlock bonus features and win big! Play now for fun spins! 🎰🎉",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/bonus-vending",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/bonus-vending"
        },
        "book-of-moon-fusion-reels": {
            "title": "Play Book of Moon Fusion Reels Slot by KA Gaming | Yolo247 India",
            "description": "Unlock mystical rewards with Book of Moon Fusion Reels Slot at Yolo247 India. Spin now and discover thrilling wins! 🌙🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/book-of-moon-fusion-reels",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/book-of-moon-fusion-reels"
        },
        "book-of-mummy": {
            "title": "Play Book of Mummy Slot by KA Gaming | Yolo247 India",
            "description": "Play Book of Mummy Slot by KA Gaming at Yolo247 India. Uncover ancient treasures and big wins with each spin! Explore now! 🏺🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/book-of-mummy",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/book-of-mummy"
        },
        "bounty-hunter": {
            "title": "Play Bounty Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Hunt for bounties in Bounty Hunter Slot by KA Gaming at Yolo247 India! Spin for huge rewards and exciting features. Play now! 🏹🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/bounty-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/bounty-hunter"
        },
        "cat-museum": {
            "title": "Play Cat Museum Slot by KA Gaming | Yolo247 India",
            "description": "Visit the Cat Museum Slot at Yolo247 India and spin for treasures. Enjoy quirky gameplay and big rewards! Play now! 🐱🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/cat-museum",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/cat-museum"
        },
        "chai-gong": {
            "title": "Play Chai Gong Slot by KA Gaming | Yolo247 India",
            "description": "Explore Chai Gong Slot at Yolo247 India for rewarding spins and exciting gameplay. Spin now for big wins and magical rewards! 🍵🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/chai-gong",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/chai-gong"
        },
        "chain-of-wild": {
            "title": "Play Chain of Wild Slot by KA Gaming | Yolo247 India",
            "description": "Spin for wild rewards in Chain of Wild Slot at Yolo247 India. Unlock exciting features and win big! Play now for endless fun! 🐾🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/chain-of-wild",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/chain-of-wild"
        },
        "chivalrous-girl": {
            "title": "Play Chivalrous Girl Slot by KA Gaming | Yolo247 India",
            "description": "Enter the world of knights with Chivalrous Girl Slot at Yolo247 India. Spin for chivalric rewards and win big! 🏰🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/chivalrous-girl",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/chivalrous-girl"
        },
        "county-seat": {
            "title": "Play County Seat Slot by KA Gaming | Yolo247 India",
            "description": "Play County Seat Slot at Yolo247 India! Spin the reels for fun rewards and huge payouts. Try it now for exciting gameplay! 🌆🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/county-seat",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/county-seat"
        },
        "crazy-women-s-jail": {
            "title": "Play Crazy Women's Jail Slot by KA Gaming | Yolo247 India",
            "description": "Spin the Crazy Women’s Jail Slot at Yolo247 India for big rewards and unpredictable gameplay. Play now for thrilling spins! 🔒🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/crazy-women-s-jail",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/crazy-women-s-jail"
        },
        "creator-ra": {
            "title": "Play Creator RA Slot by KA Gaming | Yolo247 India",
            "description": "Explore ancient Egypt with Creator RA Slot at Yolo247 India! Spin the reels and win huge rewards. Play now! 🏺🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/creator-ra",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/creator-ra"
        },
        "cthulhu-secret": {
            "title": "Play Cthulhu Secret Slot by KA Gaming | Yolo247 India",
            "description": "Dive deep into Cthulhu Secret Slot at Yolo247 India. Spin for big wins and eerie rewards from the depths! 🦑🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/cthulhu-secret",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/cthulhu-secret"
        },
        "cupid-and-psyche": {
            "title": "Play Cupid and Psyche Slot by KA Gaming | Yolo247 India",
            "description": "Unlock the love story of Cupid and Psyche Slot at Yolo247 India. Spin for romantic wins and thrilling features! 💘🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/cupid-and-psyche",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/cupid-and-psyche"
        },
        "detective-dee": {
            "title": "Play Detective Dee Slot by KA Gaming | Yolo247 India",
            "description": "Solve mysteries in Detective Dee Slot at Yolo247 India. Spin for rewards and thrilling detective action! 🕵️‍♂️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/detective-dee",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/detective-dee"
        },
        "dragon-boat-2-lock-2-spin": {
            "title": "Play Dragon Boat 2 Lock 2 Spin Slot by KA Gaming | Yolo247 India",
            "description": "Enjoy Dragon Boat 2 Lock 2 Spin Slot at Yolo247 India. Spin for rewards and unlock epic features! Play now! 🐉🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/dragon-boat-2-lock-2-spin",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/dragon-boat-2-lock-2-spin"
        },
        "dragon-turtle": {
            "title": "Play Dragon Turtle Slot by KA Gaming | Yolo247 India",
            "description": "Discover wisdom in Dragon Turtle Slot at Yolo247 India. Spin the reels for big rewards and legendary features! 🐢🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/dragon-turtle",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/dragon-turtle"
        },
        "easter-egg-party": {
            "title": "Play Easter Egg Party Slot by KA Gaming | Yolo247 India",
            "description": "Celebrate with Easter Egg Party Slot at Yolo247 India! Spin for delightful rewards and festive wins. Play now! 🐣🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/easter-egg-party",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/easter-egg-party"
        },
        "eight-treasures": {
            "title": "Play Eight Treasures Slot by KA Gaming | Yolo247 India",
            "description": "Uncover Eight Treasures Slot at Yolo247 India for big rewards and fun gameplay! Spin now and win! 🏆🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/eight-treasures",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/eight-treasures"
        },
        "emperor-qin": {
            "title": "Play Emperor QIN Slot by KA Gaming | Yolo247 India",
            "description": "Spin Emperor QIN Slot at Yolo247 India for big wins and epic features. Unveil treasures with every spin! 👑🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/emperor-qin",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/emperor-qin"
        },
        "fantasy-dragons": {
            "title": "Play Fantasy Dragons Slot by KA Gaming | Yolo247 India",
            "description": "Embark on a Fantasy Dragons adventure at Yolo247 India. Spin for mythical rewards and epic wins! 🐉🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/fantasy-dragons",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/fantasy-dragons"
        },
        "fighting-girls": {
            "title": "Play Fighting Girls Slot by KA Gaming | Yolo247 India",
            "description": "Play Fighting Girls Slot at Yolo247 India! Spin for empowering rewards and fierce fun. Play now for thrilling wins! 👊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/fighting-girls",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/fighting-girls"
        },
        "fortune-beauty-megaways": {
            "title": "Play Fortune Beauty Megaways Slot by KA Gaming | Yolo247 India",
            "description": "Play Fortune Beauty Megaways Slot at Yolo247 India! Spin for stunning rewards and beauty-filled wins. 🌟🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/fortune-beauty-megaways",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/fortune-beauty-megaways"
        },
        "fu-lu-shou": {
            "title": "Play Fu Lu Shou Slot by KA Gaming | Yolo247 India",
            "description": "Unlock Fu Lu Shou Slot at Yolo247 India. Spin for fortune and happiness with every reel! 🧧🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/fu-lu-shou",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/fu-lu-shou"
        },
        "fu-senlong": {
            "title": "Play Fu Senlong Slot by KA Gaming | Yolo247 India",
            "description": "Spin Fu Senlong Slot at Yolo247 India for rewarding spins and legendary features. Play now for big wins! 🐉🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/fu-senlong",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/fu-senlong"
        },
        "ghost-festival": {
            "title": "Play Ghost Festival Slot by KA Gaming | Yolo247 India",
            "description": "Celebrate Ghost Festival Slot at Yolo247 India! Spin for spooky rewards and festive fun. Play now! 👻🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/ghost-festival",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/ghost-festival"
        },
        "ghosts-fortune": {
            "title": "Play Ghosts Fortune Slot by KA Gaming | Yolo247 India",
            "description": "Unveil Ghosts Fortune Slot at Yolo247 India. Spin for eerie rewards and thrilling features! 🧟‍♂️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/ghosts-fortune",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/ghosts-fortune"
        },
        "giant-fish-hunter": {
            "title": "Play Giant Fish Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Hunt for rewards in Giant Fish Hunter Slot at Yolo247 India. Spin now for big wins and underwater adventures! 🐠🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/giant-fish-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/giant-fish-hunter"
        },
        "go-go-magic-cat": {
            "title": "Play Go Go Magic Cat Slot by KA Gaming | Yolo247 India",
            "description": "Go Go Magic Cat Slot at Yolo247 India! Spin for magical rewards and purrfect gameplay! 🐾🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/go-go-magic-cat",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/go-go-magic-cat"
        },
        "go-go-magic-dog": {
            "title": "Play Go Go Magic Dog Slot by KA Gaming | Yolo247 India",
            "description": "Unleash Go Go Magic Dog Slot at Yolo247 India! Spin for rewards and fun features with every spin! 🐶🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/go-go-magic-dog",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/go-go-magic-dog"
        },
        "golden-777": {
            "title": "Play Golden 777 Slot by KA Gaming | Yolo247 India",
            "description": "Play Golden 777 Slot at Yolo247 India! Spin for classic rewards and big wins. Try now! 🍀🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/golden-777",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/golden-777"
        },
        "golden-fish": {
            "title": "Play Golden Fish Slot by KA Gaming | Yolo247 India",
            "description": "Dive into Golden Fish Slot at Yolo247 India for exciting spins and huge rewards! Play now for ocean treasures! 🐟🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/golden-fish",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/golden-fish"
        },
        "hamlet": {
            "title": "Play Hamlet Slot by KA Gaming | Yolo247 India",
            "description": "Explore Hamlet Slot at Yolo247 India for epic rewards and dramatic spins! Play now! 🎭🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hamlet",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hamlet"
        },
        "hansel-and-gretel": {
            "title": "Play Hansel and Gretel Slot by KA Gaming | Yolo247 India",
            "description": "Play Hansel and Gretel Slot at Yolo247 India! Spin for sweet rewards and exciting gameplay! 🍬🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hansel-and-gretel",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hansel-and-gretel"
        },
        "happy-food-hunter": {
            "title": "Play Happy Food Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Play Happy Food Hunter Slot at Yolo247 India! Spin for tasty rewards and exciting gameplay. Unleash your inner foodie for fun wins! 🍔🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/happy-food-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/happy-food-hunter"
        },
        "happy-indian-chef": {
            "title": "Play Happy Indian Chef Slot by KA Gaming | Yolo247 India",
            "description": "Cook up big wins with Happy Indian Chef Slot at Yolo247 India! Spin the reels for mouth-watering rewards and lots of fun! 🍛🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/happy-indian-chef",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/happy-indian-chef"
        },
        "happy-thangsgiving": {
            "title": "Play Happy Thangsgiving Slot by KA Gaming | Yolo247 India",
            "description": "Celebrate Thanksgiving with Happy Thanksgiving Slot at Yolo247 India! Spin for festive rewards and exciting gameplay this season! 🍂🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/happy-thangsgiving",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/happy-thangsgiving"
        },
        "hare-vs-tortoise": {
            "title": "Play hare Vs Tortoise Slot by KA Gaming | Yolo247 India",
            "description": "Spin for speedy rewards with Hare vs Tortoise Slot at Yolo247 India! Who will win the race? 🐰🐢🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hare-vs-tortoise",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hare-vs-tortoise"
        },
        "heroines-of-the-marsh": {
            "title": "Play Heroines of the Marsh Slot by KA Gaming | Yolo247 India",
            "description": "Play Heroines of the Marsh Slot at Yolo247 India for epic rewards and thrilling gameplay with legendary heroines! ⚔️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/heroines-of-the-marsh",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/heroines-of-the-marsh"
        },
        "hip-hop": {
            "title": "Play Hip Hop Slot by KA Gaming | Yolo247 India",
            "description": "Spin to the beat of Hip Hop Slot at Yolo247 India! Unlock big rewards and enjoy smooth tunes as you play! 🎤🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hip-hop",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hip-hop"
        },
        "hong-kong-60-s": {
            "title": "Play Hong Kong 60S Slot by KA Gaming | Yolo247 India",
            "description": "Travel back to Hong Kong 60s with retro rewards and fun spins at Yolo247 India! 🏙️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hong-kong-60-s",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hong-kong-60-s"
        },
        "hunter-helibu": {
            "title": "Play Hunter Helibu Slot by KA Gaming | Yolo247 India",
            "description": "Hunt for legendary prizes in Hunter Helibu Slot at Yolo247 India. Spin for wild rewards with every hunt! 🦅🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/hunter-helibu",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/hunter-helibu"
        },
        "iron-chicken-hunter": {
            "title": "Play Iron Chicken Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Spin for wild rewards in Iron Chicken Hunter Slot at Yolo247 India! Get ready to hunt down big prizes! 🐔🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/iron-chicken-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/iron-chicken-hunter"
        },
        "ka-fish-hunter": {
            "title": "Play ka Fish Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Dive into the KA Fish Hunter Slot at Yolo247 India for big oceanic rewards. Catch the big fish for thrilling wins! 🐟🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/ka-fish-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/ka-fish-hunter"
        },
        "legend-of-akihiro": {
            "title": "Play Legend of Akihiro Slot by KA Gaming | Yolo247 India",
            "description": "Spin for legendary rewards with Legend of Akihiro Slot at Yolo247 India! Enter the world of epic adventures and rich rewards! 🗡️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/legend-of-akihiro",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/legend-of-akihiro"
        },
        "legend-of-athena": {
            "title": "Play Legend of Athena Slot by KA Gaming | Yolo247 India",
            "description": "Unlock divine rewards with Legend of Athena Slot at Yolo247 India! Spin for a goddess's bounty of riches and glory! 🏛️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/legend-of-athena",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/legend-of-athena"
        },
        "legend-of-erlang": {
            "title": "Play Legend of Erlang Slot by KA Gaming | Yolo247 India",
            "description": "Spin for legendary wins with Legend of Erlang Slot at Yolo247 India! Enter the realm of heroes and mythical powers! ⚡🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/legend-of-erlang",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/legend-of-erlang"
        },
        "lion-king-and-eagle-king": {
            "title": "Play Lion King and Eagle King Slot by KA Gaming | Yolo247 India",
            "description": "Play Lion King and Eagle King Slot at Yolo247 India. Spin for wild rewards in this fierce animal kingdom! 🦁🦅🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/lion-king-and-eagle-king",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/lion-king-and-eagle-king"
        },
        "lucky-gem": {
            "title": "Play Lucky Gem Slot by KA Gaming | Yolo247 India",
            "description": "Hunt for Lucky Gems in Lucky Gem Slot at Yolo247 India! Spin the reels for dazzling rewards and good fortune! 💎🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/lucky-gem",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/lucky-gem"
        },
        "lucky-inn": {
            "title": "Play Lucky Inn Slot by KA Gaming | Yolo247 India",
            "description": "Relax with Lucky Inn Slot at Yolo247 India. Spin for cozy rewards and fun gameplay in a charming inn setting! 🏨🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/lucky-inn",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/lucky-inn"
        },
        "lucky-tumbler": {
            "title": "Play Lucky Tumbler Slot by KA Gaming | Yolo247 India",
            "description": "Unlock fortune in Lucky Tumbler Slot at Yolo247 India. Spin for exciting rewards and endless fun! 🍀🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/lucky-tumbler",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/lucky-tumbler"
        },
        "magic-queen": {
            "title": "Play Magic Queen Slot by KA Gaming | Yolo247 India",
            "description": "Enter the Magic Queen Slot at Yolo247 India and spin for royal wins and enchanted treasures! 👑🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/magic-queen",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/magic-queen"
        },
        "magician-house": {
            "title": "Play Magician House Slot by KA Gaming | Yolo247 India",
            "description": "Play Magician House Slot at Yolo247 India. Spin for magical rewards and a world of enchanted fun! 🎩🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/magician-house",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/magician-house"
        },
        "medusa": {
            "title": "Play Medusa Slot by KA Gaming | Yolo247 India",
            "description": "Unlock monstrous rewards with Medusa Slot at Yolo247 India! Spin the reels for creepy-cool wins and legendary prizes! 🐍🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/medusa",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/medusa"
        },
        "mermaid-hunter": {
            "title": "Play Mermaid Hunter Slot by KA Gaming | Yolo247 India",
            "description": "Spin for sea treasures in Mermaid Hunter Slot at Yolo247 India! Dive into the deep for magical rewards and legendary prizes! 🧜‍♀️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/mermaid-hunter",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/mermaid-hunter"
        },
        "midas-touch": {
            "title": "Play Midas Touch Slot by KA Gaming | Yolo247 India",
            "description": "Unlock golden rewards with Midas Touch Slot at Yolo247 India. Spin for rich prizes and mythological riches! ✨🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/midas-touch",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/midas-touch"
        },
        "mirror-house": {
            "title": "Play Mirror House Slot by KA Gaming | Yolo247 India",
            "description": "Spin for mysterious rewards in Mirror House Slot at Yolo247 India! Unlock hidden treasures in this enchanted house of mystery! 🏰🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/mirror-house",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/mirror-house"
        },
        "monkey-maniac": {
            "title": "Play Monkey Maniac Slot by KA Gaming | Yolo247 India",
            "description": "Go wild with Monkey Maniac Slot at Yolo247 India! Spin for fun rewards and jungle madness with every reel! 🐒🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/monkey-maniac",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/monkey-maniac"
        },
        "monster-island": {
            "title": "Play Monster Island Slot by KA Gaming | Yolo247 India",
            "description": "Explore Monster Island for wild rewards and thrilling spins at Yolo247 India! Face the monsters for legendary prizes! 🏝️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/monster-island",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/monster-island"
        },
        "moulin-rouge": {
            "title": "Play Moulin Rouge Slot by KA Gaming | Yolo247 India",
            "description": "Spin through Moulin Rouge with big prizes at Yolo247 India! Enjoy a night of dancing and dazzling rewards! 💃🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/moulin-rouge",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/moulin-rouge"
        },
        "muay-thai": {
            "title": "Play Muay Thai Slot by KA Gaming | Yolo247 India",
            "description": "Play Muay Thai Slot at Yolo247 India. Spin for powerful rewards and thrilling action in this martial arts adventure! 🥊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/muay-thai",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/muay-thai"
        },
        "mythical-beast": {
            "title": "Play Mythical Beast Slot by KA Gaming | Yolo247 India",
            "description": "Spin for mythical rewards with Mythical Beast Slot at Yolo247 India! Unlock legendary creatures and huge wins with every spin! 🐲🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/mythical-beast",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/mythical-beast"
        },
        "ninja": {
            "title": "Play Ninja Slot by KA Gaming | Yolo247 India",
            "description": "Unlock stealthy rewards with Ninja Slot at Yolo247 India! Spin for ninja skills and big prizes in this action-packed slot! 🥷🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/ninja",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/ninja"
        },
        "ocean-princess": {
            "title": "Play Ocean Princess Slot by KA Gaming | Yolo247 India",
            "description": "Spin for treasures in Ocean Princess Slot at Yolo247 India! Dive into the ocean for rich rewards and amazing prizes! 🐠🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/ocean-princess",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/ocean-princess"
        },
        "oktoberfest": {
            "title": "Play Oktoberfest Slot by KA Gaming | Yolo247 India",
            "description": "Celebrate Oktoberfest with fun rewards at Yolo247 India! Spin for festive fun, tasty prizes, and exciting gameplay! 🍺🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/oktoberfest",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/oktoberfest"
        },
        "panda-family": {
            "title": "Play Panda Family Slot by KA Gaming | Yolo247 India",
            "description": "Play Panda Family Slot at Yolo247 India. Spin for sweet rewards and fun gameplay with this adorable panda family! 🐼🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/panda-family",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/panda-family"
        },
        "pied-piper": {
            "title": "Play Pied Piper Slot by KA Gaming | Yolo247 India",
            "description": "Unlock magical rewards with Pied Piper Slot at Yolo247 India! Spin the reels for enchanting wins and fairy-tale rewards! 🎶🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/pied-piper",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/pied-piper"
        },
        "poseidon-s-secret": {
            "title": "Play Poseidon's Secret Slot by KA Gaming | Yolo247 India",
            "description": "Spin for oceanic treasures in Poseidon's Secret Slot at Yolo247 India! Unlock mythical rewards with every spin! 🌊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/poseidon-s-secret",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/poseidon-s-secret"
        },
        "robot-wars": {
            "title": "Play Robot Wars Slot by KA Gaming | Yolo247 India",
            "description": "Play Robot Wars Slot at Yolo247 India! Spin for futuristic rewards and high-tech action in this exciting slot! 🤖🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/robot-wars",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/robot-wars"
        },
        "sailor-man": {
            "title": "Play Sailor Man Slot by KA Gaming | Yolo247 India",
            "description": "Sail with Sailor Man Slot at Yolo247 India. Spin for seafaring rewards and thrilling ocean adventures! ⛵🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/sailor-man",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/sailor-man"
        },
        "santa-bumble-bee-hold-and-win": {
            "title": "Play Santa Bumble Bee Hold And Win Slot by KA Gaming | Yolo247 India",
            "description": "Spin for festive fun in Santa Bumble Bee Slot at Yolo247 India! Celebrate the holidays with big wins and Christmas cheer! 🎅🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/santa-bumble-bee-hold-and-win",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/santa-bumble-bee-hold-and-win"
        },
        "seagull": {
            "title": "Play Seagull Slot by KA Gaming | Yolo247 India",
            "description": "Play Seagull Slot at Yolo247 India. Spin for breezy rewards and exciting gameplay in this coastal adventure! 🌊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/seagull",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/seagull"
        },
        "secret-agent": {
            "title": "Play Secret Agent Slot by KA Gaming | Yolo247 India",
            "description": "Spin as a secret agent in Secret Agent Slot at Yolo247 India! Unlock thrilling missions and big rewards with every spin! 🕶️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/secret-agent",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/secret-agent"
        },
        "shaolin-legend": {
            "title": "Play Shaolin Legend Slot by KA Gaming | Yolo247 India",
            "description": "Enter Shaolin legends with Shaolin Legend Slot at Yolo247 India! Spin for martial arts rewards and legendary adventures! 🥋🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/shaolin-legend",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/shaolin-legend"
        },
        "shen-nong": {
            "title": "Play Shen Nong Slot by KA Gaming | Yolo247 India",
            "description": "Spin for ancient rewards with Shen Nong Slot at Yolo247 India! Discover mystical prizes and unlock the secrets of history! 🌿🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/shen-nong",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/shen-nong"
        },
        "sky-journey": {
            "title": "Play Sky Journey Slot by KA Gaming | Yolo247 India",
            "description": "Play Sky Journey Slot at Yolo247 India. Spin for heavenly rewards and a sky-high adventure with every reel! ☁️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/sky-journey",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/sky-journey"
        },
        "solomon-s-treasure": {
            "title": "Play Solomon's Treasure Slot by KA Gaming | Yolo247 India",
            "description": "Unlock royal riches with Solomon's Treasure Slot at Yolo247 India! Spin for treasures fit for a king! 👑🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/solomon-s-treasure",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/solomon-s-treasure"
        },
        "space-cat": {
            "title": "Play Space Cat Slot by KA Gaming | Yolo247 India",
            "description": "Explore Space Cat Slot at Yolo247 India. Spin for cosmic rewards and intergalactic fun! 🚀🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/space-cat",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/space-cat"
        },
        "space-cowboy": {
            "title": "Play Space Cowboy Slot by KA Gaming | Yolo247 India",
            "description": "Discover Space Cowboy rewards at Yolo247 India! Spin for stellar wins and cosmic prizes in this wild adventure! 🌌🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/space-cowboy",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/space-cowboy"
        },
        "special-ops": {
            "title": "Play Special OPS Slot by KA Gaming | Yolo247 India",
            "description": "Join Special OPS Slot at Yolo247 India for thrilling action, stealth, and big rewards! 🎖️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/special-ops",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/special-ops"
        },
        "squid-party-lock-2-spin": {
            "title": "Play Squid Party Lock 2 Spin Slot by KA Gaming | Yolo247 India",
            "description": "Spin deep-sea rewards with Squid Party Lock 2 Spin Slot at Yolo247 India! Dive into underwater adventures for big prizes! 🦑🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/squid-party-lock-2-spin",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/squid-party-lock-2-spin"
        },
        "super-dragon-tiger": {
            "title": "Play Super Dragon Tiger Slot by KA Gaming | Yolo247 India",
            "description": "Spin for fiery rewards in Super Dragon Tiger Slot at Yolo247 India! Battle for massive wins with every spin! 🐉🐯🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/super-dragon-tiger",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/super-dragon-tiger"
        },
        "super-energy": {
            "title": "Play Super Energy Slot by KA Gaming | Yolo247 India",
            "description": "Power up with Super Energy Slot at Yolo247 India! Spin for exciting, high-energy rewards and massive wins! ⚡🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/super-energy",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/super-energy"
        },
        "super-keno": {
            "title": "Play Super Keno Slot by KA Gaming | Yolo247 India",
            "description": "Play Super Keno Slot at Yolo247 India for lucky prizes and thrilling gameplay with every spin! 🎰🎉",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/super-keno",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/super-keno"
        },
        "super-slime": {
            "title": "Play Super Slime Slot by KA Gaming | Yolo247 India",
            "description": "Spin for fun rewards in Super Slime Slot at Yolo247 India! Unleash sticky fun and huge prizes with every reel! 🧪🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/super-slime",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/super-slime"
        },
        "sweet-maid": {
            "title": "Play Sweet Maid Slot by KA Gaming | Yolo247 India",
            "description": "Enjoy sweet rewards with Sweet Maid Slot at Yolo247 India! Spin for delicious prizes in this sugar-coated adventure! 🍬🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/sweet-maid",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/sweet-maid"
        },
        "sweetopia": {
            "title": "Play Sweetopia Slot by KA Gaming | Yolo247 India",
            "description": "Play Sweetopia Slot at Yolo247 India. Spin for candy-filled rewards and magical gameplay in this sweet world! 🍭🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/sweetopia",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/sweetopia"
        },
        "the-boy-who-cried-wolf": {
            "title": "Play The Boy Who Cried Wolf Slot by KA Gaming | Yolo247 India",
            "description": "Unlock thrilling adventures with The Boy Who Cried Wolf Slot at Yolo247 India! Spin for wild rewards and legendary tales! 🐺🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/the-boy-who-cried-wolf",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/the-boy-who-cried-wolf"
        },
        "the-deep-monster": {
            "title": "Play The Deep Monster Slot by KA Gaming | Yolo247 India",
            "description": "Spin for monstrous rewards in The Deep Monster Slot at Yolo247 India! Dive deep into the ocean for thrilling prizes! 🐙🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/the-deep-monster",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/the-deep-monster"
        },
        "three-headed-dragon": {
            "title": "Play Three Headed Dragon Slot by KA Gaming | Yolo247 India",
            "description": "Unlock epic rewards with Three Headed Dragon Slot at Yolo247 India! Spin for legendary prizes and fierce action! 🐉🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/three-headed-dragon",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/three-headed-dragon"
        },
        "treasure-bowl-megaways": {
            "title": "Play Treasure Bowl Megaways Slot by KA Gaming | Yolo247 India",
            "description": "Spin for massive rewards with Treasure Bowl Megaways Slot at Yolo247 India! Unlock exciting bonuses and legendary wins! 🏆🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/treasure-bowl-megaways",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/treasure-bowl-megaways"
        },
        "treasure-carp": {
            "title": "Play Treasure Carp Slot by KA Gaming | Yolo247 India",
            "description": "Unlock aquatic treasures in Treasure Carp Slot at Yolo247 India! Dive for riches and exciting rewards in this underwater adventure! 🐟🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/treasure-carp",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/treasure-carp"
        },
        "treasure-raider": {
            "title": "Play Treasure Raider Slot by KA Gaming | Yolo247 India",
            "description": "Spin for riches with Treasure Raider Slot at Yolo247 India! Embark on a treasure hunt for big wins and legendary rewards! 🏺🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/treasure-raider",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/treasure-raider"
        },
        "treasure-tiger": {
            "title": "Play Treasure Tiger Slot by KA Gaming | Yolo247 India",
            "description": "Hunt for big rewards in Treasure Tiger Slot at Yolo247 India! Spin for exotic riches and wild prizes! 🐅🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/treasure-tiger",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/treasure-tiger"
        },
        "undersea-battle": {
            "title": "Play Undersea Battle Slot by KA Gaming | Yolo247 India",
            "description": "Play Undersea Battle Slot at Yolo247 India. Spin for deep-sea treasures and huge rewards with every reel! 🌊🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/undersea-battle",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/undersea-battle"
        },
        "whale-wild": {
            "title": "Play Whale Wild Slot by KA Gaming | Yolo247 India",
            "description": "Spin for oceanic rewards in Whale Wild Slot at Yolo247 India! Dive into deep waters for thrilling wins and legendary prizes! 🐋🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/whale-wild",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/whale-wild"
        },
        "white-deer": {
            "title": "Play White deer Slot by KA Gaming | Yolo247 India",
            "description": "Play White Deer Slot at Yolo247 India for serene rewards and spins in this peaceful, natural setting! 🦌🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/white-deer",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/white-deer"
        },
        "who-let-the-dogs-out": {
            "title": "Play Who let the dogs out Slot by KA Gaming | Yolo247 India",
            "description": "Who Let the Dogs Out Slot at Yolo247 India. Spin for fun and big rewards in this playful, dog-loving slot! 🐕🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/who-let-the-dogs-out",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/who-let-the-dogs-out"
        },
        "wild-jungle": {
            "title": "Play Wild Jungle Slot by KA Gaming | Yolo247 India",
            "description": "Explore Wild Jungle Slot at Yolo247 India. Spin for untamed fun and wild rewards in this jungle adventure! 🌳🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/wild-jungle",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/wild-jungle"
        },
        "wild-vick-2-adventure-journey": {
            "title": "Play Wild Vick 2 Adventure Journey Slot by KA Gaming | Yolo247 India",
            "description": "Join the adventure with Wild Vick 2 Slot at Yolo247 India. Spin for big rewards and action-packed excitement! 🧭🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/wild-vick-2-adventure-journey",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/wild-vick-2-adventure-journey"
        },
        "witch-academy": {
            "title": "Play Witch Academy Slot by KA Gaming | Yolo247 India",
            "description": "Unlock magical rewards with Witch Academy Slot at Yolo247 India! Spin for magical adventures and big prizes! 🧙‍♀️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/witch-academy",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/witch-academy"
        },
        "wu-zetian": {
            "title": "Play Wu Zetian Slot by KA Gaming | Yolo247 India",
            "description": "Spin for historical rewards in Wu Zetian Slot at Yolo247 India! Unlock royal treasures and legendary wins with every spin! 🏯🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/wu-zetian",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/wu-zetian"
        },
        "zashiki-god": {
            "title": "Play Zashiki God Slot by KA Gaming | Yolo247 India",
            "description": "Discover divine rewards in Zashiki God Slot at Yolo247 India! Spin for mythical rewards and ancient riches! 🏯🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/zashiki-god",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/zashiki-god"
        },
        "zombie-chicken": {
            "title": "Play Zombie Chicken Slot by KA Gaming | Yolo247 India",
            "description": "Spin for eerie fun and rewards in Zombie Chicken Slot at Yolo247 India! Enter a spooky adventure for thrilling wins! 🐔🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/zombie-chicken",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/zombie-chicken"
        },
        "zombie-expert-lock-2-spin": {
            "title": "Play Zombie Expert Lock 2 Spin Slot by KA Gaming | Yolo247 India",
            "description": "Play Zombie Expert Lock 2 Spin Slot at Yolo247 India for thrilling undead rewards and spooky action! 🧟‍♂️🎰",
            "canonical": "https://www.yolo247.site/slots/ka-gaming/zombie-expert-lock-2-spin",
            "alternate": "https://m.yolo247.site/slots/ka-gaming/zombie-expert-lock-2-spin"
        },
        "live-games": {
            "title": "Play Live Slot Games | Yolo247 India",
            "description": "Experience the thrill of live slots at Yolo247. Play in real-time with professional dealers and enjoy interactive gameplay with big win opportunities!",
            "canonical": "https://www.yolo247.site/slots/live-games",
            "alternate": "https://m.yolo247.site/slots/live-games"
        },
        "bollywood-stars": {
            "title": "Play Bollywood Stars Slot by Mplay | Yolo247 India",
            "description": "Play Bollywood Stars Slot by Mplay at Yolo247 🎮. Spin the reels with your favorite stars and win real money rewards! 🌟💰",
            "canonical": "https://www.yolo247.site/slots/mplay/bollywood-stars",
            "alternate": "https://m.yolo247.site/slots/mplay/bollywood-stars"
        },
        "book-of-ankh": {
            "title": "Play Book of Ankh Slot by Mplay | Yolo247 India",
            "description": "Play Book of Ankh Slot by Mplay at Yolo247 🎮. Dive into ancient treasures and win real money with every spin! 📚💰",
            "canonical": "https://www.yolo247.site/slots/mplay/book-of-ankh",
            "alternate": "https://m.yolo247.site/slots/mplay/book-of-ankh"
        },
        "crorepati-challenge": {
            "title": "Play Crorepati Challenge Slot by Mplay | Yolo247 India",
            "description": "Play Crorepati Challenge Slot by Mplay at Yolo247 🎮. Spin to win exciting rewards and experience the thrill of real money gameplay. Start winning today! 💰✨",
            "canonical": "https://www.yolo247.site/slots/mplay/crorepati-challenge",
            "alternate": "https://m.yolo247.site/slots/mplay/crorepati-challenge"
        },
        "diwali-dhamaka": {
            "title": "Play Diwali Dhamaka Slot by Mplay | Yolo247 India",
            "description": "Play Diwali Dhamaka Slot by Mplay at Yolo247 🎮. Celebrate the festival of lights with exciting spins and real money rewards! 🎆💰🪔",
            "canonical": "https://www.yolo247.site/slots/mplay/diwali-dhamaka",
            "alternate": "https://m.yolo247.site/slots/mplay/diwali-dhamaka"
        },
        "double-up": {
            "title": "Play Double up Slot by Mplay | Yolo247 India",
            "description": "Play Double Up Slot by Mplay at Yolo247 🎮. Enjoy double the fun and exciting rewards with each spin! Win big with real money gameplay! 💸🎉",
            "canonical": "https://www.yolo247.site/slots/mplay/double-up",
            "alternate": "https://m.yolo247.site/slots/mplay/double-up"
        },
        "fruity-777": {
            "title": "Play Fruity 777 Slot by Mplay | Yolo247 India",
            "description": "Play Fruity 777 Slot by Mplay at Yolo247 🎮. Enjoy a refreshing experience with juicy rewards and real money wins! 🍓🍊💸",
            "canonical": "https://www.yolo247.site/slots/mplay/fruity-777",
            "alternate": "https://m.yolo247.site/slots/mplay/fruity-777"
        },
        "indian-t20-league": {
            "title": "Play Indian T20 League Slot by Mplay | Yolo247 India",
            "description": "Play Indian T20 League Slot by Mplay at Yolo247 🎮. Join the action, enjoy great bonuses, and win real money rewards! Start spinning now! 🏏💰",
            "canonical": "https://www.yolo247.site/slots/mplay/indian-t20-league",
            "alternate": "https://m.yolo247.site/slots/mplay/indian-t20-league"
        },
        "ludo-express": {
            "title": "Play Ludo Express Slot by Mplay | Yolo247 India",
            "description": "Play Ludo Express Slot by Mplay at Yolo247 🎮. Spin the reels for a classic game with modern twists and win real money! 🎲💰",
            "canonical": "https://www.yolo247.site/slots/mplay/ludo-express",
            "alternate": "https://m.yolo247.site/slots/mplay/ludo-express"
        },
        "mystic-magician": {
            "title": "Play Mystic Magician Slot by Mplay | Yolo247 India",
            "description": "Play Mystic Magician Slot by Mplay at Yolo247 🎮. Unveil magical rewards and spin the reels for exciting real money wins! ✨🎩💰",
            "canonical": "https://www.yolo247.site/slots/mplay/mystic-magician",
            "alternate": "https://m.yolo247.site/slots/mplay/mystic-magician"
        },
        "snakes-ladders": {
            "title": "Play Snakes & Ladders Slot by Mplay | Yolo247 India",
            "description": "Play Snakes & Ladders Slot by Mplay at Yolo247 🎮. Climb the ladder to big wins and dodge the snakes for real money rewards! 🐍🎲💸",
            "canonical": "https://www.yolo247.site/slots/mplay/snakes-ladders",
            "alternate": "https://m.yolo247.site/slots/mplay/snakes-ladders"
        },
        "tropical-birds": {
            "title": "Play Tropical Birds Slot by Mplay | Yolo247 India",
            "description": "Play Tropical Birds Slot by Mplay at Yolo247 🎮. Dive into the tropical paradise, enjoy thrilling features, and win real money! 🦜🌴💸",
            "canonical": "https://www.yolo247.site/slots/mplay/tropical-birds",
            "alternate": "https://m.yolo247.site/slots/mplay/tropical-birds"
        },
        "wild-west-mystery": {
            "title": "Play Wild West Mystery Slot by Mplay | Yolo247 India",
            "description": "Play Wild West Mystery Slot by Mplay at Yolo247 🎮. Uncover hidden treasures and win big in the wild west with real money! 🤠💸",
            "canonical": "https://www.yolo247.site/slots/mplay/wild-west-mystery",
            "alternate": "https://m.yolo247.site/slots/mplay/wild-west-mystery"
        },
        "aloha-christmas": {
            "title": "Play Aloha! Christmas Slot by Netent | Yolo247 India",
            "description": "Play Aloha! Christmas Slot by NetEnt at Yolo247 India. Enjoy festive reels, big wins, and free spins in this tropical Christmas-themed slot game!",
            "canonical": "https://www.yolo247.site/slots/netent/aloha-christmas",
            "alternate": "https://m.yolo247.site/slots/netent/aloha-christmas"
        },
        "aloha-cluster-pays": {
            "title": "Play Aloha! Cluster Pays Slot by Netent | Yolo247 India",
            "description": "Play Aloha! Cluster Pays Slot by NetEnt at Yolo247 India. Enjoy a fun Hawaiian-themed slot with cluster pays, free spins, and massive win potential!",
            "canonical": "https://www.yolo247.site/slots/netent/aloha-cluster-pays",
            "alternate": "https://m.yolo247.site/slots/netent/aloha-cluster-pays"
        },
        "asgardian-stones": {
            "title": "Play Asgardian Stones Slot by Netent | Yolo247 India",
            "description": "Play Asgardian Stones Slot by NetEnt at Yolo247 India. Immerse yourself in Norse mythology with cascading reels and exciting bonus features for big wins!",
            "canonical": "https://www.yolo247.site/slots/netent/asgardian-stones",
            "alternate": "https://m.yolo247.site/slots/netent/asgardian-stones"
        },
        "blood-suckers": {
            "title": "Play Blood Suckers Slot by Netent | Yolo247 India",
            "description": "Play Blood Suckers Slot by Netent at Yolo247 🎮. Enter a spooky world of vampires, trigger bonus features, and win real money rewards. Spin the reels now! 🧛‍♂️",
            "canonical": "https://www.yolo247.site/slots/netent/blood-suckers",
            "alternate": "https://m.yolo247.site/slots/netent/blood-suckers"
        },
        "blood-suckers-2": {
            "title": "Play Blood Suckers 2 Slot by Netent | Yolo247 India",
            "description": "Play Blood Suckers 2 Slot by NetEnt at Yolo247 India. Enter a thrilling vampire world filled with big rewards, exciting bonus features, and immersive gameplay!",
            "canonical": "https://www.yolo247.site/slots/netent/blood-suckers-2",
            "alternate": "https://m.yolo247.site/slots/netent/blood-suckers-2"
        },
        "butterfly-staxx": {
            "title": "Play Butterfly Staxx Slot by Netent | Yolo247 India",
            "description": "Play Butterfly Staxx Slot by NetEnt at Yolo247 India. Experience an enchanting slot game filled with vibrant butterflies, exciting features, and big rewards!",
            "canonical": "https://www.yolo247.site/slots/netent/butterfly-staxx",
            "alternate": "https://m.yolo247.site/slots/netent/butterfly-staxx"
        },
        "butterfly-staxx-2": {
            "title": "Play Butterfly Staxx 2 Slot by Netent | Yolo247 India",
            "description": "Play Butterfly Staxx 2 Slot by NetEnt at Yolo247 India. Dive into a colorful world of butterflies, exciting features, and high rewards!",
            "canonical": "https://www.yolo247.site/slots/netent/butterfly-staxx-2",
            "alternate": "https://m.yolo247.site/slots/netent/butterfly-staxx-2"
        },
        "cash-noire": {
            "title": "Play Cash Noire Slot by Netent | Yolo247 India",
            "description": "Play Cash Noire Slot by NetEnt at Yolo247 India. Immerse yourself in a mysterious detective-themed game with captivating features and rewarding bonuses!",
            "canonical": "https://www.yolo247.site/slots/netent/cash-noire",
            "alternate": "https://m.yolo247.site/slots/netent/cash-noire"
        },
        "cash-o-matic": {
            "title": "Play Cash-o-Matic Slot by Netent | Yolo247 India",
            "description": "Play Cash-o-Matic Slot by NetEnt at Yolo247 India. Enjoy a fast-paced, high-energy slot with exciting features and big win potential. Spin now!",
            "canonical": "https://www.yolo247.site/slots/netent/cash-o-matic",
            "alternate": "https://m.yolo247.site/slots/netent/cash-o-matic"
        },
        "casino-hold-em": {
            "title": "Play Casino Hold'em Slot by Netent | Yolo247 India",
            "description": "Play Casino Hold'em Slot by NetEnt at Yolo247 India. Experience exciting poker action with thrilling gameplay and rewarding opportunities. Play now!",
            "canonical": "https://www.yolo247.site/slots/netent/casino-hold-em",
            "alternate": "https://m.yolo247.site/slots/netent/casino-hold-em"
        },
        "codex-of-fortune": {
            "title": "Play Codex of Fortune Slot by Netent | Yolo247 India",
            "description": "lay Codex of Fortune Slot by NetEnt at Yolo247 India! Discover ancient treasures with exciting features and high rewards. Spin the reels and win big today!",
            "canonical": "https://www.yolo247.site/slots/netent/codex-of-fortune",
            "alternate": "https://m.yolo247.site/slots/netent/codex-of-fortune"
        },
        "creature-from-the-black-lagoon": {
            "title": "Creature From the Black Lagoon Slot by Netent | Yolo247 India",
            "description": "Play Creature From the Black Lagoon Slot by NetEnt at Yolo247 India. Dive into thrilling gameplay, captivating graphics, and big win opportunities!",
            "canonical": "https://www.yolo247.site/slots/netent/creature-from-the-black-lagoon",
            "alternate": "https://m.yolo247.site/slots/netent/creature-from-the-black-lagoon"
        },
        "dark-king-forbidden-riches": {
            "title": "Play Dark King Forbidden Riches Slot by Netent | Yolo247 India",
            "description": "Play Dark King: Forbidden Riches Slot by Netent at Yolo247 🎮. Spin the reels and uncover hidden treasures with big wins on this thrilling slot game! Join now!",
            "canonical": "https://www.yolo247.site/slots/netent/dark-king-forbidden-riches",
            "alternate": "https://m.yolo247.site/slots/netent/dark-king-forbidden-riches"
        },
        "dazzle-me": {
            "title": "Play Dazzle Me Slot by Netent | Yolo247 India",
            "description": "Play Dazzle Me Slot by Netent on Yolo247 🎮. Enjoy dazzling reels, exciting features, and real money rewards in this vibrant slot game. Start spinning today!",
            "canonical": "https://www.yolo247.site/slots/netent/dazzle-me",
            "alternate": "https://m.yolo247.site/slots/netent/dazzle-me"
        },
        "dazzle-me-megaways": {
            "title": "Play Dazzle Me Megaways Slot by Netent | Yolo247 India",
            "description": "Play Dazzle Me Megaways Slot by Netent at Yolo247 🎮. Experience thrilling Megaways action with dazzling features and big wins. Spin the reels now! ✨",
            "canonical": "https://www.yolo247.site/slots/netent/dazzle-me-megaways",
            "alternate": "https://m.yolo247.site/slots/netent/dazzle-me-megaways"
        },
        "dead-or-alive": {
            "title": "Play Dead or Alive Slot by Netent | Yolo247 India",
            "description": "Play Dead or Alive Slot by NetEnt at Yolo247 India for thrilling wilds, free spins, and big rewards! Experience high-action gameplay and big wins today.",
            "canonical": "https://www.yolo247.site/slots/netent/dead-or-alive",
            "alternate": "https://m.yolo247.site/slots/netent/dead-or-alive"
        },
        "dead-or-alive-2": {
            "title": "Play Dead or Alive 2 Feature Buy Slot | Yolo247 India",
            "description": "Play Dead or Alive 2 Feature Buy Slot by Netent at Yolo247 🎮. Trigger bonus rounds, win big, and enjoy thrilling real money gameplay. Spin the reels now!",
            "canonical": "https://www.yolo247.site/slots/netent/dead-or-alive-2",
            "alternate": "https://m.yolo247.site/slots/netent/dead-or-alive-2"
        },
        "dead-or-alive-2-feature-buy": {
            "title": "Play Dead or Alive 2 Feature Buy by NetEnt Slots | Yolo247",
            "description": "Experience the thrilling action of Dead or Alive 2 with the Feature Buy option at Yolo247. Unlock big wins and enjoy fast-paced gameplay in this exciting NetEnt slot!",
            "canonical": "https://www.yolo247.site/slots/netent/dead-or-alive-2-feature-buy",
            "alternate": "https://m.yolo247.site/slots/netent/dead-or-alive-2-feature-buy"
        },
        "disco-danny": {
            "title": "Play Disco Danny Slot by Netent | Yolo247 India",
            "description": "Play Disco Danny Slot by Netent at Yolo247. Groove to the disco vibes, spin the reels, and win real money rewards. Get ready for sparkling fun and big wins! 🕺",
            "canonical": "https://www.yolo247.site/slots/netent/disco-danny",
            "alternate": "https://m.yolo247.site/slots/netent/disco-danny"
        },
        "divine-fortune-megaways": {
            "title": "Play Divine Fortune Megaways Slot by Netent | Yolo247 India",
            "description": "Play Divine Fortune Megaways Slot by Netent at Yolo247 🎮. Discover ancient treasures, exciting Megaways features, and win big real money rewards. Spin now!🏺",
            "canonical": "https://www.yolo247.site/slots/netent/divine-fortune-megaways",
            "alternate": "https://m.yolo247.site/slots/netent/divine-fortune-megaways"
        },
        "double-stacks": {
            "title": "Play Double Stacks Slot by Netent | Yolo247 India",
            "description": "Play Double Stacks Slot by Netent at Yolo247 . Enjoy classic slot vibes with stacked symbols and big win potential. Spin the reels and win real money today! 💎",
            "canonical": "https://www.yolo247.site/slots/netent/double-stacks",
            "alternate": "https://m.yolo247.site/slots/netent/double-stacks"
        },
        "dracula": {
            "title": "Play Dracula Slot by Netent | Yolo247 India",
            "description": "Play Dracula Slot by Netent at Yolo247 🎮. Dive into mystery, enjoy thrilling features, and win big with real money rewards. Spin the reels now! 🧛‍♂️✨",
            "canonical": "https://www.yolo247.site/slots/netent/dracula",
            "alternate": "https://m.yolo247.site/slots/netent/dracula"
        },
        "druids-dream": {
            "title": "Play Druid's Dream Slot by Netent | Yolo247 India",
            "description": "Play Druid's Dream Slot by Netent at Yolo247 🎮. Explore mystical forests, enjoy magical features, and win real money rewards. Spin the reels today! 🌲✨",
            "canonical": "https://www.yolo247.site/slots/netent/druids-dream",
            "alternate": "https://m.yolo247.site/slots/netent/druids-dream"
        },
        "eggomatic": {
            "title": "Play Eggomatic Slot by Netent | Yolo247 India",
            "description": "Play Eggomatic Slot by Netent at Yolo247 🎮. Enjoy quirky features, fun animations, and big win potential. Spin the reels for real money rewards today! 🥚✨",
            "canonical": "https://www.yolo247.site/slots/netent/eggomatic",
            "alternate": "https://m.yolo247.site/slots/netent/eggomatic"
        },
        "elements": {
            "title": "Play Elements The Awakening Slot by Netent | Yolo247 India",
            "description": "Play Elements: The Awakening Slot by Netent at Yolo247 🎮. Unleash the power of the elements, trigger exciting features, and win real money rewards! ✨🌍",
            "canonical": "https://www.yolo247.site/slots/netent/elements",
            "alternate": "https://m.yolo247.site/slots/netent/elements"
        },
        "excalibur": {
            "title": "Play Excalibur Slot by Netent | Yolo247 India",
            "description": "Play Excalibur Slot by Netent at Yolo247 🎮. Embark on an epic adventure with legendary features and win real money rewards. Spin the reels today! ⚔️👑",
            "canonical": "https://www.yolo247.site/slots/netent/excalibur",
            "alternate": "https://m.yolo247.site/slots/netent/excalibur"
        },
        "fairytale-legends-hansel-and-gretel": {
            "title": "Play Fairytale Legends Hansel & Gretel Slot | Yolo247",
            "description": "Play Fairytale Legends: Hansel & Gretel Slot by Netent at Yolo247 🎮. Discover magical features and win real money rewards. Spin the reels for an adventure! ✨🎠",
            "canonical": "https://www.yolo247.site/slots/netent/fairytale-legends-hansel-and-gretel",
            "alternate": "https://m.yolo247.site/slots/netent/fairytale-legends-hansel-and-gretel"
        },
        "fairytale-legends-mirror-mirror": {
            "title": "Play Fairytale Legends Mirror Mirror Slot | Yolo247 India",
            "description": "Play Fairytale Legends: Mirror Mirror Slot by Netent at Yolo247 🎮. Enter a magical world, enjoy exciting features, and win real money rewards! ✨🪞",
            "canonical": "https://www.yolo247.site/slots/netent/fairytale-legends-mirror-mirror",
            "alternate": "https://m.yolo247.site/slots/netent/fairytale-legends-mirror-mirror"
        },
        "fairytale-legends-red-riding-hood": {
            "title": "Play Fairytale Legends Red Riding Hood Slot | Yolo247 India",
            "description": "Play Fairytale Legends: Red Riding Hood Slot by Netent at Yolo247 🎮. Enter a fairy tale world, enjoy thrilling features, and win real money rewards! 🌲✨",
            "canonical": "https://www.yolo247.site/slots/netent/fairytale-legends-red-riding-hood",
            "alternate": "https://m.yolo247.site/slots/netent/fairytale-legends-red-riding-hood"
        },
        "finn-and-the-swirly-spin": {
            "title": "Play Finn & the Swirly Spin Slot by Netent | Yolo247 India",
            "description": "Enjoy magical adventures with Finn and the Swirly Spin Slot by Netent! Spin the reels for exciting features and big rewards at Yolo247 India. 🎰✨",
            "canonical": "https://www.yolo247.site/slots/netent/finn-and-the-swirly-spin",
            "alternate": "https://m.yolo247.site/slots/netent/finn-and-the-swirly-spin"
        },
        "finns-golden-tavern": {
            "title": "Play Finn's Golden Tavern Slot by Netent | Yolo247 India",
            "description": "Play Finn's Golden Tavern Slot by Netent at Yolo247 🎮. Join Finn for a fun-filled adventure, enjoy exciting features, and win real money rewards! 🍀🍻",
            "canonical": "https://www.yolo247.site/slots/netent/finns-golden-tavern",
            "alternate": "https://m.yolo247.site/slots/netent/finns-golden-tavern"
        },
        "flowers": {
            "title": "Play Flowers Slot by Netent | Yolo247 India",
            "description": "Bloom with big wins in Flowers Slot by Netent! Spin the reels and enjoy exciting gameplay and rewards at Yolo247 India. 🌸🎰",
            "canonical": "https://www.yolo247.site/slots/netent/flowers",
            "alternate": "https://m.yolo247.site/slots/netent/flowers"
        },
        "football-champions-cup": {
            "title": "Play Football Champions Cup Slot by Netent | Yolo247 India",
            "description": "Score big wins in Football: Champions Cup Slot by Netent! Spin and celebrate your victories on the reels at Yolo247 India. ⚽🏆🎰",
            "canonical": "https://www.yolo247.site/slots/netent/football-champions-cup",
            "alternate": "https://m.yolo247.site/slots/netent/football-champions-cup"
        },
        "french-roulette": {
            "title": "Play French Roulette Slot by Netent | Yolo247 India",
            "description": "Experience the elegance of French Roulette Slot by Netent! Place your bets and enjoy the thrill of the spin at Yolo247 India. 🎡💸",
            "canonical": "https://www.yolo247.site/slots/netent/french-roulette",
            "alternate": "https://m.yolo247.site/slots/netent/french-roulette"
        },
        "fruit-blaze": {
            "title": "Play Fruit Blaze Slot by Netent | Yolo247 India",
            "description": "Ignite your winnings with Fruit Blaze Slot by Netent! Spin the reels for fiery fun and juicy rewards at Yolo247 India.",
            "canonical": "https://www.yolo247.site/slots/netent/fruit-blaze",
            "alternate": "https://m.yolo247.site/slots/netent/fruit-blaze"
        },
        "fruit-shop": {
            "title": "Play Fruit Shop Slot by Netent | Yolo247 India",
            "description": "Play Fruit Shop Slot by NetEnt at Yolo247 India! Enjoy vibrant fruit symbols, exciting bonuses, and the chance to win big with every spin. Join now!",
            "canonical": "https://www.yolo247.site/slots/netent/fruit-shop",
            "alternate": "https://m.yolo247.site/slots/netent/fruit-shop"
        },
        "fruit-shop-christmas-edition": {
            "title": "Fruit Shop Christmas Edition Slot by Netent | Yolo247 India",
            "description": "Celebrate festive spins with Fruit Shop Christmas Edition Slot by Netent! Enjoy holiday-themed fun and big wins at Yolo247 India. 🎄🍎❄️",
            "canonical": "https://www.yolo247.site/slots/netent/fruit-shop-christmas-edition",
            "alternate": "https://m.yolo247.site/slots/netent/fruit-shop-christmas-edition"
        },
        "fruit-shop-megaways": {
            "title": "Play Fruit Shop Megaways Slot by Netent | Yolo247 India",
            "description": "Experience thrilling gameplay with Fruit Shop Megaways Slot by Netent! Enjoy dynamic reels and big winning opportunities at Yolo247 India. 🍉💥🎰",
            "canonical": "https://www.yolo247.site/slots/netent/fruit-shop-megaways",
            "alternate": "https://m.yolo247.site/slots/netent/fruit-shop-megaways"
        },
        "fruit-spin": {
            "title": "Play Fruit Spin Slot by Netent | Yolo247 India",
            "description": "Play Fruit Spin Slot by Netent at Yolo247 India! Spin the reels for juicy wins and exciting features in this vibrant fruit-filled slot game. 🍒🍊💰",
            "canonical": "https://www.yolo247.site/slots/netent/fruit-spin",
            "alternate": "https://m.yolo247.site/slots/netent/fruit-spin"
        },
        "gods-of-gold-infinireels": {
            "title": "Gods of Gold Infinireels Slot by Netent | Yolo247 India",
            "description": "Play Gods of Gold: Infinireels Slot by Netent at Yolo247 India! Unlock infinite reels and big rewards in this mythical adventure. 🏆💰",
            "canonical": "https://www.yolo247.site/slots/netent/gods-of-gold-infinireels",
            "alternate": "https://m.yolo247.site/slots/netent/gods-of-gold-infinireels"
        },
        "gonzos-quest": {
            "title": "Play Gonzo's Quest Slot by Netent | Yolo247 India",
            "description": "Play Gonzo's Quest Slot by NetEnt at Yolo247 India! Join the adventurous Gonzo in his quest for hidden treasures. Enjoy exciting features and big wins!",
            "canonical": "https://www.yolo247.site/slots/netent/gonzos-quest",
            "alternate": "https://m.yolo247.site/slots/netent/gonzos-quest"
        },
        "gordon-ramsay-hells-kitchen": {
            "title": "Gordon Ramsay Hells Kitchen Slot by Netent | Yolo247 India",
            "description": "Play Gordon Ramsay Hell's Kitchen Slot by Netent | Yolo247 India! 🍽️🔥 Cook up big wins in this thrilling, action-packed slot game.",
            "canonical": "https://www.yolo247.site/slots/netent/gordon-ramsay-hells-kitchen",
            "alternate": "https://m.yolo247.site/slots/netent/gordon-ramsay-hells-kitchen"
        },
        "gorilla-kingdom": {
            "title": "Play Gorilla Kingdom Slot by Netent | Yolo247 India 🦍🎰",
            "description": "Embark on an adventure with Gorilla Kingdom Slot by Netent at Yolo247 India! Spin and win in the wild jungle! 🦍🌿💰",
            "canonical": "https://www.yolo247.site/slots/netent/gorilla-kingdom",
            "alternate": "https://m.yolo247.site/slots/netent/gorilla-kingdom"
        },
        "grand-spinn": {
            "title": "Play Grand Spinn Slot by Netent | Yolo247 India",
            "description": "Spin the reels of fortune with Grand Spinn Slot by Netent at Yolo247 India. Experience vintage charm and big wins! 🍒🎰",
            "canonical": "https://www.yolo247.site/slots/netent/grand-spinn",
            "alternate": "https://m.yolo247.site/slots/netent/grand-spinn"
        },
        "guns-n-roses": {
            "title": "Guns N' Roses Video Slots Slot by Netent | Yolo247 India",
            "description": "Rock out with Guns N' Roses Video Slots by Netent at Yolo247 India. Spin for legendary wins with classic rock vibes! 🎸💰",
            "canonical": "https://www.yolo247.site/slots/netent/guns-n-roses",
            "alternate": "https://m.yolo247.site/slots/netent/guns-n-roses"
        },
        "halloween-jack": {
            "title": "Play Halloween Jack Slot by Netent | Yolo247 India",
            "description": "Play Halloween Jack Slot by NetEnt at Yolo247 India! Experience thrilling Halloween-themed adventures with spooky symbols, big wins, & exciting bonus features.",
            "canonical": "https://www.yolo247.site/slots/netent/halloween-jack",
            "alternate": "https://m.yolo247.site/slots/netent/halloween-jack"
        },
        "hotline": {
            "title": "Play Hotline Slot by Netent | Yolo247 India",
            "description": "Enjoy the excitement of Hotline Slot by Netent at Yolo247 India. Spin the reels and win big in this thrilling, Miami-inspired game! 🚓🎰",
            "canonical": "https://www.yolo247.site/slots/netent/hotline",
            "alternate": "https://m.yolo247.site/slots/netent/hotline"
        },
        "hotline-2": {
            "title": "Play Hotline 2 Slot by Netent | Yolo247 India",
            "description": "Experience the thrill of Hotline 2 Slot by Netent at Yolo247 India. Spin for big wins in this retro-styled, action-packed game! 🚔💥",
            "canonical": "https://www.yolo247.site/slots/netent/hotline-2",
            "alternate": "https://m.yolo247.site/slots/netent/hotline-2"
        },
        "irish-pot-luck": {
            "title": "Play Irish Pot Luck Slot by Netent | Yolo247 India",
            "description": "Spin the reels and uncover lucky treasures in Irish Pot Luck Slot by Netent! Join the fun and win big at Yolo247 India! 🍀🎰",
            "canonical": "https://www.yolo247.site/slots/netent/irish-pot-luck",
            "alternate": "https://m.yolo247.site/slots/netent/irish-pot-luck"
        },
        "jack-and-the-beanstalk": {
            "title": "Play Jack and the Beanstalk Slot by Netent | Yolo247 India",
            "description": "Climb the beanstalk for magical rewards in Jack and the Beanstalk Slot by Netent! Play now at Yolo247 India and enjoy the adventure! 🌱🎰",
            "canonical": "https://www.yolo247.site/slots/netent/jack-and-the-beanstalk",
            "alternate": "https://m.yolo247.site/slots/netent/jack-and-the-beanstalk"
        },
        "jack-hammer": {
            "title": "Play Jack Hammer Slot by Netent | Yolo247 India",
            "description": "Join Jack Hammer in his crime-fighting adventure and win big in the Jack Hammer Slot by Netent! Spin and play now at Yolo247 India! 🕵️‍♂️💰",
            "canonical": "https://www.yolo247.site/slots/netent/jack-hammer",
            "alternate": "https://m.yolo247.site/slots/netent/jack-hammer"
        },
        "jack-hammer-2": {
            "title": "Jack Jammer 2 Fishy Business Slot by Netent | Yolo247 India",
            "description": "Join Jack Hammer in his fishy adventure and win big in Jack Hammer 2: Fishy Business Slot by Netent! Play now at Yolo247 India! 🐟🎰",
            "canonical": "https://www.yolo247.site/slots/netent/jack-hammer-2",
            "alternate": "https://m.yolo247.site/slots/netent/jack-hammer-2"
        },
        "jackpot-6000": {
            "title": "Play Jackpot 6000 Slot by Netent | Yolo247 India",
            "description": "Experience the classic thrill of Jackpot 6000 Slot by Netent! Spin for huge wins and exciting payouts at Yolo247 India! 🎰💸",
            "canonical": "https://www.yolo247.site/slots/netent/jackpot-6000",
            "alternate": "https://m.yolo247.site/slots/netent/jackpot-6000"
        },
        "jacks-or-better-double-up": {
            "title": "Jacks or Better Double Up Slot by Netent | Yolo247 India",
            "description": "Enjoy the exciting Jacks or Better Double Up Slot by Netent! Double your winnings and take your chances at Yolo247 India! ♠️♦️🎰",
            "canonical": "https://www.yolo247.site/slots/netent/jacks-or-better-double-up",
            "alternate": "https://m.yolo247.site/slots/netent/jacks-or-better-double-up"
        },
        "jimi-hendrix": {
            "title": "Play Jimi Hendrix Online Slot by Netent | Yolo247 India",
            "description": "Rock out with Jimi Hendrix Online Slot by Netent! Spin the reels to unlock electrifying wins at Yolo247 India! 🎸💥",
            "canonical": "https://www.yolo247.site/slots/netent/jimi-hendrix",
            "alternate": "https://m.yolo247.site/slots/netent/jimi-hendrix"
        },
        "jingle-spin": {
            "title": "Play Jingle Spin Slot by Netent | Yolo247 India",
            "description": "Celebrate the holiday season with Jingle Spin Slot by Netent! Spin for festive wins and bonuses at Yolo247 India! 🎄🔔",
            "canonical": "https://www.yolo247.site/slots/netent/jingle-spin",
            "alternate": "https://m.yolo247.site/slots/netent/jingle-spin"
        },
        "joker-pro": {
            "title": "Play Joker Pro Slot by Netent | Yolo247 India",
            "description": "Unlock thrilling features in Joker Pro Slot by Netent! Spin for fun rewards and exciting wins at Yolo247 India! 🎉🤡",
            "canonical": "https://www.yolo247.site/slots/netent/joker-pro",
            "alternate": "https://m.yolo247.site/slots/netent/joker-pro"
        },
        "jumanji": {
            "title": "Play Jumanji Slot Game by Netent | Yolo247 India",
            "description": "Play Jumanji Slot by NetEnt at Yolo247 India! Join the thrilling adventure with free spins, wild features, and big rewards in this exciting slot game.",
            "canonical": "https://www.yolo247.site/slots/netent/jumanji",
            "alternate": "https://m.yolo247.site/slots/netent/jumanji"
        },
        "jungle-spirit": {
            "title": "Jungle spirit Call of the Wild Slot | Yolo247 India",
            "description": "Explore the jungle and hunt for big wins with Jungle Spirit: Call of the Wild Slot at Yolo247 India! 🌿🐅",
            "canonical": "https://www.yolo247.site/slots/netent/jungle-spirit",
            "alternate": "https://m.yolo247.site/slots/netent/jungle-spirit"
        },
        "koi-princess": {
            "title": "Play KOI Princess Slot by Netent | Yolo247 India",
            "description": "Discover the fortune-filled KOI Princess Slot by Netent! Spin to win big at Yolo247 India! 🐟💎",
            "canonical": "https://www.yolo247.site/slots/netent/koi-princess",
            "alternate": "https://m.yolo247.site/slots/netent/koi-princess"
        },
        "lost-relics": {
            "title": "Play Lost Relics Slot by Netent | Yolo247 India",
            "description": "Uncover hidden treasures with Lost Relics Slot by Netent! Spin for ancient wins at Yolo247 India! 🏺💰",
            "canonical": "https://www.yolo247.site/slots/netent/lost-relics",
            "alternate": "https://m.yolo247.site/slots/netent/lost-relics"
        },
        "motorhead": {
            "title": "Play Motorhead Video Slot by Netent | Yolo247 India",
            "description": "Rock on with Motorhead Video Slot by Netent! Spin for electrifying wins and high-voltage action at Yolo247 India! 🎸⚡",
            "canonical": "https://www.yolo247.site/slots/netent/motorhead",
            "alternate": "https://m.yolo247.site/slots/netent/motorhead"
        },
        "multiplier-mayhem": {
            "title": "Play Drive Multiplier Mayhem Slot by Netent | Yolo247 India",
            "description": "Play Drive: Multiplier Mayhem Slot by NetEnt at Yolo247 India! Experience thrilling gameplay with exciting multipliers, fast-paced action, and big rewards.",
            "canonical": "https://www.yolo247.site/slots/netent/multiplier-mayhem",
            "alternate": "https://m.yolo247.site/slots/netent/multiplier-mayhem"
        },
        "mythic-maiden": {
            "title": "Play Mythic Maiden Slot by Netent | Yolo247 India",
            "description": "Enter a world of mystery and magic with Mythic Maiden Slot! Unlock big wins at Yolo247 India! 🧙‍♀️💎",
            "canonical": "https://www.yolo247.site/slots/netent/mythic-maiden",
            "alternate": "https://m.yolo247.site/slots/netent/mythic-maiden"
        },
        "narcos": {
            "title": "Play Narcos Slot by Netent | Yolo247 India",
            "description": "Step into the dangerous world of Narcos Slot by Netent! Spin for thrilling rewards at Yolo247 India! 💵🔫",
            "canonical": "https://www.yolo247.site/slots/netent/narcos",
            "alternate": "https://m.yolo247.site/slots/netent/narcos"
        },
        "oceans-treasure": {
            "title": "Play Ocean's Treasure Slot by Netent | Yolo247 India",
            "description": "Dive deep into the sea with Ocean’s Treasure Slot by Netent! Spin for underwater riches at Yolo247 India! 🌊💎",
            "canonical": "https://www.yolo247.site/slots/netent/oceans-treasure",
            "alternate": "https://m.yolo247.site/slots/netent/oceans-treasure"
        },
        "ozzy-osbourne": {
            "title": "Ozzy Osbourne Video Slot by Netent | Yolo247 India",
            "description": "Rock out with Ozzy Osbourne Video Slot by Netent! Spin for wild wins at Yolo247 India! 🎤🎸",
            "canonical": "https://www.yolo247.site/slots/netent/ozzy-osbourne",
            "alternate": "https://m.yolo247.site/slots/netent/ozzy-osbourne"
        },
        "parthenon-quest-for-immortality": {
            "title": "Parthenon Quest for Immortality Netent Slot | Yolo247 India",
            "description": "Embark on a mythic adventure with Parthenon: Quest for Immortality Slot! Spin to win immortal rewards at Yolo247 India! 🏛️💰",
            "canonical": "https://www.yolo247.site/slots/netent/parthenon-quest-for-immortality",
            "alternate": "https://m.yolo247.site/slots/netent/parthenon-quest-for-immortality"
        },
        "piggy-riches": {
            "title": "Play Piggy Riches Slot by Netent | Yolo247 India",
            "description": "Get rich with the adorable Piggy Riches Slot by Netent! Spin for big wins at Yolo247 India! 🐖💰",
            "canonical": "https://www.yolo247.site/slots/netent/piggy-riches",
            "alternate": "https://m.yolo247.site/slots/netent/piggy-riches"
        },
        "pyramid": {
            "title": "Pyramid Quest for Immortality Netent Slot | Yolo247 India",
            "description": "Uncover the treasures of ancient Egypt with Pyramid: Quest for Immortality! Spin for epic wins at Yolo247 India! 🏺💎",
            "canonical": "https://www.yolo247.site/slots/netent/pyramid",
            "alternate": "https://m.yolo247.site/slots/netent/pyramid"
        },
        "rage-of-the-seas": {
            "title": "Play Rage of the Seas Slot by Netent | Yolo247 India",
            "description": "Sail the high seas for big rewards with Rage of the Seas Slot by Netent! Spin for treasure at Yolo247 India! 🚢💰",
            "canonical": "https://www.yolo247.site/slots/netent/rage-of-the-seas",
            "alternate": "https://m.yolo247.site/slots/netent/rage-of-the-seas"
        },
        "reef-raider": {
            "title": "Play Reef Raider Slot by Netent | Yolo247 India",
            "description": "Dive into the underwater adventure with Reef Raider Slot! Spin for treasure at Yolo247 India! 🌊💎",
            "canonical": "https://www.yolo247.site/slots/netent/reef-raider",
            "alternate": "https://m.yolo247.site/slots/netent/reef-raider"
        },
        "reel-rush": {
            "title": "Play Reel Rush Slot by Netent | Yolo247 India",
            "description": "Spin for thrilling wins in the colorful Reel Rush Slot by Netent at Yolo247 India! 🍇🎰",
            "canonical": "https://www.yolo247.site/slots/netent/reel-rush",
            "alternate": "https://m.yolo247.site/slots/netent/reel-rush"
        },
        "riches-of-midgard": {
            "title": "Riches of Midgard Land & Expand Netent Slot | Yolo247 India",
            "description": "Unlock Viking treasures with Riches of Midgard: Land & Expand! Spin to win big at Yolo247 India! ⚔️💰",
            "canonical": "https://www.yolo247.site/slots/netent/riches-of-midgard",
            "alternate": "https://m.yolo247.site/slots/netent/riches-of-midgard"
        },
        "rome-the-golden-age": {
            "title": "Play Rome The Gold Age Slot by Netent | Yolo247 India",
            "description": "Play Rome: The Gold Age Slot by NetEnt at Yolo247 India! Immerse yourself in the ancient Roman empire, enjoy rewarding features, and chase big wins. Join now!",
            "canonical": "https://www.yolo247.site/slots/netent/rome-the-golden-age",
            "alternate": "https://m.yolo247.site/slots/netent/rome-the-golden-age"
        },
        "roulette-advanced": {
            "title": "Roulette Advanced (Roulette Pro) Netent Slot | Yolo247 India",
            "description": "Spin the wheel for big wins with Roulette Advanced (Roulette Pro) by Netent at Yolo247 India! 🎡💥",
            "canonical": "https://www.yolo247.site/slots/netent/roulette-advanced",
            "alternate": "https://m.yolo247.site/slots/netent/roulette-advanced"
        },
        "scruffy-duck": {
            "title": "Play Scruffy Duck Slot by Netent | Yolo247 India",
            "description": "Join the fun with Scruffy Duck Slot by Netent! Spin for quirky wins at Yolo247 India! 🦆🎉",
            "canonical": "https://www.yolo247.site/slots/netent/scruffy-duck",
            "alternate": "https://m.yolo247.site/slots/netent/scruffy-duck"
        },
        "secret-of-the-stones": {
            "title": "Play Secret of the Stones Slot by Netent | Yolo247 India",
            "description": "Unlock ancient secrets with Secret of the Stones Slot! Spin for hidden treasures at Yolo247 India! 🏺💎",
            "canonical": "https://www.yolo247.site/slots/netent/secret-of-the-stones",
            "alternate": "https://m.yolo247.site/slots/netent/secret-of-the-stones"
        },
        "secrets-of-atlantis": {
            "title": "Play Secrets of Atlantis Slot by Netent | Yolo247 India",
            "description": "Dive into the mythical world of Atlantis with Secrets of Atlantis Slot! Spin for oceanic riches at Yolo247 India! 🌊🏛️",
            "canonical": "https://www.yolo247.site/slots/netent/secrets-of-atlantis",
            "alternate": "https://m.yolo247.site/slots/netent/secrets-of-atlantis"
        },
        "secrets-of-christmas": {
            "title": "Play Secrets of Christmas Slot by Netent | Yolo247 India",
            "description": "Celebrate the festive season with Secrets of Christmas Slot by Netent! Spin for jolly wins at Yolo247 India! 🎄🎁",
            "canonical": "https://www.yolo247.site/slots/netent/secrets-of-christmas",
            "alternate": "https://m.yolo247.site/slots/netent/secrets-of-christmas"
        },
        "serengeti-kings": {
            "title": "Play Serengeti Kings Slot by Netent | Yolo247 India",
            "description": "Explore the wilds of the Serengeti with Serengeti Kings Slot by Netent! Spin for jungle riches at Yolo247 India! 🦁🌍",
            "canonical": "https://www.yolo247.site/slots/netent/serengeti-kings",
            "alternate": "https://m.yolo247.site/slots/netent/serengeti-kings"
        },
        "space-wars": {
            "title": "Play Space Wars Slot by Netent | Yolo247 India",
            "description": "Play Space Wars Slot by NetEnt at Yolo247 India! Enjoy a thrilling space-themed game with exciting features, free spins, and big win potential. Play now!",
            "canonical": "https://www.yolo247.site/slots/netent/space-wars",
            "alternate": "https://m.yolo247.site/slots/netent/space-wars"
        },
        "starburst": {
            "title": "Play Starburst Slot by Netent | Yolo247 India",
            "description": "Spin for cosmic rewards with Starburst Slot by Netent! Enjoy dazzling wins at Yolo247 India! 🌟💎",
            "canonical": "https://www.yolo247.site/slots/netent/starburst",
            "alternate": "https://m.yolo247.site/slots/netent/starburst"
        },
        "starburst-xxxtreme": {
            "title": "Play Starburst XXXtreme Slot by Netent | Yolo247 India",
            "description": "Enjoy the extreme version of Starburst Slot with Starburst XXXtreme! Spin for stellar rewards at Yolo247 India! 🌟🔥",
            "canonical": "https://www.yolo247.site/slots/netent/starburst-xxxtreme",
            "alternate": "https://m.yolo247.site/slots/netent/starburst-xxxtreme"
        },
        "steam-tower": {
            "title": "Play Steam Tower Slot by Netent | Yolo247 India",
            "description": "Climb the Steam Tower for big wins with Netent’s Steam Tower Slot! Spin for victory at Yolo247 India! ⚙️🎰",
            "canonical": "https://www.yolo247.site/slots/netent/steam-tower",
            "alternate": "https://m.yolo247.site/slots/netent/steam-tower"
        },
        "street-fighter-2": {
            "title": "Street fighter II: The World Warrior Slot | Yolo247 India",
            "description": "Enter the fight with Street Fighter II: The World Warrior Slot! Spin for knockout wins at Yolo247 India! 👊🎮",
            "canonical": "https://www.yolo247.site/slots/netent/street-fighter-2",
            "alternate": "https://m.yolo247.site/slots/netent/street-fighter-2"
        },
        "the-invisible-man": {
            "title": "Play The Invisible Man Slot by Netent | Yolo247 India",
            "description": "Unmask thrilling rewards with The Invisible Man Slot by Netent! Spin for invisible treasures at Yolo247 India! 🕵️‍♂️💰",
            "canonical": "https://www.yolo247.site/slots/netent/the-invisible-man",
            "alternate": "https://m.yolo247.site/slots/netent/the-invisible-man"
        },
        "the-wolfs-bane": {
            "title": "Play The wolf's Bane Slot by Netent | Yolo247 India",
            "description": "Howl for wins with The Wolf’s Bane Slot by Netent! Spin for wild prizes at Yolo247 India! 🐺🎰",
            "canonical": "https://www.yolo247.site/slots/netent/the-wolfs-bane",
            "alternate": "https://m.yolo247.site/slots/netent/the-wolfs-bane"
        },
        "turn-your-fortune": {
            "title": "Play Turn Your Fortune Slot by Netent | Yolo247 India",
            "description": "Spin to turn your luck with Turn Your Fortune Slot by Netent! Big wins await at Yolo247 India! 🍀💰",
            "canonical": "https://www.yolo247.site/slots/netent/turn-your-fortune",
            "alternate": "https://m.yolo247.site/slots/netent/turn-your-fortune"
        },
        "twin-spin": {
            "title": "Play Twin Spin Slot by Netent | Yolo247 India",
            "description": "Double your fun with Twin Spin Slot by Netent! Spin for big wins at Yolo247 India! 🎰🔄",
            "canonical": "https://www.yolo247.site/slots/netent/twin-spin",
            "alternate": "https://m.yolo247.site/slots/netent/twin-spin"
        },
        "twin-spin-megaways": {
            "title": "Play Twin Spin Megaways Slot by Netent | Yolo247 India",
            "description": "Experience the excitement of Twin Spin Megaways Slot! Spin for massive wins at Yolo247 India! 🔄💥",
            "canonical": "https://www.yolo247.site/slots/netent/twin-spin-megaways",
            "alternate": "https://m.yolo247.site/slots/netent/twin-spin-megaways"
        },
        "victorious": {
            "title": "Play Victorious Slot by Netent | Yolo247 India",
            "description": "Claim victory with Victorious Slot by Netent! Spin for heroic wins at Yolo247 India! 🏅🎰",
            "canonical": "https://www.yolo247.site/slots/netent/victorious",
            "alternate": "https://m.yolo247.site/slots/netent/victorious"
        },
        "warlords-crystals-of-power": {
            "title": "Play Warlords Crystals of Power Netent Slot | Yolo247 India",
            "description": "Battle for power in Warlords: Crystals of Power Slot by Netent! Spin for epic rewards at Yolo247 India! ⚔️💎",
            "canonical": "https://www.yolo247.site/slots/netent/warlords-crystals-of-power",
            "alternate": "https://m.yolo247.site/slots/netent/warlords-crystals-of-power"
        },
        "wild-water": {
            "title": "Play Wild Water Slot by Netent | Yolo247 India",
            "description": "Dive into the waves with Wild Water Slot by Netent! Spin for beachside riches at Yolo247 India! 🌊🏖️",
            "canonical": "https://www.yolo247.site/slots/netent/wild-water",
            "alternate": "https://m.yolo247.site/slots/netent/wild-water"
        },
        "wild-wild-west": {
            "title": "Play Wild Wild West the Great Train Heist Slot | Yolo247",
            "description": "Join the Wild Wild West adventure with The Great Train Heist Slot! Spin for high-stakes wins at Yolo247 India! 🚂💰",
            "canonical": "https://www.yolo247.site/slots/netent/wild-wild-west",
            "alternate": "https://m.yolo247.site/slots/netent/wild-wild-west"
        },
        "wild-worlds": {
            "title": "Play Wild Worlds Slot by Netent | Yolo247 India",
            "description": "Explore new worlds with Wild Worlds Slot by Netent! Spin for futuristic wins at Yolo247 India! 🌍💎",
            "canonical": "https://www.yolo247.site/slots/netent/wild-worlds",
            "alternate": "https://m.yolo247.site/slots/netent/wild-worlds"
        },
        "wilderland": {
            "title": "Play Wilderland Slot by Netent | Yolo247 India",
            "description": "Enter Wilderland for magical rewards with Wilderland Slot by Netent! Spin for wild prizes at Yolo247 India! 🍄🎰",
            "canonical": "https://www.yolo247.site/slots/netent/wilderland",
            "alternate": "https://m.yolo247.site/slots/netent/wilderland"
        },
        "willys-hot-chillies": {
            "title": "Play Willy's Hot Chillies Slot by Netent | Yolo247 India",
            "description": "Spice up your wins with Willy's Hot Chillies Slot! Spin for fiery rewards at Yolo247 India! 🌶️🔥",
            "canonical": "https://www.yolo247.site/slots/netent/willys-hot-chillies",
            "alternate": "https://m.yolo247.site/slots/netent/willys-hot-chillies"
        },
        "wolf-cub": {
            "title": "Play Wolf Cub Slot by Netent | Yolo247 India",
            "description": "Spin for wild rewards with Wolf Cub Slot by Netent! Embrace the wild side at Yolo247 India! 🐺💰",
            "canonical": "https://www.yolo247.site/slots/netent/wolf-cub",
            "alternate": "https://m.yolo247.site/slots/netent/wolf-cub"
        },
        "no-limit-city": {
            "title": "Play No Limit City Slots at Yolo247 India | Win Real Money",
            "description": "Explore No Limit City slot games at Yolo247 India. Enjoy thrilling spins, innovative features, and win big rewards. Start your real money adventure today! 🎰💰",
            "canonical": "https://www.yolo247.site/slots/no-limit-city",
            "alternate": "https://m.yolo247.site/slots/no-limit-city"
        },
        "barbarian-fury": {
            "title": "Play Barbarian Fury Slot | No Limit City | Yolo247 India",
            "description": "Play Barbarian Fury Slot at Yolo247 India. Enjoy thrilling action and big rewards with No Limit City’s fierce gameplay. Spin today and win!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/barbarian-fury",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/barbarian-fury"
        },
        "benji-killed-in-vegas": {
            "title": "Play Benji Killed in Vegas Slot at Yolo247 India now!",
            "description": "Play Benji Killed in Vegas Slot at Yolo247 India. Dive into the crime-filled world of Vegas with exciting features and real rewards. Spin now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/benji-killed-in-vegas",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/benji-killed-in-vegas"
        },
        "blood-and-shadow": {
            "title": "Play Blood and Shadow Slot | No Limit City | Yolo247 India",
            "description": "Play Blood and Shadow Slot at Yolo247 India. Uncover dark secrets and win big with No Limit City’s thrilling features. Spin for rewards!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/blood-and-shadow",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/blood-and-shadow"
        },
        "bonus-bunnies": {
            "title": "Play Bonus Bunnies Slot | No Limit City | Yolo247 India",
            "description": "Play Bonus Bunnies Slot at Yolo247 India. Hop into fun with No Limit City’s Bonus Bunnies and win big rewards. Start spinning today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/bonus-bunnies",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/bonus-bunnies"
        },
        "book-of-shadows": {
            "title": "Play Book of Shadows Slot | No Limit City | Yolo247 India",
            "description": "Play Book of Shadows Slot at Yolo247 India. Unlock mystical treasures with thrilling features and big wins. Spin today with No Limit City’s Book of Shadows!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/book-of-shadows",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/book-of-shadows"
        },
        "buffalo-hunter": {
            "title": "Play Buffalo Hunter Slot | No Limit City | Yolo247 India",
            "description": "Play Buffalo Hunter Slot at Yolo247 India. Hunt for wild wins with No Limit City’s exciting Buffalo Hunter! Spin and win today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/buffalo-hunter",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/buffalo-hunter"
        },
        "bushido-ways-xnudge": {
            "title": "Play Bushido Way Xnudge Slot | No Limit City | Yolo247 India",
            "description": "Play Bushido Way Xnudge Slot at Yolo247 India. Experience Samurai action and win big with No Limit City’s Bushido Way Xnudge. Spin for rewards now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/bushido-ways-xnudge",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/bushido-ways-xnudge"
        },
        "casino-win-spin": {
            "title": "Play Casino Win Spin Slot | No Limit City | Yolo247 India",
            "description": "Play Casino Win Spin Slot at Yolo247 India. Spin for huge wins and exciting rewards with No Limit City’s Casino Win Spin. Play today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/casino-win-spin",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/casino-win-spin"
        },
        "coins-of-fortune": {
            "title": "Play Coins of Fortune Slot | No Limit City | Yolo247 India",
            "description": "Play Coins of Fortune Slot at Yolo247 India. Unlock hidden treasures with No Limit City’s Coins of Fortune. Spin for big rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/coins-of-fortune",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/coins-of-fortune"
        },
        "das-xboot": {
            "title": "Play Das Xboot Slot | No Limit City | Yolo247 India",
            "description": "Play Das Xboot Slot at Yolo247 India. Embark on a treasure hunt with No Limit City’s Das Xboot and spin for huge wins and rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/das-xboot",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/das-xboot"
        },
        "dead-canary": {
            "title": "Play Dead Canary Slot | No Limit City | Yolo247 India",
            "description": "Play Dead Canary Slot at Yolo247 India. Venture into the mines with No Limit City’s Dead Canary and spin for big rewards. Play now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/dead-canary",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/dead-canary"
        },
        "deadwood": {
            "title": "Play Deadwood Slot | No Limit City | Yolo247 India",
            "description": "Play Deadwood Slot at Yolo247 India. Enter the wild west with No Limit City’s Deadwood and spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/deadwood",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/deadwood"
        },
        "dragon-tribe": {
            "title": "Play Dragon Tribe Slot | No Limit City | Yolo247 India",
            "description": "Play Dragon Tribe Slot at Yolo247 India. Spin with the mighty dragons in No Limit City’s Dragon Tribe for thrilling rewards and big wins!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/dragon-tribe",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/dragon-tribe"
        },
        "dungeon-quest": {
            "title": "Play Dungeon Quest Slot | No Limit City | Yolo247 India",
            "description": "Play Dungeon Quest Slot at Yolo247 India. Embark on an adventure with No Limit City’s Dungeon Quest and win exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/dungeon-quest",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/dungeon-quest"
        },
        "east-coast-vs-west-coast": {
            "title": "Play East Coast vs West Coast Slot at Yolo247 India!",
            "description": "Play East Coast vs West Coast Slot at Yolo247 India. Choose your side in No Limit City’s East Coast vs West Coast and win big rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/east-coast-vs-west-coast",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/east-coast-vs-west-coast"
        },
        "el-paso-gunfight-xnudge": {
            "title": "Play El Paso Gunfight Xnudge Slot at Yolo247 India!",
            "description": "Play El Paso Gunfight Xnudge Slot at Yolo247 India. Relive wild west gunfights and win big with No Limit City’s El Paso Gunfight Xnudge!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/el-paso-gunfight-xnudge",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/el-paso-gunfight-xnudge"
        },
        "evil-goblins-xbomb": {
            "title": "Play Evil Goblins Xbomb Slot | No Limit City | Yolo247 India",
            "description": "Play Evil Goblins Xbomb Slot at Yolo247 India. Unleash explosive fun with No Limit City’s Evil Goblins Xbomb. Spin for big wins today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/evil-goblins-xbomb",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/evil-goblins-xbomb"
        },
        "fire-in-the-hole-xbomb": {
            "title": "Play Fire in the Hole Xbomb Slot at Yolo247 India!",
            "description": "Play Fire in the Hole Xbomb Slot at Yolo247 India. Ignite your winnings with No Limit City’s Fire in the Hole Xbomb! Spin and win now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/fire-in-the-hole-xbomb",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/fire-in-the-hole-xbomb"
        },
        "folsom-prison": {
            "title": "Play Folsom Prison Slot | No Limit City | Yolo247 India",
            "description": "Play Folsom Prison Slot at Yolo247 India. Escape the prison and win real rewards with No Limit City’s Folsom Prison Slot. Spin today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/folsom-prison",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/folsom-prison"
        },
        "fruits": {
            "title": "Play Fruits Slot | No Limit City | Yolo247 India",
            "description": "Play Fruits Slot at Yolo247 India. Enjoy classic fruit-themed fun with No Limit City’s Fruits Slot. Spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/fruits",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/fruits"
        },
        "gaelic-gold": {
            "title": "Play Gaelic Gold Slot | No Limit City | Yolo247 India",
            "description": "Play Gaelic Gold Slot at Yolo247 India. Seek treasures in Gaelic Gold with No Limit City’s exciting features. Spin for rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/gaelic-gold",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/gaelic-gold"
        },
        "golden-genie-and-the-walking-wilds": {
            "title": "Play Golden Genie Slot | No Limit City | Yolo247 India",
            "description": "Play Golden Genie and the Walking Wilds Slot at Yolo247 India. Win big with No Limit City’s Golden Genie! Spin for thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/golden-genie-and-the-walking-wilds",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/golden-genie-and-the-walking-wilds"
        },
        "harlequin-carnival": {
            "title": "Play Harlequin Carnival Slot | No Limit City | Yolo247 India",
            "description": "Play Harlequin Carnival Slot at Yolo247 India. Join the carnival fun and win big rewards with No Limit City’s Harlequin Carnival. Spin today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/harlequin-carnival",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/harlequin-carnival"
        },
        "hot-4-cash": {
            "title": "Play Hot 4 Cash Slot | No Limit City | Yolo247 India",
            "description": "Play Hot 4 Cash Slot at Yolo247 India. Heat up your winnings with No Limit City’s Hot 4 Cash. Spin for rewards and big wins today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/hot-4-cash",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/hot-4-cash"
        },
        "hot-nudge": {
            "title": "Play Hot Nudge Slot | No Limit City | Yolo247 India",
            "description": "Play Hot Nudge Slot at Yolo247 India. Experience fiery fun with No Limit City’s Hot Nudge. Spin and win big today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/hot-nudge",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/hot-nudge"
        },
        "ice-ice-yeti": {
            "title": "Play Ice Ice Yeti Slot | No Limit City | Yolo247 India",
            "description": "Play Ice Ice Yeti Slot at Yolo247 India. Chill out and win big with No Limit City’s Ice Ice Yeti! Spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/ice-ice-yeti",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/ice-ice-yeti"
        },
        "immortal-fruits": {
            "title": "Play Immortal Fruits Slot | No Limit City | Yolo247 India",
            "description": "Play Immortal Fruits Slot at Yolo247 India. Harvest big wins in No Limit City’s Immortal Fruits! Spin today for real money rewards!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/immortal-fruits",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/immortal-fruits"
        },
        "infectious-5-xways": {
            "title": "Play Infectious 5 Xways Slot | No Limit City | Yolo247 India",
            "description": "Play Infectious 5 Xways Slot at Yolo247 India. Catch the infectious fun and win big with No Limit City’s Infectious 5 Xways! Spin today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/infectious-5-xways",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/infectious-5-xways"
        },
        "karen-maneater": {
            "title": "Play Karen Maneater Slot | No Limit City | Yolo247 India",
            "description": "Play Karen Maneater Slot at Yolo247 India. Step into the wild world of Karen Maneater and win big rewards with No Limit City’s thrilling features!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/karen-maneater",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/karen-maneater"
        },
        "kitchen-drama-bbq-frenzy": {
            "title": "Play Kitchen Drama BBQ Frenzy Slot | Yolo247 Indi",
            "description": "Play Kitchen Drama BBQ Frenzy Slot at Yolo247 India. Sizzle with big wins in No Limit City’s Kitchen Drama BBQ Frenzy. Spin for rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/kitchen-drama-bbq-frenzy",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/kitchen-drama-bbq-frenzy"
        },
        "legion-x": {
            "title": "Play Legion X Slot | No Limit City | Yolo247 India",
            "description": "Play Legion X Slot at Yolo247 India. Join the Legion X and spin for big rewards with No Limit City’s action-packed features today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/legion-x",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/legion-x"
        },
        "little-bighorn": {
            "title": "Play Little Bighorn Slot | No Limit City | Yolo247 India",
            "description": "Play Little Bighorn Slot at Yolo247 India. Relive the legendary battle and win exciting rewards with No Limit City’s Little Bighorn! Spin today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/little-bighorn",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/little-bighorn"
        },
        "manhattan-goes-wild": {
            "title": "Play Manhattan Goes Wild Slot | No Limit City | Yolo247 India",
            "description": "Play Manhattan Goes Wild Slot at Yolo247 India. Experience the madness of Manhattan and win big with No Limit City’s exciting slot game! Spin now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/manhattan-goes-wild",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/manhattan-goes-wild"
        },
        "mayan-magic-wildfire": {
            "title": "Play Mayan Magic Wildfire Slot | Yolo247 India",
            "description": "Play Mayan Magic Wildfire Slot at Yolo247 India. Uncover magical treasures with No Limit City’s Mayan Magic Wildfire! Spin for rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/mayan-magic-wildfire",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/mayan-magic-wildfire"
        },
        "mental": {
            "title": "Play Mental Slot | No Limit City | Yolo247 India",
            "description": "Play Mental Slot at Yolo247 India. Explore mind-bending fun with No Limit City’s Mental Slot. Spin today for thrilling rewards!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/mental",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/mental"
        },
        "milky-ways": {
            "title": "Play Milky Ways Slot | No Limit City | Yolo247 India",
            "description": "Play Milky Ways Slot at Yolo247 India. Explore the galaxy with No Limit City’s Milky Ways Slot! Spin for cosmic rewards and big wins today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/milky-ways",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/milky-ways"
        },
        "misery-mining": {
            "title": "Play Misery Mining Slot | No Limit City | Yolo247 India",
            "description": "Play Misery Mining Slot at Yolo247 India. Mine for big rewards with No Limit City’s Misery Mining. Spin today for exciting features!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/misery-mining",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/misery-mining"
        },
        "monkey-s-gold-xpays": {
            "title": "Play Monkey's Gold Xpays Slot | No Limit City | Yolo247 India",
            "description": "Play Monkey's Gold Xpays Slot at Yolo247 India. Discover treasures in the jungle with No Limit City’s Monkey’s Gold Xpays. Spin for big wins!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/monkey-s-gold-xpays",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/monkey-s-gold-xpays"
        },
        "owls": {
            "title": "Play Owls Slot | No Limit City | Yolo247 India",
            "description": "Play Owls Slot at Yolo247 India. Unlock wisdom and riches with No Limit City’s Owls Slot. Spin today for exciting rewards!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/owls",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/owls"
        },
        "pearl-harbor": {
            "title": "Play Pearl Harbor Slot | No Limit City | Yolo247 India",
            "description": "Play Pearl Harbor Slot at Yolo247 India. Relive history with No Limit City’s Pearl Harbor Slot! Spin for big wins and thrilling rewards now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/pearl-harbor",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/pearl-harbor"
        },
        "pixies-vs-pirates": {
            "title": "Play Pixies vs Pirates Slot | No Limit City | Yolo247 India",
            "description": "Play Pixies vs Pirates Slot at Yolo247 India. Choose your side and spin for rewards with No Limit City’s Pixies vs Pirates slot!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/pixies-vs-pirates",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/pixies-vs-pirates"
        },
        "poison-eve": {
            "title": "Play Poison Eve Slot | No Limit City | Yolo247 India",
            "description": "Play Poison Eve Slot at Yolo247 India. Unleash deadly fun with No Limit City’s Poison Eve! Spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/poison-eve",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/poison-eve"
        },
        "punk-rocker": {
            "title": "Play Punk Rocker Slot | No Limit City | Yolo247 India",
            "description": "Play Punk Rocker Slot at Yolo247 India. Rock out with No Limit City’s Punk Rocker! Spin for big wins and exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/punk-rocker",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/punk-rocker"
        },
        "punk-toilet": {
            "title": "Play Punk Toilet Slot | No Limit City | Yolo247 India",
            "description": "Play Punk Toilet Slot at Yolo247 India. Get wild with No Limit City’s Punk Toilet! Spin for big wins and thrilling rewards now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/punk-toilet",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/punk-toilet"
        },
        "remember-gulag": {
            "title": "Play Remember Gulag Slot | No Limit City | Yolo247 India",
            "description": "Play Remember Gulag Slot at Yolo247 India. Step into history and spin for big wins with No Limit City’s Remember Gulag slot. Play now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/remember-gulag",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/remember-gulag"
        },
        "road-rage": {
            "title": "Play Road Rage Slot | No Limit City | Yolo247 India",
            "description": "Play Road Rage Slot at Yolo247 India. Hit the road with No Limit City’s Road Rage slot! Spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/road-rage",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/road-rage"
        },
        "rock-bottom": {
            "title": "Play Rock Bottom Slot | No Limit City | Yolo247 India",
            "description": "Play Rock Bottom Slot at Yolo247 India. Dig for treasures with No Limit City’s Rock Bottom! Spin for big wins today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/rock-bottom",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/rock-bottom"
        },
        "san-quentin-xways": {
            "title": "Play San Quentin Xways Slot | No Limit City | Yolo247 India",
            "description": "Play San Quentin Xways Slot at Yolo247 India. Face the wild west challenge with San Quentin Xways! Spin for thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/san-quentin-xways",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/san-quentin-xways"
        },
        "serial": {
            "title": "Play Serial Slot | No Limit City | Yolo247 India",
            "description": "Play Serial Slot at Yolo247 India. Get hooked on big wins with No Limit City’s Serial Slot! Spin for rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/serial",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/serial"
        },
        "starstruck": {
            "title": "Play Starstruck Slot | No Limit City | Yolo247 India",
            "description": "Play Starstruck Slot at Yolo247 India. Shine bright with No Limit City’s Starstruck! Spin for exciting rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/starstruck",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/starstruck"
        },
        "tesla-jolt": {
            "title": "Play Tesla Jolt Slot | No Limit City | Yolo247 India",
            "description": "Play Tesla Jolt Slot at Yolo247 India. Get electrified with No Limit City’s Tesla Jolt! Spin for thrilling rewards and big wins today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tesla-jolt",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tesla-jolt"
        },
        "the-border": {
            "title": "Play The Border Slot | No Limit City | Yolo247 India",
            "description": "Play The Border Slot at Yolo247 India. Cross into big wins with No Limit City’s The Border slot! Spin for rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/the-border",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/the-border"
        },
        "the-creepy-carnival": {
            "title": "Play The Creepy Carnival Slot | No Limit City | Yolo247 India",
            "description": "Play The Creepy Carnival Slot at Yolo247 India. Enter the spooky carnival with No Limit City’s slot game. Spin for big rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/the-creepy-carnival",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/the-creepy-carnival"
        },
        "the-rave": {
            "title": "Play The Rave Slot | No Limit City | Yolo247 India",
            "description": "Play The Rave Slot at Yolo247 India. Get into the groove with No Limit City’s The Rave! Spin for big wins and rewards now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/the-rave",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/the-rave"
        },
        "thor-hammer-time": {
            "title": "Play Thor Hammer Time Slot | No Limit City | Yolo247 India",
            "description": "Play Thor Hammer Time Slot at Yolo247 India. Join Thor and win big with Hammer Time! Spin for thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/thor-hammer-time",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/thor-hammer-time"
        },
        "tomb-of-akhenaten": {
            "title": "Play Tomb of Akhenaten Slot | No Limit City | Yolo247 India",
            "description": "Play Tomb of Akhenaten Slot at Yolo247 India. Discover hidden treasures in the Tomb of Akhenaten! Spin for rewards and real money today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tomb-of-akhenaten",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tomb-of-akhenaten"
        },
        "tomb-of-nefertiti": {
            "title": "Play Tomb of Nefertiti Slot | No Limit City | Yolo247 India",
            "description": "Play Tomb of Nefertiti Slot at Yolo247 India. Uncover Egypt’s treasures with No Limit City’s Tomb of Nefertiti. Spin and win today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tomb-of-nefertiti",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tomb-of-nefertiti"
        },
        "tombstone": {
            "title": "Play Tombstone Slot | No Limit City | Yolo247 India",
            "description": "Play Tombstone Slot at Yolo247 India. Relive the wild west with No Limit City’s Tombstone Slot! Spin for exciting rewards now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tombstone",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tombstone"
        },
        "tombstone-rip": {
            "title": "Play Tombstone R.I.P Slot | No Limit City | Yolo247 India",
            "description": "Play Tombstone R.I.P Slot at Yolo247 India. Experience epic features in Tombstone R.I.P! Spin for big wins and thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tombstone-rip",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tombstone-rip"
        },
        "tractor-beam": {
            "title": "Play Tractor Beam Slot | No Limit City | Yolo247 India",
            "description": "Play Tractor Beam Slot at Yolo247 India. Get abducted by big wins with No Limit City’s Tractor Beam! Spin for exciting features now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/tractor-beam",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/tractor-beam"
        },
        "true-grit-redemption": {
            "title": "Play True Grit Redemption Slot | Yolo247 India",
            "description": "Play True Grit Redemption Slot at Yolo247 India. Experience the wild west with True Grit Redemption! Spin for thrilling rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/true-grit-redemption",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/true-grit-redemption"
        },
        "walk-of-shame": {
            "title": "Play Walk of Shame Slot | No Limit City | Yolo247 India",
            "description": "Play Walk of Shame Slot at Yolo247 India. Take the Walk of Shame with big wins! Spin for exciting rewards today with No Limit City!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/walk-of-shame",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/walk-of-shame"
        },
        "warrior-graveyard-xnudge": {
            "title": "Play Warrior Graveyard Xnudge Slot | Yolo247 India",
            "description": "Play Warrior Graveyard Xnudge Slot at Yolo247 India. Battle for big rewards in Warrior Graveyard with Xnudge! Spin today for epic wins!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/warrior-graveyard-xnudge",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/warrior-graveyard-xnudge"
        },
        "wixx": {
            "title": "Play Wixx Slot | No Limit City | Yolo247 India",
            "description": "Play Wixx Slot at Yolo247 India. Dive into Wixx and spin for big rewards with No Limit City’s mystical slot game! Play now!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/wixx",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/wixx"
        },
        "xways-hoarder-xsplit": {
            "title": "Play Xways Hoarder Xsplit Slot | Yolo247 India",
            "description": "Play Xways Hoarder Xsplit Slot at Yolo247 India. Explore big rewards with Xways Hoarder Xsplit! Spin and win real rewards today!",
            "canonical": "https://www.yolo247.site/slots/no-limit-city/xways-hoarder-xsplit",
            "alternate": "https://m.yolo247.site/slots/no-limit-city/xways-hoarder-xsplit"
        },
        "red-tiger": {
            "title": "Play Red Tiger Slot Games at Yolo247 India | Real Money Fun",
            "description": "Explore thrilling Red Tiger slot games at Yolo247 India. Enjoy innovative features, stunning graphics, and win real money rewards. Start playing today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger",
            "alternate": "https://m.yolo247.site/slots/red-tiger"
        },
        "10-001-nights": {
            "title": "Play 10,001 Nights Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enter the world of 10,001 Nights Slot by Red Tiger Gaming at Yolo247 India. Enjoy magical features and big wins with every spin. Play now for a chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/10-001-nights",
            "alternate": "https://m.yolo247.site/slots/red-tiger/10-001-nights"
        },
        "10-001-nights-megaways": {
            "title": "Play 10,001 Nights Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Spin the reels on 10,001 Nights Megaways Slot by Red Tiger at Yolo247 India. Unlock free spins and endless opportunities for huge wins. Try it now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/10-001-nights-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/10-001-nights-megaways"
        },
        "1942-sky-warriors": {
            "title": "Play 1942 Sky Warriors Slot | Red Tiger | Yolo247 India",
            "description": "Take flight with 1942 Sky Warriors Slot by Red Tiger at Yolo247 India. Experience aerial battles and win big in this action-packed slot. Start spinning now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/1942-sky-warriors",
            "alternate": "https://m.yolo247.site/slots/red-tiger/1942-sky-warriors"
        },
        "24-hour-grand-prix": {
            "title": "Play 24 Hour Grand Prix Slot | Red Tiger | Yolo247 India",
            "description": "Race to win big with 24 Hour Grand Prix Slot by Red Tiger at Yolo247 India. Spin to unlock high-speed wins and exciting features. Try it today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/24-hour-grand-prix",
            "alternate": "https://m.yolo247.site/slots/red-tiger/24-hour-grand-prix"
        },
        "4-squad": {
            "title": "Play 4 Squad Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play 4 Squad Slot by Red Tiger Gaming at Yolo247 India. Enjoy dynamic features and big wins with this action-packed slot game. Start spinning today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/4-squad",
            "alternate": "https://m.yolo247.site/slots/red-tiger/4-squad"
        },
        "5-families": {
            "title": "Play 5 Families Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover 5 Families Slot by Red Tiger Gaming at Yolo247 India. Enjoy exciting bonus rounds, thrilling gameplay, and massive jackpots. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/5-families",
            "alternate": "https://m.yolo247.site/slots/red-tiger/5-families"
        },
        "777-stike": {
            "title": "Play 777 Stike Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels on 777 Strike Slot by Red Tiger Gaming at Yolo247 India. Enjoy classic symbols, high rewards, and thrilling gameplay. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/777-stike",
            "alternate": "https://m.yolo247.site/slots/red-tiger/777-stike"
        },
        "age-of-akkadia": {
            "title": "Play Age of Akkadia Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore the ancient world with Age of Akkadia Slot by Red Tiger Gaming at Yolo247 India. Uncover treasures and win big. Start playing today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/age-of-akkadia",
            "alternate": "https://m.yolo247.site/slots/red-tiger/age-of-akkadia"
        },
        "agent-royale": {
            "title": "Play Agent Royale Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the thrilling world of espionage with Agent Royale Slot by Red Tiger Gaming at Yolo247 India. Unlock rewards with every spin. Play now for a chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/agent-royale",
            "alternate": "https://m.yolo247.site/slots/red-tiger/agent-royale"
        },
        "ancient-disco": {
            "title": "Play Ancient Disco Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Groove to the beats of Ancient Disco Slot by Red Tiger Gaming at Yolo247 India. Enjoy funky features and big rewards. Spin now for a fun-filled experience!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ancient-disco",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ancient-disco"
        },
        "ancient-script": {
            "title": "Play Ancient Script Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Ancient Script Slot by Red Tiger Gaming at Yolo247 India. Uncover secrets and win big with this thrilling slot full of ancient symbols and features!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ancient-script",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ancient-script"
        },
        "ancients-blessing": {
            "title": "Play Ancients' Blessing Slot | Red Tiger | Yolo247 India",
            "description": "Play Ancients' Blessing Slot by Red Tiger Gaming at Yolo247 India. Experience the magical features and big wins in this exciting ancient-themed slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ancients-blessing",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ancients-blessing"
        },
        "apache-way": {
            "title": "Play Apache Way Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore Apache Way Slot by Red Tiger Gaming at Yolo247 India. Enjoy wild features, big rewards, and the spirit of the American frontier. Spin now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/apache-way",
            "alternate": "https://m.yolo247.site/slots/red-tiger/apache-way"
        },
        "arcade-bomb": {
            "title": "Play Arcade Bomb Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Arcade Bomb Slot by Red Tiger Gaming at Yolo247 India. Dive into a classic arcade experience with explosive wins and thrilling gameplay. Start spinning today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/arcade-bomb",
            "alternate": "https://m.yolo247.site/slots/red-tiger/arcade-bomb"
        },
        "athens-megaways": {
            "title": "Play Athens Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Experience Athens Megaways Slot by Red Tiger Gaming at Yolo247 India. Unlock exciting features, huge jackpots, and endless entertainment in this Greek-themed slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/athens-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/athens-megaways"
        },
        "aurum-codex": {
            "title": "Play Aurum Codex Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Uncover the mystery in Aurum Codex Slot by Red Tiger Gaming at Yolo247 India. Spin to win with golden symbols and thrilling bonuses. Try your luck today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/aurum-codex",
            "alternate": "https://m.yolo247.site/slots/red-tiger/aurum-codex"
        },
        "aztec-spins": {
            "title": "Play Aztec Spins Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels on Aztec Spins Slot by Red Tiger Gaming at Yolo247 India. Discover hidden treasures in this exciting Aztec-themed slot. Start playing now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/aztec-spins",
            "alternate": "https://m.yolo247.site/slots/red-tiger/aztec-spins"
        },
        "bass-boss": {
            "title": "Play Bass Boss Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Bass Boss Slot by Red Tiger Gaming at Yolo247 India. Dive into a music-inspired adventure with big rewards and exciting features. Spin now to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/bass-boss",
            "alternate": "https://m.yolo247.site/slots/red-tiger/bass-boss"
        },
        "beriched": {
            "title": "Play Beriched Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Beriched Slot by Red Tiger Gaming at Yolo247 India. Unlock thrilling gameplay and big wins with this captivating slot game. Play now for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/beriched",
            "alternate": "https://m.yolo247.site/slots/red-tiger/beriched"
        },
        "betty-boris-and-boo": {
            "title": "Play Betty Boris' And Boo Slot | Red Tiger | Yolo247 India",
            "description": "Join Betty, Boris & Boo in this thrilling slot from Red Tiger at Yolo247 India. Experience spooky fun with big features and even bigger rewards. Start spinning!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/betty-boris-and-boo",
            "alternate": "https://m.yolo247.site/slots/red-tiger/betty-boris-and-boo"
        },
        "big-cat-rescue": {
            "title": "Play Big Cat Rescue Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Big Cat Rescue Slot by Red Tiger Gaming at Yolo247 India. Save the big cats and win big with wild features and thrilling gameplay. Spin today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/big-cat-rescue",
            "alternate": "https://m.yolo247.site/slots/red-tiger/big-cat-rescue"
        },
        "blazing-clusters": {
            "title": "Play Blazing Clusters Slot | Red Tiger | Yolo247 India",
            "description": "Discover Blazing Clusters Slot by Red Tiger at Yolo247 India. Unlock wild clusters and blazing rewards in this fiery, action-packed slot game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/blazing-clusters",
            "alternate": "https://m.yolo247.site/slots/red-tiger/blazing-clusters"
        },
        "blobsters-cluster-buster": {
            "title": "Play Blobsters Cluster Buster Slot | Yolo247 India",
            "description": "Play Blobsters Cluster Buster Slot at Yolo247 India. Enjoy quirky, fun gameplay with big clusters and wild rewards. Spin now for a chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/blobsters-cluster-buster",
            "alternate": "https://m.yolo247.site/slots/red-tiger/blobsters-cluster-buster"
        },
        "blood-sukcers-megaways": {
            "title": "Play Blood Sukcers Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Play Blood Suckers Megaways Slot by Red Tiger at Yolo247 India. Unleash the vampire features for big wins in this thrilling Megaways slot game. Try your luck today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/blood-sukcers-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/blood-sukcers-megaways"
        },
        "blue-diamond": {
            "title": "Play Blue Diamond Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Blue Diamond Slot by Red Tiger Gaming at Yolo247 India. Spin to win big with sparkling diamonds and exciting features in this dazzling slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/blue-diamond",
            "alternate": "https://m.yolo247.site/slots/red-tiger/blue-diamond"
        },
        "bombuster": {
            "title": "Play Bombuster Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Bombuster Slot by Red Tiger Gaming at Yolo247 India. Enjoy explosive features, big rewards, and thrilling gameplay in this action-packed slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/bombuster",
            "alternate": "https://m.yolo247.site/slots/red-tiger/bombuster"
        },
        "bounty-raid": {
            "title": "Play Bounty Raid Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the Bounty Raid in this slot from Red Tiger Gaming at Yolo247 India. Unlock massive rewards with thrilling features and action-packed spins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/bounty-raid",
            "alternate": "https://m.yolo247.site/slots/red-tiger/bounty-raid"
        },
        "buffalo-mania-megaways": {
            "title": "Play Buffalo Mania Megaways Slot | Yolo247 India",
            "description": "Play Buffalo Mania Megaways Slot at Yolo247 India. Spin to win big with buffalo-themed symbols and Megaways features in this thrilling slot game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/buffalo-mania-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/buffalo-mania-megaways"
        },
        "bugsy-s-bar": {
            "title": "Play Bugsy's Bar Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Bugsy's Bar Slot by Red Tiger Gaming at Yolo247 India. Dive into a classic bar setting with big rewards, wild features, and plenty of fun!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/bugsy-s-bar",
            "alternate": "https://m.yolo247.site/slots/red-tiger/bugsy-s-bar"
        },
        "bulls-run-wild": {
            "title": "Play Bulls Run Wild Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Bulls Run Wild Slot by Red Tiger Gaming at Yolo247 India and experience thrilling wild spins and exciting bonus features. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/bulls-run-wild",
            "alternate": "https://m.yolo247.site/slots/red-tiger/bulls-run-wild"
        },
        "cake-ice-cream": {
            "title": "Play Cake & Ice Cream Slot | Red Tiger | Yolo247 India",
            "description": "Indulge in the fun of Cake & Ice Cream Slot by Red Tiger at Yolo247 India. Enjoy sweet rewards and exciting gameplay! Try your luck today.",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cake-ice-cream",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cake-ice-cream"
        },
        "cash-or-nothing": {
            "title": "Play Cash or Nothing Slot | Red Tiger | Yolo247 India",
            "description": "Try Cash or Nothing Slot by Red Tiger at Yolo247 India for a thrilling game with big rewards and exciting features. Play now and win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cash-or-nothing",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cash-or-nothing"
        },
        "cash-ultimate": {
            "title": "Play Cash Ultimate Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Cash Ultimate Slot by Red Tiger Gaming at Yolo247 India. Spin for cash prizes and big wins with every turn! Join the fun now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cash-ultimate",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cash-ultimate"
        },
        "cash-volt": {
            "title": "Play Cash Volt Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Cash Volt Slot by Red Tiger Gaming at Yolo247 India. Enjoy electrifying gameplay and big potential rewards. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cash-volt",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cash-volt"
        },
        "cindrella-s-ball": {
            "title": "Play Cinderella's Ball Slot | Red Tiger | Yolo247 India",
            "description": "Step into Cinderella's Ball Slot by Red Tiger at Yolo247 India. Experience magical moments and fantastic features. Spin to win now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cindrella-s-ball",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cindrella-s-ball"
        },
        "cirque-de-la-fortune": {
            "title": "Play Cirque De La Fortune Slot | Red Tiger | Yolo247 India",
            "description": "Experience Cirque De La Fortune Slot by Red Tiger at Yolo247 India. Dive into a world of wonders with exciting bonuses and big rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cirque-de-la-fortune",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cirque-de-la-fortune"
        },
        "clash-of-the-beasts": {
            "title": "Play Clash of the Beasts Slot | Red Tiger | Yolo247 India",
            "description": "Clash of the Beasts Slot by Red Tiger at Yolo247 India offers fierce action and incredible payouts. Spin the reels and face the beasts now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/clash-of-the-beasts",
            "alternate": "https://m.yolo247.site/slots/red-tiger/clash-of-the-beasts"
        },
        "classic-blackjack": {
            "title": "Play Classic Blackjack Slot | Red Tiger | Yolo247 India",
            "description": "Play Classic Blackjack Slot by Red Tiger at Yolo247 India. Enjoy timeless casino fun with big winning chances and endless excitement!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/classic-blackjack",
            "alternate": "https://m.yolo247.site/slots/red-tiger/classic-blackjack"
        },
        "crazy-genie": {
            "title": "Play Crazy Genie Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Crazy Genie Slot by Red Tiger Gaming at Yolo247 India. Rub the lamp for a chance to unlock amazing rewards and magical bonuses!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/crazy-genie",
            "alternate": "https://m.yolo247.site/slots/red-tiger/crazy-genie"
        },
        "crystal-mirror": {
            "title": "Play Crystal Mirror Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Crystal Mirror Slot by Red Tiger Gaming at Yolo247 India. Discover a world of mystery and big rewards! Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/crystal-mirror",
            "alternate": "https://m.yolo247.site/slots/red-tiger/crystal-mirror"
        },
        "da-vinci-s-mystery": {
            "title": "Play Da Vinci's Mystery Slot | Red Tiger | Yolo247 India",
            "description": "Explore Da Vinci's Mystery Slot at Yolo247 India. Unlock the secrets of the Renaissance and win fantastic prizes in this exciting game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/da-vinci-s-mystery",
            "alternate": "https://m.yolo247.site/slots/red-tiger/da-vinci-s-mystery"
        },
        "devil-s-number": {
            "title": "Play Devil's Number Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Dare to play Devil's Number Slot by Red Tiger Gaming at Yolo247 India. Unleash thrilling features and devilish rewards in this exciting game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/devil-s-number",
            "alternate": "https://m.yolo247.site/slots/red-tiger/devil-s-number"
        },
        "diamond-blitz": {
            "title": "Play Diamond Blitz Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience Diamond Blitz Slot by Red Tiger Gaming at Yolo247 India. Spin the reels for stunning gems and sparkling rewards! Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/diamond-blitz",
            "alternate": "https://m.yolo247.site/slots/red-tiger/diamond-blitz"
        },
        "dice-dice-dice": {
            "title": "Play Dice Dice Dice Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Dice Dice Dice Slot by Red Tiger Gaming at Yolo247 India. Enjoy the thrill of dice rolls and win amazing rewards in this exciting slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dice-dice-dice",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dice-dice-dice"
        },
        "divine-ways": {
            "title": "Play Divine Ways Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Divine Ways Slot by Red Tiger Gaming at Yolo247 India. Spin for divine wins with exciting features and special bonuses. Try now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/divine-ways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/divine-ways"
        },
        "doggy-riches-megaways": {
            "title": "Play Doggy Riches Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Play Doggy Riches Megaways Slot at Yolo247 India. Unlock doggone rewards with every spin and enjoy exciting Megaways mechanics!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/doggy-riches-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/doggy-riches-megaways"
        },
        "dracula-awakening": {
            "title": "Play Dracula Awakening Slot | Red Tiger | Yolo247 India",
            "description": "Enter Dracula Awakening Slot at Yolo247 India. Enjoy spooky spins and eerie rewards in this thrilling Red Tiger game. Spin to win now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dracula-awakening",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dracula-awakening"
        },
        "dragon-king-legend-of-the-seas": {
            "title": "Play Dragon King Legend of the Seas Slot | Yolo247 India",
            "description": "Embark on a journey with Dragon King Legend of the Seas Slot at Yolo247 India. Enjoy thrilling rewards and oceanic adventures with every spin!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-king-legend-of-the-seas",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-king-legend-of-the-seas"
        },
        "dragon-s-cluster-buster": {
            "title": "Play Dragon's Cluster Buster Slot | Yolo247 India",
            "description": "Play Dragon's Cluster Buster Slot at Yolo247 India. Experience explosive wins and fun-packed gameplay with this exciting Red Tiger slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-cluster-buster",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-cluster-buster"
        },
        "dragon-s-fire": {
            "title": "Play Dragon's Fire Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the Dragon's Fire Slot by Red Tiger Gaming at Yolo247 India. Enjoy fiery wins and scorching bonus features with every spin. Try it now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-fire",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-fire"
        },
        "dragon-s-fire-infinireels": {
            "title": "Play Dragon's Fire Infinireels Slot | Yolo247 India",
            "description": "Play Dragon's Fire Infinireels Slot at Yolo247 India for endless reels and exciting features. Unleash fiery rewards and big wins today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-fire-infinireels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-fire-infinireels"
        },
        "dragon-s-luck": {
            "title": "Play Dragon's Luck Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Dragon's Luck Slot by Red Tiger Gaming at Yolo247 India. Experience dragon-powered spins and epic rewards in this thrilling slot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-luck",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-luck"
        },
        "dragon-s-luck-deluxe": {
            "title": "Play Dragon's Luck Deluxe Slot | Yolo247 India",
            "description": "Spin Dragon's Luck Deluxe Slot at Yolo247 India for amazing wins and exciting features. Enter a world of fortune with every turn of the reel!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-luck-deluxe",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-luck-deluxe"
        },
        "dragon-s-luck-megaways": {
            "title": "Play Dragon's Luck Megaways Slot | Yolo247 India",
            "description": "Play Dragon's Luck Megaways Slot at Yolo247 India. Unlock massive wins with each spin and enjoy dynamic gameplay with the Megaways feature!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-luck-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-luck-megaways"
        },
        "dragon-s-luck-power-reels": {
            "title": "Play Dragon's Luck Power Reels Slot | Yolo247 India",
            "description": "Try Dragon's Luck Power Reels Slot at Yolo247 India. Enjoy powerful features and win big with every turn of the reel in this exciting game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dragon-s-luck-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dragon-s-luck-power-reels"
        },
        "dynamite-riches": {
            "title": "Play Dynamite Riches Slot | Yolo247 India",
            "description": "Spin the reels of Dynamite Riches Slot at Yolo247 India. Unlock explosive wins and thrilling features in this action-packed Red Tiger game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dynamite-riches",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dynamite-riches"
        },
        "dynamite-riches-megaways": {
            "title": "Play Dynamite Riches Megaways Slot | Yolo247 India",
            "description": "Play Dynamite Riches Megaways Slot at Yolo247 India. Experience explosive wins with every spin and the dynamic Megaways feature. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/dynamite-riches-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/dynamite-riches-megaways"
        },
        "eagle-riches": {
            "title": "Play Eagle Riches Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Eagle Riches Slot by Red Tiger Gaming at Yolo247 India. Enjoy high-flying wins and thrilling features. Take flight to big rewards today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/eagle-riches",
            "alternate": "https://m.yolo247.site/slots/red-tiger/eagle-riches"
        },
        "emerald-diamond": {
            "title": "Play Emerald Diamond Slot | Red Tiger | Yolo247 India",
            "description": "Spin the Emerald Diamond Slot at Yolo247 India for dazzling wins and exciting features. Discover sparkling rewards with every spin!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/emerald-diamond",
            "alternate": "https://m.yolo247.site/slots/red-tiger/emerald-diamond"
        },
        "esqueleto-mariachi": {
            "title": "Play Esqueleto Mariachi Slot | Red Tiger | Yolo247 India",
            "description": "Play Esqueleto Mariachi Slot at Yolo247 India. Join the mariachi band for fun spins and lively rewards in this festive Red Tiger game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/esqueleto-mariachi",
            "alternate": "https://m.yolo247.site/slots/red-tiger/esqueleto-mariachi"
        },
        "five-star": {
            "title": "Play Five Star Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the Five Star Slot by Red Tiger Gaming at Yolo247 India for a chance at fantastic rewards. Enjoy five-star features with every spin!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/five-star",
            "alternate": "https://m.yolo247.site/slots/red-tiger/five-star"
        },
        "five-star-power-reels": {
            "title": "Play Five Star Power Reels Slot | Red Tiger | Yolo247 India",
            "description": "Play Five Star Power Reels Slot at Yolo247 India. Unlock five-star rewards with every spin in this exciting and rewarding game! Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/five-star-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/five-star-power-reels"
        },
        "flaming-fox": {
            "title": "Play Flaming Fox Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience the fiery adventure with the Flaming Fox slot by Red Tiger Gaming. Spin and win exciting prizes now at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/flaming-fox",
            "alternate": "https://m.yolo247.site/slots/red-tiger/flaming-fox"
        },
        "flodder": {
            "title": "Play Flodder Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Dive into the quirky world of Flodder Slot by Red Tiger Gaming. Spin the reels and win amazing prizes at Yolo247 India.",
            "canonical": "https://www.yolo247.site/slots/red-tiger/flodder",
            "alternate": "https://m.yolo247.site/slots/red-tiger/flodder"
        },
        "forever-7-s": {
            "title": "Play Forever 7's Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover timeless wins with Forever 7’s slot by Red Tiger Gaming. Play today at Yolo247 India for thrilling rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/forever-7-s",
            "alternate": "https://m.yolo247.site/slots/red-tiger/forever-7-s"
        },
        "fortune-fest": {
            "title": "Play Fortune Fest Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Fortune Fest Slot by Red Tiger Gaming at Yolo247 India. Celebrate big wins and thrilling features in this exciting slot game. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/fortune-fest",
            "alternate": "https://m.yolo247.site/slots/red-tiger/fortune-fest"
        },
        "fortune-house": {
            "title": "Play Fortune House Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Fortune House Slot by Red Tiger Gaming at Yolo247 India. Spin for luck and fortune with every turn in this exciting and rewarding game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/fortune-house",
            "alternate": "https://m.yolo247.site/slots/red-tiger/fortune-house"
        },
        "fortune-house-power-reels": {
            "title": "Play Fortune House Power Reels Slot | Yolo247 India",
            "description": "Spin the Fortune House Power Reels Slot at Yolo247 India. Unlock powerful features and thrilling wins in this exciting Red Tiger game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/fortune-house-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/fortune-house-power-reels"
        },
        "fruit-snap": {
            "title": "Play Fruit Snap Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enjoy a fun, fruity adventure in Fruit Snap by Red Tiger Gaming. Spin and win big at Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/fruit-snap",
            "alternate": "https://m.yolo247.site/slots/red-tiger/fruit-snap"
        },
        "gems-gone-wild-power-reels": {
            "title": "Play Gems Gone Wild Power Reels Slot | Yolo247 India",
            "description": "Play Gems Gone Wild Power Reels Slot at Yolo247 India. Discover dazzling gems and big rewards with every spin in this exciting Red Tiger game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/gems-gone-wild-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/gems-gone-wild-power-reels"
        },
        "genie-nights": {
            "title": "Play Genie Nights Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Genie Nights Slot by Red Tiger Gaming at Yolo247 India. Uncover magical wins and enchanting features in this mystical slot game!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/genie-nights",
            "alternate": "https://m.yolo247.site/slots/red-tiger/genie-nights"
        },
        "masquerade": {
            "title": "Play Masquerade Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Masquerade Slot by Red Tiger Gaming at Yolo247 India. Unmask big wins and enjoy exciting gameplay with every spin. Join the fun now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/masquerade",
            "alternate": "https://m.yolo247.site/slots/red-tiger/masquerade"
        },
        "mayan-gods": {
            "title": "Play Mayan Gods Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unveil ancient treasures with Mayan Gods slot by Red Tiger Gaming. Start spinning and winning at Yolo247 India now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mayan-gods",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mayan-gods"
        },
        "mayhem": {
            "title": "Play Mayhem Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Embrace the chaos and excitement with Mayhem Slot by Red Tiger Gaming. Play now at Yolo247 India and win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mayhem",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mayhem"
        },
        "mega-dragon": {
            "title": "Play Mega Dragon Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unleash the power of the Mega Dragon Slot by Red Tiger Gaming. Play today at Yolo247 India for epic wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mega-dragon",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mega-dragon"
        },
        "mega-jade": {
            "title": "Play Mega Jade Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the Mega Jade slot by Red Tiger Gaming for magical wins. Play now at Yolo247 India and claim your treasures!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mega-jade",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mega-jade"
        },
        "mega-pyramid": {
            "title": "Play Mega Pyramid Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Embark on a quest to uncover hidden riches in Mega Pyramid by Red Tiger Gaming. Start playing at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mega-pyramid",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mega-pyramid"
        },
        "mega-rise": {
            "title": "Play Mega Rise Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Rise to fortune with the Mega Rise slot by Red Tiger Gaming. Play at Yolo247 India now for massive rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mega-rise",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mega-rise"
        },
        "midas-gold": {
            "title": "Play Midas Gold Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Midas Gold Slot by Red Tiger Gaming and discover golden treasures. Play now at Yolo247 India for thrilling wins and exciting gameplay!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/midas-gold",
            "alternate": "https://m.yolo247.site/slots/red-tiger/midas-gold"
        },
        "multiplier-riches": {
            "title": "Play Multiplier Riches Slot | Red Tiger | Yolo247 India",
            "description": "Enjoy the riches of Multiplier Riches Slot by Red Tiger. Spin for big multipliers and even bigger rewards at Yolo247 India. Play today for great rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/multiplier-riches",
            "alternate": "https://m.yolo247.site/slots/red-tiger/multiplier-riches"
        },
        "mystery-reels": {
            "title": "Play Mystery Reels Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience the excitement of Mystery Reels Slot by Red Tiger Gaming. Spin the reels for mystery-filled rewards and features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystery-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystery-reels"
        },
        "mystery-reels-deluxe": {
            "title": "Play Mystery Reels Deluxe Slot | Red Tiger | Yolo247 India",
            "description": "Unlock deluxe features and amazing rewards in Mystery Reels Deluxe Slot by Red Tiger. Spin the reels and win big at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystery-reels-deluxe",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystery-reels-deluxe"
        },
        "mystery-reels-megaways": {
            "title": "Play Mystery Reels Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Get ready for an epic adventure in Mystery Reels Megaways Slot by Red Tiger. Spin the reels for exciting rewards and endless features at Yolo247 India!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystery-reels-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystery-reels-megaways"
        },
        "mystery-reels-power-reels": {
            "title": "Play Mystery Reels Power Reels | Red Tiger | Yolo247 India",
            "description": "Play the thrilling Mystery Reels Power Reels Slot by Red Tiger. Spin for powerful rewards and feature-packed gameplay at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystery-reels-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystery-reels-power-reels"
        },
        "mystic-staxx": {
            "title": "Play Mystic Staxx Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unlock mystical wins in Mystic Staxx Slot by Red Tiger Gaming. Spin the reels and win big at Yolo247 India. Experience the magic today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystic-staxx",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystic-staxx"
        },
        "mystic-wheel": {
            "title": "Play Mystic Wheel Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore the enchanting Mystic Wheel Slot by Red Tiger Gaming. Spin for mystical rewards and bonus features at Yolo247 India. Play today for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/mystic-wheel",
            "alternate": "https://m.yolo247.site/slots/red-tiger/mystic-wheel"
        },
        "narcos-mexico": {
            "title": "Play Narcos Mexico Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Dive into the action of Narcos Mexico Slot by Red Tiger Gaming. Spin the reels for thrilling features and big wins. Join Yolo247 India for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/narcos-mexico",
            "alternate": "https://m.yolo247.site/slots/red-tiger/narcos-mexico"
        },
        "neon-links": {
            "title": "Play Neon Links Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Brighten your day with the Neon Links Slot by Red Tiger Gaming. Spin the reels for exciting wins and vibrant gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/neon-links",
            "alternate": "https://m.yolo247.site/slots/red-tiger/neon-links"
        },
        "nft-megaways": {
            "title": "Play NFT Megaways Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore the future of gaming with NFT Megaways Slot by Red Tiger Gaming. Spin for huge rewards and exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/nft-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/nft-megaways"
        },
        "night-roller": {
            "title": "Play Night Roller Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Hit the jackpot with Night Roller Slot by Red Tiger Gaming. Spin for big wins and exciting features today at Yolo247 India. Join the fun now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/night-roller",
            "alternate": "https://m.yolo247.site/slots/red-tiger/night-roller"
        },
        "ninja-ways": {
            "title": "Play Ninja Ways Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover ninja-themed adventures with Ninja Ways Slot by Red Tiger Gaming. Spin for exciting rewards and thrilling features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ninja-ways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ninja-ways"
        },
        "ocean-fortune": {
            "title": "Play Ocean Fortune Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unlock ocean treasures with Ocean Fortune Slot by Red Tiger Gaming. Spin the reels for underwater riches and rewards at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ocean-fortune",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ocean-fortune"
        },
        "path-of-destiny": {
            "title": "Play Path of Destiny Slot by Red Tiger | Yolo247 India",
            "description": "Walk the Path of Destiny Slot by Red Tiger Gaming for mysterious rewards and exciting features. Spin and win today at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/path-of-destiny",
            "alternate": "https://m.yolo247.site/slots/red-tiger/path-of-destiny"
        },
        "peggy-sweets": {
            "title": "Play Peggy Sweets Slot by Red Tiger | Yolo247 India",
            "description": "Treat yourself with Peggy Sweets Slot by Red Tiger Gaming. Spin the reels for sweet rewards and fun features at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/peggy-sweets",
            "alternate": "https://m.yolo247.site/slots/red-tiger/peggy-sweets"
        },
        "persian-fortune": {
            "title": "Play Persian Fortune Slot by Red Tiger | Yolo247 India",
            "description": "Find fortune in Persian Fortune Slot by Red Tiger Gaming. Spin the reels for incredible wins and features at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/persian-fortune",
            "alternate": "https://m.yolo247.site/slots/red-tiger/persian-fortune"
        },
        "phoenix": {
            "title": "Play Phoenix Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Set off on an adventure with Phoenix Slot by Red Tiger Gaming. Spin the reels for fiery rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/phoenix",
            "alternate": "https://m.yolo247.site/slots/red-tiger/phoenix"
        },
        "phoenix-fire-power-reels": {
            "title": "Play Phoenix Fire Power Reels Slot | Yolo247 India",
            "description": "Unleash fiery features in Phoenix Fire Power Reels Slot by Red Tiger. Spin the reels for huge rewards at Yolo247 India. Join the firestorm today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/phoenix-fire-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/phoenix-fire-power-reels"
        },
        "piggy-pirates": {
            "title": "Play Piggy Pirates Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Embark on a pirate journey with Piggy Pirates Slot by Red Tiger Gaming. Spin the reels for treasure-filled rewards at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/piggy-pirates",
            "alternate": "https://m.yolo247.site/slots/red-tiger/piggy-pirates"
        },
        "piggy-riches-megaways": {
            "title": "Play Piggy Riches Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Spin for big wins with Piggy Riches Megaways Slot by Red Tiger Gaming. Enjoy exciting features and rewards at Yolo247 India. Play today and win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/piggy-riches-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/piggy-riches-megaways"
        },
        "pinatas-ponies": {
            "title": "Play Pinatas & Ponies Slot | Red Tiger | Yolo247 India",
            "description": "Get ready for a fun-filled fiesta with Pinatas & Ponies Slot by Red Tiger. Spin the reels for colorful rewards and exciting gameplay at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/pinatas-ponies",
            "alternate": "https://m.yolo247.site/slots/red-tiger/pinatas-ponies"
        },
        "pirate-s-plenty-megaways": {
            "title": "Play Pirate's Plenty Megaways Slot | Yolo247 India",
            "description": "Set sail for treasure in Pirate's Plenty Megaways Slot. Enjoy thrilling features and massive wins at Yolo247 India. Play now for your chance at pirate gold!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/pirate-s-plenty-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/pirate-s-plenty-megaways"
        },
        "primate-king": {
            "title": "Play Primate king Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Embark on a wild adventure with Primate King Slot by Red Tiger Gaming. Spin for big rewards and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/primate-king",
            "alternate": "https://m.yolo247.site/slots/red-tiger/primate-king"
        },
        "puss-n-boots": {
            "title": "Play Puss'N Boots Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the adventure with Puss'N Boots Slot by Red Tiger Gaming. Spin the reels for feline fun and huge rewards at Yolo247 India. Play now for exciting wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/puss-n-boots",
            "alternate": "https://m.yolo247.site/slots/red-tiger/puss-n-boots"
        },
        "ra-s-legend": {
            "title": "Play RA's Legend Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unlock the mysteries of RA's Legend Slot by Red Tiger Gaming. Spin the reels for legendary rewards and thrilling features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ra-s-legend",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ra-s-legend"
        },
        "rainbow-jackpots": {
            "title": "Play Rainbow Jackpots Slot | Red Tiger | Yolo247 India",
            "description": "Discover the colorful rewards in Rainbow Jackpots Slot by Red Tiger. Spin for jackpot wins and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/rainbow-jackpots",
            "alternate": "https://m.yolo247.site/slots/red-tiger/rainbow-jackpots"
        },
        "rainbow-jackpots-power-lines": {
            "title": "Play Rainbow Jackpots Power Lines Slot | Yolo247 India",
            "description": "Spin the reels of Rainbow Jackpots Power Lines Slot for exciting features and massive rewards. Play now at Yolo247 India for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/rainbow-jackpots-power-lines",
            "alternate": "https://m.yolo247.site/slots/red-tiger/rainbow-jackpots-power-lines"
        },
        "reactor": {
            "title": "Play Reactor Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience the excitement of Reactor Slot by Red Tiger Gaming. Spin for big wins and explosive features at Yolo247 India. Play now and hit the jackpot!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reactor",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reactor"
        },
        "red-diamond": {
            "title": "Play Red Diamond Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Red Diamond Slot by Red Tiger Gaming and discover glittering rewards. Play today at Yolo247 India for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/red-diamond",
            "alternate": "https://m.yolo247.site/slots/red-tiger/red-diamond"
        },
        "reel-heist": {
            "title": "Play Reel Heist Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Plan the perfect heist with Reel Heist Slot by Red Tiger Gaming. Spin for thrilling rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reel-heist",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reel-heist"
        },
        "reel-keeper": {
            "title": "Play Reel Keeper Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unlock exciting rewards with Reel Keeper Slot by Red Tiger Gaming. Spin the reels for thrilling gameplay and big wins at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reel-keeper",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reel-keeper"
        },
        "reel-keeper-power-reels": {
            "title": "Play Reel Keeper Power Reels Slot | Yolo247 India",
            "description": "Enjoy big wins with Reel Keeper Power Reels Slot. Spin for massive rewards and exciting features at Yolo247 India. Play now for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reel-keeper-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reel-keeper-power-reels"
        },
        "reel-king-mega": {
            "title": "Play Reel King Mega Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin for massive rewards with Reel King Mega Slot by Red Tiger. Play today at Yolo247 India and enjoy exciting features and big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reel-king-mega",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reel-king-mega"
        },
        "regal-beasts": {
            "title": "Play Regal Beasts Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore the wild with Regal Beasts Slot by Red Tiger Gaming. Spin for thrilling rewards and exciting features at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/regal-beasts",
            "alternate": "https://m.yolo247.site/slots/red-tiger/regal-beasts"
        },
        "regal-streak": {
            "title": "Play Regal Streak Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enjoy exciting gameplay with Regal Streak Slot by Red Tiger Gaming. Spin the reels for regal rewards and big wins at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/regal-streak",
            "alternate": "https://m.yolo247.site/slots/red-tiger/regal-streak"
        },
        "reptizillions-power-reels": {
            "title": "Play Reptizillions Power Reels Slot | Yolo247 India",
            "description": "Get ready for massive rewards with Reptizillions Power Reels Slot. Spin for exciting features and big wins at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/reptizillions-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/reptizillions-power-reels"
        },
        "riddle-of-the-sphinx": {
            "title": "Play Riddle of the Sphinx Slot | Red Tiger | Yolo247 India",
            "description": "Solve the Riddle of the Sphinx Slot by Red Tiger Gaming. Spin the reels for mysterious rewards and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/riddle-of-the-sphinx",
            "alternate": "https://m.yolo247.site/slots/red-tiger/riddle-of-the-sphinx"
        },
        "rio-stars": {
            "title": "Play Rio Stars Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Rio Stars Slot by Red Tiger Gaming for exciting rewards and a taste of the carnival spirit. Join Yolo247 India today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/rio-stars",
            "alternate": "https://m.yolo247.site/slots/red-tiger/rio-stars"
        },
        "risque-megaways": {
            "title": "Play Risque Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Spin the reels of Risque Megaways Slot by Red Tiger and enjoy exciting rewards and features. Play today at Yolo247 India for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/risque-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/risque-megaways"
        },
        "robin-hoods-wild-forest": {
            "title": "Play Robin Hoods Wild Forest Slot | Yolo247 India",
            "description": "Explore the enchanted forest with Robin Hoods Wild Forest Slot by Red Tiger Gaming. Spin for exciting rewards and thrilling gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/robin-hoods-wild-forest",
            "alternate": "https://m.yolo247.site/slots/red-tiger/robin-hoods-wild-forest"
        },
        "rock-n-lock": {
            "title": "Play Rock 'N' Lock Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Unlock thrilling features in Rock 'N' Lock Slot by Red Tiger Gaming. Spin the reels for big wins and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/rock-n-lock",
            "alternate": "https://m.yolo247.site/slots/red-tiger/rock-n-lock"
        },
        "rocket-men": {
            "title": "Play Rocket Men Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the Rocket Men for an out-of-this-world adventure in Rocket Men Slot by Red Tiger Gaming. Spin for galactic rewards at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/rocket-men",
            "alternate": "https://m.yolo247.site/slots/red-tiger/rocket-men"
        },
        "royal-gems": {
            "title": "Play Royal Gems Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Royal Gems Slot by Red Tiger Gaming and discover glittering rewards. Play now at Yolo247 India for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/royal-gems",
            "alternate": "https://m.yolo247.site/slots/red-tiger/royal-gems"
        },
        "santa-spins": {
            "title": "Play Santa Spins Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Celebrate the festive season with Santa Spins Slot by Red Tiger Gaming. Spin the reels for merry rewards and exciting gameplay at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/santa-spins",
            "alternate": "https://m.yolo247.site/slots/red-tiger/santa-spins"
        },
        "shadow-society": {
            "title": "Play Shadow Society Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the mysterious world of Shadow Society Slot by Red Tiger Gaming. Spin for thrilling rewards and secret features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/shadow-society",
            "alternate": "https://m.yolo247.site/slots/red-tiger/shadow-society"
        },
        "shah-mat": {
            "title": "Play Shah mat Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Checkmate your way to big wins with Shah Mat Slot by Red Tiger Gaming. Spin for exciting features and rewards at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/shah-mat",
            "alternate": "https://m.yolo247.site/slots/red-tiger/shah-mat"
        },
        "snow-wild-and-the-7-features": {
            "title": "Play Snow Wild & the 7 Features Slot | Yolo247 India",
            "description": "Discover magical wins with Snow Wild & the 7 Features Slot. Spin for big rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/snow-wild-and-the-7-features",
            "alternate": "https://m.yolo247.site/slots/red-tiger/snow-wild-and-the-7-features"
        },
        "spin-town": {
            "title": "Play Spin Town Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Spin Town Slot by Red Tiger Gaming for exciting rewards and thrilling gameplay. Join Yolo247 India today for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/spin-town",
            "alternate": "https://m.yolo247.site/slots/red-tiger/spin-town"
        },
        "stage-888": {
            "title": "Play Stage 888 Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enter Stage 888 Slot by Red Tiger Gaming for a world of thrilling features and big wins. Play today at Yolo247 India and enjoy exciting gameplay!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/stage-888",
            "alternate": "https://m.yolo247.site/slots/red-tiger/stage-888"
        },
        "stolen-treasures": {
            "title": "Play Stolen Treasures Slot | Red Tiger | Yolo247 India",
            "description": "Unlock hidden treasures in Stolen Treasures Slot by Red Tiger Gaming. Spin for big rewards and exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/stolen-treasures",
            "alternate": "https://m.yolo247.site/slots/red-tiger/stolen-treasures"
        },
        "sumo-spins": {
            "title": "Play Sumo Spins Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience big wins with Sumo Spins Slot by Red Tiger Gaming. Spin for exciting rewards and features at Yolo247 India. Play now for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/sumo-spins",
            "alternate": "https://m.yolo247.site/slots/red-tiger/sumo-spins"
        },
        "sylvan-spirits": {
            "title": "Play Sylvan Spirits Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Sylvan Spirits Slot by Red Tiger Gaming for magical rewards and exciting features. Play today at Yolo247 India for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/sylvan-spirits",
            "alternate": "https://m.yolo247.site/slots/red-tiger/sylvan-spirits"
        },
        "ten-elements": {
            "title": "Play Ten Elements Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover the power of the Ten Elements Slot by Red Tiger Gaming. Spin for exciting gameplay and big rewards at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/ten-elements",
            "alternate": "https://m.yolo247.site/slots/red-tiger/ten-elements"
        },
        "the-equalizer": {
            "title": "Play The Equalizer Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Bring balance to the reels with The Equalizer Slot by Red Tiger Gaming. Spin for thrilling rewards and exciting features at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/the-equalizer",
            "alternate": "https://m.yolo247.site/slots/red-tiger/the-equalizer"
        },
        "the-greatest-train-robbery": {
            "title": "Play The Greatest Train Robbery Slot | Yolo247 India",
            "description": "Join the heist of the century in The Greatest Train Robbery Slot. Spin the reels for big wins and thrilling gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/the-greatest-train-robbery",
            "alternate": "https://m.yolo247.site/slots/red-tiger/the-greatest-train-robbery"
        },
        "the-wild-hatter": {
            "title": "Play The Wild Hatter Slot | Red Tiger | Yolo247 India",
            "description": "Experience the wacky world of The Wild Hatter Slot by Red Tiger Gaming. Spin for big wins and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/the-wild-hatter",
            "alternate": "https://m.yolo247.site/slots/red-tiger/the-wild-hatter"
        },
        "the-wisecracker-lightning": {
            "title": "Play The Wisecracker Lightning Slot | Yolo247 India",
            "description": "Get ready for lightning-fast wins with The Wisecracker Lightning Slot at Yolo247 India. Spin the reels for huge rewards and exciting gameplay!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/the-wisecracker-lightning",
            "alternate": "https://m.yolo247.site/slots/red-tiger/the-wisecracker-lightning"
        },
        "thor-s-lightning": {
            "title": "Play Thor's Lightning Slot | Red Tiger | Yolo247 India",
            "description": "Join Thor in his thunderous adventure with Thor's Lightning Slot. Spin for exciting features and big rewards at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/thor-s-lightning",
            "alternate": "https://m.yolo247.site/slots/red-tiger/thor-s-lightning"
        },
        "thor-s-vengeance": {
            "title": "Play Thor's Vengeance Slot | Red Tiger | Yolo247 India",
            "description": "Unleash Thor's fury in Thor's Vengeance Slot by Red Tiger. Spin for big wins and exciting features at Yolo247 India. Play today and win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/thor-s-vengeance",
            "alternate": "https://m.yolo247.site/slots/red-tiger/thor-s-vengeance"
        },
        "three-musketeers": {
            "title": "Play Three Musketeers Slot | Red Tiger | Yolo247 India",
            "description": "Join the Three Musketeers in their epic adventure with Three Musketeers Slot by Red Tiger. Spin for exciting rewards at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/three-musketeers",
            "alternate": "https://m.yolo247.site/slots/red-tiger/three-musketeers"
        },
        "tiki-fruits": {
            "title": "Play Tiki Fruits Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Spin the reels of Tiki Fruits Slot by Red Tiger Gaming for tropical fun and big rewards. Play today at Yolo247 India for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/tiki-fruits",
            "alternate": "https://m.yolo247.site/slots/red-tiger/tiki-fruits"
        },
        "tiki-fruits-totem-frenzy": {
            "title": "Play Tiki Fruits Totem Frenzy Slot | Yolo247 India",
            "description": "Experience the frenzy of Tiki Fruits Totem Frenzy Slot at Yolo247 India. Spin for thrilling rewards and exciting gameplay. Join now for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/tiki-fruits-totem-frenzy",
            "alternate": "https://m.yolo247.site/slots/red-tiger/tiki-fruits-totem-frenzy"
        },
        "totem-lightning": {
            "title": "Play Totem Lightning Slot | Red Tiger | Yolo247 India",
            "description": "Unlock the power of Totem Lightning Slot by Red Tiger Gaming. Spin for magical rewards and thrilling features at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/totem-lightning",
            "alternate": "https://m.yolo247.site/slots/red-tiger/totem-lightning"
        },
        "totem-lightning-power-reels": {
            "title": "Play Totem Lightning Power Reels Slot | Yolo247 India",
            "description": "Play Totem Lightning Power Reels Slot at Yolo247 India for exciting features and huge rewards. Spin the reels today and discover big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/totem-lightning-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/totem-lightning-power-reels"
        },
        "treasure-mine": {
            "title": "Play Treasure Mine Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Dig deep for treasure in Treasure Mine Slot by Red Tiger Gaming. Spin for exciting rewards and big wins at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/treasure-mine",
            "alternate": "https://m.yolo247.site/slots/red-tiger/treasure-mine"
        },
        "treasure-mine-power-reels": {
            "title": "Play Treasure Mine Power Reels Slot | Yolo247 India",
            "description": "Discover the power of Treasure Mine Power Reels Slot for huge rewards. Spin the reels for exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/treasure-mine-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/treasure-mine-power-reels"
        },
        "tricks-and-treats": {
            "title": "Play Tricks and Treats Slot | Red Tiger | Yolo247 India",
            "description": "Get ready for spooky fun with Tricks and Treats Slot by Red Tiger. Spin the reels for thrilling rewards and big wins at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/tricks-and-treats",
            "alternate": "https://m.yolo247.site/slots/red-tiger/tricks-and-treats"
        },
        "trillionaire": {
            "title": "Play Trillionaire Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Try your luck with the Trillionaire Slot by Red Tiger Gaming. Spin for big rewards and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/trillionaire",
            "alternate": "https://m.yolo247.site/slots/red-tiger/trillionaire"
        },
        "vault-cracker": {
            "title": "Play Vault Cracker Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Break into the Vault Cracker Slot for thrilling gameplay and big rewards. Spin the reels for exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/vault-cracker",
            "alternate": "https://m.yolo247.site/slots/red-tiger/vault-cracker"
        },
        "cault-of-anubis": {
            "title": "Play Vault of Anubis Slot by Red Tiger | Yolo247 India",
            "description": "Uncover the treasures of ancient Egypt in Vault of Anubis Slot. Spin for thrilling rewards at Yolo247 India. Join today for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/cault-of-anubis",
            "alternate": "https://m.yolo247.site/slots/red-tiger/cault-of-anubis"
        },
        "vicky-ventura": {
            "title": "Play Vicky Ventura Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join Vicky Ventura in her adventurous quest with Vicky Ventura Slot by Red Tiger Gaming. Spin for rewards and exciting gameplay at Yolo247 India.",
            "canonical": "https://www.yolo247.site/slots/red-tiger/vicky-ventura",
            "alternate": "https://m.yolo247.site/slots/red-tiger/vicky-ventura"
        },
        "viral-spiral": {
            "title": "Play Viral Spiral Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover the mysteries of the Viral Spiral Slot by Red Tiger Gaming. Spin for big rewards and exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/viral-spiral",
            "alternate": "https://m.yolo247.site/slots/red-tiger/viral-spiral"
        },
        "war-of-gods": {
            "title": "Play War of Gods Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the gods in the War of Gods Slot by Red Tiger Gaming. Spin for divine rewards and exciting gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/war-of-gods",
            "alternate": "https://m.yolo247.site/slots/red-tiger/war-of-gods"
        },
        "well-of-wilds-megaways": {
            "title": "Play Well of Wilds Megaways Slot | Red Tiger | Yolo247 India",
            "description": "Unlock the magic of Well of Wilds Megaways Slot. Spin for big wins and exciting gameplay at Yolo247 India. Play now for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/well-of-wilds-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/well-of-wilds-megaways"
        },
        "well-of-wishes": {
            "title": "Play Well of Wishes Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover the mystical Well of Wishes Slot by Red Tiger Gaming. Spin the reels for exciting features and big rewards at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/well-of-wishes",
            "alternate": "https://m.yolo247.site/slots/red-tiger/well-of-wishes"
        },
        "what-the-fox-megaways": {
            "title": "What the Fox! Megaways Slot by Red Tiger | Yolo247 India",
            "description": "Spin the reels of What the Fox! Megaways Slot for wild rewards and exciting gameplay. Play now at Yolo247 India and discover big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/what-the-fox-megaways",
            "alternate": "https://m.yolo247.site/slots/red-tiger/what-the-fox-megaways"
        },
        "wild-cats-multiline": {
            "title": "Wild Cats Multiline Slot by Red Tiger | Yolo247 India",
            "description": "Join the Wild Cats Multiline Slot by Red Tiger Gaming for exciting rewards and thrilling gameplay. Spin the reels at Yolo247 India now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-cats-multiline",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-cats-multiline"
        },
        "wild-circus": {
            "title": "Play Wild Circus Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Step into the Wild Circus Slot by Red Tiger Gaming for thrilling wins and exciting features. Spin the reels today at Yolo247 India for big rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-circus",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-circus"
        },
        "wild-elements": {
            "title": "Play Wild Elements Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience elemental power with Wild Elements Slot by Red Tiger Gaming. Spin for big rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-elements",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-elements"
        },
        "wild-expedition": {
            "title": "Wild Expedition Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Embark on a thrilling journey with Wild Expedition Slot by Red Tiger Gaming. Spin for exciting rewards at Yolo247 India. Play now for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-expedition",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-expedition"
        },
        "wild-fight": {
            "title": "Play Wild Fight Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Join the action in Wild Fight Slot by Red Tiger Gaming. Spin the reels for epic rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-fight",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-fight"
        },
        "wild-hot-chilli-reels": {
            "title": "Wild Hot Chilli Reels Slot by Red Tiger | Yolo247 India",
            "description": "Get spicy with Wild Hot Chilli Reels Slot by Red Tiger. Spin for fiery wins and thrilling features at Yolo247 India. Play today for big rewards!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-hot-chilli-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-hot-chilli-reels"
        },
        "wild-nords": {
            "title": "Play Wild Nords Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Explore the Wild Nords Slot by Red Tiger Gaming. Spin for epic rewards and exciting features at Yolo247 India. Play now for your chance to win big!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-nords",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-nords"
        },
        "wild-o-clock": {
            "title": "Play Wild O' Clock Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Discover the magic of Wild O' Clock Slot by Red Tiger Gaming. Spin the reels for thrilling wins and exciting gameplay at Yolo247 India. Join now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-o-clock",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-o-clock"
        },
        "wild-spartans": {
            "title": "Play Wild Spartans Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enter the world of Wild Spartans Slot by Red Tiger Gaming. Spin for exciting rewards and action-packed gameplay at Yolo247 India. Play today!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-spartans",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-spartans"
        },
        "wild-tundra": {
            "title": "Play Wild Tundra Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience the frozen beauty of Wild Tundra Slot by Red Tiger Gaming. Spin for wild rewards and exciting gameplay at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-tundra",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-tundra"
        },
        "wild-wild-chest": {
            "title": "Play Wild Wild Chest Slot by Red Tiger | Yolo247 India",
            "description": "Unlock treasures in Wild Wild Chest Slot by Red Tiger. Spin the reels for exciting rewards at Yolo247 India. Play today for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wild-wild-chest",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wild-wild-chest"
        },
        "win-escalator": {
            "title": "Play Win Escalator Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Ride the Win Escalator Slot by Red Tiger Gaming for thrilling features and big rewards. Spin the reels at Yolo247 India now for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/win-escalator",
            "alternate": "https://m.yolo247.site/slots/red-tiger/win-escalator"
        },
        "wings-of-ra": {
            "title": "Play Wings of RA Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Soar with the Wings of RA Slot by Red Tiger Gaming. Spin for divine rewards and epic wins at Yolo247 India. Play today for exciting gameplay!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/wings-of-ra",
            "alternate": "https://m.yolo247.site/slots/red-tiger/wings-of-ra"
        },
        "winter-wonders": {
            "title": "Play Winter Wonders Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Experience the magic of Winter Wonders Slot by Red Tiger Gaming. Spin the reels for big rewards and festive features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/winter-wonders",
            "alternate": "https://m.yolo247.site/slots/red-tiger/winter-wonders"
        },
        "yucantan-s-mystery": {
            "title": "Yucantan's Mystery Slot by Red Tiger | Yolo247 India",
            "description": "Unravel the mystery in Yucantan's Mystery Slot by Red Tiger. Spin for exciting rewards and adventure at Yolo247 India. Play today for big wins!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/yucantan-s-mystery",
            "alternate": "https://m.yolo247.site/slots/red-tiger/yucantan-s-mystery"
        },
        "zaida-s-fortune": {
            "title": "Zaida's Fortune Slot by Red Tiger | Yolo247 India",
            "description": "Seek fortune in Zaida's Fortune Slot by Red Tiger. Spin for big wins and exciting gameplay at Yolo247 India. Join today for your chance to win!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/zaida-s-fortune",
            "alternate": "https://m.yolo247.site/slots/red-tiger/zaida-s-fortune"
        },
        "zeus-lightning-power-reels": {
            "title": "Zeus Lightning Power Reels Slot by Red Tiger | Yolo247 India",
            "description": "Strike lightning with Zeus Lightning Power Reels Slot. Spin the reels for godly rewards and exciting features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/zeus-lightning-power-reels",
            "alternate": "https://m.yolo247.site/slots/red-tiger/zeus-lightning-power-reels"
        },
        "zillard-king": {
            "title": "Play Zillard King Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Enter the world of Zillard King Slot by Red Tiger Gaming. Spin for mythical rewards and thrilling features at Yolo247 India. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/zillard-king",
            "alternate": "https://m.yolo247.site/slots/red-tiger/zillard-king"
        },
        "slotopia": {
            "title": "Play Slotopia Slot Games at Yolo247 India | Real Money Fun",
            "description": "Explore thrilling Slotopia slot games at Yolo247 India. Enjoy innovative features, stunning graphics, and win real money rewards. Start playing today!",
            "canonical": "https://www.yolo247.site/slots/slotopia",
            "alternate": "https://m.yolo247.site/slots/slotopia"
        },
        "arctic-spins": {
            "title": "Play Arctic Spins Slot by Slotopia | Yolo247 India",
            "description": "Enter the icy world of Arctic Spins by Slotopia at Yolo247! Enjoy thrilling spins with cool rewards and exciting gameplay in this winter-themed slot game.",
            "canonical": "https://www.yolo247.site/slots/slotopia/arctic-spins",
            "alternate": "https://m.yolo247.site/slots/slotopia/arctic-spins"
        },
        "book-of-plenty": {
            "title": "Play Book of Plenty Slot by Slotopia | Yolo247 India",
            "description": "Unlock hidden treasures with the Book of Plenty slot by Slotopia at Yolo247. Spin the reels for big wins and explore the mystical rewards in this exciting game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/book-of-plenty",
            "alternate": "https://m.yolo247.site/slots/slotopia/book-of-plenty"
        },
        "book-of-reels": {
            "title": "Play Book of Reels Slot by Slotopia | Yolo247 India",
            "description": "Spin the reels of fortune with Book of Reels by Slotopia at Yolo247. Enjoy the classic theme and exciting bonus features for a chance to win big!",
            "canonical": "https://www.yolo247.site/slots/slotopia/book-of-reels",
            "alternate": "https://m.yolo247.site/slots/slotopia/book-of-reels"
        },
        "candy-luck": {
            "title": "Play Candy Luck Slot by Slotopia | Yolo247 India",
            "description": "Indulge in sweet wins with Candy Luck by Slotopia at Yolo247. Spin the colorful reels for delicious rewards in this sugary-themed slot game. Play now!",
            "canonical": "https://www.yolo247.site/slots/slotopia/candy-luck",
            "alternate": "https://m.yolo247.site/slots/slotopia/candy-luck"
        },
        "christmas-wonders": {
            "title": "Play Christmas Wonders Slot by Slotopia | Yolo247 India",
            "description": "Celebrate the holidays with Christmas Wonders by Slotopia at Yolo247. Enjoy festive spins, holiday-themed rewards, and a chance for big wins in this merry slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/christmas-wonders",
            "alternate": "https://m.yolo247.site/slots/slotopia/christmas-wonders"
        },
        "coin-forge": {
            "title": "Play Coin Forge Slot by Slotopia | Yolo247 India",
            "description": "Forge your own fortune with Coin Forge by Slotopia at Yolo247. Spin the reels and discover powerful bonuses in this exciting slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/coin-forge",
            "alternate": "https://m.yolo247.site/slots/slotopia/coin-forge"
        },
        "coin-forge-bonus-buy": {
            "title": "Play Coin Forge Bonus Buy Slot by Slotopia | Yolo247 India",
            "description": "Buy your way to big wins with Coin Forge Bonus Buy by Slotopia at Yolo247. Unlock exciting bonus features and enjoy thrilling gameplay for huge rewards!",
            "canonical": "https://www.yolo247.site/slots/slotopia/coin-forge-bonus-buy",
            "alternate": "https://m.yolo247.site/slots/slotopia/coin-forge-bonus-buy"
        },
        "coin-forge-magnetic": {
            "title": "Play Coin Forge Magnetic Slot by Slotopia | Yolo247 India",
            "description": "Experience the magnetic gameplay of Coin Forge Magnetic by Slotopia at Yolo247. Spin the reels and attract massive rewards with this exciting slot game.",
            "canonical": "https://www.yolo247.site/slots/slotopia/coin-forge-magnetic",
            "alternate": "https://m.yolo247.site/slots/slotopia/coin-forge-magnetic"
        },
        "coin-galactic": {
            "title": "Play Coin Galactic Slot by Slotopia | Yolo247 India",
            "description": "Explore the cosmos with Coin Galactic by Slotopia at Yolo247. Spin the reels to uncover stellar rewards in this space-themed slot game full of adventure!",
            "canonical": "https://www.yolo247.site/slots/slotopia/coin-galactic",
            "alternate": "https://m.yolo247.site/slots/slotopia/coin-galactic"
        },
        "coin-meteor": {
            "title": "Play Coin Meteor Slot by Slotopia | Yolo247 India",
            "description": "Watch the meteors fall in Coin Meteor by Slotopia at Yolo247! Spin the reels to catch cosmic rewards and bonuses in this thrilling slot game.",
            "canonical": "https://www.yolo247.site/slots/slotopia/coin-meteor",
            "alternate": "https://m.yolo247.site/slots/slotopia/coin-meteor"
        },
        "diamonds-expand": {
            "title": "Play Diamonds Expand Slot by Slotopia | Yolo247 India",
            "description": "Sparkle and shine with Diamonds Expand at Yolo247. Expand your rewards and win big with every spin in this dazzling slot game.",
            "canonical": "https://www.yolo247.site/slots/slotopia/diamonds-expand",
            "alternate": "https://m.yolo247.site/slots/slotopia/diamonds-expand"
        },
        "diamonds-hunt": {
            "title": "Play Diamonds Hunt Slot by Slotopia | Yolo247 India",
            "description": "Embark on a hunt for precious diamonds in Diamonds Hunt by Slotopia at Yolo247. Spin the reels and dig for big wins and valuable rewards!",
            "canonical": "https://www.yolo247.site/slots/slotopia/diamonds-hunt",
            "alternate": "https://m.yolo247.site/slots/slotopia/diamonds-hunt"
        },
        "double-up-machine": {
            "title": "Play Double Up Machine Slot by Slotopia | Yolo247 India",
            "description": "Test your luck with the Double Up Machine by Slotopia at Yolo247. Double your winnings with every spin in this action-packed slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/double-up-machine",
            "alternate": "https://m.yolo247.site/slots/slotopia/double-up-machine"
        },
        "double-up-respin": {
            "title": "Play Double Up Respin Slot by Slotopia | Yolo247 India",
            "description": "Get a chance to double your wins with Double Up Respin by Slotopia at Yolo247. Spin the reels again for exciting bonus features and massive rewards!",
            "canonical": "https://www.yolo247.site/slots/slotopia/double-up-respin",
            "alternate": "https://m.yolo247.site/slots/slotopia/double-up-respin"
        },
        "dragon-balls": {
            "title": "Play Dragon Balls Slot by Slotopia | Yolo247 India",
            "description": "Unlock the magic of Dragon Balls by Slotopia at Yolo247. Spin the reels for mystical rewards and enjoy exciting gameplay in this fantasy-themed slot.",
            "canonical": "https://www.yolo247.site/slots/slotopia/dragon-balls",
            "alternate": "https://m.yolo247.site/slots/slotopia/dragon-balls"
        },
        "fishing-mania": {
            "title": "Play Fishing Mania Slot by Slotopia | Yolo247 India",
            "description": "Cast your line and reel in big rewards with Fishing Mania by Slotopia at Yolo247. Enjoy a relaxing yet rewarding fishing adventure in this exciting slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/fishing-mania",
            "alternate": "https://m.yolo247.site/slots/slotopia/fishing-mania"
        },
        "fruit-fantasy-100": {
            "title": "Play Fruit Fantasy 100 Slot by Slotopia | Yolo247 India",
            "description": "Experience juicy wins with Fruit Fantasy 100 by Slotopia at Yolo247. Spin the colorful reels for tasty rewards and exciting bonuses in this fruity slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/fruit-fantasy-100",
            "alternate": "https://m.yolo247.site/slots/slotopia/fruit-fantasy-100"
        },
        "fruit-fantasy-40": {
            "title": "Play Fruit Fantasy 40 Slot by Slotopia | Yolo247 India",
            "description": "Enjoy a fruity adventure with Fruit Fantasy 40 by Slotopia at Yolo247. Spin the reels for big rewards in this exciting, fruit-themed slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/fruit-fantasy-40",
            "alternate": "https://m.yolo247.site/slots/slotopia/fruit-fantasy-40"
        },
        "fruit-fantasy-hold-win": {
            "title": "Play Fruit Fantasy Hold & Win Slot by Slotopia | Yolo247 India",
            "description": "Hold your win with Fruit Fantasy Hold Win by Slotopia at Yolo247. Spin the reels for delicious rewards and experience exciting gameplay in this fruity slot!",
            "canonical": "https://www.yolo247.site/slots/slotopia/fruit-fantasy-hold-win",
            "alternate": "https://m.yolo247.site/slots/slotopia/fruit-fantasy-hold-win"
        },
        "green-hat-magic": {
            "title": "Play Green Hat Magic Slot by Slotopia | Yolo247 India",
            "description": "Enter a magical world with Green Hat Magic by Slotopia at Yolo247. Spin the reels and experience enchanted rewards in this whimsical slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/green-hat-magic",
            "alternate": "https://m.yolo247.site/slots/slotopia/green-hat-magic"
        },
        "green-hat-tale": {
            "title": "Play Green Hat Tale Slot by Slotopia | Yolo247 India",
            "description": "Discover the magical tale of Green Hat Tale by Slotopia at Yolo247. Spin the reels and enjoy mystical rewards and bonuses in this charming slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/green-hat-tale",
            "alternate": "https://m.yolo247.site/slots/slotopia/green-hat-tale"
        },
        "hellfire-joker": {
            "title": "Play Hellfire Joker Slot by Slotopia | Yolo247 India",
            "description": "Enter the fiery world of Hellfire Joker by Slotopia at Yolo247. Spin the reels and unleash blazing rewards with every spin in this action-packed slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/hellfire-joker",
            "alternate": "https://m.yolo247.site/slots/slotopia/hellfire-joker"
        },
        "immortal-treasures": {
            "title": "Play Immortal Treasures Slot by Slotopia | Yolo247 India",
            "description": "Unearth Immortal Treasures by Slotopia at Yolo247. Spin the reels and claim ancient rewards with powerful bonuses in this mystical slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/immortal-treasures",
            "alternate": "https://m.yolo247.site/slots/slotopia/immortal-treasures"
        },
        "joker-juicer-6": {
            "title": "Play Joker Juicer 6 Slot by Slotopia | Yolo247 India",
            "description": "Get juicy rewards with Joker Juicer 6 by Slotopia at Yolo247. Spin the reels for big wins and exciting features in this playful and entertaining slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/joker-juicer-6",
            "alternate": "https://m.yolo247.site/slots/slotopia/joker-juicer-6"
        },
        "jokers-invasion": {
            "title": "Play Jokers Invasion Slot by Slotopia | Yolo247 India",
            "description": "The jokers are invading by Slotopia at Yolo247! Spin the reels of Jokers Invasion for wild bonuses and exciting rewards in this action-packed slot game.",
            "canonical": "https://www.yolo247.site/slots/slotopia/jokers-invasion",
            "alternate": "https://m.yolo247.site/slots/slotopia/jokers-invasion"
        },
        "juicy-triple-bonus": {
            "title": "Play Juicy Triple Bonus Slot by Slotopia | Yolo247 India",
            "description": "Enjoy the juicy rewards with Juicy Triple Bonus by Slotopia at Yolo247. Spin for big wins and multiple bonuses in this fruit-filled slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/juicy-triple-bonus",
            "alternate": "https://m.yolo247.site/slots/slotopia/juicy-triple-bonus"
        },
        "lucky-fruit-spins": {
            "title": "Play Lucky Fruit Spins Slot by Slotopia | Yolo247 India",
            "description": "Spin the lucky fruit reels by Slotopia at Yolo247! Unlock sweet rewards and enjoy exciting gameplay in Lucky Fruit Spins. Play now for big wins!",
            "canonical": "https://www.yolo247.site/slots/slotopia/lucky-fruit-spins",
            "alternate": "https://m.yolo247.site/slots/slotopia/lucky-fruit-spins"
        },
        "lucky-fruit-spins-bonus-buy": {
            "title": "Play Lucky Fruit Spins Bonus Buy Slot by Slotopia | Yolo247 India",
            "description": "Buy your way to big rewards with Lucky Fruit Spins Bonus Buy by Slotopia at Yolo247. Unlock exciting features and enjoy sweet rewards in this fun slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/lucky-fruit-spins-bonus-buy",
            "alternate": "https://m.yolo247.site/slots/slotopia/lucky-fruit-spins-bonus-buy"
        },
        "mega-diamonds-hunt": {
            "title": "Play Mega Diamonds Hunt Slot by Slotopia | Yolo247 India",
            "description": "Go on a diamond hunt with Mega Diamonds Hunt by Slotopia at Yolo247. Spin the reels for sparkling rewards and exciting bonuses in this glitzy slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-diamonds-hunt",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-diamonds-hunt"
        },
        "mega-fruit-100": {
            "title": "Play Mega Fruit 100 Slot by Slotopia | Yolo247 India",
            "description": "Spin for mega rewards in Mega Fruit 100 by Slotopia at Yolo247. Unlock big wins and exciting bonuses in this fruit-themed slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-fruit-100",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-fruit-100"
        },
        "mega-fruit-20": {
            "title": "Play Mega Fruit 20 Slot by Slotopia | Yolo247 India",
            "description": "Dive into the juicy world of Mega Fruit 20 at Yolo247. Spin the reels for massive rewards and exciting features in this fruity slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-fruit-20",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-fruit-20"
        },
        "mega-fruit-40": {
            "title": "Play Mega Fruit 40 Slot by Slotopia | Yolo247 India",
            "description": "Enjoy the fruity excitement of Mega Fruit 40 by Slotopia at Yolo247. Spin the reels for big wins and juicy rewards in this fun and vibrant slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-fruit-40",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-fruit-40"
        },
        "mega-juicer-5": {
            "title": "Play Mega Juicer 5 Slot by Slotopia | Yolo247 India",
            "description": "Enjoy the juicy action of Mega Juicer 5 by Slotopia at Yolo247. Spin the reels and unlock massive rewards and bonuses in this exciting slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-juicer-5",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-juicer-5"
        },
        "mega-juicer-6": {
            "title": "Play Mega Juicer 6 Slot by Slotopia | Yolo247 India",
            "description": "Squeeze out big rewards with Mega Juicer 6 by Slotopia at Yolo247. Spin the reels and enjoy juicy wins in this fun, action-packed slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/mega-juicer-6",
            "alternate": "https://m.yolo247.site/slots/slotopia/mega-juicer-6"
        },
        "pepper-mania": {
            "title": "Play Pepper Mania Slot by Slotopia | Yolo247 India",
            "description": "Spice up your game with Pepper Mania by Slotopia at Yolo247! Spin the sizzling reels for fiery rewards in this fun and vibrant slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/pepper-mania",
            "alternate": "https://m.yolo247.site/slots/slotopia/pepper-mania"
        },
        "sizzling-sevens": {
            "title": "Play Sizzling Sevens Slot by Slotopia | Yolo247 India",
            "description": "Enjoy the fiery fun of Sizzling Sevens by Slotopia at Yolo247. Spin the reels for big wins and sizzling rewards in this hot slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/sizzling-sevens",
            "alternate": "https://m.yolo247.site/slots/slotopia/sizzling-sevens"
        },
        "sizzling-sevens-hold-win": {
            "title": "Play Sizzling Sevens Hold & Win Slot by Slotopia | Yolo247 India",
            "description": "Hold your winnings in Sizzling Sevens Hold Win by Slotopia at Yolo247. Spin the reels for big rewards and exciting features in this fiery slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/sizzling-sevens-hold-win",
            "alternate": "https://m.yolo247.site/slots/slotopia/sizzling-sevens-hold-win"
        },
        "sizzling-sevens-special": {
            "title": "Play Sizzling Sevens Special Slot by Slotopia | Yolo247 India",
            "description": "Spice up your gameplay with Sizzling Sevens Special by Slotopia at Yolo247. Unlock hot bonuses and big rewards in this special version of the classic slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/sizzling-sevens-special",
            "alternate": "https://m.yolo247.site/slots/slotopia/sizzling-sevens-special"
        },
        "sizzling-super-sevens": {
            "title": "Play Sizzling Super Sevens Slot by Slotopia | Yolo247 India",
            "description": "Heat up your gameplay with Sizzling Super Sevens by Slotopia at Yolo247. Spin the reels for hot rewards and sizzling bonuses in this exciting slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/sizzling-super-sevens",
            "alternate": "https://m.yolo247.site/slots/slotopia/sizzling-super-sevens"
        },
        "the-greatest-treasures": {
            "title": "Play The Greatest Treasures Slot by Slotopia | Yolo247 India",
            "description": "Discover hidden riches with The Greatest Treasures by Slotopia at Yolo247. Spin the reels for big wins and treasure-filled rewards in this exciting slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/the-greatest-treasures",
            "alternate": "https://m.yolo247.site/slots/slotopia/the-greatest-treasures"
        },
        "ultra-coin-magnetic-bonus-buy": {
            "title": "Play Ultra Coin Magnetic Bonus Buy Slot by Slotopia | Yolo247 India",
            "description": "Unlock magnetic bonuses with Ultra Coin Magnetic Bonus Buy by Slotopia at Yolo247. Buy your way to big wins and exciting rewards in this thrilling slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/ultra-coin-magnetic-bonus-buy",
            "alternate": "https://m.yolo247.site/slots/slotopia/ultra-coin-magnetic-bonus-buy"
        },
        "ultra-hot-sevens": {
            "title": "Play Ultra Hot Sevens Slot by Slotopia | Yolo247 India",
            "description": "Feel the heat with Ultra Hot Sevens by Slotopia at Yolo247. Spin the reels and enjoy scorching rewards in this exciting and fast-paced slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/ultra-hot-sevens",
            "alternate": "https://m.yolo247.site/slots/slotopia/ultra-hot-sevens"
        },
        "wild-double-up": {
            "title": "Play Wild Double Up Slot by Slotopia | Yolo247 India",
            "description": "Double your excitement with Wild Double Up by Slotopia at Yolo247. Spin the reels and unlock wild rewards and exciting bonuses in this thrilling slot game!",
            "canonical": "https://www.yolo247.site/slots/slotopia/wild-double-up",
            "alternate": "https://m.yolo247.site/slots/slotopia/wild-double-up"
        },
        "vbi": {
            "title": "Virtual Major League Baseball Betting | Yolo247 India",
            "description": "Bet on Virtual Major League Baseball at Yolo247 India. Predict the winning team and enjoy thrilling baseball action in real time! ⚾💥",
            "canonical": "https://www.yolo247.site/virtualSports/vbi",
            "alternate": "https://m.yolo247.site/virtualSports/vbi"
        },
        "vfac": {
            "title": "Virtual Football Asian Cup Betting | Yolo247 India",
            "description": "Bet on exciting virtual football matches at the Asian Cup with Yolo247 India. Predict your winning team and win instantly! ⚽🏆",
            "canonical": "https://www.yolo247.site/virtualSports/vfac",
            "alternate": "https://m.yolo247.site/virtualSports/vfac"
        },
        "vfcc": {
            "title": "Virtual Football Champions Cup Betting | Yolo247 India",
            "description": "Bet on Virtual Football Champions Cup matches at Yolo247 India! Enjoy fast-paced football action and win instantly on your favorite teams. 🏆⚽",
            "canonical": "https://www.yolo247.site/virtualSports/vfcc",
            "alternate": "https://m.yolo247.site/virtualSports/vfcc"
        },
        "vfnc": {
            "title": "Virtual Football Nations Cup Betting | Yolo247 India",
            "description": "Bet on the Virtual Football Nations Cup at Yolo247 India. Experience thrilling matches between top countries and win instantly! 🌍⚽",
            "canonical": "https://www.yolo247.site/virtualSports/vfnc",
            "alternate": "https://m.yolo247.site/virtualSports/vfnc"
        },
        "vgr": {
            "title": "Virtual Greyhound Racing Betting | Yolo247 India",
            "description": "Bet on exciting virtual greyhound races at Yolo247 India. Predict the fastest dog and win big with each race! 🐕💨",
            "canonical": "https://www.yolo247.site/virtualSports/vgr",
            "alternate": "https://m.yolo247.site/virtualSports/vgr"
        },
        "vhk": {
            "title": "Virtual Horse Racing Betting | Yolo247 India",
            "description": "Experience the thrill of virtual horse racing at Yolo247 India. Bet on fast-paced races and win instantly! 🏇💨",
            "canonical": "https://www.yolo247.site/virtualSports/vhk",
            "alternate": "https://m.yolo247.site/virtualSports/vhk"
        },
        "vwmfe": {
            "title": "Virtual World Match Football England | Bet on Yolo247 India",
            "description": "Bet on Virtual World Match Football England at Yolo247 India. Watch dynamic football matches and predict your winning team for fast payouts! ⚽🏆",
            "canonical": "https://www.yolo247.site/virtualSports/vwmfe",
            "alternate": "https://m.yolo247.site/virtualSports/vwmfe"
        },
        "vwmfi": {
            "title": "Virtual World Match Football Italy | Bet at Yolo247 India",
            "description": "bet on exciting virtual football matches in Italy with Yolo247 India! Predict the outcome of top teams and enjoy thrilling wins. ⚽",
            "canonical": "https://www.yolo247.site/virtualSports/vwmfi",
            "alternate": "https://m.yolo247.site/virtualSports/vwmfi"
        },
        "vwmfs": {
            "title": "Bet on Virtual World Match Football Spain at Yolo247 India",
            "description": "Enjoy fast-paced virtual football action with Virtual World Match Football Spain at Yolo247 India. Bet on exciting matches and win instantly! ⚽🔥",
            "canonical": "https://www.yolo247.site/virtualSports/vwmfs",
            "alternate": "https://m.yolo247.site/virtualSports/vwmfs"
        },

        "supernowa-32-cards": {
            "title": "Play 32 Cards by Supernowa Live Casino | Yolo247 India",
            "description": "Enjoy 32 Cards by Supernowa Live Casino at Yolo247 India. Bet, play, and win big in this exciting card game with live dealers! 💰🎲",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/32-cards",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/32-cards"
        },
        "supernowa-andar-bahar": {
            "title": "Play Andar Bahar by Supernowa Live Casino | Yolo247 India",
            "description": "Experience Andar Bahar by Supernowa Live Casino at Yolo247 India. Bet on your luck with live dealers and win big rewards! 💵🎉",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/andar-bahar"
        },
        "ag-teenpatti": {
            "title": "Play Teen Patti Online by Amazing Gaming | Yolo247 India",
            "description": "Play Teen Patti Live at Yolo247 🎮. Enjoy Amazing Gaming’s real money action with live dealers for nonstop entertainment and big wins!",
            "canonical": "https://www.yolo247.site/live-casino/ag/teenpatti",
            "alternate": "https://m.yolo247.site/live-casino/ag/teenpatti"
        },
        "tvbet-keno": {
            "title": "Play Keno by TV Bet Live Casino | Yolo247 India 🎯💎",
            "description": "Try your luck with Keno at TV Bet Live Casino on Yolo247 India. Fun, fast, and full of chances to win big! 🎲✨",
            "canonical": "https://www.yolo247.site/live-casino/tvbet/keno",
            "alternate": "https://m.yolo247.site/live-casino/tvbet/keno"
        },
        "netent-baccarat": {
            "title": "Play Baccarat Slot Game by Netent | Yolo247 India",
            "description": "Play Baccarat Slot by NetEnt at Yolo247 India. Enjoy fast-paced action, immersive gameplay, and chances to win big with exciting features and rewards!",
            "canonical": "https://www.yolo247.site/slots/netent/baccarat",
            "alternate": "https://m.yolo247.site/slots/netent/baccarat"
        },

        "slots-roulette": {
            "title": "Play Roulette Slots | Yolo247 India",
            "description": "Spin the reels in roulette-inspired slot games at Yolo247. Enjoy the excitement of the wheel combined with slots for a truly unique gaming experience.",
            "canonical": "https://www.yolo247.site/slots/roulette",
            "alternate": "https://m.yolo247.site/slots/roulette"
        },
        "jiliblackjack": {
            "title": "Play Blackjack by JILI Slot Games | Yolo247 India",
            "description": "Enjoy a thrilling game of Blackjack with JILI slots at Yolo247. Spin the reels and experience classic casino gameplay with exciting rewards!",
            "canonical": "https://www.yolo247.site/slots/jili-slot/blackjack",
            "alternate": "https://m.yolo247.site/slots/jili-slot/blackjack"
        },
        "slots-evoplay": {
            "title": "Evoplay Slots | Thrilling Wins & Themes | Yolo247 India",
            "description": "Discover Evoplay slots at Yolo247 India. Enjoy cutting-edge themes, engaging mechanics, and thrilling wins. Start spinning today!",
            "canonical": "https://www.yolo247.site/slots/evoplay",
            "alternate": "https://m.yolo247.site/slots/evoplay"
        },

        "slots-european-roulette": {
            "title": "Play European Roulette Slot | Red Tiger | Yolo247 India",
            "description": "Play European Roulette Slot by Red Tiger at Yolo247 India. Enjoy classic roulette action with exciting twists and big rewards. Spin now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/european-roulette",
            "alternate": "https://m.yolo247.site/slots/red-tiger/european-roulette"
        },
        "slots-atlantis": {
            "title": "Play Atlantis Slot by Red Tiger Gaming | Yolo247 India",
            "description": "Play Atlantis Slot by Red Tiger Gaming at Yolo247 India. Dive into an underwater adventure filled with treasures, bonus features, and big wins. Play now!",
            "canonical": "https://www.yolo247.site/slots/red-tiger/atlantis",
            "alternate": "https://m.yolo247.site/slots/red-tiger/atlantis"
        },

        "ag-baccarat": {
            "title": "Play Baccarat Live Casino by Amazing Gaming | Yolo247 India",
            "description": "Enjoy Baccarat Live Casino Game by Amazing Gaming at Yolo247 🎰. Bet real money, play live, and win big on trusted casino tables!",
            "canonical": "https://www.yolo247.site/live-casino/ag/baccarat",
            "alternate": "https://m.yolo247.site/live-casino/ag/baccarat"
        },
        "ag-roulette": {
            "title": "Play Roulette Online by Amazing Gaming | Yolo247 India",
            "description": "Play Roulette Live Casino Game by Amazing Gaming 🎯. Join Yolo247 to bet real money and enjoy the excitement of live casino games with professional dealers",
            "canonical": "https://www.yolo247.site/live-casino/ag/roulette",
            "alternate": "https://m.yolo247.site/live-casino/ag/roulette"
        },
        "canio-mplay":{
            "title": "Play Mplay Live Casino Games at Yolo247 India 🎮✨",
            "description": "Experience Mplay Live Casino at Yolo247 India! Play exciting games like Andar Bahar, Teen Patti, and more with live dealers and big rewards. 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/mplay",
            "alternate": "https://m.yolo247.site/live-casino/mplay"
        },
        "mplay-andar-bahar": {
            "title": "Play Andar Bahar by Mplay Live Casino | Yolo247 India",
            "description": "Enjoy Andar Bahar by Mplay Live Casino at Yolo247 India. Play with live dealers and experience the thrill of this iconic Indian game! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/mplay/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/mplay/andar-bahar"
        },

        "mplay-blackjack":{
           "title": "Play Blackjack by Mplay Live Casino | Yolo247 India",
            "description": "Play Blackjack by Mplay Live Casino at Yolo247 India. Test your strategy and win big with every hand in this classic casino game! 🎲💰",
            "canonical": "https://www.yolo247.site/live-casino/mplay/blackjack",
            "alternate": "https://m.yolo247.site/live-casino/mplay/blackjack" 
        },
        "mplay-dragonTiger": {
            "title": "Play Dragon Tiger by Mplay Live Casino | Yolo247 India",
            "description": "Play Dragon Tiger by Mplay Live Casino at Yolo247 India. Bet on your favorite dragon or tiger and win big in this thrilling game! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/mplay/dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/mplay/dragon-tiger" 
        },
        "mplay-sic-bo": {
            "title": "Play Sic Bo by Mplay Live Casino | Yolo247 India",
            "description": "Enjoy Sic Bo by Mplay Live Casino at Yolo247 India. Bet on the dice and win exciting rewards in this fast-paced live game! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/mplay/sic-bo",
            "alternate": "https://m.yolo247.site/live-casino/mplay/sic-bo" 
        },
        "sup-teenpatti2020": {
            "title": "Play Teen Patti 20-20 by Super Spade Live Casino at Yolo247!",
            "description": "Experience Teen Patti 20-20 by Super Spade, the iconic Indian card game, live at Yolo247 India. Play now and win big with real-time action! 🃏💵",
            "canonical": "https://www.yolo247.site/live-casino/super-spade/teen-patti-20-20",
            "alternate": "https://m.yolo247.site/live-casino/super-spade/teen-patti-20-20"
        },
        "supernowa-baccarat": {
            "title": "Play Baccarat by Supernowa Live Casino | Yolo247 India",
            "description": "Experience the thrill of Baccarat by Supernowa Live Casino at Yolo247 India. Enjoy real-time action and big wins with every hand! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/supernowa/baccarat",
            "alternate": "https://m.yolo247.site/live-casino/supernowa/baccarat"
        },

        "7mojos-andar-bahar":{
            "title": "Play Andar Bahar by 7 Mojos Live Casino | Yolo247 India",
            "description": "Enjoy Andar Bahar by 7 Mojos Live Casino at Yolo247 India. Bet live and experience the thrill of this classic Indian card game! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/7mojos/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/7mojos/andar-bahar"
        },
        "7mojos-dragonTiger":{
            "title": "Play Dragon Tiger by 7 Mojos Live Casino | Yolo247 India",
            "description": "Try Dragon Tiger by 7 Mojos Live Casino at Yolo247 India. Bet on the dragon or tiger and win big in this exciting, fast-paced game! 💰🔥",
            "canonical": "https://www.yolo247.site/live-casino/7mojos/dragon-tiger",
            "alternate": "https://m.yolo247.site/live-casino/7mojos/dragon-tiger"
        },
        "ag-andar-bahar":{
            "title": "Play Andar Bahar Online by Amazing Gaming | Yolo247 India",
            "description": "Join Yolo247 for Andar Bahar Live 🎰. Play Amazing Gaming’s classic live casino game and win real money with trusted live dealer action.",
            "canonical": "https://www.yolo247.site/live-casino/ag/andar-bahar",
            "alternate": "https://m.yolo247.site/live-casino/ag/andar-bahar"
        }
    },
    "32": {
        "default": {
            "title": "Online Betting ID | Best Site for Casino & Sports | Yolo247",
            "description": "Online betting ID makes casino & sports betting experience better. On Yolo247, get betting ID through WhatsApp instantly. Faster deposit & withdrawal.",
            "canonical": "https://www.yolo247.club",
            "alternate": "https://m.yolo247.club/",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site, online betting id, bet id, betting id online, online betting id provider"
        },
        "bingo": {
            "title": "Get Your Bingo Betting ID at Yolo247 Now",
            "description": "Secure your Bingo experience with your unique Betting ID at Yolo247. Enjoy seamless gameplay and track your bets easily with our personalized ID system",
            "canonical": "https://www.yolo247.club/live-casino/bingo",
            "alternate": "https://m.yolo247.club/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "teenpatti2020-2020": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti 2020 is a popular casino card game on Yolo247. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.yolo247.club/live-casino/teenpatti%2020%2020",
            "alternate": "https://m.yolo247.club/live-casino/teenpatti%2020%2020",
            "keywords": "Teenpatti 2020"
        },
        "teenpatti": {
            "title": "Grab Your Teenpatti ID | Play at Yolo247 Now!",
            "description": "Experience the magic of Teen Patti ID online. Play with friends, showcase your skills, and win amazing prizes. Join the gaming revolution now!",
            "canonical": "https://www.yolo247.club/live-casino/teenpatti",
            "alternate": "https://m.yolo247.club/live-casino/teenpatti",
            "keywords": "Teenpatti"
        },
        "First-Person-Blackjack": {
            "title": "Play Blackjack Live Casino Games | Free or Real Money",
            "description": "Play blackjack online on the most safest online casino. Register now at Yolo247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
            "canonical": "https://www.yolo247.club/live-casino/first%20person%20blackjack",
            "alternate": "https://m.yolo247.club/live-casino/first%20person%20blackjack",
            "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
        },
        "Funky Time": {
            "title": "Yolo247 Betting ID for Funky Time Games | Join the Fun",
            "description": "Your unique Betting ID for Funky Time Games at Yolo247: [Your ID]. Get started with thrilling games and exciting wins. Enjoy your gameplay and good luck!",
            "canonical": "https://www.yolo247.club/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.club/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "evo": {
            "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
            "canonical": "https://www.yolo247.club/live-casino/evo",
            "alternate": "https://m.yolo247.club/live-casino/evo",
            "keywords": "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
        },
        "nlc": {
            "title": "NLC Casino Game on Yolo247 | 400% Signup  Bonus",
            "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
            "canonical": "https://www.yolo247.club/slots/no-limit-city",
            "alternate": "https://m.yolo247.club/slots/no-limit-city",
            "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
        },
        "Funkytime": {
            "title": "Play Funky Time Casino Online Game at Yolo247",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
            "canonical": "https://www.yolo247.club/live-casino/evolution/funky-time",
            "alternate": "https://m.yolo247.club/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "ag": {
            "title": "Your Betting ID for AG Games at Yolo247",
            "description": "Get your Betting ID for AG Games at Yolo247. Enjoy a seamless betting experience with top-notch games and secure transactions. Sign up now!",
            "canonical": "https://www.yolo247.club/live-casino/ag",
            "alternate": "https://m.yolo247.club/live-casino/ag",
            "keywords": "Amazing Gaming"
        },
        "slotGames": {
            "title": "Slot Game ID | Betting Opportunities Online | Yolo247",
            "description": "Experience the future of online gaming with advanced slot game IDs. Enjoy a seamless journey into the world of virtual entertainment!",
            "canonical": "https://www.yolo247.club/slots",
            "alternate": "https://m.yolo247.club/slots",
            "keywords": "slot games for real money, casino slot games, casino slot casino id, Slot game id, slot casino id for real money"
        },
        "macaw": {
            "title": "Macaw Games | Top Macaw Casino Online Games on Yolo247",
            "description": "Macaw games are thrilling and are available online on Yolo247. Learn more about the popular Macaw casino online games.",
            "canonical": "https://www.yolo247.club/live-casino/macaw",
            "alternate": "https://m.yolo247.club/live-casino/macaw",
            "keywords": "macaw games, macaw casino online"
        },
        "sexygaming": {
            "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
            "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
            "canonical": "https://www.yolo247.club/live-casino/sexy-gaming",
            "alternate": "https://m.yolo247.club/live-casino/sexy-gaming",
            "keywords": "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
        },
        "monopolylive": {
            "title": "Monopoly ID | Grab Your Exclusive Deals Now!",
            "description": "Unlock the secrets of Monopoly ID on our dynamic webpage. From game updates to expert advice, find everything you need for success",
            "canonical": "https://www.yolo247.club/live-casino/monopoly-live",
            "alternate": "https://m.yolo247.club/live-casino/monopoly-live",
            "keywords": "MONOPOLY LIVE, monopoly id, monopoly live casino, monopoly live casino game, Play monopoly live online, monopoly live game"
        },
        "lightningdice": {
            "title": "Get Your Betting ID for Yolo247's Lightning Dice Games",
            "description": "Your Exclusive Betting ID for Lightning Dice Games at Yolo247 – Unlock the Thrill and Maximize Your Wins with Our Top-Rated Online Casino Platform",
            "canonical": "https://www.yolo247.club/live-casino/evolution/lightning-dice",
            "alternate": "https://m.yolo247.club/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice"
        },
        "onlineCasinoGames": {
            "title": "Live Casino ID | Play Exciting casino Online",
            "description": "Step into the excitement at live casino ID. Play your favorite casino with live dealers for an unparalleled gaming experience",
            "canonical": "https://www.yolo247.club/live-casino",
            "alternate": "https://m.yolo247.club/live-casino",
            "keywords": " Online Casino Games, Live Casino Online, Play Casino games online, casino id, live casino id, Roulette ID Provider"
        },
        "live-baccarat": {
            "title": "Play Baccarat Online for FREE or Real Money | Yolo247",
            "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
            "canonical": "https://www.yolo247.club/live-casino/live-baccarat",
            "alternate": "https://m.yolo247.club/live-casino/live-baccarat",
            "keywords": "live-baccarat, Live-Baccarat, Live Baccarat, livebaccarat, Livebaccarat, Baccarat Online Game, Baccarat Live, Baccarat"
        },
        "live-blackjack": {
            "title": "Play Blackjack Online Casino Game | 100% Safe & Fast Payouts",
            "description": "Play Blackjack online game on the best casino platform. Safest & fastest payouts guaranteed on Yolo247 for the Blackjack game. Play Blackjack card game now!",
            "canonical": "https://www.yolo247.club/live-casino/live-blackjack",
            "alternate": "https://m.yolo247.club/live-casino/live-blackjack",
            "keywords": "live-blackjack, blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
        },
        "live-roulette": {
            "title": "Online Roulette with Roulette ID | Win Big with Yolo247",
            "description": "Elevate your gaming adventure with Roulette ID, the premier platform for immersive and rewarding roulette action.",
            "canonical": "https://www.yolo247.club/live-casino/live-roulette",
            "alternate": "https://m.yolo247.club/live-casino/live-roulette",
            "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,roulette id,online roulette,online roulette real money,"
        },
        "lightningroulette": {
            "title": "Spin to Win with Lightning Roulette ID | Grab Exclusive Bonuses",
            "description": "Get ready for high-voltage fun with Lightning Roulette ID. Spin the wheel and feel the excitement with unique twists and chances to win big",
            "canonical": "https://www.yolo247.club/live-casino/lightning-roulette",
            "alternate": "https://m.yolo247.club/live-casino/lightning-roulette",
            "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,lightningroulette id,lightning roulette online,lightning roulette online casino,play lightning roulette online"
        },
        "dragonTiger": {
            "title": "Online Dragon Tiger Game | Get Instant Wins on Yolo247",
            "description": "Play Dragon Tiger Game Online on the best casino platform, Yolo247. Fast, safe & simple. Get the best Dragon Tiger Casino betting experience in India.",
            "canonical": "https://www.yolo247.club/live-casino/dragon-tiger",
            "alternate": "https://m.yolo247.club/live-casino/dragon-tiger",
            "keywords": "dragon tiger id, dragon tiger online, Dragon tiger real money game, Dragon Tiger"
        },
        "baccarat": {
            "title": "Get Your Baccarat ID for Seamless Online Play with Yolo247",
            "description": "Elevate your gaming with Baccarat ID. Dive into the captivating world of online baccarat and redefine your winning moments.",
            "canonical": "https://www.yolo247.club/live-casino/live-baccarat",
            "alternate": "https://m.yolo247.club/live-casino/live-baccarat",
            "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat,baccarat id,baccarat online"
        },
        "teenpattiOnlinePlay": {
            "title": "Grab Your Teenpatti ID | Play at Yolo247 Now!",
            "description": "Experience the magic of Teen Patti ID online. Play with friends, showcase your skills, and win amazing prizes. Join the gaming revolution now!",
            "canonical": "https://www.yolo247.club/teenpatti-online-play",
            "alternate": "https://m.yolo247.club/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino, teen patti id, teen patti online play, teenpatti online"
        },
        "sportsBetting": {
            "title": "Sports Betting ID | Place a Bet On Live Sports",
            "description": "Explore Live Sports on Yolo247 with Sports Betting ID- Betting Exchange offers you the best sports betting experience in India.  400% Welcome Bonus | Instant Withdrawal",
            "canonical": "https://www.yolo247.club/betting-exchange",
            "alternate": "https://m.yolo247.club/betting-exchange?id=0",
            "keywords": " betting exchange, sports betting id, best online sports betting id provider, sports betting id provider, online sports betting id provider, online sports betting id"
        },
        "onlineCricketSattaBazar": {
            "title": "Get Your Cricket Betting ID | Bet On Your Favorite Sports",
            "description": "Get Your Cricket Betting ID and bet on your favorite cricket matches in one of the best cricket betting sites in India. Place bets on Sportsbook, Exchange, and Virtual Sports on Yolo247",
            "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar, cricket betting id, online cricket betting id, betting id cricket, online cricket betting id in india, online id for cricket betting",
            "canonical": "https://www.yolo247.club/betting-exchange/online-cricket-betting",
            "alternate": "https://m.yolo247.club/betting-exchange/online-cricket-betting",
        },
        "footballBetting": {
            "title": "Your Ultimate Football Betting ID Provider | Yolo247",
            "description": "Kick off your winning streak with our football betting ID. Instant sign-up, smooth transactions, and a plethora of options. Your path to victory starts here!",
            "canonical": "https://www.yolo247.club/betting-exchange/football-betting",
            "alternate": "https://m.yolo247.club/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites, football betting id, football betting online, online football betting india"
        },
        "tennisBetting": {
            "title": "Tennis Betting Id | Turning Predictions into Profits",
            "description": "Enhance your tennis betting thrill with a dedicated betting ID. Quick access and seamless transactions for an elevated gaming experience. Join now!",
            "canonical": "https://www.yolo247.club/betting-exchange/tennis-betting",
            "alternate": "https://m.yolo247.club/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites,tennis betting id,tennis betting,tennis id,best tennis betting sites"
        },
        "volleyballBetting": {
            "title": "Trusted Volleyball Betting ID Platform | Yolo247",
            "description": "Volleyball betting ID is made simple with our dedicated ID. Instant registration, seamless transactions, and a world of betting opportunities at your fingertips",
            "canonical": "https://www.yolo247.club/betting-exchange/volleyball-betting",
            "alternate": "https://m.yolo247.club/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites,volleyball betting id,volleyball betting,volleyball betting odds,best online volleyball betting sites"
        },
        "promotions": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.yolo247.club/promotions",
            "alternate": "https://m.yolo247.club/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "specialMarket": {
            "title": "Special Markets on Yolo 247",
            "description": "Special Markets on Yolo 247",
            "canonical": "https://www.yolo247.club/specialMarket",
            "alternate": "https://m.yolo247.club/specialMarket",
        },
        "virtualSports": {
            "title": "Hit the Jackpot with Online Cricket ID | Your way to Win Big!",
            "description": "Transform into a cricket champion with your online circket ID. Engage in competitive matches, tournaments, and connect with a community of passionate gamers",
            "canonical": "https://www.yolo247.club/virtualSports/vci",
            "alternate": "https://m.yolo247.club/virtualSports/vci",
        },
        "virtualSportsBL": {
            "title": "Elevate Your Game with Virtual basketball betting id",
            "description": "Unlock the excitement with premier basketball betting IDs. Safe, secure, and loaded with options for the ultimate hoops betting thrill.",
            "canonical": "https://www.yolo247.club/virtualSports/vbl",
            "alternate": "https://m.yolo247.club/virtualSports/vbl",
        },
        "virtualSportsFB": {
            "title": "Bundesliga Betting: Place Your Virtual Sports Bets at Yolo247",
            "description": "Unleash the power of your Bundesliga betting ID for a premier football wagering experience. Predict, bet, and celebrate victories!",
            "canonical": "https://www.yolo247.club/virtualSports/vfb",
            "alternate": "https://m.yolo247.club/virtualSports/vfb",
        },
        "virtualSportsFEL": {
            "title": "EPL Betting ID | Bet on Premier League Matches Online",
            "description": "Get in the game with an EPL betting ID. Elevate your stakes, enjoy bonuses, and experience football like never before. Bet smart, win big!",
            "canonical": "https://www.yolo247.club/virtualSports/vfel",
            "alternate": "https://m.yolo247.club/virtualSports/vfel",
        },
        "virtualSportsFEC": {
            "title": "European Cup Betting ID | Place Your Bets and Win Big!",
            "description": "Join the action with our European Cup betting ID. Elevate your game with top-notch security and access to premium odds. Bet with confidence!",
            "canonical": "https://www.yolo247.club/virtualSports/vfec",
            "alternate": "https://m.yolo247.club/virtualSports/vfec",
        },
        "virtualSportsVTI": {
            "title": "Elevate Your Game with Virtual Tennis Betting ID",
            "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
            "canonical": "https://www.yolo247.club/virtualSports/vti",
            "alternate": "https://m.yolo247.club/virtualSports/vti",
        },
        "virtualSportsWC": {
            "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
            "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
            "canonical": "https://www.yolo247.club/virtualSports/vfwc",
            "alternate": "https://m.yolo247.club/virtualSports/vfwc",
        },
        "aviator": {
            "title": "Get Aviator ID & Explore More Instant casino - Yolo247",
            "description": "Unlock a world of aviation with Aviator ID – your passport to exclusive pilot resources and communities. Elevate your flying experience now!",
            "canonical": "https://www.yolo247.club/instant-games/spribe/aviator",
            "alternate": "https://m.yolo247.club/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game, aviator id, aviator betting sites, aviator game betting, aviator betting game"
        },
        "cashshow": {
            "title": "Yolo247 | Your Online Gaming Cash-Show ID",
            "description": "Yolo247: Get your unique Cash-Show Betting ID for seamless access to thrilling online games and exclusive betting opportunities. Play smart and win big!",
            "canonical": "https://www.yolo247.club/instant-games/galaxsys/cash-show",
            "alternate": "https://m.yolo247.club/instant-games/galaxsys/cash-show",
            "keywords": "Cash Show game, Cash Show online game "
        },
        "jetx": {
            "title": "Claim Your jetx ID & Get Exclusive Bonus Rewards",
            "description": "Unlock limitless possibilities with JetX ID – your gateway to seamless online experiences and secure digital identity management",
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/jetx",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/jetx",
            "keywords": "Jet X game, Jet X online game, jetx id, jetx game, jetx online"
        },
        "rockpaperscissors": {
            "title": "Rockpaperscissors ID | Grab Your Exclusive Deals Now!",
            "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
            "canonical": "https://www.yolo247.club/instant-games/stp/rock-paper-scissors",
            "alternate": "https://m.yolo247.club/instant-games/stp/rock-paper-scissors",
            "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
        },
        "plinkox": {
            "title": "Grab Plinkox ID- Inspect More Instant casino | Yolo247",
            "description": "Step into the future with Plinkox ID - Your trusted partner for secure, seamless, and personalized digital experiences",
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/plinkox",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/plinkox",
            "keywords": "Plinkox game, Plinkox online game "
        },
        "footballx": {
            "title": "Get Footballx ID Play FootballX Online Game on yolo247",
            "description": "Step into the future of football with FootballX ID. Access exclusive features, connect with like-minded fans, and celebrate the game you love",
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/footballx",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/footballx",
            "keywords": "Football X game, Football X online game "
        },
        "balloon": {
            "title": "Get Balloon Betting ID with 400% bonus | Yolo247",
            "description": "Find your perfect balloon betting ID platform. Enjoy a variety of casino, competitive odds, and a secure environment for online betting",
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/balloon",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/balloon",
            "keywords": "Balloon game, Balloon online game "
        },
        "cricketx": {
            "title": "Claim Your CricketX ID & Get Exclusive Bonus Rewards",
            "description": "Elevate your cricket experience with CricketX ID. Access real-time updates, player profiles, and immersive content. Be part of the game!",
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/cricketx",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/cricketx",
            "keywords": "Cricketx Games,Yolo247 Cricketx games,Yolo Cricketx games,Cricketx casino games,CricketX id,cricket x bet,cricket x game"
        },
        "mines": {
            "title": "Get Mines Game ID & Explore More Instant casino | Yolo247",
            "description": "Elevate your betting journey with a mines betting ID. Unleash the thrill of wagering on a cutting-edge platform",
            "canonical": "https://www.yolo247.club/instant-games/spribe/mines",
            "alternate": "https://m.yolo247.club/instant-games/spribe/mines",
            "keywords": "Mines game, Online mines game"
        },
        "macludo": {
            "title": "Get Your Personalized Betting ID for Macludo Games at Yolo247",
            "description": "Secure your winning chance with your Betting ID for Macludo Games at Yolo247. Enjoy a seamless and thrilling gaming experience with top-notch security!",
            "canonical": "https://www.yolo247.club/live-casino/macludo",
            "alternate": "https://m.yolo247.club/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online"
        },
        "crazytime": {
            "title": "Maximize Your Fun with Crazy Time ID on Yolo247",
            "description": "Experience the ultimate gaming frenzy with Crazy Time ID. Join the chaos, win big, and let the craziness unfold in every click!",
            "canonical": "https://www.yolo247.club/live-casino/evolution/crazy-time",
            "alternate": "https://m.yolo247.club/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online, crazy time betting, crazy time online game, crazy time id"
        },
        "welcomeBonus": {
            "title": "Play Online Casino & Sports casino and Get 400%  Welcome Bonus at Yolo247",
            "description": "Begin your adventure with a bang! Our welcome bonus is your ticket to a thrilling journey of bonuses and rewards.",
            "canonical": "https://www.yolo247.club/promotions/welcome-bonus",
            "alternate": "https://m.yolo247.club/promotions/welcome-bonus",
            "keywords": "Welcome Bonus, casino welcome bonus"
        },
        "refillBonus": {
            "title": "Refill Casino Bonus | Claim Your Way to Boosted Winnings Today!",
            "description": "Reload your luck with our exclusive refill casino bonus. Elevate your gaming experience with extra rewards. Play smarter, win bigger!",
            "canonical": "https://www.yolo247.club/promotions/refill-bonus",
            "alternate": "https://m.yolo247.club/promotions/refill-bonus",
            "keywords": "Refill bonus, Refill casino bonus"
        },
        "cashbackBonus": {
            "title": "Cash Back Bonus | Get A 5% Cashback Bonus Instantly",
            "description": "Explore the world of online casinos with cashback bonuses. Enhance your gameplay, recover losses, and enjoy a rewarding casino adventure like never before",
            "canonical": "https://www.yolo247.club/promotions/cashback-bonus",
            "alternate": "https://m.yolo247.club/promotions/cashback-bonus",
            "keywords": "Cashback Bonus, casino cashback bonus, online casino with cashback bonus"
        },
        "referralBonus": {
            "title": "Referral Bonus Bonanza | Elevate Your Rewards Game Today",
            "description": "Unleash the power of online casino referral bonuses! Elevate your play with exclusive rewards and exciting promotions on yolo247",
            "canonical": "https://www.yolo247.club/promotions/referral-bonus",
            "alternate": "https://m.yolo247.club/promotions/referral-bonus",
            "keywords": "Referral Bonus, online casino referral bonus, casino referral bonus, best online casino referral bonus"
        },
        "termsandconditions": {
            "title": "Terms & Conditions | Yolo247 Online Betting ID T&C",
            "description": "Yolo247 T&Cs Updated! Review obligations, usage terms, registration details, gameplay rules, deposit & withdrawal policies, and more for a seamless experience.",
            "canonical": "https://www.yolo247.club/terms-conditions",
            "alternate": "https://m.yolo247.club/about/terms-conditions",
            "keywords": "Terms & Conditions"
        },
        "responsibleGambling": {
            "title": "Yolo247 | Responsible Betting & ID Services in India",
            "description": "Yolo247 | Secure and responsible betting in India with trusted ID verification. Enjoy safe, smart gaming with Yolo247’s commitment to player safety.",
            "canonical": "https://www.yolo247.club/responsible-gambling",
            "alternate": "https://m.yolo247.club/about/responsible-gambling",
            "keywords": "Responsible Gambling"
        },
        "privacyPolicy": {
            "title": "Yolo247 Privacy Policy | Online Betting ID Provider in India",
            "description": "Understanding the Privacy Policy is crucial for safe betting. Review the data collected and how it's used. Click here for more details!",
            "canonical": "https://www.yolo247.club/privacy-policy",
            "alternate": "https://m.yolo247.club/about/privacy-policy",
            "keywords": "privacy policy"
        },
        "kycPolicy": {
            "title": "Yolo247 KYC Policy | Essential Requirements to Know",
            "description": "Discover Yolo247 KYC Policy: Key essentials you need to verify your identity and enjoy seamless gaming, ensuring compliance and security for all users",
            "canonical": "https://www.yolo247.club/kyc-policy",
            "alternate": "https://m.yolo247.club/about/kyc-policy",
            "keywords": "KYC Policy"
        },
        "exclusionPolicy": {
            "title": "Exclusion Policy  | Self-Exclusion Policy of Yolo247",
            "description": "Exclusion Policy (self-exclusion) is a facility that the site offers to help customers who feel that their Gambling is out of ",
            "canonical": "https://www.yolo247.club/exclusion-policy",
            "alternate": "https://m.yolo247.club/about/self-exclusion-policy",
            "keywords": "exclusion policy "
        },
        "amlPolicy": {
            "title": "AML Policy at Yolo247 | Protecting Integrity and Compliance",
            "description": "Yolo247's Anti-Money Laundering (AML) Policy ensures secure, compliant operations by preventing illegal activities and safeguarding customer transactions from financial crimes",
            "canonical": "https://www.yolo247.club/aml-policy",
            "alternate": "https://m.yolo247.club/about/aml-policy",
            "keywords": "AML Policy"
        },
        "aboutus": {
            "title": "About Us | Yolo247 | World's Biggest Betting ID Provider",
            "description": "Discover the world's biggest betting ID provider,With a wide range of options and a seamless user experience, Join us today and experience the ultimate thrill of online betting with Yolo 247.",
            "canonical": "https://www.yolo247.club/about-us",
            "alternate": "https://m.yolo247.club/about/about-us",
            "keywords": "About us, About Yolo247"
        },
        "SPRIBE": {
            "title": "Spribe Betting ID: Unlocking Exclusive Betting Features",
            "description": "Discover Spribe Betting ID: Unlock exclusive features, personalized bets, and enhanced gaming experiences. Elevate your betting with tailored options and rewards!",
            "canonical": "https://www.yolo247.club/instant-games/spribe",
            "alternate": "https://m.yolo247.club/instant-games/spribe",
            "keywords": "spribe casino games"
        },
        "aviatrix": {
            "title": "Yolo247 | Your Aviatrix Betting ID Guide",
            "description": "Your Aviatrix Betting ID at Yolo247: Play thrilling Aviatrix games and track your bets effortlessly with your unique ID. Enjoy an enhanced gaming experience!",
            "canonical": "https://www.yolo247.club/instant-games/aviatrix",
            "alternate": "https://m.yolo247.club/instant-games/aviatrix",
            "keywords": "Aviatrix casino games"
        },
        "7MOJOS": {
            "title": "7mojos Games Betting ID at Yolo247 | Easy Access Here",
            "description": "Your Betting ID for 7mojos Games at Yolo247: Unlock exclusive games and big wins. Use your ID for personalized betting experiences and exciting rewards!",
            "canonical": "https://www.yolo247.club/live-casino/7mojos",
            "alternate": "https://m.yolo247.club/live-casino/7mojos",
            "keywords": "7Mojos Games"
        },
        "supernowa": {
            "title": "Supernowa Online Casino Game | Play & Win at Yolo247",
            "description": "Experience the thrill of Supernowa at Yolo247. Play this exciting online casino game and win big with our secure and fun gaming environment.",
            "canonical": "https://www.yolo247.club/live-casino/supernowa",
            "alternate": "https://m.yolo247.club/live-casino/supernowa",
            "keywords": "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
        },
        "pragmatic": {
            "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
            "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
            "canonical": "https://www.yolo247.club/slots/pragmatic-play",
            "alternate": "https://m.yolo247.club/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games"
        },
        "superspade": {
            "title": "Yolo247 | Your Ultimate Betting ID for Super Spade Games",
            "description": "Enjoy thrilling Super Spade online casino games on Yolo247. Experience live dealers, real-time action, and exciting wins. Play now!",
            "canonical": "https://www.yolo247.club/live-casino/super-spade",
            "alternate": "https://m.yolo247.club/live-casino/super-spade",
            "keywords": "super spade games,play super spade casino games, super spade casino"
        },
        "tvbet": {
            "title": " TVBet Online Casino Games | Play Live at Yolo247",
            "description": "Experience the thrill of TVBet live casino games at Yolo247. Play now and enjoy seamless, exciting gaming action with real-time results.",
            "canonical": "https://www.yolo247.club/live-casino/tvbet",
            "alternate": "https://m.yolo247.club/live-casino/tvbet",
            "keywords": "TVBET casino games, Yolo247 TVBET games"
        },
        "netent": {
            "title": "Netent Casino Game on Yolo247 | Get Upto 400%  Bonus",
            "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
            "canonical": "https://www.yolo247.club/slots/netent",
            "alternate": "https://m.yolo247.club/slots/netent",
            "keywords": "netent games"
        },
        "betgames": {
            "title": "BetGames On Yolo247 | Play BetGames & Win Big",
            "description": "BetGames is one of the modern-day game providers in Yolo247. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on Yolo247 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
            "canonical": "https://yolo247.club/live-casino/betgames",
            "alternate": "https://m.yolo247.club/live-casino/betgames",
            "keywords": "betgames,Yolo247 BetGames"
        },
        "sportsbook": {
            "title": "Get your sportsbook ID & Place a bet on your favorite sports",
            "description": "Unleash the power of your sportsbook ID for a premium betting experience. Join us for quick registration, seamless transactions, and endless excitement",
            "canonical": "https://www.yolo247.club/sportsbook",
            "alternate": "https://m.yolo247.club/sportsbook",
            "keywords": "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India, sportsbook id, online sportsbook betting, sportsbook betting, best sportsbook betting sites"
        },
        "premiumSportsbook": {
            "title": "Best Online Sportsbook ID | Experience Victory with Every Wager!",
            "description": "Bet smarter with the best ID sportsbook. Elevate your gaming experience with instant deposits, fast withdrawals, and a wide array of sports to choose from",
            "canonical": "https://www.yolo247.club/premium-sportsbook",
            "alternate": "https://m.yolo247.club/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India, best id sportsbook, sportsbook betting online, sportsbook betting"
        },
        "appDownload": {
            "title": "Yolo247 App Download | Free Download for Android",
            "description": "Get the Yolo247 app for Android devices, supporting English and Hindi. Download the latest version from the Google Play Store and register to use its features.",
            "canonical": "https://www.yolo247.club/app-download",
            "alternate": "https://m.yolo247.club/app-download",
            "keywords": "Yolo App Download, Yolo Android App"
        },
        "login": {
            "title": "Secure Yolo247 Login | Access Your Account Safely",
            "description": "Login to your Yolo247 account, Safely access your account, and enjoy the convenience of managing your Yolo247 services hassle-free.",
            "canonical": "https://www.yolo247.club/login",
            "alternate": "https://m.yolo247.club/login",
            "keywords": "Yolo Login"
        },
        "signup": {
            "title": "Betting Made Easy | Register on yolo247 for Your Online Betting ID",
            "description": "Yolo 247 Signup Your key to a seamless and efficient entry into an exciting Online Gaming world, Don't miss out on the fun, sign up now!",
            "canonical": "https://www.yolo247.club/signup",
            "alternate": "https://m.yolo247.club/signup",
            "keywords": "Yolo User Registration"
        },
        "faq": {
            "title": "FAQ Yolo247 | Online Betting ID Provider in India",
            "description": "Get the most out of Yolo 247 with our concise FAQ. Troubleshoot issues, discover hidden features, and enhance your experience!",
            "canonical": "https://www.yolo247.club/faq",
            "alternate": "https://m.yolo247.club/faq",
        },
        "affiliate": {
            "title": "Affiliate Page",
            "description": "Affiliate Page",
            "canonical": "https://www.yolo247.club/affiliate",
            "alternate": "https://m.yolo247.club/affiliate",
        },
        "loyalty": {
            "title": "Loyalty Page",
            "description": "Loyalty Page",
            "canonical": "https://www.yolo247.club/loyalty",
            "alternate": "https://m.yolo247.club/loyalty",
        },
        "instantGames": {
            "title": "Yolo247 | Your Instant Betting ID for Online Games",
            "description": "Yolo247: Your gateway to instant betting on top online games. Enjoy seamless play, real-time action, and exciting wins with our user-friendly platform",
            "canonical": "https://www.yolo247.club/instant-games",
            "alternate": "https://m.yolo247.club/instant-games",
            "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "majorLeagueCricketBetting": {
            "title": "MLC Betting in India | Major League Cricket Betting Online",
            "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
            "canonical": "https://www.yolo247.club/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.yolo247.club/sports/tournaments/major-league-cricket-betting",
            "keywords": "Major League Cricket Betting Online, MLC Betting in India"
        },
        "dailyDepositBonus": {
            "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
            "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
            "canonical": "https://www.yolo247.club/daily-deposit-bonus",
            "alternate": "https://m.yolo247.club/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "popular-games": {
            "title": "Best Casino Games  ID | Bet, Spin, and Win Instantly | Yolo247",
            "description": "Get ready for non-stop fun with a personalized casino ID. Join the excitement, play your favorite casino, and win big!",
            "canonical": "https://www.yolo247.club/popular-games",
            "alternate": "https://m.yolo247.club/popular-games",
            "keywords": "Best Casino Games Popular Casino Games, online casino real money, popular games in india, popular pc games, popular video games, online casino betting sites, casino id"
        },
        "evolution": {
            "title": "Your Evolution Gaming Betting ID for Yolo247",
            "description": "Your Personalized Betting ID for Evolution Gaming on Yolo247: Enjoy Unmatched Gaming Thrills and Exclusive Betting Features Designed for Winners!",
            "canonical": "https://www.yolo247.club/live-casino/evolution",
            "alternate": "https://m.yolo247.club/live-casino/evolution",
            "keywords": "Evolution Gaming, Evolution Gaming Casino"
        },
        "spribe": {
            "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
            "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
            "canonical": "https://www.yolo247.club/instant-games/spribe",
            "alternate": "https://m.yolo247.club/instant-games/spribe",
            "keywords": "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
        },
        "ezugi": {
            "title": "Ezugi Live Casino | Play Yolo247 Ezugi Games",
            "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on Yolo247. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
            "canonical": "https://yolo247.club/live-casino/ezugi",
            "alternate": "https://m.yolo247.club/live-casino/ezugi",
            "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.yolo247.club/asia-cup-bonanza",
            "alternate": "https://m.yolo247.club/asia-cup-bonanza"
        },
        //member dashboard pages
        "memberDashboard": {
            "canonical": "https://www.yolo247.club/member/profile",
            "alternate": "https://m.yolo247.club/member/profile"
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.yolo247.club/member/profile/accountinfo",
            "alternate": "https://m.yolo247.club/member/profile/accountinfo"
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.yolo247.club/member/profile/password",
            "alternate": "https://m.yolo247.club/member/profile/password"
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.yolo247.club/member/profile/bankdetails",
            "alternate": "https://m.yolo247.club/member/profile/bankdetails"
        },
        "memberProfileKYC": {
            "canonical": "https://www.yolo247.club/member/profile/kycpage",
            "alternate": "https://m.yolo247.club/member/profile/kycpage"
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.yolo247.club/member/finance/deposit",
            "alternate": "https://m.yolo247.club/member/finance/deposit"
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.yolo247.club/member/finance/withdrawal",
            "alternate": "https://m.yolo247.club/member/finance/withdrawal"
        },
        "memberFinanceHistory": {
            "canonical": "https://www.yolo247.club/member/finance/history",
            "alternate": "https://m.yolo247.club/member/finance/history"
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.yolo247.club/member/finance/transfer",
            "alternate": "https://m.yolo247.club/member/finance/transfer"
        },
        "memberMyBets": {
            "canonical": "https://www.yolo247.club/member/betDetails/mybets",
            "alternate": "https://m.yolo247.club/member/betDetails/mybets"
        },
        "memberP&L": {
            "canonical": "https://www.yolo247.club/member/betDetails/profitandloss",
            "alternate": "https://m.yolo247.club/member/betDetails/profitandloss"
        },
        "memberCasinoStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/casinostatement",
            "alternate": "https://m.yolo247.club/member/betDetails/casinostatement"
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/sportbookstatement",
            "alternate": "https://m.yolo247.club/member/betDetails/sportbookstatement"
        },
        "memberSTPStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/splitThePotStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/splitThePotStatement"
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/galaxyStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/galaxyStatement"
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/aviatrixStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/aviatrixStatement"
        },
        "memberEvoPlayStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/evoplayStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/evoplayStatement"
        },
        "memberJiliStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/jiliStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/jiliStatement"
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/turbogamesStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/turbogamesStatement"
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/indiaLotteryStatement"
        },
        "memberAccountStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/accountStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/accountStatement"
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/virtualSportsStatement"
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.yolo247.club/member/betDetails/turnoverStatement",
            "alternate": "https://m.yolo247.club/member/betDetails/turnoverStatement"
        },
        "memberPrormotions": {
            "canonical": "https://www.yolo247.club/member/promotions",
            "alternate": "https://m.yolo247.club/member/promotions"
        },
        "memberMessages": {
            "canonical": "https://www.yolo247.club/member/message",
            "alternate": "https://m.yolo247.club/member/message"
        },
        "ninjacrash": {
            "title": "Grab Ninja Crash ID- Explore More Instant casino | Yolo247",
            "description": "Join the action in Ninja Crash at Yolo247 Club. Experience fast-paced gameplay and exciting rewards. Play now and unleash your inner ninja!",
            "canonical": "https://www.yolo247.club/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.yolo247.club/instant-games/galaxsys/ninja-crash",
            "keywords": "NinjaCrash game, Online NinjaCrash game"
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.yolo247.club/promotions/big-bash",
            "alternate": "https://m.yolo247.club/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },

        "lucky-fruits-lines": {
            "canonical": "https://www.yolo247.club/slots/lucky-fruits-lines",
            "alternate": "https://m.yolo247.club/slots/lucky-fruits-lines"
        },
        "andar-bahar": {
            "title": "Andar Bahar Betting ID | Yolo247 Insights",
            "description": "Bet on Andar-Bahar with Yolo247! Use your Betting ID to place bets and enjoy the thrill of this classic game. Win big and experience top-notch gaming!",
            "canonical": "https://www.yolo247.club/live-casino/andar-bahar",
            "alternate": "https://m.yolo247.club/live-casino/andar-bahar"
        },
        "dream-catcher": {
            "title": "Get Your Dream Catcher Betting ID at Yolo247",
            "description": "Unlock exciting gameplay with your Betting ID for Dream Catcher games at Yolo247. Enjoy immersive casino action and spin the wheel to win big!",
            "canonical": "https://www.yolo247.club/live-casino/evolution/dream-catcher",
            "alternate": "https://m.yolo247.club/live-casino/evolution/dream-catcher"
        },
        "hi-lo": {
            "title": "Get Your Betting ID for Yolo247 Hi-Lo Games",
            "description": "Get Your Personalized Betting ID for Hi-Lo Games on Yolo247: Start Winning Today!",
            "canonical": "https://www.yolo247.club/instant-games/spribe/hi-lo",
            "alternate": "https://m.yolo247.club/instant-games/spribe/hi-lo"
        },
        "rocketon": {
            "title": "Get Your Betting ID for Rocketon Games at Yolo247",
            "description": "Your Betting ID for Rocketon Games at Yolo247. Use it to place bets and track your gameplay seamlessly. Enjoy the thrill and win big with Yolo247!",
            "canonical": "https://www.yolo247.club/instant-games/galaxsys/rocketon",
            "alternate": "https://m.yolo247.club/instant-games/galaxsys/rocketon"
        },
        "burning-ice": {
            "title": "Yolo247 | Your Burning Ice Betting ID for Online Games",
            "description": "Yolo247: Ignite your gaming passion with our unique Betting ID for seamless access to online games. Experience top-tier gaming and betting excitement today!",
            "canonical": "https://www.yolo247.club/instant-games/burning-ice",
            "alternate": "https://m.yolo247.club/instant-games/burning-ice"
        },
        "cappa-docia": {
            "canonical": "https://www.yolo247.club/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.yolo247.club/instant-games/smartsoft/cappadocia"
        },
        "genies-bonanza": {
            "canonical": "https://www.yolo247.club/instant-games/genies-bonanza",
            "alternate": "https://m.yolo247.club/instant-games/genies-bonanza"
        },
        "goal": {
            "title": "Yolo247 | Essential Goal Betting ID for Online Games",
            "description": "Yolo247: Secure your Betting ID for seamless online gaming. Enjoy thrilling sports betting, casino games, and more with our reliable platform.",
            "canonical": "https://www.yolo247.club/instant-games/spribe/goal",
            "alternate": "https://m.yolo247.club/instant-games/spribe/goal"
        },
        "hunter-x": {
            "title": "Get Your HunterX Betting ID on Yolo247 for Online Games",
            "description": "Yolo247: Get your HunterX Betting ID for thrilling online games. Start betting on your favorite games with ease and security. Join now for exclusive action!",
            "canonical": "https://www.yolo247.club/instant-games/hunter-x",
            "alternate": "https://m.yolo247.club/instant-games/hunter-x"
        },
        "jungles": {
            "title": "Yolo247 | Top Jungle Betting ID for You",
            "description": "Yolo247: Your gateway to thrilling online betting! Use your unique ID to access top-notch games and win big. Dive into the action now with Yolo247!",
            "canonical": "https://www.yolo247.club/instant-games/jungles",
            "alternate": "https://m.yolo247.club/instant-games/jungles"
        },
        "magic-garden": {
            "title": "Yolo247 Magic Garden | Your Betting ID Unveiled",
            "description": "Unlock the magic with Yolo247's Magic Garden! Discover your exclusive betting ID and dive into a world of exciting games and big wins. Join now!",
            "canonical": "https://www.yolo247.club/instant-games/magic-garden",
            "alternate": "https://m.yolo247.club/instant-games/magic-garden"
        },
        "multi-hot-ways": {
            "title": "Get Your Yolo247 Multi Hot Ways Betting ID Today!",
            "description": "Explore the Power of Yolo247's Multi-Hot-Ways Betting ID for Diverse and Dynamic Betting Strategies",
            "canonical": "https://www.yolo247.club/instant-games/multi-hot-ways",
            "alternate": "https://m.yolo247.club/instant-games/multi-hot-ways"
        },
        "paper-plane": {
            "title": "Yolo247 Paper Plane Betting ID | Quick and Easy Setup",
            "description": "Get Started with Yolo247 Paper Plane Betting ID: Simple Steps for a Fast and Easy Setup",
            "canonical": "https://www.yolo247.club/instant-games/stp/paper-plane",
            "alternate": "https://m.yolo247.club/instant-games/stp/paper-plane"
        },
        "stockmarket": {
            "title": "Stock Market Betting ID | Unlock Exclusive Features at Yolo247",
            "description": "Unlock exclusive betting features with StockMarket Betting ID! Enjoy advanced tools, personalized insights, and enhanced odds for smarter, more rewarding bets",
            "canonical": "https://www.yolo247.club/instant-games/evolution/stock-market",
            "alternate": "https://m.yolo247.club/instant-games/evolution/stock-market"
        },
        "rulesRgulations": {
            "canonical": "https://www.yolo247.club/rules-regulations",
            "alternate": "https://m.yolo247.club/about/rules-regulations"
        },
        "teen-patti": {
            "title": "",
            "description": "",
            "canonical": "https://www.yolo247.club/live-casino/teen-patti",
            "alternate": "https://m.yolo247.club/live-casino/teen-patti",
            "keywords": ""
        },
        "sponsorships": {
            "title": "Yolo247 Sponsorships",
            "description": "Yolo247 Sponsorships",
            "canonical": "https://www.yolo247.club/sponsors",
            "alternate": "https://m.yolo247.club/sponsors",
            "keywords": ""
        },
    },
    "33": {
            "default": {
                "title": "Play Online Casino Games in India 24/7 - Win Real Money ₹₹",
                "description": "Experience non-stop thrills with our Online Casino Games in India. Play to win Real Money and enjoy the excitement of the game. Start your winning journey now!",
                "canonical": "https://www.iw247.com",
                "alternate": "https://m.iw247.com/",
                "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games in India | Win Real Money in India",
                "description": "With our Online Casino Games, Win real money and experience the thrill of gaming like never before. Join us at IW247 for an unforgettable journey to riches!",
                "canonical": "https://www.iw247.com/live-casino",
                "alternate": "https://m.iw247.com/live-casino",
                "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
            },
            "live-blackjack": {
                "title": "Play Blackjack Online Casino Game | 100% Safe & Fast Payouts",
                "description": "Play Blackjack online game on the best casino platform. Safest & fastest payouts guaranteed on IW247 for the Blackjack game. Play Blackjack card game now!",
                "canonical": "https://www.iw247.com/live-casino/live-blackjack",
                "alternate": "https://m.iw247.com/live-casino/live-blackjack",
                "keywords": "live-blackjack, blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
            },
            "live-roulette": {
                "title": "Play Roulette Online for Free & Real Money - IW247",
                "description": "Spin the wheel of fortune! Play roulette online for free or real money at IW247. Unleash the excitement and test your luck today for a chance to Win Big!",
                "canonical": "https://www.iw247.com/live-casino/live-roulette",
                "alternate": "https://m.iw247.com/live-casino/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Win Real Money in India",
                "description": "Spin the wheel of fortune with Lightning Roulette online at IW247! Win real money in India as you feel the electrifying thrill of every spin.",
                "canonical": "https://www.iw247.com/live-casino/lightning-roulette",
                "alternate": "https://m.iw247.com/live-casino/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Online | Play for Real Money at IW247",
                "description": "Enter the mystical world of Dragon Tiger online. Play for real money at IW247 and let the fortunes unfold.",
                "canonical": "https://www.iw247.com/live-casino/dragon-tiger",
                "alternate": "https://m.iw247.com/live-casino/dragon-tiger",
                "keywords": "iw247 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Online for Free & Real Money in India 2024",
                "description": "Play for free or try your luck with real money in India 2024. Join IW247 for an immersive gaming experience and big wins!",
                "canonical": "https://www.iw247.com/live-casino/live-baccarat",
                "alternate": "https://m.iw247.com/live-casino/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "teenpattiOnlinePlay": {
                "title": "Teen Patti Online | Win Real Money in India - IW247",
                "description": "Embrace the spirit of Teen Patti online at IW247! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.iw247.com/teenpatti-online-play",
                "alternate": "https://m.iw247.com/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange | Everything you need to Win at Betting",
                "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
                "canonical": "https://www.iw247.com/betting-exchange",
                "alternate": "https://m.iw247.com/betting-exchange?id=0",
                "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting in India | Best Odds at IW247",
                "description": "Elevate your game with online cricket betting at IW247! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
                "canonical": "https://www.iw247.com/betting-exchange/online-cricket-satta-bazar",
                "alternate": "https://m.iw247.com/betting-exchange/online-cricket-satta-bazar",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, iw247 online cricket betting, iw247 cricket betting odds, iw247 cricket betting, cricket betting sites, online cricket satta bazar",
            },
            "footballBetting": {
                "title": "Football Betting Online in India | Best Odds at IW247",
                "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
                "canonical": "https://www.iw247.com/betting-exchange/football-betting",
                "alternate": "https://m.iw247.com/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting in India | Best Odds at IW247",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.iw247.com/betting-exchange/tennis-betting",
                "alternate": "https://m.iw247.com/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Best Odds at IW247",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.iw247.com/betting-exchange/volleyball-betting",
                "alternate": "https://m.iw247.com/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at IW247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical": "https://www.iw247.com/promotions",
                "alternate": "https://m.iw247.com/promotions",
                "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "popular-games": {
                "title": "Best Casino Games to Win Money Online | Play Now & Win Big",
                "description": "Discover the Best Casino Games to Win money online! Unleash the thrill of gaming and turn your luck into real money rewards. Let the winning begin!",
                "canonical": "https://www.iw247.com/popular-games",
                "alternate": "https://m.iw247.com/popular-games",
                "keywords": "popular games in india, popular pc games, popular video games"
            },
            "slotGames": {
                "title": "Play Slot Games Online & Win Real Money | IW247",
                "description": "Spin the reels and win big! Play Slot Games Online at IW247 to win real money. Your jackpot awaits – start spinning now and let the winnings roll in!",
                "canonical": "https://www.iw247.com/slots",
                "alternate": "https://m.iw247.com/slots",
                "keywords": "Slot games, Online slots, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Casino Game Online 🕹️ at IW247",
                "description": "Take off with excitement! Play the Aviator Casino Game online at IW247. Get ready for a gaming experience like no other, where big wins await.",
                "canonical": "https://www.iw247.com/instant-games/aviator",
                "alternate": "https://m.iw247.com/instant-games/aviator",
                "keywords": "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Online | Play at IW247 and Win Big",
                "description": "Join the Cash Show online at IW247 and claim your chance to win big! Play for real money and showcase your skills in this exciting game.",
                "canonical": "https://www.iw247.com/instant-games/cash-show",
                "alternate": "https://m.iw247.com/instant-games/cash-show",
                "keywords": "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "JetX Online | Play for Real Money at IW247",
                "description": "Rev up the thrill with JetX online at IW247. Play for real money and experience the rush of high-speed gaming.",
                "canonical": "https://www.iw247.com/instant-games/jetx",
                "alternate": "https://m.iw247.com/instant-games/jetx",
                "keywords": "Jet X game, Jet X online game"
            },
            "rockpaperscissors": {
                "title": "Play Exciting Rock Paper Scissors Games on IW247 for Quick Wins!",
                "description": "Play Rock Paper Scissors on IW247 for instant rewards! Quick wins, thrilling gameplay, and endless fun. Get started today and score big!",
                "canonical": "https://www.iw247.com/instant-games/rock-paper-scissors",
                "alternate": "https://m.iw247.com/instant-games/rock-paper-scissors",
                "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "PlinkoX Game Online | Play for Real Money at IW247",
                "description": "Drop, win, repeat! Immerse yourself in the PlinkoX game online at IW247. Play for real money.",
                "canonical": "https://www.iw247.com/instant-games/plinko-x",
                "alternate": "https://m.iw247.com/instant-games/plinko-x",
                "keywords": "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game on IW247 – Your Ticket to Quick Wins",
                "description": "Play Football X on IW247 for quick wins! Exciting gameplay, fast rewards, and non-stop action await. Join now and kick off your winning streak!",
                "canonical": "https://www.iw247.com/instant-games/football-x",
                "alternate": "https://m.iw247.com/instant-games/football-x",
                "keywords": "Football X game, Football X online game "
            },
            "balloon": {
                "title": "IW247: Dive into balloon Games and Win Big Today",
                "description": "Balloon on IW247 – the ultimate game for quick wins! Test your skills, score big, and win fast. Play now and enjoy thrilling rewards!",
                "canonical": "https://www.iw247.com/instant-games/balloon",
                "alternate": "https://m.iw247.com/instant-games/balloon",
                "keywords": "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Play Mines Online | Play for Real Money at IW247",
                "description": "Unearth treasures and win real money! Play Mines online at IW247 and experience the excitement of this thrilling game.",
                "canonical": "https://www.iw247.com/instant-games/mines",
                "alternate": "https://m.iw247.com/instant-games/mines",
                "keywords": "Mines game, Online mines game"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical": "https://www.iw247.com/instant-games/ninja-crash",
                "alternate": "https://m.iw247.com/instant-games/ninja-crash",
                "keywords": "NinjaCrash game, Online NinjaCrash game"
            },
            "aviatrix": {
                "title": "Aviatrix Casino Game Online | Play Now & Win Big",
                "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at IW247 and seize the opportunity to win big.",
                "canonical": "https://www.iw247.com/instant-games/aviatrix",
                "alternate": "https://m.iw247.com/instant-games/aviatrix",
                "keywords": "aviatrix game, Online aviatrix game"
            },
            "macludo": {
                "title": "Experience Macludo Games on IW247 - Play and Win Today!",
                "description": "Get seamless access to Macludo Games on IW247! Enjoy smooth gameplay and win big. Dive into the action today for an exciting betting experience.",
                "canonical": "https://www.iw247.com/live-casino/macludo",
                "alternate": "https://m.iw247.com/live-casino/macludo",
                "keywords": "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play CrazyTime Online for Free & Real Money - IW247",
                "description": "Dive into the whirlwind of excitement with CrazyTime online at IW247! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
                "canonical": "https://www.iw247.com/live-casino/crazy-time",
                "alternate": "https://m.iw247.com/live-casino/crazy-time",
                "keywords": "Crazytime Game, Crazytime game Online"
            },
            "monopolylive": {
                "title": "Monopoly Live, Win Real Money | IW247",
                "description": "Step into the world of Monopoly Live and win real money at IW247. Play now for a chance to build your empire and claim victory!",
                "canonical": "https://www.iw247.com/live-casino/monopoly-live",
                "alternate": "https://m.iw247.com/live-casino/monopoly-live",
                "keywords": "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Funkytime: Play at IW247 & Win Real Money Online",
                "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
                "canonical": "https://www.iw247.com/live-casino/funky-time",
                "alternate": "https://m.iw247.com/live-casino/funky-time",
                "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "lightningdice": {
                "title": "Lightningdice | Play at IW247 & Win Real Money Online",
                "description": "Feel the electrifying thrill of Lightningdice at IW247! Play and win real money online as you roll the lightning-infused dice.",
                "canonical": "https://www.iw247.com/live-casino/lightning-dice",
                "alternate": "https://m.iw247.com/live-casino/lightning-dice",
                "keywords": "Lightning Dice"
            },
            "cricketx": {
                "title": "Play CricketX Live Online & Win Real Money at IW247",
                "description": "Experience the excitement of CricketX Live at IW247! Play online, showcase your cricket skills, and win real money.",
                "canonical": "https://www.iw247.com/instant-games/cricket-x",
                "alternate": "https://m.iw247.com/instant-games/cricket-x",
                "keywords": "Cricketx Games,iw247 Cricketx games,iw247 Cricketx games,Cricketx casino games"
            },
            "virtualSports": {
                "title": "Virtual Cricket betting: Real thrills, virtual wins!",
                "description": "Step into the Virtual Cricket League and feel the adrenaline! Instant action, non-stop fun, and your chance to win big. Start playing today!",
                "canonical": "https://www.iw247.com/virtualSports/vci",
                "alternate": "https://m.iw247.com/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Join Virtual Basketball League for fast-paced excitement!",
                "description": "Get in the game with Virtual Basketball League! Fast-paced action, real-time excitement, and big wins await. Play now for a thrilling experience!",
                "canonical": "https://www.iw247.com/virtualSports/vbl",
                "alternate": "https://m.iw247.com/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Place your bets on Virtual Football Bundesliga and win big!",
                "description": "Join the Virtual Football Bundesliga League and experience basketball like never before! Exciting matches and big rewards are just a click away. Play now!",
                "canonical": "https://www.iw247.com/virtualSports/vfb",
                "alternate": "https://m.iw247.com/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "  Get in the game with Virtual Football English League betting action!",
                "description": "Love Football ? Try the Virtual Football English League  for fast-paced fun and instant rewards. Play now and experience the thrill of the court!",
                "canonical": "https://www.iw247.com/virtualSports/vfel",
                "alternate": "https://m.iw247.com/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Experience the thrill of Virtual Football Euro Cup betting today!",
                "description": "The Virtual Football Euro Cup is here! Play now, enjoy intense games, and score big. Don’t miss the action—jump into the court and start winning!",
                "canonical": "https://www.iw247.com/virtualSports/vfec",
                "alternate": "https://m.iw247.com/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": " Fast, exciting virtual Tennis betting – start playing!",
                "description": "Feel the rush of Tennis with the Virtual Tennis League! Instant games, real excitement, and big wins. Get started and play today!",
                "canonical": "https://www.iw247.com/virtualSports/vti",
                "alternate": "https://m.iw247.com/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "  Bet smarter with Virtual Football World Cup – try it now!",
                "description": "Virtual Football World Cup : Real excitement, virtual action, and massive rewards. Jump into the game today for your chance to win big!",
                "canonical": "https://www.iw247.com/virtualSports/vfwc",
                "alternate": "https://m.iw247.com/virtualSports/vfwc",
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook | Online Sports Betting at IW247",
                "description": "Go for gold with our Premium Sportsbook at IW247! Engage in online sports betting with the best odds and premium features.",
                "canonical": "https://www.iw247.com/premium-sportsbook",
                "alternate": "https://m.iw247.com/premium-sportsbook",
                "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "welcomeBonus": {
                "title": "400% Welcome Bonus to get you started (2024) 💰",
                "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at IW247! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
                "canonical": "https://www.iw247.com/promotions/welcome-bonus",
                "alternate": "https://m.iw247.com/promotions/welcome-bonus",
                "keywords": "Welcome Bonus"
            },
            "refillBonus": {
                "title": "Get the IW247 Refill Bonus 💰 and Win Big!",
                "description": "Refill your luck and Win Big with IW247's Refill Bonus! 💰 Enjoy extra playing power. It's the perfect boost to increase your chances of winning!",
                "canonical": "https://www.iw247.com/promotions/refill-bonus",
                "alternate": "https://m.iw247.com/promotions/refill-bonus",
                "keywords": "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus 💰 at IW247 2024",
                "description": "Enjoy the thrill of gaming with Cashback Bonus at IW247 in 2024! 💰 It's your chance to turn every bet into a win!",
                "canonical": "https://www.iw247.com/promotions/cashback-bonus",
                "alternate": "https://m.iw247.com/promotions/cashback-bonus",
                "keywords": "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus 💰 at iw247 2024",
                "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
                "canonical": "https://www.iw247.com/promotions/referral-bonus",
                "alternate": "https://m.iw247.com/promotions/referral-bonus",
                "keywords": "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | IW247 Online Casino",
                "description": "Read the Terms & Conditions at IW247 Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
                "canonical": "https://www.iw247.com/terms-conditions",
                "alternate": "https://m.iw247.com/about/terms-conditions",
                "keywords": "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | IW247 Online Casino",
                "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
                "canonical": "https://www.iw247.com/responsible-gambling",
                "alternate": "https://m.iw247.com/about/responsible-gambling",
                "keywords": "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | IW247 Online Casino",
                "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
                "canonical": "https://www.iw247.com/privacy-policy",
                "alternate": "https://m.iw247.com/about/privacy-policy",
                "keywords": "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy | IW247 Online Casino",
                "description": "Learn about our Know Your Customer policy at IW247. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
                "canonical": "https://www.iw247.com/kyc-policy",
                "alternate": "https://m.iw247.com/about/kyc-policy",
                "keywords": "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy | IW247 Online Casino",
                "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
                "canonical": "https://www.iw247.com/exclusion-policy",
                "alternate": "https://m.iw247.com/about/self-exclusion-policy",
                "keywords": "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | IW247 Online Casino",
                "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
                "canonical": "https://www.iw247.com/aml-policy",
                "alternate": "https://m.iw247.com/about/aml-policy",
                "keywords": "AML Policy"
            },
            "aboutus": {
                "title": "About Us | IW247 Online Casino",
                "description": "Get to know us better! Explore the story behind IW247. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
                "canonical": "https://www.iw247.com/about-us",
                "alternate": "https://m.iw247.com/about/about-us",
                "keywords": "About us, About iw247"
            },
            "faq": {
                "title": "Frequently Asked Questions | IW247 Online Casino",
                "description": "Have questions? Find answers in our Frequently Asked Questions section at IW247 Online Casino.",
                "canonical": "https://www.iw247.com/faq",
                "alternate": "https://m.iw247.com/faq",
            },
            "affiliate": {
                "title": "Affiliate | IW247 Online Casino",
                "description": "Join the IW247 Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
                "canonical": "https://www.iw247.com/affiliate",
                "alternate": "https://m.iw247.com/affiliate",
            },
            "bingo": {
                "title": "Play Bingo Online - endless fun & real money at IW247",
                "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
                "canonical": "https://www.iw247.com/live-casino/bingo",
                "alternate": "https://m.iw247.com/live-casino/bingo",
                "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "SPRIBE": {
                "title": "Play Exciting spribe Games on IW247 - Win Big Instantly!",
                "description": "Join spribe Games on iw247 and enjoy quick wins with every game! Easy to play, hard to resist - start now and feel the thrill!",
                "canonical": "https://www.iw247.com/instant-games/spribe",
                "alternate": "https://m.iw247.com/instant-games/spribe",
                "keywords": "spribe casino games"
            },
            "7MOJOS": {
                "title": "Play 7Mojos Games on IW247 Now for Ultimate Fun!",
                "description": "Unlock instant access to 7mojos Games on IW247! Enjoy seamless gameplay and a premium betting experience. Dive in now and start winning!",
                "canonical": "https://www.iw247.com/live-casino/7mojos",
                "alternate": "https://m.iw247.com/live-casino/7mojos",
                "keywords": "7Mojos Games"
            },
            "supernowa": {
                "title": "Supernowa Games Are Here on IW247 - Dive Into the Fun!",
                "description": "Get instant access to supernowa Games on IW247 for seamless betting fun! Top-quality games and big wins await – start playing today!",
                "canonical": "https://www.iw247.com/live-casino/supernowa",
                "alternate": "https://m.iw247.com/live-casino/supernowa",
                "keywords": "supernowa games"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Games online| Play on IW247",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical": "https://www.iw247.com/slots/pragmatic-play",
                "alternate": "https://m.iw247.com/slots/pragmatic-play",
                "keywords": "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Discover Super Spade Games on IW247 - Join the Action Now!",
                "description": "Explore Super Spade Games at IW247! Enjoy smooth integration for the best betting action. Join today and start racking up your wins!",
                "canonical": "https://www.iw247.com/live-casino/super-spade",
                "alternate": "https://m.iw247.com/live-casino/super-spade",
                "keywords": "super spade games"
            },
            "tvbet": {
                "title": "IW247 Brings TV Bet Games – Play for Exciting Wins!",
                "description": "Instant access to TV Bet Games at IW247! Enjoy seamless betting and top-tier gameplay. Start playing now and aim for those big wins!",
                "canonical": "https://www.iw247.com/live-casino/tvbet",
                "alternate": "https://m.iw247.com/live-casino/tvbet",
                "keywords": "tvbet games"
            },
            "netent": {
                "title": "NetEnt Games | Slots & Casino Games on IW247",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical": "https://www.iw247.com/slots/netent",
                "alternate": "https://m.iw247.com/slots/netent",
                "keywords": "netent games"
            },
            "betgames": {
                "title": "BetGames | Live Dealer of Online Games on IW247",
                "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
                "canonical": "https://www.iw247.com/live-casino/betgames",
                "alternate": "https://m.iw247.com/live-casino/betgames",
                "keywords": "betgames"
            },
            "sportsbook": {
                "title": "Sportsbook Online | Sports Betting at IW247",
                "description": "Elevate your sports betting experience with Sportsbook Online at IW247! Enjoy a seamless and thrilling betting experience with the best odds.",
                "canonical": "https://www.iw247.com/sportsbook",
                "alternate": "https://m.iw247.com/sportsbook",
                "keywords": "BTI Sports, BTI Sports Betting"
            },
            "appDownload": {
                "title": "IW247 APK  App - Download & Win!",
                "description": "Check out the main reasons why you need to download the IW247 app on your device. Learn more about the benefits of our app, bonuses and other offers",
                "canonical": "https://www.iw247.com/app-download",
                "alternate": "https://m.iw247.com/app-download",
                "keywords": "iw247 App Download, iw247 Android App"
            },
            "login": {
                "title": "Login IW247 | Play Online Casino Games",
                "description": "IW247 Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
                "canonical": "https://www.iw247.com/login",
                "alternate": "https://m.iw247.com/login",
                "keywords": "iw247 Login"
            },
            "signup": {
                "title": "Sign Up Now - IW247 & explore Online Casino Games",
                "description": "Signup at the IW247 official website today for access to premium Sports & Casino Games",
                "canonical": "https://www.iw247.com/signup",
                "alternate": "https://m.iw247.com/signup",
                "keywords": "iw247 User Registration"
            },
            "instantGames": {
                "title": "Play Instant Games on IW247 - Fun & Rewards Await!",
                "description": "Launch into Instant Games for fast-paced action and thrilling wins! Bet now for endless excitement and boost your winnings with every round!",
                "canonical": "https://www.iw247.com/instant-games",
                "alternate": "https://m.iw247.com/instant-games",
                "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "dailyDepositBonus": {
                "title": "IW247 Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
                "description": "IW247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on IW247.",
                "canonical": "https://www.iw247.com/daily-deposit-bonus",
                "alternate": "https://m.iw247.com/daily-deposit-bonus",
                "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on IW247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, IW247 online cricket betting, IW247 cricket betting odds, IW247 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical": "https://www.iw247.com/asia-cup-bonanza",
                "alternate": "https://m.iw247.com/asia-cup-bonanza"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical": "https://www.iw247.com/promotions/big-bash",
                "alternate": "https://m.iw247.com/promotions/big-bash",
                "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },

            "lucky-fruits-lines": {
                "canonical": "https://www.iw247.com/slots/lucky-fruits-lines",
                "alternate": "https://m.iw247.com/slots/lucky-fruits-lines"
            },
            "andar-bahar": {
                "title": "Get in on Andar Bahar Gaming for real-time excitement!",
                "description": "Take your shot with Andar Bahar for fast wins and adrenaline-packed action! Bet now and enjoy the ride with every round!",
                "canonical": "https://www.iw247.com/live-casino/andar-bahar",
                "alternate": "https://m.iw247.com/live-casino/andar-bahar"
            },
            "dream-catcher": {
                "title": "Enjoy Dream Catcher – quick thrills, fast wins!",
                "description": "Bet on Dream Catcher and experience rapid wins with sky-high excitement! Jump in now and feel the rush with every spin. Play for quick success!",
                "canonical": "https://www.iw247.com/live-casino/dream-catcher",
                "alternate": "https://m.iw247.com/live-casino/dream-catcher"
            },
            "hi-lo": {
                "title": "Hi lo : Fast Betting and Thrills Await – Start Winning Now!",
                "description": "Soar with Hi lo and bet your way to thrilling wins! Instant rewards and nonstop excitement are just a click away – play now!",
                "canonical": "https://www.iw247.com/live-casino/hi-lo",
                "alternate": "https://m.iw247.com/live-casino/hi-lo"
            },
            "rocketon": {
                "title": "Rocketon Betting: Your Shortcut to Fast Wins and Fun!",
                "description": "Take off with Rocketon and enjoy quick wins with non-stop thrills! Bet now for your chance to soar high and score big. Join the excitement!",
                "canonical": "https://www.iw247.com/instant-games/rocketon",
                "alternate": "https://m.iw247.com/instant-games/rocketon"
            },
            "burning-ice": {
                "title": "Instant Wins on IW247 – Play Exciting Burning Ice",
                "description": "Score fast with Burning Ice on IW247! Jump into the action and experience quick wins and endless fun. Start playing today!",
                "canonical": "https://www.iw247.com/instant-games/burning-ice",
                "alternate": "https://m.iw247.com/instant-games/burning-ice"
            },
            "cappa-docia": {
                "title": "IW247 Instant Cappa Docia: Quick Fun, Big Wins",
                "description": "Cappa Docia on IW247 – fast-paced fun with instant rewards! Step into the game and unlock your chance to win big now!",
                "canonical": "https://www.iw247.com/instant-games/cappa-docia",
                "alternate": "https://m.iw247.com/instant-games/cappa-docia"
            },
            "genies-bonanza": {
                "title": "Join IW247 for Genies Bonanza and Win Big in No Time!",
                "description": "Quick wins are waiting in Genies Bonanza on IW247! Play now for exciting rewards and non-stop football action. Don't miss your chance to win!",
                "canonical": "https://www.iw247.com/instant-games/genies-bonanza",
                "alternate": "https://m.iw247.com/instant-games/genies-bonanza"
            },
            "goal": {
                "title": "Enjoy Thrilling Instant Goal Games on IW247 Anytime, Anywhere",
                "description": "Instant Goal Games on IW247 – your shortcut to quick wins! Play now, test your football skills, and enjoy fast rewards in this exciting game",
                "canonical": "https://www.iw247.com/instant-games/goal",
                "alternate": "https://m.iw247.com/instant-games/goal"
            },
            "hunter-x": {
                "title": "IW247 Instant Games – Hunter-x Quick Play, Wins, Big Rewards",
                "description": "Join Hunter-X on IW247 for quick and thrilling wins! Start playing today for fast-paced action and rewarding gameplay",
                "canonical": "https://www.iw247.com/instant-games/hunter-x",
                "alternate": "https://m.iw247.com/instant-games/hunter-x"
            },
            "jungles": {
                "title": "Jungles Instant Games: Non-stop action, real prizes!",
                "description": "Win big, win fast with Jungles on IW247! Join now for exciting football action and quick rewards. Start your winning journey today!",
                "canonical": "https://www.iw247.com/instant-games/jungles",
                "alternate": "https://m.iw247.com/instant-games/jungles"
            },
            "magic-garden": {
                "title": "Boost your excitement with Magic Garden",
                "description": "Magic Garden on IW247 – where football meets quick wins! Step onto the virtual pitch and score big rewards now. Play today!",
                "canonical": "https://www.iw247.com/instant-games/magic-garden",
                "alternate": "https://m.iw247.com/instant-games/magic-garden"
            },
            "multi-hot-ways": {
                "title": "Bet on multi hot ways now and enjoy instant wins!",
                "description": "Quick wins await in Multi hot ways on IW247! Play now for fast-paced football fun and rewarding gameplay. Kickstart your winning streak today!",
                "canonical": "https://www.iw247.com/instant-games/multi-hot-ways",
                "alternate": "https://m.iw247.com/instant-games/multi-hot-ways"
            },
            "paper-plane": {
                "title": "Play Exciting Paper Plane Games on IW247 for Quick Wins!",
                "description": "Paper Plane Games on IW247 – Fast-paced action and quick wins await! Jump into the excitement now and score big with every move!",
                "canonical": "https://www.iw247.com/instant-games/paper-plane",
                "alternate": "https://m.iw247.com/instant-games/paper-plane"
            },
            "stockmarket": {
                "title": "Instant Fun Awaits! Play stockmarket Instant Games on IW247 Now",
                "description": "Stockmarket Instant Games on IW247  – Your gateway to fast wins and exciting gameplay! Dive into the action and experience victory at your fingertips!",
                "canonical": "https://www.iw247.com/instant-games/stockmarket",
                "alternate": "https://m.iw247.com/instant-games/stockmarket"
            },
            "nlc": {
                "canonical": "https://www.iw247.com/slots/no-limit-city",
                "alternate": "https://m.iw247.com/slots/no-limit-city",
            },
            "ag": {
                "title": "Explore AG Games on IW247 – Play & Win Big!",
                "description": "Access AG Games instantly at IW247 for a seamless betting journey. Get ready for top-tier gameplay and exciting rewards – start now!",
                "canonical": "https://www.iw247.com/live-casino/ag",
                "alternate": "https://m.iw247.com/live-casino/ag",
                "keywords": "Amazing Gaming"
            },
            "evolution": {
                "title": "Discover Evolution Gaming on IW247 – Start Winning!",
                "description": "Discover Evolution Games on IW247! Enjoy a smooth betting experience with instant access. Begin your journey to winning today!",
                "canonical": "https://www.iw247.com/live-casino/evolution",
                "alternate": "https://m.iw247.com/live-casino/evolution",
                "keywords": "Evolution Gaming, Evolution Gaming Casino"
            },
            "ezugi": {
                "title": "Ezugi live casino Games Now Available on IW247 – Join the Fun!",
                "description": "Instantly access Ezugi live casino Games on IW247 for an unbeatable betting experience. Smooth gameplay and big rewards await – start winning now!",
                "canonical": "https://www.iw247.com/live-casino/ezugi",
                "alternate": "https://m.iw247.com/live-casino/ezugi",
                "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Now on IW247 – Endless Fun Awaits!",
                "description": "Play Sexy Gaming on IW247 with seamless integration! Unlock an unbeatable betting experience and start winning big now!s",
                "canonical": "https://www.iw247.com/live-casino/sexy-gaming",
                "alternate": "https://m.iw247.com/live-casino/sexy-gaming",
                "keywords": "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
            },
            "teenpatti": {
                "title": "Teen Patti Online | Win Real Money in India - IW247",
                "description": "Embrace the spirit of Teen Patti online at IW247! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.iw247.com/live-casino/teenpatti",
                "alternate": "https://m.iw247.com/live-casino/teenpatti",
                "keywords": "Teenpatti"
            },
            "macaw": {
                "title": "Place your bets on Macaw for thrilling outcomes!",
                "description": "Fly high with Macaw! Bet now for quick wins and a heart-pounding experience that keeps you on the edge. Take off and play today!",
                "canonical": "https://www.iw247.com/live-casino/macaw",
                "alternate": "https://m.iw247.com/live-casino/macaw",
                "keywords": "macaw games, macaw casino online"
            },
            "rulesRgulations": {
                "canonical": "https://www.iw247.com/rules-regulations",
                "alternate": "https://m.iw247.com/about/rules-regulations"
            },
        },
    "34": {
        "default": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.betdaily.club/",
            "alternate": "https://m.betdaily.club/",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "onlineCasinoGames": {
            "title": "Online Casino Games (Real Money) - Play with Any Currency",
            "description": "Experience the ultimate online casino games in India at Betdaily. Play online casino for real money and enjoy the thrill of gambling with exciting bonus offers.",
            "canonical": "https://www.betdaily.club/live-casino",
            "alternate": "https://m.betdaily.club/live-casino",
            "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
        },
        "live-blackjack": {
            "title": "Play Blackjack Online Casino Game | 100% Safe & Fast Payouts",
            "description": "Play Blackjack online game on the best casino platform. Safest & fastest payouts guaranteed on BaaziAdda for the Blackjack game. Play Blackjack card game now!",
            "canonical": "https://www.baaziadda.club/live-casino/live-blackjack",
            "alternate": "https://m.baaziadda.club/live-casino/live-blackjack",
            "keywords": "live-blackjack, blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
        },
        "live-roulette": {
            "title": "Play Roulette Game Online - Free Registration & Instant Payouts",
            "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
            "canonical": "https://www.betdaily.club/live-casino/live-roulette",
            "alternate": "https://m.betdaily.club/live-casino/live-roulette",
            "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "lightningroulette": {
            "title": "Play Lightning Roulette Online | Win Real Money in India",
            "description": "Spin the wheel of fortune with Lightning Roulette online at Betdaily! Win real money in India as you feel the electrifying thrill of every spin.",
            "canonical": "https://www.betdaily.club/live-casino/lightning-roulette",
            "alternate": "https://m.betdaily.club/live-casino/lightning-roulette",
            "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "dragonTiger": {
            "title": "Online Dragon Tiger Game - Get Instant Wins 💸 at Betdaily",
            "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
            "canonical": "https://www.betdaily.club/live-casino/dragon-tiger",
            "alternate": "https://m.betdaily.club/live-casino/dragon-tiger",
            "keywords": "Betdaily is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
        },
        "baccarat": {
            "title": "Play Online Baccarat Game - Get the Best Odds at Betdaily",
            "description": "Play baccarat online game with the latest baccarat rules and winnings to suit your taste. Crafted by experts, the Betdaily baccarat casino is just a click away. Play now!!",
            "canonical": "https://www.betdaily.club/live-casino/live-baccarat",
            "alternate": "https://m.betdaily.club/live-casino/live-baccarat",
            "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
        },
        "teenpattiOnlinePlay": {
            "title": "Teen Patti Online | Win Real Money in India - Betdaily",
            "description": "Embrace the spirit of Teen Patti online at Betdaily! Win real money in India as you play this beloved card game. Play Now!",
            "canonical": "https://www.betdaily.club/teenpatti-online-play",
            "alternate": "https://m.betdaily.club/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
        },
        "sportsBetting": {
            "title": "Betting Exchange | Everything you need to Win at Betting",
            "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
            "canonical": "https://www.betdaily.club/betting-exchange",
            "alternate": "https://m.betdaily.club/betting-exchange",
            "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
        },
        "onlineCricketSattaBazar": {
            "title": "Online Cricket Betting in India | Best Odds at Betdaily",
            "description": "Elevate your game with online cricket betting at Betdaily! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
            "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.betdaily.club/betting-exchange/online-cricket-satta-bazar",
            "alternate": "https://m.betdaily.club/betting-exchange/online-cricket-satta-bazar"
        },
        "footballBetting": {
            "title": "Football Betting Online in India | Best Odds at Betdaily",
            "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
            "canonical": "https://www.betdaily.club/betting-exchange/football-betting",
            "alternate": "https://m.betdaily.club/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
        },
        "tennisBetting": {
            "title": "Online Tennis Betting in India | Best Odds at Betdaily",
            "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
            "canonical": "https://www.betdaily.club/betting-exchange/tennis-betting",
            "alternate": "https://m.betdaily.club/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
        },
        "volleyballBetting": {
            "title": "Volleyball Betting in India | Best Odds at Betdaily",
            "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
            "canonical": "https://www.betdaily.club/betting-exchange/volleyball-betting",
            "alternate": "https://m.betdaily.club/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
        },
        "promotions": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.betdaily.club/promotions",
            "alternate": "https://m.betdaily.club/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "popular-games": {
            "title": "Best Casino Games Online | Get Instant Welcome Bonus & Offer",
            "description": "Explore the most popular and best casino casino online at Betdaily. Choose from thousands of classic casino casino, including online slots, blackjack and roulette.",
            "canonical": "https://www.betdaily.club/popular-games",
            "alternate": "https://m.betdaily.club/popular-games",
            "keywords": "popular games in india, popular pc games, popular video games"
        },
        "slotGames": {
            "title": "Play Slot Games - Online Slots | Betdaily",
            "description": "Slot Games at the best! Play online slots with easy registration and easier withdrawal process. Get 100+ casino slot games to choose from.",
            "canonical": "https://www.betdaily.club/slots",
            "alternate": "https://m.betdaily.club/slots",
            "keywords": "Slot games, Online slots, casino slot games"
        },
        "aviator": {
            "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
            "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
            "canonical": "https://www.betdaily.club/instant-games/spribe/aviator",
            "alternate": "https://m.betdaily.club/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game "
        },
        "cashshow": {
            "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
            "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
            "canonical": "https://www.betdaily.club/instant-games/galaxsys/cash-show",
            "alternate": "https://m.betdaily.club/instant-games/galaxsys/cash-show",
            "keywords": "Cash Show game, Cash Show online game "
        },
        "jetx": {
            "title": "JetX Online | Play for Real Money at Betdaily",
            "description": "Rev up the thrill with JetX online at Betdaily. Play for real money and experience the rush of high-speed gaming.",
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/jetx",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/jetx",
            "keywords": "Jet X game, Jet X online game"
        },
        "rockpaperscissors": {
            "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
            "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
            "canonical": "https://www.betdaily.club/instant-games/stp/rock-paper-scissors",
            "alternate": "https://m.betdaily.club/instant-games/stp/rock-paper-scissors",
            "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
        },
        "plinkox": {
            "title": "Plinkox Game | Live Casino Online Games on Betdaily",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/plinkox",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/plinkox",
            "keywords": "Plinkox game, Plinkox online game "
        },
        "footballx": {
            "title": "Football X Game | Live Casino Online Games on Betdaily",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/footballx",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/footballx",
            "keywords": "Football X game, Football X online game "
        },
        "balloon": {
            "title": "Balloon Game | Live Casino Online Games on Betdaily",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/balloon",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/balloon",
            "keywords": "Balloon game, Balloon online game "
        },
        "mines": {
            "title": "Mines Game | Live Casino Online Games on Betdaily",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
            "canonical": "https://www.betdaily.club/instant-games/spribe/mines",
            "alternate": "https://m.betdaily.club/instant-games/spribe/mines",
            "keywords": "Mines game, Online mines game "
        },
        "ninjacrash": {
            "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
            "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
            "canonical": "https://www.betdaily.club/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.betdaily.club/instant-games/galaxsys/ninja-crash",
            "keywords": "NinjaCrash game, Online NinjaCrash game"
        },
        "aviatrix": {
            "title": "Aviatrix Casino Game Online | Play Now & Win Big",
            "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at Betdaily and seize the opportunity to win big.",
            "canonical": "https://www.betdaily.club/instant-games/aviatrix",
            "alternate": "https://m.betdaily.club/instant-games/aviatrix",
            "keywords": "aviatrix game, Online aviatrix game"
        },
        "macludo": {
            "title": "Ludo Game | Play Ludo Game Online for real cash",
            "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
            "canonical": "https://www.betdaily.club/live-casino/macludo",
            "alternate": "https://m.betdaily.club/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online"
        },
        "crazytime": {
            "title": "Play CrazyTime Online for Free & Real Money - Betdaily",
            "description": "Dive into the whirlwind of excitement with CrazyTime online at Betdaily! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
            "canonical": "https://www.betdaily.club/live-casino/evolution/crazy-time",
            "alternate": "https://m.betdaily.club/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online"
        },
        "monopolylive": {
            "title": "Monopoly Live, Win Real Money | Betdaily",
            "description": "Step into the world of Monopoly Live and win real money at Betdaily. Play now for a chance to build your empire and claim victory!",
            "canonical": "https://www.betdaily.club/live-casino/monopoly-live",
            "alternate": "https://m.betdaily.club/live-casino/monopoly-live",
            "keywords": "MONOPOLY LIVE"
        },
        "funkytime": {
            "title": "Funkytime: Play at Betdaily & Win Real Money Online",
            "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
            "canonical": "https://www.betdaily.club/live-casino/evolution/funky-time",
            "alternate": "https://m.betdaily.club/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "lightningdice": {
            "title": "Lightningdice | Play at Betdaily & Win Real Money Online",
            "description": "Feel the electrifying thrill of Lightningdice at Betdaily! Play and win real money online as you roll the lightning-infused dice.",
            "canonical": "https://www.betdaily.club/live-casino/evolution/lightning-dice",
            "alternate": "https://m.betdaily.club/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice"
        },
        "cricketx": {
            "title": "Play CricketX Live Online & Win Real Money at Betdaily",
            "description": "Experience the excitement of CricketX Live at Betdaily! Play online, showcase your cricket skills, and win real money.",
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/cricketx",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/cricketx",
            "keywords": "Cricketx Games,Betdaily Cricketx games,Betdaily Cricketx games,Cricketx casino games"
        },
        "virtualSports": {
            "title": "Online Betting for Sports and Casino at Betdaily",
            "description": "Experience the ultimate thrill with online betting for sports and casino at Betdaily! Enjoy the best of both worlds as you bet on your favorite sports and casino games.",
            "canonical": "https://www.betdaily.club/virtualSports/vci",
            "alternate": "https://m.betdaily.club/virtualSports/vci",
        },
        "virtualSportsBL": {
            "title": "Virtual Sports on Betdaily",
            "description": "Virtual Sports on Betdaily",
            "canonical": "https://www.betdaily.club/virtualSports/vbl",
            "alternate": "https://m.betdaily.club/virtualSports/vbl",
        },
        "virtualSportsFB": {
            "title": "Virtual Sports on Betdaily",
            "description": "Virtual Sports on Betdaily",
            "canonical": "https://www.betdaily.club/virtualSports/vfb",
            "alternate": "https://m.betdaily.club/virtualSports/vfb",
        },
        "virtualSportsFEL": {
            "title": "Virtual Sports on Betdaily",
            "description": "Virtual Sports on Betdaily",
            "canonical": "https://www.betdaily.club/virtualSports/vfel",
            "alternate": "https://m.betdaily.club/virtualSports/vfel",
        },
        "virtualSportsFEC": {
            "title": "Virtual Sports on Betdaily",
            "description": "Virtual Sports on Betdaily",
            "canonical": "https://www.betdaily.club/virtualSports/vfec",
            "alternate": "https://m.betdaily.club/virtualSports/vfec",
        },
        "virtualSportsVTI": {
            "title": "Elevate Your Game with Virtual Tennis Betting ID",
            "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
            "canonical": "https://www.betdaily.club/virtualSports/vti",
            "alternate": "https://m.betdaily.club/virtualSports/vti",
        },
        "virtualSportsWC": {
            "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
            "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
            "canonical": "https://www.betdaily.club/virtualSports/vfwc",
            "alternate": "https://m.betdaily.club/virtualSports/vfwc",
        },
        "premiumSportsbook": {
            "title": "Premium Sportsbook | Online Sports Betting at Betdaily",
            "description": "Go for gold with our Premium Sportsbook at Betdaily! Engage in online sports betting with the best odds and premium features.",
            "canonical": "https://www.betdaily.club/premium-sportsbook",
            "alternate": "https://m.betdaily.club/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
        },
        "welcomeBonus": {
            "title": "400% Welcome Bonus to get you started (2024)💰",
            "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at Betdaily! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
            "canonical": "https://www.betdaily.club/promotions/welcome-bonus",
            "alternate": "https://m.betdaily.club/promotions/welcome-bonus",
            "keywords": "Welcome Bonus"
        },
        "refillBonus": {
            "title": "Get the Betdaily Refill Bonus 💰 and Win Big!",
            "description": "BaaziAdda gives up to a 5% refill bonus on every deposit after the first deposit. Know the BaaziAdda refill bonus with its terms and & conditions.",
            "canonical": "https://www.betdaily.club/promotions/refill-bonus",
            "alternate": "https://m.betdaily.club/promotions/refill-bonus",
            "keywords": "Refill bonus"
        },
        "cashbackBonus": {
            "title": "Cashback Bonus 💰 at Betdaily 2024",
            "description": "Enjoy the thrill of gaming with Cashback Bonus at Betdaily in 2024! 💰 It's your chance to turn every bet into a win!",
            "canonical": "https://www.betdaily.club/promotions/cashback-bonus",
            "alternate": "https://m.betdaily.club/promotions/cashback-bonus",
            "keywords": "Cashback Bonus"
        },
        "referralBonus": {
            "title": "Referral Bonus 💰 at Betdaily 2024",
            "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
            "canonical": "https://www.betdaily.club/promotions/referral-bonus",
            "alternate": "https://m.betdaily.club/promotions/referral-bonus",
            "keywords": "Referral Bonus"
        },
        "termsandconditions": {
            "title": "Terms & Conditions | Betdaily Online Casino",
            "description": "Read the Terms & Conditions at Betdaily Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
            "canonical": "https://www.betdaily.club/about/terms-conditions",
            "alternate": "https://m.betdaily.club/about/terms-conditions",
            "keywords": "Terms & Conditions"
        },
        "responsibleGambling": {
            "title": "Responsible Gambling | Betdaily Online Casino",
            "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
            "canonical": "https://www.betdaily.club/about/responsible-gambling",
            "alternate": "https://m.betdaily.club/about/responsible-gambling",
            "keywords": "Responsible Gambling"
        },
        "privacyPolicy": {
            "title": "Privacy Policy | Betdaily Online Casino",
            "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
            "canonical": "https://www.betdaily.club/privacy-policy",
            "alternate": "https://m.betdaily.club/about/privacy-policy",
            "keywords": "privacy policy"
        },
        "kycPolicy": {
            "title": "KYC Policy | Betdaily Online Casino",
            "description": "Learn about our Know Your Customer policy at Betdaily. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
            "canonical": "https://www.betdaily.club/about/kyc-policy",
            "alternate": "https://m.betdaily.club/about/kyc-policy",
            "keywords": "KYC Policy"
        },
        "exclusionPolicy": {
            "title": "Exclusion Policy | Betdaily Online Casino",
            "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
            "canonical": "https://www.betdaily.club/exclusion-policy",
            "alternate": "https://m.betdaily.club/about/self-exclusion-policy",
            "keywords": "exclusion policy "
        },
        "amlPolicy": {
            "title": "AML Policy | Betdaily Online Casino",
            "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
            "canonical": "https://www.betdaily.club/about/aml-policy",
            "alternate": "https://m.betdaily.club/about/aml-policy",
            "keywords": "AML Policy"
        },
        "aboutus": {
            "title": "About Us | Betdaily Online Casino",
            "description": "Get to know us better! Explore the story behind Betdaily. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
            "canonical": "https://www.betdaily.club/about/about-us",
            "alternate": "https://m.betdaily.club/about/about-us",
            "keywords": "About us, About Betdaily"
        },
        "faq": {
            "title": "Frequently Asked Questions | Betdaily Online Casino",
            "description": "Have questions? Find answers in our Frequently Asked Questions section at Betdaily Online Casino.",
            "canonical": "https://www.betdaily.club/faq",
            "alternate": "https://m.betdaily.club/faq",
        },
        "affiliate": {
            "title": "Affiliate | Betdaily Online Casino",
            "description": "Join the Betdaily Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
            "canonical": "https://www.betdaily.club/affiliate",
            "alternate": "https://m.betdaily.club/affiliate",
        },
        "bingo": {
            "title": "Play Bingo Online - endless fun & real money at Betdaily",
            "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
            "canonical": "https://www.betdaily.club/live-casino/bingo",
            "alternate": "https://m.betdaily.club/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "SPRIBE": {
            "title": "Spribe Casino Games | Get the best Spribe Games on Betdaily",
            "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
            "canonical": "https://www.betdaily.club/instant-games/spribe",
            "alternate": "https://m.betdaily.club/instant-games/spribe",
            "keywords": "spribe casino games"
        },
        "7MOJOS": {
            "title": "7Mojos Games | Explore the top games at the best platform",
            "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
            "canonical": "https://www.betdaily.club/live-casino/7mojos",
            "alternate": "https://m.betdaily.club/live-casino/7mojos",
            "keywords": "7Mojos Games"
        },
        "supernowa": {
            "title": "Supernowa Games | Live Casino Games from Betdaily",
            "description": "Supernowa games at the best! Play live casino games, online card games and slots on the safest gaming platform. Click to explore.",
            "canonical": "https://www.betdaily.club/live-casino/supernowa",
            "alternate": "https://m.betdaily.club/live-casino/supernowa",
            "keywords": "supernowa games"
        },
        "pragmatic": {
            "title": "Pragmatic Casino Games online| Play on Betdaily",
            "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
            "canonical": "https://www.betdaily.club/slots/pragmatic-play",
            "alternate": "https://m.betdaily.club/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games"
        },
        "superspade": {
            "title": "Super Spade Games | Online Casino on Betdaily",
            "description": "Super Spade Games are online casino's delight. Play the best games with just one sign-in. Simple process, simpler registration. Join now!",
            "canonical": "https://www.betdaily.club/live-casino/super-spade",
            "alternate": "https://m.betdaily.club/live-casino/super-spade",
            "keywords": "super spade games"
        },
        "tvbet": {
            "title": "TVBet Games | Live Betting & Gaming on Betdaily",
            "description": "TV Bet Games offers a wide variety of online gaming options. Explore all the top-rated games in one platform through one sign-in. Join now!",
            "canonical": "https://www.betdaily.club/live-casino/tvbet",
            "alternate": "https://m.betdaily.club/live-casino/tvbet",
            "keywords": "tvbet games"
        },
        "netent": {
            "title": "NetEnt Games | Slots & Casino Games on Betdaily",
            "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
            "canonical": "https://www.betdaily.club/slots/netent",
            "alternate": "https://m.betdaily.club/slots/netent",
            "keywords": "netent games"
        },
        "betgames": {
            "title": "BetGames | Live Dealer of Online Games on Betdaily",
            "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
            "canonical": "https://www.betdaily.club/live-casino/betgames",
            "alternate": "https://m.betdaily.club/live-casino/betgames",
            "keywords": "betgames"
        },
        "sportsbook": {
            "title": "Sportsbook Online | Sports Betting at Betdaily",
            "description": "Elevate your sports betting experience with Sportsbook Online at Betdaily! Enjoy a seamless and thrilling betting experience with the best odds.",
            "canonical": "https://www.betdaily.club/sportsbook",
            "alternate": "https://m.betdaily.club/sportsbook",
            "keywords": "BTI Sports, BTI Sports Betting"
        },
        "appDownload": {
            "title": "Betdaily APK  App - Download & Win!",
            "description": "Check out the main reasons why you need to download the Betdaily app on your device. Learn more about the benefits of our app, bonuses and other offers",
            "canonical": "https://www.betdaily.club/app-download",
            "alternate": "https://m.betdaily.club/app-download",
            "keywords": "Betdaily App Download, Betdaily Android App"
        },
        "login": {
            "title": "Login Betdaily | Play Online Casino Games",
            "description": "Betdaily Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
            "canonical": "https://www.betdaily.club/login",
            "alternate": "https://m.betdaily.club/login",
            "keywords": "Betdaily Login"
        },
        "signup": {
            "title": "Sign Up Now - Betdaily & explore Online Casino Games",
            "description": "Signup at the Betdaily official website today for access to premium Sports & Casino Games",
            "canonical": "https://www.betdaily.club/signup",
            "alternate": "https://m.betdaily.club/signup",
            "keywords": "Betdaily User Registration"
        },
        "instantGames": {
            "title": "Instant Games | Enjoy the Best Instant Casino Games Now!",
            "description": " Experience the thrill of instant games and win big in seconds! Play a wide variety of exciting casino games for instant entertainment & instant rewards. Join & start winning instantly!.",
            "canonical": "https://www.betdaily.club/instant-games",
            "alternate": "https://m.betdaily.club/instant-games",
            "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "dailyDepositBonus": {
            "title": "Betdaily Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
            "description": "Betdaily Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Betdaily.",
            "canonical": "https://www.betdaily.club/daily-deposit-bonus",
            "alternate": "https://m.betdaily.club/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on Betdaily provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.betdaily.club/asia-cup-bonanza",
            "alternate": "https://m.betdaily.club/asia-cup-bonanza"
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at Yolo247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.betdaily.club/promotions/big-bash",
            "alternate": "https://m.betdaily.club/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },

        "lucky-fruits-lines": {
            "canonical": "https://www.betdaily.club/slots/lucky-fruits-lines",
            "alternate": "https://m.betdaily.club/slots/lucky-fruits-lines"
        },
        "andar-bahar": {
            "canonical": "https://www.betdaily.club/live-casino/andar-bahar",
            "alternate": "https://m.betdaily.club/live-casino/andar-bahar"
        },
        "dream-catcher": {
            "canonical": "https://www.betdaily.club/live-casino/evolution/dream-catcher",
            "alternate": "https://m.betdaily.club/live-casino/evolution/dream-catcher"
        },
        "hi-lo": {
            "canonical": "https://www.betdaily.club/instant-games/spribe/hi-lo",
            "alternate": "https://m.betdaily.club/instant-games/spribe/hi-lo"
        },
        "rocketon": {
            "canonical": "https://www.betdaily.club/instant-games/galaxsys/rocketon",
            "alternate": "https://m.betdaily.club/instant-games/galaxsys/rocketon"
        },
        "burning-ice": {
            "canonical": "https://www.betdaily.club/instant-games/burning-ice",
            "alternate": "https://m.betdaily.club/instant-games/burning-ice"
        },
        "cappa-docia": {
            "canonical": "https://www.betdaily.club/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.betdaily.club/instant-games/smartsoft/cappadocia"
        },
        "genies-bonanza": {
            "canonical": "https://www.betdaily.club/instant-games/genies-bonanza",
            "alternate": "https://m.betdaily.club/instant-games/genies-bonanza"
        },
        "goal": {
            "canonical": "https://www.betdaily.club/instant-games/spribe/goal",
            "alternate": "https://m.betdaily.club/instant-games/spribe/goal"
        },
        "hunter-x": {
            "canonical": "https://www.betdaily.club/instant-games/hunter-x",
            "alternate": "https://m.betdaily.club/instant-games/hunter-x"
        },
        "jungles": {
            "canonical": "https://www.betdaily.club/instant-games/jungles",
            "alternate": "https://m.betdaily.club/instant-games/jungles"
        },
        "magic-garden": {
            "canonical": "https://www.betdaily.club/instant-games/magic-garden",
            "alternate": "https://m.betdaily.club/instant-games/magic-garden"
        },
        "multi-hot-ways": {
            "canonical": "https://www.betdaily.club/instant-games/multi-hot-ways",
            "alternate": "https://m.betdaily.club/instant-games/multi-hot-ways"
        },
        "paper-plane": {
            "canonical": "https://www.betdaily.club/instant-games/stp/paper-plane",
            "alternate": "https://m.betdaily.club/instant-games/stp/paper-plane"
        },
        "stockmarket": {
            "canonical": "https://www.betdaily.club/instant-games/evolution/stock-market",
            "alternate": "https://m.betdaily.club/instant-games/evolution/stock-market"
        },
        "rulesRgulations": {
            "canonical": "https://www.betdaily.club/rules-regulations",
            "alternate": "https://m.betdaily.club/about/rules-regulations"
        },
    },
    "39": {
        "default": {
            "title": "FOMO7 Official India | Online Casino & Sports Betting Site",
            "description": "FOMO7 is the best online betting site in India, offering casino games & sports betting for real money. Join the biggest real money gambling site & win big!",
            "canonical": "https://www.fomo7.com",
            "alternate": "https://m.fomo7.com",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "onlineCasinoGames": {
            "title": "Live Casino on FOMO7 | Play Real Money Online Casino Games",
            "description": "Play the best live casino games for real money on FOMO7. Explore live dealer online casino games with mega casino bonuses & rewards. Register now!  ",
            "canonical": "https://www.fomo7.com/live-casino",
            "alternate": "https://m.fomo7.com/live-casino",
            "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
        },
        "live-baccarat": {
            "title": "Play Baccarat Online for FREE or Real Money | FOMO7",
            "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
            "canonical": "https://www.fomo7.com/live-casino/live-baccarat",
            "alternate": "https://m.fomo7.com/live-casino/live-baccarat",
            "keywords": "live-baccarat, Live-Baccarat, Live Baccarat, livebaccarat, Livebaccarat, Baccarat Online Game, Baccarat Live, Baccarat"
        },
        "live-blackjack": {
            "title": "Live Blackjack | Play Online for Real Money on FOMO7 India",
            "description": "Step into the live casino world & play Blackjack online on FOMO7. Play online, enjoy higher payouts, and win real money in the Blackjack online game.",
            "canonical": "https://www.fomo7.com/live-casino/live-blackjack",
            "alternate": "https://m.fomo7.com/live-casino/live-blackjack",
            "keywords": "live-blackjack, blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
        },
        "live-roulette": {
            "title": "Live Roulette | Play Online for Real Money on FOMO7 India",
            "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
            "canonical": "https://www.fomo7.com/live-casino/live-roulette",
            "alternate": "https://m.fomo7.com/live-casino/live-roulette",
            "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "lightningroulette": {
            "title": "Play Lightning Roulette Online on FOMO7 | Best Wheel Game",
            "description": "Lightning Roulette live casino game on FOMO7 gives higher multipliers of up to 500x every round. Play Lightning Roulette online for real money.",
            "canonical": "https://www.fomo7.com/live-casino/lightning-roulette",
            "alternate": "https://m.fomo7.com/live-casino/lightning-roulette",
            "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "dragonTiger": {
            "title": "Dragon Tiger | Play Online for Real Money on FOMO7 India",
            "description": "Elevate your gaming experience with FOMO7 Dragon Tiger game. Play Dragon Tiger online with faster withdrawal & higher payout.",
            "canonical": "https://www.fomo7.com/live-casino/dragon-tiger",
            "alternate": "https://m.fomo7.com/live-casino/dragon-tiger",
            "keywords": "fomo7 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
        },
        "baccarat": {
            "title": "Live Baccarat | Play Baccarat Online for Real Money on FOMO7",
            "description": "Experience the excitement of Baccarat casino game online. Play Baccarat for real money on FOMO7 and enjoy massive rewards daily.",
            "canonical": "https://www.fomo7.com/live-casino/live-baccarat",
            "alternate": "https://m.fomo7.com/live-casino/live-baccarat",
            "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
        },
        "teenpattiOnlinePlay": {
            "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
            "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
            "canonical": "https://www.fomo7.com/teenpatti-online-play",
            "alternate": "https://m.fomo7.com/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
        },
        "sportsBetting": {
            "title": "Best Sports Betting Exchange in India | FOMO7 Exchange",
            "description": "Bet on all the top sports on the best sports betting exchange in India. Get high odds on FOMO7 exchange with Back & Lay options and Fancy bets.",
            "canonical": "https://www.fomo7.com/betting-exchange",
            "alternate": "https://m.fomo7.com/betting-exchange?id=0",
            "keywords": "betting exchange"
        },
        "onlineCricketSattaBazar": {
            "title": "Online Cricket Betting on FOMO7 | Best Cricket Satta Bazar",
            "description": "Bet on cricket matches on FOMO7 online cricket satta bazar. Get the best odds for all cricket matches & experience the top online cricket betting in India.",
            "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar,",
            "canonical": "https://www.fomo7.com/betting-exchange/online-cricket-betting",
            "alternate": "https://m.fomo7.com/betting-exchange/online-cricket-betting",
        },
        "footballBetting": {
            "title": "Bet on Football in India | Maximize your winnings on FOMO7",
            "description": "Bet on football matches on FOMO7. Get high odds for football matches with the best online football betting experience in India.",
            "canonical": "https://www.fomo7.com/betting-exchange/football-betting",
            "alternate": "https://m.fomo7.com/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
        },
        "tennisBetting": {
            "title": "Online Tennis Betting | Maximize your winnings on FOMO7",
            "description": "Discover the ultimate tennis betting experience in India. Bet on tennis matches online on FOMO7 with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/betting-exchange/tennis-betting",
            "alternate": "https://m.fomo7.com/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
        },
        "volleyballBetting": {
            "title": "Live Volleyball Betting | Maximize your winnings on FOMO7",
            "description": "Bet on volleyball matches in India with our trusted platform. Exciting odds & secure transactions on FOMO7 online volleyball betting!",
            "canonical": "https://www.fomo7.com/betting-exchange/volleyball-betting",
            "alternate": "https://m.fomo7.com/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
        },
        "promotions": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.fomo7.com/promotions",
            "alternate": "https://m.fomo7.com/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "specialMarket": {
            "title": "Special Markets on Fomo7",
            "description": "Special Markets on Fomo7",
            "canonical": "https://www.fomo7.com/specialMarket",
            "alternate": "https://m.fomo7.com/specialMarket",
        },
        "virtualSports": {
            "title": "Virtual Cricket In-Play | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Cricket In-Play with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vci",
            "alternate": "https://m.fomo7.com/virtualSports/vci",
        },
        "virtualSportsBL": {
            "title": "Virtual Basketball League | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Basketball League with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vbl",
            "alternate": "https://m.fomo7.com/virtualSports/vbl",
        },
        "virtualSportsFB": {
            "title": "Virtual Football Bundesliga | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Football Bundesliga with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vfb",
            "alternate": "https://m.fomo7.com/virtualSports/vfb",
        },
        "virtualSportsFEL": {
            "title": "Virtual Football English League | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Football English League with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vfel",
            "alternate": "https://m.fomo7.com/virtualSports/vfel",
        },
        "virtualSportsFEC": {
            "title": "Virtual Football Euro Cup | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Football Euro Cup with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vfec",
            "alternate": "https://m.fomo7.com/virtualSports/vfec",
        },
        "virtualSportsVTI": {
            "title": "Virtual Tennis In-Play | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Tennis In-Play with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vti",
            "alternate": "https://m.fomo7.com/virtualSports/vti",
        },
        "virtualSportsWC": {
            "title": "Virtual Football World Cup | Bet on FOMO7 India",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore Virtual Football World Cup with high odds & fast payouts.",
            "canonical": "https://www.fomo7.com/virtualSports/vfwc",
            "alternate": "https://m.fomo7.com/virtualSports/vfwc",
        },
        "slotGames": {
            "title": "Best Slot Games in India | Play now on FOMO7!",
            "description": "Explore a variety of casino slot games on FOMO7. Play the best slots online & win real money instantly. Hit the jackpot everyday on FOMO7 slots.",
            "canonical": "https://www.fomo7.com/slots",
            "alternate": "https://m.fomo7.com/slots",
            "keywords": "slot games for real money, casino slot games"
        },
        "aviator": {
            "title": "Play Aviator | Instant game on FOMO7 for Instant Wins",
            "description": "Fly high in the Aviator game online! Play Aviator on FOMO7 for real money with higher multiplier every round. Cash out instantly to your wallet.",
            "canonical": "https://www.fomo7.com/instant-games/spribe/aviator",
            "alternate": "https://m.fomo7.com/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game "
        },
        "cashshow": {
            "title": "Play Cash Show | Instant games on FOMO7 for Instant Wins",
            "description": "Play Cash show casino game from Galaxsys on FOMO7! Get real money cashow game with the mix of fashion & thrill.",
            "canonical": "https://www.fomo7.com/instant-games/galaxsys/cash-show",
            "alternate": "https://m.fomo7.com/instant-games/galaxsys/cash-show",
            "keywords": "Cash Show game, Cash Show online game "
        },
        "jetx": {
            "title": "Play Jetx | Instant games on FOMO7 for Instant Wins",
            "description": "Play Jetx game online on FOMO7. Get higher multiplier every round, excited features & faster withdrawals instantly to your wallet. Explore Jetx casino game.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/jetx",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/jetx",
            "keywords": "Jet X game, Jet X online game "
        },
        "rockpaperscissors": {
            "title": "Rock Paper Scissors | Instant games on FOMO7 for Instant Wins",
            "description": "Play Rock Paper Scissors online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/stp/rock-paper-scissors",
            "alternate": "https://m.fomo7.com/instant-games/stp/rock-paper-scissors",
            "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
        },
        "plinkox": {
            "title": "Play PlinkoX | Instant games on FOMO7 for Instant Wins",
            "description": "Play PlinkoX online on FOMO7 & win big! Get the highest multiplier for PlinkoX Casino game in India with faster withdrawal.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/plinkox",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/plinkox",
            "keywords": "Plinkox game, Plinkox online game "
        },
        "footballx": {
            "title": "Play Football X | Instant games on FOMO7 for Instant Wins",
            "description": "Play Football X online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/footballx",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/footballx",
            "keywords": "Football X game, Football X online game "
        },
        "balloon": {
            "title": "Play Balloon | Instant games on FOMO7 for Instant Wins",
            "description": "Play Balloon online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/balloon",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/balloon",
            "keywords": "Balloon game, Balloon online game "
        },
        "mines": {
            "title": "Mines Casino Games on FOMO7 | Best Mine Game Online",
            "description": "Discover the excitement of Mines Casino games on FOMO7! Play real money mines game, win big & feel the thrill with every move.",
            "canonical": "https://www.fomo7.com/instant-games/spribe/mines",
            "alternate": "https://m.fomo7.com/instant-games/spribe/mines",
            "keywords": "Mines game, Online mines game"
        },
        "macludo": {
            "title": "Macludo Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Macludo games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/macludo",
            "alternate": "https://m.fomo7.com/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online"
        },
        "crazytime": {
            "title": "Play Crazy Time | India's Top-Rated Game on FOMO7",
            "description": "Play Crazy Time live game on FOMO7 for higher payouts every round. Get live crazy time stats, scores & more. Join & win real money.",
            "canonical": "https://www.fomo7.com/live-casino/evolution/crazy-time",
            "alternate": "https://m.fomo7.com/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online"
        },
        "welcomeBonus": {
            "title": "Best Casino & Sports Betting Welcome Bonus in India | FOMO7",
            "description": "FOMO7 offers a massive 700% welcome bonus for casino & sports betting. Get the best online betting sign-up bonus. Join & get FOMO7 welcome bonus.",
            "canonical": "https://www.fomo7.com/promotions/welcome-bonus",
            "alternate": "https://m.fomo7.com/promotions/welcome-bonus",
            "keywords": "Joining Bonus"
        },
        "premiumSportsbook": {
            "title": "Premium Sportsbook in India | Unbelievable features on FOMO7",
            "description": "Bet on sports through FOMO7 Premium Sportsbook. Get premium options, features & payouts, available for all matches, leagues & sports.",
            "canonical": "https://www.fomo7.com/premium-sportsbook",
            "alternate": "https://m.fomo7.com/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
        },
        "refillBonus": {
            "title": "Redeposit Bonus for Casino & Sports Betting in India | FOMO7",
            "description": "Get 5% redeposit bonus instantly on FOMO7! Play top games & bet on sports with extra rewards for every deposit. Get the best redeposit bonus for online casinos now!",
            "canonical": "https://www.fomo7.com/promotions/refill-bonus",
            "alternate": "https://m.fomo7.com/promotions/refill-bonus",
            "keywords": "Redeposit bonus"
        },
        "cashbackBonus": {
            "title": "Lossback Bonus on FOMO7 | Get 5% Cashback for your losses",
            "description": "FOMO7 offers a grand 5% lossback bonus without any RO conditions for both casino games & sports betting in India.",
            "canonical": "https://www.fomo7.com/promotions/cashback-bonus",
            "alternate": "https://m.fomo7.com/promotions/cashback-bonus",
            "keywords": "Cashback Bonus"
        },
        "referralBonus": {
            "title": "Refer & Get 21% | Best Online Casino Referral Bonus on FOMO7",
            "description": "A mega 21% referral bonus awaits you on FOMO7! Refer, earn & play the best casino games and bet on your favourite sports. Every referral gets a reward.",
            "canonical": "https://www.fomo7.com/promotions/referral-bonus",
            "alternate": "https://m.fomo7.com/promotions/referral-bonus",
            "keywords": "Referral Bonus"
        },
        "termsandconditions": {
            "title": "Terms & Conditions | FOMO7 Official Site India",
            "description": "Find the complete terms & conditions for playing on FOMO7. Get the restrictions, obligations and allowed practices.",
            "canonical": "https://www.fomo7.com/terms-conditions",
            "alternate": "https://m.fomo7.com/about/terms-conditions",
            "keywords": "Terms & Conditions"
        },
        "responsibleGambling": {
            "title": "Responsible Gaming | FOMO7 Official Site India",
            "description": "Get the requisite details of Responsible Gaming on FOMO7. It is applicable to both casino games and sports prediction.",
            "canonical": "https://www.fomo7.com/responsible-gambling",
            "alternate": "https://m.fomo7.com/about/responsible-gambling",
            "keywords": "Responsible Gambling"
        },
        "privacyPolicy": {
            "title": "Privacy Policy | FOMO7 Official Site India",
            "description": "Find all the information regarding the Privacy Policy of FOMO7. From data storage to security details, get everything related to privacy maintenance.",
            "canonical": "https://www.fomo7.com/privacy-policy",
            "alternate": "https://m.fomo7.com/about/privacy-policy",
            "keywords": "privacy policy"
        },
        "kycPolicy": {
            "title": "KYC Policy of FOMO7 | Find the KYC Requirements & Process",
            "description": "Find the detailed KYC policy of FOMO7. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
            "canonical": "https://www.fomo7.com/kyc-policy",
            "alternate": "https://m.fomo7.com/about/kyc-policy",
            "keywords": "KYC Policy"
        },
        "exclusionPolicy": {
            "title": "Self-Exclusion Policy | FOMO7 Official site India",
            "description": "Find what is the Self-Exclusion Policy of FOMO7. Get detailed information of the entire process of self-exclusion.",
            "canonical": "https://www.fomo7.com/exclusion-policy",
            "alternate": "https://m.fomo7.com/about/self-exclusion-policy",
            "keywords": "exclusion policy "
        },
        "amlPolicy": {
            "title": "Anti-Money Laundering Policy | FOMO7 Official site India",
            "description": "Find the complete Anti-Money Laundering policy of FOMO7. Get the details of compliance procedures.",
            "canonical": "https://www.fomo7.com/aml-policy",
            "alternate": "https://m.fomo7.com/about/aml-policy",
            "keywords": "AML Policy"
        },
        "aboutus": {
            "title": "About FOMO7 | Your Online Betting Destination",
            "description": "Learn about FOMO7, a leading online betting site in India that offers various casino games and sports betting options. Find more about us.",
            "canonical": "https://www.fomo7.com/about-us",
            "alternate": "https://m.fomo7.com/about/about-us",
            "keywords": "About us, About fomo7"
        },
        "SPRIBE": {
            "title": "Spribe Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Spirbe games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/instant-games/spribe",
            "alternate": "https://m.fomo7.com/instant-games/spribe",
            "keywords": "spribe casino games"
        },
        "aviatrix": {
            "title": "Play Aviatrix | Instant games on FOMO7 for Instant Wins",
            "description": "Play Aviatrix Online game for real cash on FOMO7. Fly more, win more. Get high multipliers for Aviatrix casino crash game.",
            "canonical": "https://www.fomo7.com/instant-games/aviatrix",
            "alternate": "https://m.fomo7.com/instant-games/aviatrix",
            "keywords": "Aviatrix casino games"
        },
        "7MOJOS": {
            "title": "7Mojos Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play 7Mojos games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/7mojos",
            "alternate": "https://m.fomo7.com/live-casino/7mojos",
            "keywords": "7Mojos Games"
        },
        "supernowa": {
            "title": "Supernowa Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Supernowa games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/supernowa",
            "alternate": "https://m.fomo7.com/live-casino/supernowa",
            "keywords": "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
        },
        "pragmatic": {
            "title": "Pragmatic Slot Games | Play your favourites on FOMO7 Now!",
            "description": "Play Pragmatic games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/slots/pragmatic-play",
            "alternate": "https://m.fomo7.com/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games"
        },
        "superspade": {
            "title": "Super Spade Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Super Spade games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/super-spade",
            "alternate": "https://m.fomo7.com/live-casino/super-spade",
            "keywords": "super spade games,play super spade casino games, super spade casino"
        },
        "tvbet": {
            "title": "TV Bet Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play TV Bet games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/tvbet",
            "alternate": "https://m.fomo7.com/live-casino/tvbet",
            "keywords": "TVBET casino games, fomo7 TVBET games"
        },
        "netent": {
            "title": "Netent Slot Games | Play your favourites on FOMO7 Now!",
            "description": "Play Netent games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/slots/netent",
            "alternate": "https://m.fomo7.com/slots/netent",
            "keywords": "netent games"
        },
        "betgames": {
            "title": "BetGames On fomo7 | Play BetGames & Win Big",
            "description": "BetGames is one of the modern-day game providers in fomo7. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on fomo7 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
            "canonical": "https://fomo7.com/live-casino/betgames",
            "alternate": "https://m.fomo7.com/live-casino/betgames",
            "keywords": "betgames,fomo7 BetGames"
        },
        "sportsbook": {
            "title": "Online Sportsbook in India | Get the Highest Odds on FOMO7",
            "description": "Bet on sports through FOMO7 online sportsbook. Explore betting options for all sports with high odds, fast payouts and for all matches, leagues & tournaments.",
            "canonical": "https://www.fomo7.com/sportsbook",
            "alternate": "https://m.fomo7.com/sportsbook",
            "keywords": "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
        },
        "appDownload": {
            "title": "Download the FOMO7 App | Best Casino & Sports Betting Apk",
            "description": "Download FOMO7 App for the best casino & sports betting experience in India. Play real-money gambling on FOMO7 apk. Get the best online betting app now!",
            "canonical": "https://www.fomo7.com/app-download",
            "alternate": "https://m.fomo7.com/app-download",
            "keywords": "fomo7 App Download, fomo7 Android App"
        },
        "signup": {
            "title": "Register on FOMO7 | Best online casino & sports betting site",
            "description": "FOMO7 registration process is faster & smoother. Get the best offers & bonuses on FOMO7. Explore casino & sports betting options with one registration.",
            "canonical": "https://www.fomo7.com/signup",
            "alternate": "https://m.fomo7.com/signup",
            "keywords": "fomo7 User Registration"
        },
        "faq": {
            "title": "Your Questions Answered | FOMO7 FAQs",
            "description": "Go through a list of frequently asked questions on FOMO7 about registration, deposit, withdrawal & other important aspects of online gambling.",
            "canonical": "https://www.fomo7.com/faq",
            "alternate": "https://m.fomo7.com/faq",
        },
        "affiliate": {
            "title": "FOMO7 Affiliate Program | Coming Soon!",
            "description": "Exciting opportunities are on the way with the FOMO7 Affiliate Program. Stay tuned to earn commissions by promoting our top casino and sports betting services. Coming soon!",
            "canonical": "https://www.fomo7.com/affiliate",
            "alternate": "https://m.fomo7.com/affiliate",
        },
        "loyalty": {
            "title": "FOMO7 Loyalty Program | Unlock & Explore Your Rewards",
            "description": "Check out your rewards with the FOMO7 Loyalty Program. Discover exclusive bonuses and benefits we offer just for you. Start earning today!",
            "canonical": "https://www.fomo7.com/loyalty",
            "alternate": "https://m.fomo7.com/loyalty",
        },
        "login": {
            "title": "Log in to FOMO7 | Best online casino & sports betting site",
            "description": "Use your existing FOMO7 credentials to log in now. Explore top casino & sports betting options. Get a mega bonus for every deposit.",
            "canonical": "https://www.fomo7.com/login",
            "alternate": "https://m.fomo7.com/login",
            "keywords": "fomo7 Login"
        },
        "instantGames": {
            "title": "Instant Games on FOMO7 | Play the Best Instant Games Online",
            "description": "Explore the FOMO7 Instant Games & play for real money. Get the best instant games in online casinos in India. Instant rewards await you. Join now!",
            "canonical": "https://www.fomo7.com/instant-games",
            "alternate": "https://m.fomo7.com/instant-games",
            "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "majorLeagueCricketBetting": {
            "title": "MLC Betting in India | Major League Cricket Betting Online",
            "description": "MLC Betting is available on fomo7! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
            "canonical": "https://www.fomo7.com/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.fomo7.com/sports/tournaments/major-league-cricket-betting",
            "keywords": "Major League Cricket Betting Online, MLC Betting in India"
        },
        "dailyDepositBonus": {
            "title": "Refill bonus | Best Casino Refill Bonus on fomo7",
            "description": "fomo7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fomo7.",
            "canonical": "https://www.fomo7.com/daily-deposit-bonus",
            "alternate": "https://m.fomo7.com/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "popular-games": {
            "title": "Top Casino Games at Fomo7 | Play Now & Win Real Cash!",
            "description": "Explore the top casino games in India on FOMO7. Play the best online casino games for real money, with higher chances of winning & withdraw instantly.",
            "canonical": "https://www.fomo7.com/popular-games",
            "alternate": "https://m.fomo7.com/popular-games",
            "keywords": "Best Casino Games Popular Casino Games, online casino real money"
        },
        "evolution": {
            "title": "Evolution Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Evolution Gaming games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/evolution",
            "alternate": "https://m.fomo7.com/live-casino/evolution",
            "keywords": "Evolution Gaming, Evolution Gaming Casino"
        },

        "spribe": {
            "title": "Spribe Games | Play The Best fomo7 Spribe Games",
            "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling fomo7 Spribe Games.",
            "canonical": "https://www.fomo7.com/instant-games/spribe",
            "alternate": "https://m.fomo7.com/instant-games/spribe",
            "keywords": "Spribe Games,fomo7 Spribe games,fomo7 Spribe games,Spribe live casino games"
        },
        "cricketx": {
            "title": "Play Cricket X | Instant games on FOMO7 for Instant Wins",
            "description": "Play CricketX Casino game on FOMO7. Immerse yourself in the ultimate gaming experience! Get the thrill of cricket & fun of casino with CricketX game.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/cricketx",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/cricketx",
            "keywords": "Cricketx Games,fomo7 Cricketx games,fomo7 Cricketx games,Cricketx casino games"
        },
        "ezugi": {
            "title": "Ezugi Live Casino | Play fomo7 Ezugi Games",
            "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on fomo7. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
            "canonical": "https://fomo7.com/live-casino/ezugi",
            "alternate": "https://m.fomo7.com/live-casino/ezugi",
            "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, fomo7 Ezugi Live Casino"
        },
        "sexygaming": {
            "title": "Sexy Gaming Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Saxy Gaming games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/sexy-gaming",
            "alternate": "https://m.fomo7.com/live-casino/sexy-gaming",
            "keywords": "sexy gaming casino,sexy gaming casino games, fomo7 sexy gaming casino games, fomo7 sexy gaming live casino game"
        },
        "monopolylive": {
            "title": "Play Monopoly Game Online & Win Real Money on FOMO7 Casino",
            "description": "Play Monopoly Live game on FOMO7 casino. Get higher payouts every round & win real money to your account directly.",
            "canonical": "https://www.fomo7.com/live-casino/monopoly-live",
            "alternate": "https://m.fomo7.com/live-casino/monopoly-live",
            "keywords": "MONOPOLY LIVE"
        },
        "funkytime": {
            "title": "Play Funky Time Casino Game for Real Money on FOMO7",
            "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
            "canonical": "https://www.fomo7.com/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.com/live-casino/evolution/funky-time",
            "keywords": "funkytime"
        },
        "lightningdice": {
            "title": "Play Lightning Dice Casino Game for Real Money on FOMO7",
            "description": "Light up your gaming experience with Lightning Dice casino game on FOMO7. Get lightning multipliers & bigger payouts on online lightning dice game.",
            "canonical": "https://www.fomo7.com/live-casino/evolution/lightning-dice",
            "alternate": "https://m.fomo7.com/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice"
        },
        "macaw": {
            "title": "Macaw Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Macaw games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/macaw",
            "alternate": "https://m.fomo7.com/live-casino/macaw",
            "keywords": "macaw games, macaw casino online"
        },
        "ag": {
            "title": "AG Casino Games | Play your favourites on FOMO7 Now!",
            "description": "Play Amazing Gaming games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/live-casino/ag",
            "alternate": "https://m.fomo7.com/live-casino/ag",
            "keywords": "Amazing Gaming"
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on fomo7 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.fomo7.com/asia-cup-bonanza",
            "alternate": "https://m.fomo7.com/asia-cup-bonanza"
        },

        "bingo": {
            "title": "Play Bingo | Win Real Cash on FOMO7's Top-Rated Game",
            "description": "Your favorite game Bingo is now on FOMO7 Official. Enjoy seamless gameplay and win real cash in India. Play now!",
            "canonical": "https://www.fomo7.com/live-casino/bingo",
            "alternate": "https://m.fomo7.com/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "Funkytime": {
            "title": "Play Funky Time Casino Online Game at fomo7",
            "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
            "canonical": "https://www.fomo7.com/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.com/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "evo": {
            "title": "Evo Casino Game on fomo7 | Get Upto 400%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
            "canonical": "https://www.fomo7.com/live-casino/evo",
            "alternate": "https://m.fomo7.com/live-casino/evo",
            "keywords": "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
        },
        "nlc": {
            "title": "No Limit City Slot Games | Play your favourites on FOMO7 Now!",
            "description": "Play No Limit City games on FOMO7 India. Spin slots, smash instant games, and join live dealers. Win real money with your favorite game providers!",
            "canonical": "https://www.fomo7.com/slots/no-limit-city",
            "alternate": "https://m.fomo7.com/slots/no-limit-city",
            "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
        },
        "Funky Time": {
            "title": "Play Funky Time Casino Game for Real Money on FOMO7",
            "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
            "canonical": "https://www.fomo7.com/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.com/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "First-Person-Blackjack": {
            "title": "Play Blackjack Live Casino Games | Free or Real Money",
            "description": "Play blackjack online on the most safest online casino. Register now at fomo7 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on fomo7, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
            "canonical": "https://www.fomo7.com/live-casino/first%20person%20blackjack",
            "alternate": "https://m.fomo7.com/live-casino/first%20person%20blackjack",
            "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
        },
        "teenpatti2020-2020": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti 2020 is a popular casino card game on fomo7. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.fomo7.com/live-casino/teenpatti%2020%2020",
            "alternate": "https://m.fomo7.com/live-casino/teenpatti%2020%2020",
            "keywords": "Teenpatti 2020"
        },
        "teenpatti": {
            "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
            "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
            "canonical": "https://www.fomo7.com/live-casino/teenpatti",
            "alternate": "https://m.fomo7.com/live-casino/teenpatti",
            "keywords": "Teenpatti"
        },
        //member dashboard pages
        "memberDashboard": {
            "canonical": "https://www.fomo7.com/member/profile",
            "alternate": "https://m.fomo7.com/member/profile"
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.fomo7.com/member/profile/accountinfo",
            "alternate": "https://m.fomo7.com/member/profile/accountinfo"
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.fomo7.com/member/profile/password",
            "alternate": "https://m.fomo7.com/member/profile/password"
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.fomo7.com/member/profile/bankdetails",
            "alternate": "https://m.fomo7.com/member/profile/bankdetails"
        },
        "memberProfileKYC": {
            "canonical": "https://www.fomo7.com/member/profile/kycpage",
            "alternate": "https://m.fomo7.com/member/profile/kycpage"
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.fomo7.com/member/finance/deposit",
            "alternate": "https://m.fomo7.com/member/finance/deposit"
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.fomo7.com/member/finance/withdrawal",
            "alternate": "https://m.fomo7.com/member/finance/withdrawal"
        },
        "memberFinanceHistory": {
            "canonical": "https://www.fomo7.com/member/finance/history",
            "alternate": "https://m.fomo7.com/member/finance/history"
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.fomo7.com/member/finance/transfer",
            "alternate": "https://m.fomo7.com/member/finance/transfer"
        },
        "memberMyBets": {
            "canonical": "https://www.fomo7.com/member/betDetails/mybets",
            "alternate": "https://m.fomo7.com/member/betDetails/mybets"
        },
        "memberP&L": {
            "canonical": "https://www.fomo7.com/member/betDetails/profitandloss",
            "alternate": "https://m.fomo7.com/member/betDetails/profitandloss"
        },
        "memberCasinoStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/casinostatement",
            "alternate": "https://m.fomo7.com/member/betDetails/casinostatement"
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/sportbookstatement",
            "alternate": "https://m.fomo7.com/member/betDetails/sportbookstatement"
        },
        "memberSTPStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/splitThePotStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/splitThePotStatement"
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/galaxyStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/galaxyStatement"
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/aviatrixStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/aviatrixStatement"
        },
        "memberEvoPlayStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/evoplayStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/evoplayStatement"
        },
        "memberJiliStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/jiliStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/jiliStatement"
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/turbogamesStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/turbogamesStatement"
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/indiaLotteryStatement"
        },
        "memberAccountStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/accountStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/accountStatement"
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/virtualSportsStatement"
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.fomo7.com/member/betDetails/turnoverStatement",
            "alternate": "https://m.fomo7.com/member/betDetails/turnoverStatement"
        },
        "memberPrormotions": {
            "canonical": "https://www.fomo7.com/member/promotions",
            "alternate": "https://m.fomo7.com/member/promotions"
        },
        "memberMessages": {
            "canonical": "https://www.fomo7.com/member/message",
            "alternate": "https://m.fomo7.com/member/message"
        },
        "ninjacrash": {
            "title": "Play Ninja Crash | Instant games on FOMO7 for Instant Wins",
            "description": "Ready to showcase your ninja skills? Play the Ninja Crash casino game online from Galaxsys on FOMO7 for real money.",
            "canonical": "https://www.fomo7.com/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.fomo7.com/instant-games/galaxsys/ninja-crash",
            "keywords": "NinjaCrash game, Online NinjaCrash game"
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.fomo7.com/promotions/big-bash",
            "alternate": "https://m.fomo7.com/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },

        "lucky-fruits-lines": {
            "title": "Play Lucky Fruit Lines | Top Slots Games Await at FOMO7!",
            "description": "Spin the reels with Lucky Fruit Lines at FOMO7 India. Explore top slots games and enjoy big wins and exciting features. Start playing today!",
            "canonical": "https://www.fomo7.com/slots/lucky-fruits-lines",
            "alternate": "https://m.fomo7.com/slots/lucky-fruits-lines"
        },
        "andar-bahar": {
            "title": "Play Andar Bahar | India's Top-Rated Game on FOMO7",
            "description": "Your favorite game Andar Bahar is now on FOMO7 Official. Enjoy seamless gameplay and win real cash in India. Play now!",
            "canonical": "https://www.fomo7.com/live-casino/andar-bahar",
            "alternate": "https://m.fomo7.com/live-casino/andar-bahar"
        },
        "dream-catcher": {
            "title": "Play Dream Catcher | India's Top-Rated Game on FOMO7",
            "description": "Your favorite game Crazy Time is now on FOMO7 Official. Enjoy seamless gameplay and win real cash in India. Play now!",
            "canonical": "https://www.fomo7.com/live-casino/evolution/dream-catcher",
            "alternate": "https://m.fomo7.com/live-casino/evolution/dream-catcher"
        },
        "hi-lo": {
            "title": "Play Hi Lo | Win Real Cash on FOMO7's Top-Rated Game",
            "description": "Your favorite game Hi LO is now on FOMO7 Official. Enjoy seamless gameplay and win real cash in India. Play now!",
            "canonical": "https://www.fomo7.com/instant-games/spribe/hi-lo",
            "alternate": "https://m.fomo7.com/instant-games/spribe/hi-lo"
        },
        "rocketon": {
            "title": "Play Rocketon | Instant games on FOMO7 for Instant Wins",
            "description": "Play Rocketon online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/galaxsys/rocketon",
            "alternate": "https://m.fomo7.com/instant-games/galaxsys/rocketon"
        },
        "burning-ice": {
            "title": "Play Burning Ice | Instant games on FOMO7 for Instant Wins",
            "description": "Play Burning Ice online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/burning-ice",
            "alternate": "https://m.fomo7.com/instant-games/burning-ice"
        },
        "cappa-docia": {
            "title": "Play Cappadocia | Instant games on FOMO7 for Instant Wins",
            "description": "Play Cappadocia online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.fomo7.com/instant-games/smartsoft/cappadocia"
        },
        "genies-bonanza": {
            "title": "Play Genies Bonanza | Instant games on FOMO7 for Instant Wins",
            "description": "Play Genies Bonanza online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/genies-bonanza",
            "alternate": "https://m.fomo7.com/instant-games/genies-bonanza"
        },
        "goal": {
            "title": "Play Goal | Instant games on FOMO7 for Instant Wins",
            "description": "Play Goal online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/spribe/goal",
            "alternate": "https://m.fomo7.com/instant-games/spribe/goal"
        },
        "hunter-x": {
            "title": "Play Hunter X | Instant games on FOMO7 for Instant Wins",
            "description": "Play Hunter X online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/hunter-x",
            "alternate": "https://m.fomo7.com/instant-games/hunter-x"
        },
        "jungles": {
            "title": "Play Jungles | Instant games on FOMO7 for Instant Wins",
            "description": "Play Jungles online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/jungles",
            "alternate": "https://m.fomo7.com/instant-games/jungles"
        },
        "magic-garden": {
            "title": "Play Magic Garden | Instant games on FOMO7 for Instant Wins",
            "description": "Play Magic Garden online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/magic-garden",
            "alternate": "https://m.fomo7.com/instant-games/magic-garden"
        },
        "multi-hot-ways": {
            "title": "Play Multi Hot Ways | Instant games on FOMO7 for Instant Wins",
            "description": "Play Multi Hot Ways online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/multi-hot-ways",
            "alternate": "https://m.fomo7.com/instant-games/multi-hot-ways"
        },
        "paper-plane": {
            "title": "Play Paper Plane | Instant games on FOMO7 for Instant Wins",
            "description": "Play Paper Plane online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/stp/paper-plane",
            "alternate": "https://m.fomo7.com/instant-games/stp/paper-plane"
        },
        "stockmarket": {
            "title": "Play Stock Market | Instant games on FOMO7 for Instant Wins",
            "description": "Play Stock Market online on FOMO7 Official and win big! Enjoy the highest multipliers and fast withdrawals for casino games in India.",
            "canonical": "https://www.fomo7.com/instant-games/evolution/stock-market",
            "alternate": "https://m.fomo7.com/instant-games/evolution/stock-market"
        },
        "rulesRgulations": {
            "canonical": "https://www.fomo7.com/rules-regulations",
            "alternate": "https://m.fomo7.com/about/rules-regulations"
        },
        "teen-patti": {
            "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
            "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
            "canonical": "https://www.fomo7.com/live-casino/teen-patti",
            "alternate": "https://m.fomo7.com/live-casino/teen-patti",
            "keywords": ""
        },
        "sponsorships": {
            "title": "Fomo7 Sponsorships",
            "description": "Fomo7 Sponsorships",
            "canonical": "https://www.fomo7.com/sponsors",
            "alternate": "https://m.fomo7.com/sponsors",
            "keywords": ""
        },

    },
    "40": {
        "default": {
            "title": "Fomo7 | Instant Betting & Casino ID for Quick Play",
            "description": "Secure Your Online Betting & Casino ID with FOMO7. Instant play with top games, secure transactions, and sports bets all in one place. Join today!",
            "canonical": "https://www.fomo7.xyz",
            "alternate": "https://m.fomo7.xyz",
            "keywords": ""
        },
        "onlineCasinoGames": {
            "title": "Fomo7 Live Casino ID | Get Your ID Instantly",
            "description": "Fomo7 Live Casino ID offers the ultimate live gaming thrill. Play your favorite games with real dealers and win big, all from the comfort of your home!",
            "canonical": "https://www.fomo7.xyz/live-casino",
            "alternate": "https://m.fomo7.xyz/live-casino",
            "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
        },
        "live-baccarat": {
            "title": "Play Baccarat Online for FREE or Real Money | FOMO7",
            "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
            "canonical": "https://www.fomo7.xyz/live-casino/live-baccarat",
            "alternate": "https://m.fomo7.xyz/live-casino/live-baccarat",
            "keywords": "live-baccarat, Live-Baccarat, Live Baccarat, livebaccarat, Livebaccarat, Baccarat Online Game, Baccarat Live, Baccarat"
        },
        "live-blackjack": {
            "title": "Play Blackjack Online Casino Game | 100% Safe & Fast Payouts",
            "description": "Play Blackjack online game on the best casino platform. Safest & fastest payouts guaranteed on FOMO7 for the Blackjack game. Play Blackjack card game now!",
            "canonical": "https://www.fomo7.xyz/live-casino/live-blackjack",
            "alternate": "https://m.fomo7.xyz/live-casino/live-blackjack",
            "keywords": "live-blackjack, blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
        },
        "live-roulette": {
            "title": "Play Roulette Online For Real Money | FOMO7 Live Roulette",
            "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
            "canonical": "https://www.fomo7.xyz/live-casino/live-roulette",
            "alternate": "https://m.fomo7.xyz/live-casino/live-roulette",
            "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "lightningroulette": {
            "title": "Play Lightning Roulette Online on FOMO7 | Best Wheel Game",
            "description": "Lightning Roulette live casino game on FOMO7 gives higher multipliers of up to 500x every round. Play Lightning Roulette online for real money.",
            "canonical": "https://www.fomo7.xyz/live-casino/lightning-roulette",
            "alternate": "https://m.fomo7.xyz/live-casino/lightning-roulette",
            "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
        },
        "dragonTiger": {
            "title": "Dragon Tiger Game on FOMO7 | Play Dragon Tiger for Real Cash",
            "description": "Elevate your gaming experience with FOMO7 Dragon Tiger game. Play Dragon Tiger online with faster withdrawal & higher payout.",
            "canonical": "https://www.fomo7.xyz/live-casino/dragon-tiger",
            "alternate": "https://m.fomo7.xyz/live-casino/dragon-tiger",
            "keywords": "fomo7 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
        },
        "baccarat": {
            "title": "Play Baccarat Casino Game Online for Real Money on FOMO7",
            "description": "Experience the excitement of Baccarat casino game online. Play Baccarat for real money on FOMO7 with more features & more rewards.",
            "canonical": "https://www.fomo7.xyz/live-casino/live-baccarat",
            "alternate": "https://m.fomo7.xyz/live-casino/live-baccarat",
            "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
        },
        "teenpattiOnlinePlay": {
            "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
            "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
            "canonical": "https://www.fomo7.xyz/teenpatti-online-play",
            "alternate": "https://m.fomo7.xyz/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
        },
        "sportsBetting": {
            "title": "Best Sports Betting Exchange in India - FOMO7 Exchange",
            "description": "Bet on all the top sports on the best sports betting exchange in India. Get high odds on FOMO7 exchange with Back & Lay options and Fancy bets.",
            "canonical": "https://www.fomo7.xyz/betting-exchange",
            "alternate": "https://m.fomo7.xyz/betting-exchange?id=0",
            "keywords": "betting exchange"
        },
        "onlineCricketSattaBazar": {
            "title": "Online Cricket Betting ID | Secure FOMO7 Provider",
            "description": "Join the leading online cricket betting and enjoy top odds, seamless betting, and exciting victories. Bet now for a winning experience with every wager!",
            "keywords": " ",
            "canonical": "https://www.fomo7.xyz/betting-exchange/online-cricket-betting",
            "alternate": "https://m.fomo7.xyz/betting-exchange/online-cricket-betting",
        },
        "footballBetting": {
            "title": "Bet on Football in India | Online Football Betting on FOMO7",
            "description": "Bet on football matches on FOMO7. Get high odds for football matches with the best online football betting experience in India.",
            "canonical": "https://www.fomo7.xyz/betting-exchange/football-betting",
            "alternate": "https://m.fomo7.xyz/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
        },
        "tennisBetting": {
            "title": "Online Tennis Betting | Best Tennis Betting Odds on FOMO7",
            "description": "Discover the ultimate tennis betting experience in India. Bet on tennis matches online on FOMO7 with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/betting-exchange/tennis-betting",
            "alternate": "https://m.fomo7.xyz/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
        },
        "volleyballBetting": {
            "title": "Live Volleyball Betting | Best Volleyball Betting Odds on FOMO7",
            "description": "Bet on volleyball matches in India with our trusted platform. Exciting odds & secure transactions on FOMO7 online volleyball betting!",
            "canonical": "https://www.fomo7.xyz/betting-exchange/volleyball-betting",
            "alternate": "https://m.fomo7.xyz/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
        },
        "promotions": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.fomo7.xyz/promotions",
            "alternate": "https://m.fomo7.xyz/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "specialMarket": {
            "title": "Special Markets on Fomo7",
            "description": "Special Markets on Fomo7",
            "canonical": "https://www.fomo7.xyz/specialMarket",
            "alternate": "https://m.fomo7.xyz/specialMarket",
        },
        "virtualSports": {
            "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/virtualSports/vci",
            "alternate": "https://m.fomo7.xyz/virtualSports/vci",
        },
        "virtualSportsBL": {
            "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/virtualSports/vbl",
            "alternate": "https://m.fomo7.xyz/virtualSports/vbl",
        },
        "virtualSportsFB": {
            "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/virtualSports/vfb",
            "alternate": "https://m.fomo7.xyz/virtualSports/vfb",
        },
        "virtualSportsFEL": {
            "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/virtualSports/vfel",
            "alternate": "https://m.fomo7.xyz/virtualSports/vfel",
        },
        "virtualSportsFEC": {
            "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
            "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
            "canonical": "https://www.fomo7.xyz/virtualSports/vfec",
            "alternate": "https://m.fomo7.xyz/virtualSports/vfec",
        },
        "virtualSportsVTI": {
            "title": "Elevate Your Game with Virtual Tennis Betting ID",
            "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
            "canonical": "https://www.fomo7.xyz/virtualSports/vti",
            "alternate": "https://m.fomo7.xyz/virtualSports/vti",
        },
        "virtualSportsWC": {
            "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
            "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
            "canonical": "https://www.fomo7.xyz/virtualSports/vfwc",
            "alternate": "https://m.fomo7.xyz/virtualSports/vfwc",
        },
        "slotGames": {
            "title": "Best Slot Games in India | Play Slots on FOMO7",
            "description": "Explore a variety of casino slot games on FOMO7. Play the best slots online & win real money instantly. Hit the jackpot everyday on FOMO7 slots.",
            "canonical": "https://www.fomo7.xyz/slots",
            "alternate": "https://m.fomo7.xyz/slots",
            "keywords": "slot games for real money, casino slot games"
        },
        "aviator": {
            "title": "Play Aviator Game Online - FOMO7 Aviator Game for Real Money",
            "description": "Fly high in the Aviator game online! Play Aviator on FOMO7 for real money with higher multiplier every round. Cash out instantly to your wallet.",
            "canonical": "https://www.fomo7.xyz/instant-games/spribe/aviator",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game "
        },
        "cashshow": {
            "title": "Cash Show Casino Game | Play Cash show Online on FOMO7",
            "description": "Play Cash show casino game from Galaxsys on FOMO7! Get real money cashow game with the mix of fashion & thrill.",
            "canonical": "https://www.fomo7.xyz/instant-games/galaxsys/cash-show",
            "alternate": "https://m.fomo7.xyz/instant-games/galaxsys/cash-show",
            "keywords": "Cash Show game, Cash Show online game "
        },
        "jetx": {
            "title": "Play JetX Casino Game on FOMO7 | JetX Online Game",
            "description": "Play Jetx game online on FOMO7. Get higher multiplier every round, excited features & faster withdrawals instantly to your wallet. Explore Jetx casino game.",
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/jetx",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/jetx",
            "keywords": "Jet X game, Jet X online game "
        },
        "rockpaperscissors": {
            "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
            "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
            "canonical": "https://www.fomo7.xyz/instant-games/stp/rock-paper-scissors",
            "alternate": "https://m.fomo7.xyz/instant-games/stp/rock-paper-scissors",
            "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
        },
        "plinkox": {
            "title": "Play PlinkoX Online Game for Real Money on FOMO7",
            "description": "Play PlinkoX online on FOMO7 & win big! Get the highest multiplier for PlinkoX Casino game in India with faster withdrawal.",
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/plinkox",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/plinkox",
            "keywords": "Plinkox game, Plinkox online game "
        },
        "footballx": {
            "title": "Football X Game | Live Casino Online Games on fomo7",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/footballx",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/footballx",
            "keywords": "Football X game, Football X online game "
        },
        "balloon": {
            "title": "Balloon Game | Live Casino Online Games on fomo7",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/balloon",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/balloon",
            "keywords": "Balloon game, Balloon online game "
        },
        "mines": {
            "title": "Mines Casino Games on FOMO7 | Best Mine Game Online",
            "description": "Discover the excitement of Mines Casino games on FOMO7! Play real money mines game, win big & feel the thrill with every move.",
            "canonical": "https://www.fomo7.xyz/instant-games/spribe/mines",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe/mines",
            "keywords": "Mines game, Online mines game"
        },
        "macludo": {
            "title": "Macludo Casino Games Online - Play & Win 24/7",
            "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
            "canonical": "https://www.fomo7.xyz/live-casino/macludo",
            "alternate": "https://m.fomo7.xyz/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online"
        },
        "crazytime": {
            "title": "Crazy Time Live Game on FOMO7 | Play Crazy Time Online",
            "description": "Play Crazy Time live game on FOMO7 for higher payouts every round. Get live crazy time stats, scores & tracker. Join & win real money.",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/crazy-time",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online"
        },
        "welcomeBonus": {
            "title": "Best Casino & Sports Betting welcome Bonus in India | FOMO7",
            "description": "FOMO7 offers a massive 700% welcome bonus for casino & sports betting. Get the best online betting sign-up bonus. Join & get FOMO7 welcome bonus.",
            "canonical": "https://www.fomo7.xyz/promotions/welcome-bonus",
            "alternate": "https://m.fomo7.xyz/promotions/welcome-bonus",
            "keywords": "Joining Bonus"
        },
        "premiumSportsbook": {
            "title": "Premium Sportsbook in India | FOMO7 Online Sportsbook",
            "description": "Bet on sports through FOMO7 Premium Sportsbook. Get premium options, features & payouts, available for all matches, leagues & sports.",
            "canonical": "https://www.fomo7.xyz/premium-sportsbook",
            "alternate": "https://m.fomo7.xyz/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
        },
        "refillBonus": {
            "title": "Redeposit Bonus for Casino & Sports Betting in India | FOMO7",
            "description": "Get 5% redeposit bonus instantly on FOMO7! Play top games & bet on sports with extra rewards for every deposit. Get the best redeposit bonus for online casinos now!",
            "canonical": "https://www.fomo7.xyz/promotions/refill-bonus",
            "alternate": "https://m.fomo7.xyz/promotions/refill-bonus",
            "keywords": "Redeposit bonus"
        },
        "cashbackBonus": {
            "title": "Lossback Bonus on FOMO7 | Get 5% Cashback for your losses",
            "description": "FOMO7 offers a grand 5% lossback bonus without any RO conditions for both casino games & sports betting in India.",
            "canonical": "https://www.fomo7.xyz/promotions/cashback-bonus",
            "alternate": "https://m.fomo7.xyz/promotions/cashback-bonus",
            "keywords": "Cashback Bonus"
        },
        "referralBonus": {
            "title": "Refer & Get 21% | Best Online Casino Referral Bonus on FOMO7",
            "description": "A mega 21% referral bonus awaits you on FOMO7! Refer, earn & play the best casino games and bet on your favourite sports. Every referral gets a reward.",
            "canonical": "https://www.fomo7.xyz/promotions/referral-bonus",
            "alternate": "https://m.fomo7.xyz/promotions/referral-bonus",
            "keywords": "Referral Bonus"
        },
        "termsandconditions": {
            "title": "Terms & Conditions for FOMO7",
            "description": "Find the complete terms & conditions for playing on FOMO7. Get the restrictions, obligations and allowed practices.",
            "canonical": "https://www.fomo7.xyz/terms-conditions",
            "alternate": "https://m.fomo7.xyz/about/terms-conditions",
            "keywords": "Terms & Conditions"
        },
        "responsibleGambling": {
            "title": "Responsible Gaming | FOMO7",
            "description": "Get the requisite details of Responsible Gaming on FOMO7. It is applicable to both casino games and sports prediction.",
            "canonical": "https://www.fomo7.xyz/responsible-gambling",
            "alternate": "https://m.fomo7.xyz/about/responsible-gambling",
            "keywords": "Responsible Gambling"
        },
        "privacyPolicy": {
            "title": "Complete Details of Privacy Policy of FOMO7",
            "description": "Find all the information regarding the Privacy Policy of FOMO7. From data storage to security details, get everything related to privacy maintenance.",
            "canonical": "https://www.fomo7.xyz/privacy-policy",
            "alternate": "https://m.fomo7.xyz/about/privacy-policy",
            "keywords": "privacy policy"
        },
        "kycPolicy": {
            "title": "KYC Policy of FOMO7 | Find the KYC Requirements & Process",
            "description": " Find the detailed KYC policy of FOMO7. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
            "canonical": "https://www.fomo7.xyz/kyc-policy",
            "alternate": "https://m.fomo7.xyz/about/kyc-policy",
            "keywords": "KYC Policy"
        },
        "exclusionPolicy": {
            "title": "Self-Exclusion Policy of FOMO7 | What & How",
            "description": "Find what is the Self-Exclusion Policy of FOMO7. Get detailed information of the entire process of self-exclusion.",
            "canonical": "https://www.fomo7.xyz/exclusion-policy",
            "alternate": "https://m.fomo7.xyz/about/self-exclusion-policy",
            "keywords": "exclusion policy "
        },
        "amlPolicy": {
            "title": "AML Policy of FOMO7 | Anti-Money Laundering Policy",
            "description": "Find the complete Anti-Money Laundering policy of FOMO7. Get the details of xyzpliance procedures.",
            "canonical": "https://www.fomo7.xyz/aml-policy",
            "alternate": "https://m.fomo7.xyz/about/aml-policy",
            "keywords": "AML Policy"
        },
        "aboutus": {
            "title": "About FOMO7 | Your Online Betting Destination",
            "description": "Learn about FOMO7, a leading online betting site in India that offers various casino games and sports betting options. Find more about us.",
            "canonical": "https://www.fomo7.xyz/about-us",
            "alternate": "https://m.fomo7.xyz/about/about-us",
            "keywords": "About us, About fomo7"
        },
        "SPRIBE": {
            "title": "Spribe Casino Games | Get the best Spribe Games on fomo7",
            "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
            "canonical": "https://www.fomo7.xyz/instant-games/spribe",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe",
            "keywords": "spribe casino games"
        },
        "aviatrix": {
            "title": "Play Aviatrix Game Online | FOMO7 Aviatrix For Real Cash",
            "description": "Play Aviatrix Online game for real cash on FOMO7. Fly more, win more. Get high multipliers for Aviatrix casino crash game.",
            "canonical": "https://www.fomo7.xyz/instant-games/aviatrix",
            "alternate": "https://m.fomo7.xyz/instant-games/aviatrix",
            "keywords": "Aviatrix casino games"
        },
        "7MOJOS": {
            "title": "7Mojos Games | Explore the top games at the best platform",
            "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
            "canonical": "https://www.fomo7.xyz/live-casino/7mojos",
            "alternate": "https://m.fomo7.xyz/live-casino/7mojos",
            "keywords": "7Mojos Games"
        },
        "supernowa": {
            "title": "Play Supernowa Casino Games on fomo7",
            "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on fomo7.",
            "canonical": "https://www.fomo7.xyz/live-casino/supernowa",
            "alternate": "https://m.fomo7.xyz/live-casino/supernowa",
            "keywords": "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
        },
        "pragmatic": {
            "title": "Pragmatic Casino Game on fomo7 | Get 400% welcome Bonus",
            "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
            "canonical": "https://www.fomo7.xyz/slots/pragmatic-play",
            "alternate": "https://m.fomo7.xyz/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games"
        },
        "superspade": {
            "title": "Top Super Spade Games On fomo7 | Play Now!",
            "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on fomo7 and win big. Join now!",
            "canonical": "https://www.fomo7.xyz/live-casino/super-spade",
            "alternate": "https://m.fomo7.xyz/live-casino/super-spade",
            "keywords": "super spade games,play super spade casino games, super spade casino"
        },
        "tvbet": {
            "title": "Play TVBET Casino Games Online | Real Money Games",
            "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about fomo7 TVBET Games.",
            "canonical": "https://www.fomo7.xyz/live-casino/tvbet",
            "alternate": "https://m.fomo7.xyz/live-casino/tvbet",
            "keywords": "TVBET casino games, fomo7 TVBET games"
        },
        "netent": {
            "title": "Netent Casino Game on fomo7 | Get Upto 400% Bonus",
            "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
            "canonical": "https://www.fomo7.xyz/slots/netent",
            "alternate": "https://m.fomo7.xyz/slots/netent",
            "keywords": "netent games"
        },
        "betgames": {
            "title": "BetGames On fomo7 | Play BetGames & Win Big",
            "description": "BetGames is one of the modern-day game providers in fomo7. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on fomo7 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
            "canonical": "https://fomo7.xyz/live-casino/betgames",
            "alternate": "https://m.fomo7.xyz/live-casino/betgames",
            "keywords": "betgames,fomo7 BetGames"
        },
        "sportsbook": {
            "title": "Online Sportsbook in India | Best Sportsbook Odds on FOMO7",
            "description": "Bet on sports through FOMO7 online sportsbook. Explore betting options for all sports with high odds, fast payouts and for all matches, leagues & tournaments.",
            "canonical": "https://www.fomo7.xyz/sportsbook",
            "alternate": "https://m.fomo7.xyz/sportsbook",
            "keywords": "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
        },
        "appDownload": {
            "title": "FOMO7 Online Betting App | Best Casino & Sports Betting Apk",
            "description": "Download FOMO7 App for the best casino & sports betting experience in India. Play real-money gambling on FOMO7 apk. Get the best online betting app now!",
            "canonical": "https://www.fomo7.xyz/app-download",
            "alternate": "https://m.fomo7.xyz/app-download",
            "keywords": "fomo7 App Download, fomo7 Android App"
        },
        "signup": {
            "title": "Register on FOMO7 | Best online casino & sports betting site",
            "description": "FOMO7 registration process is faster & smoother. Get the best offers & bonuses on FOMO7. Explore casino & sports betting options with one registration.",
            "canonical": "https://www.fomo7.xyz/signup",
            "alternate": "https://m.fomo7.xyz/signup",
            "keywords": "fomo7 User Registration"
        },
        "faq": {
            "title": "FOMO7 FAQs | Frequently Asked Questions",
            "description": "Go through a list of frequently asked questions on FOMO7 about registration, deposit, withdrawal & other important aspects of online gambling.",
            "canonical": "https://www.fomo7.xyz/faq",
            "alternate": "https://m.fomo7.xyz/faq",
        },
        "affiliate": {
            "title": "Affiliate Page",
            "description": "Affiliate Page",
            "canonical": "https://www.fomo7.xyz/affiliate",
            "alternate": "https://m.fomo7.xyz/affiliate",
        },
        "loyalty": {
            "title": "Loyalty Page",
            "description": "Loyalty Page",
            "canonical": "https://www.fomo7.xyz/loyalty",
            "alternate": "https://m.fomo7.xyz/loyalty",
        },
        "login": {
            "title": "Login FOMO7 & Get Your Online Betting ID Now!",
            "description": "Easy Login for Betting on Fomo7. Access Your ID and Get Started with Confidence",
            "canonical": "https://www.fomo7.xyz/login",
            "alternate": "https://m.fomo7.xyz/login",
            "keywords": "fomo7 Login"
        },
        "instantGames": {
            "title": "Instant Games on FOMO7 | Play the Best Instant Games Online",
            "description": "Explore the FOMO7 Instant Games & play for real money. Get the best instant games in online casinos in India. Instant rewards await you. Join now!",
            "canonical": "https://www.fomo7.xyz/instant-games",
            "alternate": "https://m.fomo7.xyz/instant-games",
            "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "majorLeagueCricketBetting": {
            "title": "MLC Betting in India | Major League Cricket Betting Online",
            "description": "MLC Betting is available on fomo7! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
            "canonical": "https://www.fomo7.xyz/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.fomo7.xyz/sports/tournaments/major-league-cricket-betting",
            "keywords": "Major League Cricket Betting Online, MLC Betting in India"
        },
        "dailyDepositBonus": {
            "title": "Refill bonus | Best Casino Refill Bonus on fomo7",
            "description": "fomo7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fomo7.",
            "canonical": "https://www.fomo7.xyz/daily-deposit-bonus",
            "alternate": "https://m.fomo7.xyz/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
        },
        "popular-games": {
            "title": "Play Top Casino Games Online for Real Money on FOMO7",
            "description": "Explore the top casino games in India on FOMO7. Play the best online casino games for real money, with higher chances of winning & withdraw instantly.",
            "canonical": "https://www.fomo7.xyz/popular-games",
            "alternate": "https://m.fomo7.xyz/popular-games",
            "keywords": "Best Casino Games Popular Casino Games, online casino real money"
        },
        "evolution": {
            "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution",
            "keywords": "Evolution Gaming, Evolution Gaming Casino"
        },

        "spribe": {
            "title": "Spribe Games | Play The Best fomo7 Spribe Games",
            "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling fomo7 Spribe Games.",
            "canonical": "https://www.fomo7.xyz/instant-games/spribe",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe",
            "keywords": "Spribe Games,fomo7 Spribe games,fomo7 Spribe games,Spribe live casino games"
        },
        "cricketx": {
            "title": "CricketX Casino Game | Play CricketX for Real Money on FOMO7",
            "description": "Play CricketX Casino game on FOMO7. Immerse yourself in the ultimate gaming experience! Get the thrill of cricket & fun of casino with CricketX game.",
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/cricketx",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/cricketx",
            "keywords": "Cricketx Games,fomo7 Cricketx games,fomo7 Cricketx games,Cricketx casino games"
        },
        "ezugi": {
            "title": "Ezugi Live Casino | Play fomo7 Ezugi Games",
            "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on fomo7. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
            "canonical": "https://fomo7.xyz/live-casino/ezugi",
            "alternate": "https://m.fomo7.xyz/live-casino/ezugi",
            "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, fomo7 Ezugi Live Casino"
        },
        "sexygaming": {
            "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
            "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on fomo7 and win exciting rewards.",
            "canonical": "https://www.fomo7.xyz/live-casino/sexy-gaming",
            "alternate": "https://m.fomo7.xyz/live-casino/sexy-gaming",
            "keywords": "sexy gaming casino,sexy gaming casino games, fomo7 sexy gaming casino games, fomo7 sexy gaming live casino game"
        },
        "monopolylive": {
            "title": "Play Monopoly Game Online & Win Real Money on FOMO7 Casino",
            "description": "Play Monopoly Live game on FOMO7 casino. Get higher payouts every round & win real money to your account directly.",
            "canonical": "https://www.fomo7.xyz/live-casino/monopoly-live",
            "alternate": "https://m.fomo7.xyz/live-casino/monopoly-live",
            "keywords": "MONOPOLY LIVE"
        },
        "funkytime": {
            "title": "Play Funky Time Casino Game for Real Money on FOMO7",
            "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/funky-time",
            "keywords": "funkytime"
        },
        "lightningdice": {
            "title": "Play Lightning Dice Casino Game for Real Money on FOMO7",
            "description": "Light up your gaming experience with Lightning Dice casino game on FOMO7. Get lightning multipliers & bigger payouts on online lightning dice game.",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/lightning-dice",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice"
        },
        "macaw": {
            "title": "Macaw Games | Top Macaw Casino Online Games on fomo7",
            "description": "Macaw games are thrilling and are available online on fomo7. Learn more about the popular Macaw casino online games.",
            "canonical": "https://www.fomo7.xyz/live-casino/macaw",
            "alternate": "https://m.fomo7.xyz/live-casino/macaw",
            "keywords": "macaw games, macaw casino online"
        },
        "ag": {
            "title": "Play Your Favourite AG Game Online at fomo7",
            "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on fomo7.",
            "canonical": "https://www.fomo7.xyz/live-casino/ag",
            "alternate": "https://m.fomo7.xyz/live-casino/ag",
            "keywords": "Amazing Gaming"
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on fomo7 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.fomo7.xyz/asia-cup-bonanza",
            "alternate": "https://m.fomo7.xyz/asia-cup-bonanza"
        },

        "bingo": {
            "title": "Play Bingo Casino Game on fomo7 | Upto 400%  Bonus",
            "description": "Explore a world of excitement at fomo7, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
            "canonical": "https://www.fomo7.xyz/live-casino/bingo",
            "alternate": "https://m.fomo7.xyz/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "Funkytime": {
            "title": "Play Funky Time Casino Online Game at fomo7",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "evo": {
            "title": "Evo Casino Game on fomo7 | Get Upto 400%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
            "canonical": "https://www.fomo7.xyz/live-casino/evo",
            "alternate": "https://m.fomo7.xyz/live-casino/evo",
            "keywords": "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
        },
        "nlc": {
            "title": "NLC Casino Game on fomo7 | 400% Signup  Bonus",
            "description": "Casino slot games are high on entertainment. Play slot games for real money on fomo7 and earn exciting rewards.",
            "canonical": "https://www.fomo7.xyz/slots/no-limit-city",
            "alternate": "https://m.fomo7.xyz/slots/no-limit-city",
            "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
        },
        "Funky Time": {
            "title": "Funky Time Online | Play for Free or Real Money",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/funky-time",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },
        "First-Person-Blackjack": {
            "title": "Play Blackjack Live Casino Games | Free or Real Money",
            "description": "Play blackjack online on the most safest online casino. Register now at fomo7 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on fomo7, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
            "canonical": "https://www.fomo7.xyz/live-casino/first%20person%20blackjack",
            "alternate": "https://m.fomo7.xyz/live-casino/first%20person%20blackjack",
            "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
        },
        "teenpatti2020-2020": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti 2020 is a popular casino card game on fomo7. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.fomo7.xyz/live-casino/teenpatti%2020%2020",
            "alternate": "https://m.fomo7.xyz/live-casino/teenpatti%2020%2020",
            "keywords": "Teenpatti 2020"
        },
        "teenpatti": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti is a popular casino card game on fomo7. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.fomo7.xyz/live-casino/teenpatti",
            "alternate": "https://m.fomo7.xyz/live-casino/teenpatti",
            "keywords": "Teenpatti"
        },
        //member dashboard pages
        "memberDashboard": {
            "canonical": "https://www.fomo7.xyz/member/profile",
            "alternate": "https://m.fomo7.xyz/member/profile"
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.fomo7.xyz/member/profile/accountinfo",
            "alternate": "https://m.fomo7.xyz/member/profile/accountinfo"
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.fomo7.xyz/member/profile/password",
            "alternate": "https://m.fomo7.xyz/member/profile/password"
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.fomo7.xyz/member/profile/bankdetails",
            "alternate": "https://m.fomo7.xyz/member/profile/bankdetails"
        },
        "memberProfileKYC": {
            "canonical": "https://www.fomo7.xyz/member/profile/kycpage",
            "alternate": "https://m.fomo7.xyz/member/profile/kycpage"
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.fomo7.xyz/member/finance/deposit",
            "alternate": "https://m.fomo7.xyz/member/finance/deposit"
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.fomo7.xyz/member/finance/withdrawal",
            "alternate": "https://m.fomo7.xyz/member/finance/withdrawal"
        },
        "memberFinanceHistory": {
            "canonical": "https://www.fomo7.xyz/member/finance/history",
            "alternate": "https://m.fomo7.xyz/member/finance/history"
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.fomo7.xyz/member/finance/transfer",
            "alternate": "https://m.fomo7.xyz/member/finance/transfer"
        },
        "memberMyBets": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/mybets",
            "alternate": "https://m.fomo7.xyz/member/betDetails/mybets"
        },
        "memberP&L": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/profitandloss",
            "alternate": "https://m.fomo7.xyz/member/betDetails/profitandloss"
        },
        "memberCasinoStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/casinostatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/casinostatement"
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/sportbookstatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/sportbookstatement"
        },
        "memberSTPStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/splitThePotStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/splitThePotStatement"
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/galaxyStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/galaxyStatement"
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/aviatrixStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/aviatrixStatement"
        },
        "memberEvoPlayStatement": {
            "canonical": "https://fomo7.xyz/member/betDetails/evoplayStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/evoplayStatement"
        },
        "memberJiliStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/jiliStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/jiliStatement"
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/turbogamesStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/turbogamesStatement"
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/indiaLotteryStatement"
        },
        "memberAccountStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/accountStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/accountStatement"
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/virtualSportsStatement"
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.fomo7.xyz/member/betDetails/turnoverStatement",
            "alternate": "https://m.fomo7.xyz/member/betDetails/turnoverStatement"
        },
        "memberPrormotions": {
            "canonical": "https://www.fomo7.xyz/member/promotions",
            "alternate": "https://m.fomo7.xyz/member/promotions"
        },
        "memberMessages": {
            "canonical": "https://www.fomo7.xyz/member/message",
            "alternate": "https://m.fomo7.xyz/member/message"
        },
        "ninjacrash": {
            "title": " Play Ninja Crash Game Online on FOMO7 for Real Money",
            "description": "Ready to showcase your ninja skills? Play the Ninja Crash casino game online from Galaxsys on FOMO7 for real money.",
            "canonical": "https://www.fomo7.xyz/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.fomo7.xyz/instant-games/galaxsys/ninja-crash",
            "keywords": "NinjaCrash game, Online NinjaCrash game"
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.fomo7.xyz/promotions/big-bash",
            "alternate": "https://m.fomo7.xyz/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
        },

        "lucky-fruits-lines": {
            "canonical": "https://www.fomo7.xyz/slots/lucky-fruits-lines",
            "alternate": "https://m.fomo7.xyz/slots/lucky-fruits-lines"
        },
        "andar-bahar": {
            "canonical": "https://www.fomo7.xyz/live-casino/andar-bahar",
            "alternate": "https://m.fomo7.xyz/live-casino/andar-bahar"
        },
        "dream-catcher": {
            "canonical": "https://www.fomo7.xyz/live-casino/evolution/dream-catcher",
            "alternate": "https://m.fomo7.xyz/live-casino/evolution/dream-catcher"
        },
        "hi-lo": {
            "canonical": "https://www.fomo7.xyz/instant-games/spribe/hi-lo",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe/hi-lo"
        },
        "rocketon": {
            "canonical": "https://www.fomo7.xyz/instant-games/galaxsys/rocketon",
            "alternate": "https://m.fomo7.xyz/instant-games/galaxsys/rocketon"
        },
        "burning-ice": {
            "canonical": "https://www.fomo7.xyz/instant-games/burning-ice",
            "alternate": "https://m.fomo7.xyz/instant-games/burning-ice"
        },
        "cappa-docia": {
            "canonical": "https://www.fomo7.xyz/instant-games/smartsoft/cappadocia",
            "alternate": "https://m.fomo7.xyz/instant-games/smartsoft/cappadocia"
        },
        "genies-bonanza": {
            "canonical": "https://www.fomo7.xyz/instant-games/genies-bonanza",
            "alternate": "https://m.fomo7.xyz/instant-games/genies-bonanza"
        },
        "goal": {
            "canonical": "https://www.fomo7.xyz/instant-games/spribe/goal",
            "alternate": "https://m.fomo7.xyz/instant-games/spribe/goal"
        },
        "hunter-x": {
            "canonical": "https://www.fomo7.xyz/instant-games/hunter-x",
            "alternate": "https://m.fomo7.xyz/instant-games/hunter-x"
        },
        "jungles": {
            "canonical": "https://www.fomo7.xyz/instant-games/jungles",
            "alternate": "https://m.fomo7.xyz/instant-games/jungles"
        },
        "magic-garden": {
            "canonical": "https://www.fomo7.xyz/instant-games/magic-garden",
            "alternate": "https://m.fomo7.xyz/instant-games/magic-garden"
        },
        "multi-hot-ways": {
            "canonical": "https://www.fomo7.xyz/instant-games/multi-hot-ways",
            "alternate": "https://m.fomo7.xyz/instant-games/multi-hot-ways"
        },
        "paper-plane": {
            "canonical": "https://www.fomo7.xyz/instant-games/stp/paper-plane",
            "alternate": "https://m.fomo7.xyz/instant-games/stp/paper-plane"
        },
        "stockmarket": {
            "canonical": "https://www.fomo7.xyz/instant-games/evolution/stock-market",
            "alternate": "https://m.fomo7.xyz/instant-games/evolution/stock-market"
        },
        "rulesRgulations": {
            "canonical": "https://www.fomo7.xyz/rules-regulations",
            "alternate": "https://m.fomo7.xyz/about/rules-regulations"
        },
        "sponsorships": {
            "title": "Fomo7 Sponsorships",
            "description": "Fomo7 Sponsorships",
            "canonical": "https://www.fomo7.xyz/sponsors",
            "alternate": "https://m.fomo7.xyz/sponsors",
            "keywords": ""
        },

    },
    "46": {
        "default": {
            "title": "Get Your Online Betting ID Instantly on WhatsApp | Fun88",
            "description": "Get your Fun88 online betting ID instantly through WhatsApp in just one click! Fast, secure, and easy. Get yours now!",
            "canonical": "https://www.fun88.site",
            "alternate": "https://m.fun88.site",
            "keywords": "",
            "openGraph": {
                "title": "Get Your Online Betting ID Instantly on WhatsApp | Fun88",
                "description": "Get your Fun88 online betting ID instantly through WhatsApp in just one click! Fast, secure, and easy. Get yours now!",
                "url": "https://www.fun88.site",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "onlineCasinoGames": {
            "title": "Play Live Casino with Instant Betting ID | Fun88",
            "description": "Play your favourite live caisno games with instant Betting ID through Whatsapp",
            "canonical": "https://www.fun88.site/live-casino",
            "alternate": "https://m.fun88.site/live-casino",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Casino with Instant Betting ID | Fun88",
                "description": "Play your favourite live caisno games with instant Betting ID through Whatsapp",
                "url": "https://www.fun88.site/live-casino",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "live-blackjack": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/live-blackjack",
            "alternate": "https://m.fun88.site/live-casino/live-blackjack",
            "keywords": ""
        },
        "roulette": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/roulette",
            "alternate": "https://m.fun88.site/live-casino/evolution/roulette",
            "keywords": ""
        },
        "lightningroulette": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/lightning-roulette",
            "alternate": "https://m.fun88.site/live-casino/evolution/lightning-roulette",
            "keywords": ""
        },
        "dragonTiger": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/dragon-tiger",
            "alternate": "https://m.fun88.site/live-casino/dragon-tiger",
            "keywords": ""
        },
        "live-baccarat": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/live-baccarat",
            "alternate": "https://m.fun88.site/live-casino/live-baccarat",
            "keywords": ""
        },
        "teenpattiOnlinePlay": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/teenpatti-online-play",
            "keywords": ""
        },
        "sportsBetting": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/betting-exchange",
            "alternate": "https://m.fun88.site/betting-exchange?id=0",
            "keywords": ""
        },
        "onlineCricketSattaBazar": {
            "title": "Get your Online Cricket Betting ID with 1 Click | Fun88",
            "description": "Are you looking to bet on Cricket? Get your online cricket betting ID today and start placing your bets. With our secure platform, you can bet safely. Don't wait any longer-start winning today!",
            "keywords": "",
            "canonical": "https://www.fun88.site/betting-exchange/online-cricket-betting",
            "alternate": "https://m.fun88.site/betting-exchange/online-cricket-betting",
            "openGraph": {
                "title": "Get your Online Cricket Betting ID with 1 Click | Fun88",
                "description": "Are you looking to bet on Cricket? Get your online cricket betting ID today and start placing your bets. With our secure platform, you can bet safely. Don't wait any longer-start winning today!",
                "url": "https://www.fun88.site/betting-exchange/online-cricket-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "footballBetting": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/betting-exchange/football-betting",
            "alternate": "https://m.fun88.site/betting-exchange/football-betting",
            "keywords": ""
        },
        "tennisBetting": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/betting-exchange/tennis-betting",
            "alternate": "https://m.fun88.site/betting-exchange/tennis-betting",
            "keywords": ""
        },
        "volleyballBetting": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/betting-exchange/volleyball-betting",
            "alternate": "https://m.fun88.site/betting-exchange/volleyball-betting",
            "keywords": ""
        },
        "promotions": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/promotions",
            "alternate": "https://m.fun88.site/promotions",
            "keywords": ""
        },
        "specialMarket": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/specialMarket",
            "alternate": "https://m.fun88.site/specialMarket",
            "keywords": ""
        },
        "virtualSports": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vci",
            "alternate": "https://m.fun88.site/virtualSports/vci",
            "keywords": ""
        },
        "virtualSportsBL": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vbl",
            "alternate": "https://m.fun88.site/virtualSports/vbl",
            "keywords": ""
        },
        "virtualSportsFB": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vfb",
            "alternate": "https://m.fun88.site/virtualSports/vfb",
            "keywords": ""
        },
        "virtualSportsFEL": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vfel",
            "alternate": "https://m.fun88.site/virtualSports/vfel",
            "keywords": ""
        },
        "virtualSportsFEC": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vfec",
            "alternate": "https://m.fun88.site/virtualSports/vfec",
            "keywords": ""
        },
        "virtualSportsVTI": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vti",
            "alternate": "https://www.fun88.site/virtualSports/vti",
            "keywords": ""
        },
        "virtualSportsWC": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/virtualSports/vfwc",
            "alternate": "https://www.fun88.site/virtualSports/vfwc",
            "keywords": ""
        },
        "slotGames": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots",
            "alternate": "https://m.fun88.site/slots",
            "keywords": ""
        },
        "aviator": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/aviator",
            "alternate": "https://m.fun88.site/instant-games/spribe/aviator",
            "keywords": ""
        },
        "cashshow": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/cashshow",
            "alternate": "https://m.fun88.site/instant-games/cashshow",
            "keywords": ""
        },
        "jetx": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft/jetx",
            "alternate": "https://m.fun88.site/instant-games/smartsoft/jetx",
            "keywords": ""
        },
        "rockpaperscissors": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/stp/rock-paper-scissors",
            "alternate": "https://www.fun88.site/instant-games/stp/rock-paper-scissors",
            "keywords": ""
        },
        "plinkox": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft/plinkox",
            "alternate": "https://m.fun88.site/instant-games/smartsoft/plinkox",
            "keywords": ""
        },
        "footballx": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft/footballx",
            "alternate": "https://m.fun88.site/instant-games/smartsoft/footballx",
            "keywords": ""
        },
        "balloon": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft/balloon",
            "alternate": "https://m.fun88.site/instant-games/smartsoft/balloon",
            "keywords": ""
        },
        "mines": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/mines",
            "alternate": "https://m.fun88.site/instant-games/spribe/mines",
            "keywords": ""
        },
        "macludo": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/macludo",
            "alternate": "https://m.fun88.site/live-casino/macludo",
            "keywords": ""
        },
        "crazytime": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/crazy-time",
            "alternate": "https://m.fun88.site/live-casino/evolution/crazy-time",
            "keywords": ""
        },
        "welcomeBonus": {
            "title": "Get 388% massive Welcome bonus on your 1st deposit | Fun88 India",
            "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!",
            "canonical": "https://www.fun88.site/promotions/welcome-bonus",
            "alternate": "https://m.fun88.site/promotions/welcome-bonus",
            "keywords": "",
            "openGraph": {
                "title": "Get 388% massive Welcome bonus on your 1st deposit | Fun88 India",
                "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!",
                "url": "https://www.fun88.site/promotions/welcome-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "premiumSportsbook": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/premium-sportsbook",
            "alternate": "https://m.fun88.site/premium-sportsbook",
            "keywords": ""
        },
        "refillBonus": {
            "title": "Redeposit Bonus up to 5% | Fun88 promotions India",
            "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit",
            "canonical": "https://www.fun88.site/promotions/refill-bonus",
            "alternate": "https://m.fun88.site/promotions/refill-bonus",
            "keywords": "",
            "openGraph": {
                "title": "Redeposit Bonus up to 5% | Fun88 promotions India",
                "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit",
                "url": "https://www.fun88.site/promotions/refill-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "cashbackBonus": {
            "title": "Cashback up to 5% on your Losses | Fun88 Official India",
            "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%.",
            "canonical": "https://www.fun88.site/promotions/cashback-bonus",
            "alternate": "https://m.fun88.site/promotions/cashback-bonus",
            "keywords": "",
            "openGraph": {
                "title": "Cashback up to 5% on your Losses | Fun88 Official India",
                "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%.",
                "url": "https://www.fun88.site/promotions/cashback-bonus",
            }
        },
        "referralBonus": {
            "title": "Refer Your Friend & Get 24% - FREE Bonus | Fun88",
            "description": "Want to earn 24% free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
            "canonical": "https://www.fun88.site/promotions/referral-bonus",
            "alternate": "https://m.fun88.site/promotions/referral-bonus",
            "keywords": "",
            "openGraph": {
                "title": "Refer Your Friend & Get 24% - FREE Bonus | Fun88",
                "description": "Want to earn 24% free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
                "url": "https://www.fun88.site/promotions/referral-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "termsandconditions": {
            "title": "Terms & Conditions for FUN88",
            "description": "Get the complete terms & conditions for playing on FUN88. Get the restrictions, obligations and allowed practices.",
            "canonical": "https://www.fun88.site/terms-conditions",
            "alternate": "https://m.fun88.site/about/terms-conditions",
            "keywords": ""
        },
        "responsibleGambling": {
            "title": "Responsible Gaming | FUN88",
            "description": "Get the requisite details of Responsible Gaming on FUN88. It is applicable to both casino games and sports prediction.",
            "canonical": "https://www.fun88.site/responsible-gambling",
            "alternate": "https://m.fun88.site/about/responsible-gambling",
            "keywords": ""
        },
        "privacyPolicy": {
            "title": "Complete Details of Privacy Policy of FUN88",
            "description": "Find all the information regarding the Privacy Policy of FUN88. From data storage to security details, get everything related to privacy maintenance.",
            "canonical": "https://www.fun88.site/privacy-policy",
            "alternate": "https://m.fun88.site/about/privacy-policy",
            "keywords": ""
        },
        "kycPolicy": {
            "title": "KYC Policy of FUN88 | Find the KYC Requirements & Process",
            "description": " Find the detailed KYC policy of FUN88. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
            "canonical": "https://www.fun88.site/kyc-policy",
            "alternate": "https://m.fun88.site/about/kyc-policy",
            "keywords": ""
        },
        "exclusionPolicy": {
            "title": "Self-Exclusion Policy of FUN88 | What & How",
            "description": "Find what is the Self-Exclusion Policy of FUN88. Get detailed information of the entire process of self-exclusion.",
            "canonical": "https://www.fun88.site/exclusion-policy",
            "alternate": "https://m.fun88.site/about/self-exclusion-policy",
            "keywords": ""
        },
        "amlPolicy": {
            "title": "AML Policy of FUN88 | Anti-Money Laundering Policy",
            "description": " Find the complete Anti-Money Laundering policy of FUN88. Get the details of compliance procedures.",
            "canonical": "https://www.fun88.site/aml-policy",
            "alternate": "https://m.fun88.site/about/aml-policy",
            "keywords": ""
        },
        "aboutus": {
            "title": "About FUN88 | Leading Online Betting ID Provider in India",
            "description": "Find about FUN88, a leading online betting ID provider in India that offers both sports betting IDs and online casino gaming IDs.",
            "canonical": "https://www.fun88.site/about-us",
            "alternate": "https://m.fun88.site/about/about-us",
            "keywords": ""
        },
        "SPRIBE": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe",
            "alternate": "https://m.fun88.site/instant-games/spribe",
            "keywords": ""
        },
        "aviatrix": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/aviatrix",
            "alternate": "https://m.fun88.site/instant-games/aviatrix",
            "keywords": ""
        },
        "7MOJOS": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/7mojos",
            "alternate": "https://m.fun88.site/live-casino/7mojos",
            "keywords": ""
        },
        "supernowa": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/supernowa",
            "alternate": "https://m.fun88.site/live-casino/supernowa",
            "keywords": ""
        },
        "pragmatic": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/pragmatic-play",
            "alternate": "https://m.fun88.site/slots/pragmatic-play",
            "keywords": ""
        },
        "superspade": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/super-spade",
            "alternate": "https://m.fun88.site/live-casino/super-spade",
            "keywords": ""
        },
        "tvbet": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/tvbet",
            "alternate": "https://m.fun88.site/live-casino/tvbet",
            "keywords": ""
        },
        "netent": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/netent",
            "alternate": "https://m.fun88.site/slots/netent",
            "keywords": ""
        },
        "betgames": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames",
            "alternate": "https://m.fun88.site/live-casino/betgames",
            "keywords": ""
        },
        "sportsbook": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/sportsbook",
            "alternate": "https://m.fun88.site/sportsbook",
            "keywords": ""
        },
        "appDownload": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/app-download",
            "alternate": "https://m.fun88.site/app-download",
            "keywords": ""
        },
        "signup": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/signup",
            "alternate": "https://m.fun88.site/signup",
            "keywords": ""
        },
        "faq": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/faq",
            "alternate": "https://m.fun88.site/faq",
            "keywords": ""
        },
        "affiliate": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/affiliate",
            "alternate": "https://m.fun88.site/affiliate",
            "keywords": ""
        },
        "loyalty": {
            "title": "Fun88 Loyalty | Massive Rewards for Our Loyal Members",
            "description": "Our loyalty program has 5 levels - Blue, Silver, Gold, Platinum, and Elite. Earn a minimum of 2% Refill Bonus and a maximum of up to 5%.",
            "canonical": "https://www.fun88.site/loyalty",
            "alternate": "https://m.fun88.site/loyalty",
            "keywords": ""
        },
        "login": {
            "title": "Fun88 Club Login | Get Your Betting ID Instantly",
            "description": "Log in to your Fun88 Club account or register for a new one to enjoy instant Online Betting ID",
            "canonical": "https://www.fun88.site/login",
            "alternate": "https://m.fun88.site/login",
            "keywords": "",
            "openGraph": {
                "title": "Fun88 Club Login | Get Your Betting ID Instantly",
                "description": "Log in to your Fun88 Club account or register for a new one to enjoy instant Online Betting ID",
                "url": "https://www.fun88.site/login",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "forgotPassword": {
            "title": "Forgot Password | Reset Your Fun88 Club Password",
            "description": "Forget your Fun88 Club account password? No worries! Reset your account password securely and regain access to your Online Betting ID",
            "canonical": "https://www.fun88.site/forgot-password",
            "alternate": "https://m.fun88.site/forgot-password",
            "keywords": "",
            "openGraph": {
                "title": "Forgot Password - Reset Your Fun88 Club Password",
                "description": "Forget your Fun88 Club account password? No worries! Reset your account password securely and regain access to your Online Betting ID",
                "url": "https://www.fun88.site/forgot-password",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
            }
        },
        "instantGames": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games",
            "alternate": "https://m.fun88.site/instant-games",
            "keywords": ""
        },
        "majorLeagueCricketBetting": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.fun88.site/sports/tournaments/major-league-cricket-betting",
            "keywords": ""
        },
        "dailyDepositBonus": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/daily-deposit-bonus",
            "alternate": "https://m.fun88.site/daily-deposit-bonus",
            "keywords": ""
        },
        "popular-games": {
            "title": "Best Casino Games | Online Casino Real Money on FUN88",
            "description": "Best casino games on FUN88 provide the best online gambling experience. Choose any of the top casino games & play online casino real money. Get free demo!",
            "canonical": "https://www.fun88.site/popular-games",
            "alternate": "https://m.fun88.site/popular-games",
            "keywords": ""
        },
        "evolution": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution",
            "alternate": "https://m.fun88.site/live-casino/evolution",
            "keywords": ""
        },
        "spribe": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe",
            "alternate": "https://m.fun88.site/instant-games/spribe",
            "keywords": ""
        },
        "cricketx": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft/cricketx",
            "alternate": "https://m.fun88.site/instant-games/smartsoft/cricketx",
            "keywords": ""
        },
        "ezugi": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/ezugi",
            "alternate": "https://m.fun88.site/live-casino/ezugi",
            "keywords": ""
        },
        "sexygaming": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/sexy-gaming",
            "alternate": "https://m.fun88.site/live-casino/sexy-gaming",
            "keywords": ""
        },
        "monopolylive": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/monopoly-live",
            "alternate": "https://m.fun88.site/live-casino/evolution/monopoly-live",
            "keywords": ""
        },
        "funkytime": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/funky-time",
            "alternate": "https://m.fun88.site/live-casino/evolution/funky-time",
            "keywords": ""
        },
        "lightningdice": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/lightning-dice",
            "alternate": "https://m.fun88.site/live-casino/evolution/lightning-dice",
            "keywords": ""
        },
        "macaw": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/macaw",
            "alternate": "https://m.fun88.site/live-casino/macaw",
            "keywords": ""
        },
        "ag": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/ag",
            "alternate": "https://m.fun88.site/live-casino/ag",
            "keywords": ""
        },
        "asiaCupBonanza": {
            "title": "",
            "description": "",
            "keywords": "",
            "canonical": "https://www.fun88.site/asia-cup-bonanza",
            "alternate": "https://m.fun88.site/asia-cup-bonanza"
        },
        "bingo": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/bingo",
            "alternate": "https://m.fun88.site/live-casino/bingo",
            "keywords": ""
        },
        "Funkytime": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/Funkytime",
            "alternate": "https://m.fun88.site/live-casino/Funkytime",
            "keywords": ""
        },
        "evo": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evo",
            "alternate": "https://m.fun88.site/live-casino/evo",
            "keywords": ""
        },
        "nlc": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/no-limit-city",
            "alternate": "https://m.fun88.site/slots/no-limit-city",
            "keywords": ""
        },
        "Funky Time": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/funky-time",
            "alternate": "https://m.fun88.site/live-casino/evolution/funky-time",
            "keywords": ""
        },
        "First-Person-Blackjack": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/first%20person%20blackjack",
            "alternate": "https://m.fun88.site/live-casino/first%20person%20blackjack",
            "keywords": ""
        },
        "teenpatti2020-2020": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/teenpatti%2020%2020",
            "alternate": "https://www.fun88.site/live-casino/teenpatti%2020%2020",
            "keywords": ""
        },
        "memberDashboard": {
            "canonical": "https://www.fun88.site/member/profile",
            "alternate": "https://m.fun88.site/member/profile",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.fun88.site/member/profile/accountinfo",
            "alternate": "https://m.fun88.site/member/profile/accountinfo",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.fun88.site/member/profile/password",
            "alternate": "https://m.fun88.site/member/profile/password",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.fun88.site/member/profile/bankdetails",
            "alternate": "https://m.fun88.site/member/profile/bankdetails",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberProfileKYC": {
            "canonical": "https://www.fun88.site/member/profile/kycpage",
            "alternate": "https://m.fun88.site/member/profile/kycpage",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.fun88.site/member/finance/deposit",
            "alternate": "https://m.fun88.site/member/finance/deposit",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.fun88.site/member/finance/withdrawal",
            "alternate": "https://m.fun88.site/member/finance/withdrawal",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberFinanceHistory": {
            "canonical": "https://www.fun88.site/member/finance/history",
            "alternate": "https://m.fun88.site/member/finance/history",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.fun88.site/member/finance/transfer",
            "alternate": "https://m.fun88.site/member/finance/transfer",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberMyBets": {
            "canonical": "https://www.fun88.site/member/betDetails/mybets",
            "alternate": "https://m.fun88.site/member/betDetails/mybets",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberP&L": {
            "canonical": "https://www.fun88.site/member/betDetails/profitandloss",
            "alternate": "https://m.fun88.site/member/betDetails/profitandloss",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberCasinoStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/casinostatement",
            "alternate": "https://m.fun88.site/member/betDetails/casinostatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/sportbookstatement",
            "alternate": "https://m.fun88.site/member/betDetails/sportbookstatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberSTPStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/splitThePotStatement",
            "alternate": "https://m.fun88.site/member/betDetails/splitThePotStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/galaxyStatement",
            "alternate": "https://m.fun88.site/member/betDetails/galaxyStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/aviatrixStatement",
            "alternate": "https://m.fun88.site/member/betDetails/aviatrixStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberEvoPlayStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/evoplayStatement",
            "alternate": "https://m.fun88.site/member/betDetails/evoplayStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberJiliStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/jiliStatement",
            "alternate": "https://m.fun88.site/member/betDetails/jiliStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/turbogamesStatement",
            "alternate": "https://m.fun88.site/member/betDetails/turbogamesStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.fun88.site/member/betDetails/indiaLotteryStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberAccountStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/accountStatement",
            "alternate": "https://m.fun88.site/member/betDetails/accountStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.fun88.site/member/betDetails/virtualSportsStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.fun88.site/member/betDetails/turnoverStatement",
            "alternate": "https://m.fun88.site/member/betDetails/turnoverStatement",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberPrormotions": {
            "canonical": "https://www.fun88.site/member/promotions",
            "alternate": "https://m.fun88.site/member/promotions",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "memberMessages": {
            "canonical": "https://www.fun88.site/member/message",
            "alternate": "https://m.fun88.site/member/message",
            "title": "",
            "description": "",
            "keywords": ""
        },
        "ninjacrash": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.fun88.site/instant-games/galaxsys/ninja-crash",
            "keywords": ""
        },
        "big-bash": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/promotions/big-bash",
            "alternate": "https://m.fun88.site/promotions/big-bash",
            "keywords": ""
        },
        "smartsoft": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/smartsoft",
            "alternate": "https://m.fun88.site/instant-games/smartsoft",
            "keywords": ""
        },
        "dice": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/dice",
            "alternate": "https://m.fun88.site/instant-games/spribe/dice",
            "keywords": ""
        },
        "goal": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/goal",
            "alternate": "https://m.fun88.site/instant-games/spribe/goal",
            "keywords": ""
        },
        "keno": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/keno",
            "alternate": "https://m.fun88.site/instant-games/spribe/keno",
            "keywords": ""
        },
        "plinko": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/plinko",
            "alternate": "https://m.fun88.site/instant-games/spribe/plinko",
            "keywords": ""
        },
        "andar-bahar": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/andar-bahar",
            "alternate": "https://m.fun88.site/live-casino/andar-bahar",
            "keywords": ""
        },
        "lucky-7": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames/lucky-7",
            "alternate": "https://m.fun88.site/live-casino/betgames/lucky-7",
            "keywords": ""
        },
        "skyward": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames/skyward",
            "alternate": "https://m.fun88.site/live-casino/betgames/skyward",
            "keywords": ""
        },
        "american-roulette": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/american-roulette",
            "alternate": "https://m.fun88.site/live-casino/evolution/american-roulette",
            "keywords": ""
        },
        "dream-catcher": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/dream-catcher",
            "alternate": "https://m.fun88.site/live-casino/evolution/dream-catcher",
            "keywords": ""
        },
        "hindi-lightning-roulette": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/hindi-lightning-roulette",
            "alternate": "https://m.fun88.site/live-casino/evolution/hindi-lightning-roulette",
            "keywords": ""
        },
        "monopoly-big-baller": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/monopoly-big-baller",
            "alternate": "https://m.fun88.site/live-casino/evolution/monopoly-big-baller",
            "keywords": ""
        },
        "super-andar-bahar": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/super-andar-bahar",
            "alternate": "https://m.fun88.site/live-casino/evolution/super-andar-bahar",
            "keywords": ""
        },
        "teen-patti": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/teen-patti",
            "alternate": "https://m.fun88.site/live-casino/teen-patti",
            "keywords": ""
        },
        "xxxtreme-lightning-roulette": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/xxxtreme-lightning-roulette",
            "alternate": "https://m.fun88.site/live-casino/evolution/xxxtreme-lightning-roulette",
            "keywords": ""
        },
        "7-up-7-down": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/7-up-7-down",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/7-up-7-down",
            "keywords": ""
        },
        "bonus-dice": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/bonus-dice",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/bonus-dice",
            "keywords": ""
        },
        "coin-toss": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/coin-toss",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/coin-toss",
            "keywords": ""
        },
        "color-game": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/color-game",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/color-game",
            "keywords": ""
        },
        "triple-chance": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/triple-chance",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/triple-chance",
            "keywords": ""
        },
        "jili-slot": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot",
            "alternate": "https://m.fun88.site/slots/jili-slot",
            "keywords": ""
        },
        "crazy777": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot/crazy777",
            "alternate": "https://m.fun88.site/slots/jili-slot/crazy777",
            "keywords": ""
        },
        "fortune-gems": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot/fortune-gems",
            "alternate": "https://m.fun88.site/slots/jili-slot/fortune-gems",
            "keywords": ""
        },
        "money-coming": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot/money-coming",
            "alternate": "https://m.fun88.site/slots/jili-slot/money-coming",
            "keywords": ""
        },
        "kingmaker-andarbahar": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/andar-bahar",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/andar-bahar",
            "keywords": ""
        },
        "jhandi-munda": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/jhandi-munda",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/jhandi-munda",
            "keywords": ""
        },
        "thai-fish-prawn-crab": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/kingmaker/thai-fish-prawn-crab",
            "alternate": "https://m.fun88.site/live-casino/kingmaker/thai-fish-prawn-crab",
            "keywords": ""
        },
        "hotline": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/spribe/hotline",
            "alternate": "https://m.fun88.site/instant-games/spribe/hotline",
            "keywords": ""
        },
        "baccarat": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/baccarat",
            "alternate": "https://m.fun88.site/live-casino/evolution/baccarat",
            "keywords": ""
        },
        "blackjack": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/blackjack",
            "alternate": "https://m.fun88.site/live-casino/evolution/blackjack",
            "keywords": ""
        },
        "evodragonTiger": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/dragon-tiger",
            "alternate": "https://m.fun88.site/live-casino/evolution/dragon-tiger",
            "keywords": ""
        },
        "emperor-sic-bo": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/emperor-sic-bo",
            "alternate": "https://m.fun88.site/live-casino/evolution/emperor-sic-bo",
            "keywords": ""
        },
        "evo-teenpatti": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/evolution/teen-patti",
            "alternate": "https://m.fun88.site/live-casino/evolution/teen-patti",
            "keywords": ""
        },
        "playtech": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/playtech",
            "alternate": "https://m.fun88.site/live-casino/playtech",
            "keywords": ""
        },
        "live-roulette": {
            "title": "Play Roulette Game Online and Win Real Money at Fun88",
            "description": "Get ready for an adventure with our thrilling roulette games. Join the fun and test your luck at the roulette wheel game and win real cash. Your jackpot awaits!",
            "canonical": "https://www.fun88.site/live-casino/live-roulette",
            "alternate": "https://m.fun88.site/live-casino/roulette",
            "keywords": ""
        },
        "extra-andar-bahar": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/sexy-gaming/extra-andar-bahar",
            "alternate": "https://m.fun88.site/live-casino/sexy-gaming/extra-andar-bahar",
            "keywords": ""
        },
        "sup-teenpatti2020": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/super-spade/teen-patti-20-20",
            "alternate": "https://m.fun88.site/live-casino/super-spade/teen-patti-20-20",
            "keywords": ""
        },
        "dice-duel": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames/dice-duel",
            "alternate": "https://m.fun88.site/live-casino/betgames/dice-duel",
            "keywords": ""
        },
        "satta-matka": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames/satta-matka",
            "alternate": "https://m.fun88.site/live-casino/betgames/satta-matka",
            "keywords": ""
        },
        "speedy-7": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/live-casino/betgames/speedy-7",
            "alternate": "https://m.fun88.site/live-casino/betgames/speedy-7",
            "keywords": ""
        },
        "sponsorships": {
            "title": "Fun88 Sponsorships",
            "description": "Fun88 Sponsorships",
            "canonical": "https://www.fun88.site/sponsors",
            "alternate": "https://m.fun88.site/sponsors",
            "keywords": ""
        },
        "rummy": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot/rummy",
            "alternate": "https://m.fun88.site/slots/jili-slot/rummy",
            "keywords": "",
        },
        "jiliblackjack": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/slots/jili-slot/blackjack",
            "alternate": "https://m.fun88.site/slots/jili-slot/blackjack",
            "keywords": "",
        },
        "instantskyward": {
            "title": "",
            "description": "",
            "canonical": "https://www.fun88.site/instant-games/betgames/skyward",
            "alternate": "https://m.fun88.site/instant-games/betgames/skyward",
            "keywords": "",
        }
    },
    "47": {
        "default": {
            "title": "Fun88 India Official - Online Sports Betting & Live Casino Games",
            "description": "Fun88 is a trusted online betting site in India with 100+ online casino games, slots games, & 10K different sports with the best betting odds. Join now for exciting betting online! ",
            "canonical": "https://www.gofun88.in",
            "alternate": "https://m.gofun88.in",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Fun88",
                "description": "Fun88 is a trusted online betting site in India with 100+ online casino games, slots games, & 10K different sports with the best betting odds. Join now for exciting betting online! ",
                "url": "https://www.gofun88.in",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "onlineCasinoGames": {
            "title": "Live Casinos India: Play Best Online Casino Games at Fun88",
            "description": "Fun88 offers you the best live casino games. Play casino games online like Blackjack, Rolultte, Baccarat, Andar Bahar, and more today. Join and win real money.",
            "canonical": "https://www.gofun88.in/live-casino",
            "alternate": "https://m.gofun88.in/live-casino",
            "keywords": " Online Casino Games, Live Casino Online, Play Casino games online",
            "openGraph": {
                "title": "Live Casinos India: Play Best Online Casino Games at Fun88",
                "description": "Fun88 offers you the best live casino games. Play casino games online like Blackjack, Rolultte, Baccarat, Andar Bahar, and more today. Join and win real money.",
                "url": "https://www.gofun88.in/live-casino",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "live-blackjack": {
            "title": "Play Blackjack Game Online and Win Real Money at Fun88",
            "description": "Ready to play blackjack online for real money? Discover objectives, strategies, tips, bonuses, and put your skills to the test in the ultimate casino card game.",
            "canonical": "https://www.gofun88.in/live-casino/live-blackjack",
            "alternate": "https://m.gofun88.in/live-casino/live-blackjack",
            "keywords": "",
            "openGraph": {
                "title": "Play Blackjack Game Online and Win Real Money at Fun88",
                "description": "Ready to play blackjack online for real money? Discover objectives, strategies, tips, bonuses, and put your skills to the test in the ultimate casino card game.",
                "url": "https://www.gofun88.in/live-casino/live-blackjack",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "roulette": {
            "title": "Play Live Roulette Casino Game by Evolution Gaming at Fun88",
            "description": "Play Live Roulette by Evolution Gaming at Fun88 and enjoy an immersive casino experience with real-time gameplay. Spin the wheel and win real money today!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/roulette",
            "alternate": "https://m.gofun88.in/live-casino/evolution/roulette",
            "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table",
            "openGraph": {
                "title": "Play Live Roulette Casino Game by Evolution Gaming at Fun88",
                "description": "Play Live Roulette by Evolution Gaming at Fun88 and enjoy an immersive casino experience with real-time gameplay. Spin the wheel and win real money today!",
                "url": "https://www.gofun88.in/live-casino/evolution/roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "lightningroulette": {
            "title": "Play Lightning Roulette Live Casino Game by Evolution Gaming",
            "description": "Get ready for non-stop excitement with Lightning Roulette at Fun88! Attempt to win real money online by playing live roulette casino games. ",
            "canonical": "https://www.gofun88.in/live-casino/evolution/lightning-roulette",
            "alternate": "https://m.gofun88.in/live-casino/evolution/lightning-roulette",
            "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table",
            "openGraph": {
                "title": "Play Lightning Roulette Live Casino Game by Evolution Gaming",
                "description": "Get ready for non-stop excitement with Lightning Roulette at Fun88! Attempt to win real money online by playing live roulette casino games. ",
                "url": "https://www.gofun88.in/live-casino/evolution/lightning-roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "dragonTiger": {
            "title": "Online Dragon Tiger Real Cash Games | Play & Win at Fun88",
            "description": "Experience the thrill of Dragon Tiger with real cash games at Fun88. Play dragon tiger game from top vendors like Evolution and Ezugi for seamless gameplay, and enjoy a secure gaming environment.",
            "canonical": "https://www.gofun88.in/live-casino/dragon-tiger",
            "alternate": "https://m.gofun88.in/live-casino/dragon-tiger",
            "keywords": "FUN88 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games.",
            "openGraph": {
                "title": "Online Dragon Tiger Real Cash Games | Play & Win at Fun88",
                "description": "Experience the thrill of Dragon Tiger with real cash games at Fun88. Play dragon tiger game from top vendors like Evolution and Ezugi for seamless gameplay, and enjoy a secure gaming environment.",
                "url": "https://www.gofun88.in/live-casino/dragon-tiger",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "live-baccarat": {
            "title": "Play Live Baccarat Casino Game and Win Real Money at Fun88",
            "description": "Learn more about the online baccarat card game and its thrilling gameplay. Dive into our detailed live baccarat casino to uncover everything about it at Fun88 only.",
            "canonical": "https://www.gofun88.in/live-casino/live-baccarat",
            "alternate": "https://m.gofun88.in/live-casino/live-baccarat",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Baccarat Casino Game and Win Real Money at Fun88",
                "description": "Learn more about the online baccarat card game and its thrilling gameplay. Dive into our detailed live baccarat casino to uncover everything about it at Fun88 only.",
                "url": "https://www.gofun88.in/live-casino/live-baccarat",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "teenpattiOnlinePlay": {
            "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
            "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on fun88 and earn real cash. Betting on teen patti game made easy!",
            "canonical": "https://www.gofun88.in/teenpatti-online-play",
            "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino",
            "openGraph": {
                "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
                "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on fun88 and earn real cash. Betting on teen patti game made easy!",
                "url": "https://www.gofun88.in/teenpatti-online-play",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "sportsBetting": {
            "title": "Best Online Sports Betting Exchange Site In India For Cricket, Football, Kabaddi, & A Lot More | Fun88",
            "description": "Fun88 is a top online sports betting exchange (gambling) site in India, which offers punters more than 10,000 games, bonuses, promotions, and a lot more. Visit Fun88 now!",
            "canonical": "https://www.gofun88.in/betting-exchange",
            "alternate": "https://m.gofun88.in/betting-exchange?id=0",
            "keywords": "betting exchange",
            "openGraph": {
                "title": "Best Online Sports Betting Exchange Site In India For Cricket, Football, Kabaddi, & A Lot More | Fun88",
                "description": "Cricket betting online at Fun88! Bet on cricket tournaments ✓ Best Cricket Satta Rates ✓Secure Payment ✓Bonus for thrilling live betting in India.",
                "url": "https://www.gofun88.in/betting-exchange",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "onlineCricketSattaBazar": {
            "title": "Online Cricket Betting Site: Bet on Cricket Satta at Fun88",
            "description": "Cricket betting online at Fun88! Bet on cricket tournaments ✓ Best Cricket Satta Rates ✓Secure Payment ✓Bonus for thrilling live betting in India.",
            "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fun88 online cricket betting, fun88 cricket betting odds, fun88 cricket betting, cricket betting sites, online cricket satta bazar,",
            "canonical": "https://www.gofun88.in/betting-exchange/online-cricket-betting",
            "alternate": "https://m.gofun88.in/betting-exchange/online-cricket-betting",
            "openGraph": {
                "title": "Online Cricket Betting Site: Bet on Cricket Satta at Fun88",
                "description": "Cricket betting online at Fun88! Bet on cricket tournaments ✓ Best Cricket Satta Rates ✓Secure Payment ✓Bonus for thrilling live betting in India.",
                "url": "https://www.gofun88.in/betting-exchange/online-cricket-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "footballBetting": {
            "title": "Football Bet App: Enjoy Sports Betting Online | Download Now | Fun88",
            "description": "Kick-off your betting journey with the best betting app for Football. Download the Fun88 Football bet app and win big with the best betting offers.",
            "canonical": "https://www.gofun88.in/betting-exchange/football-betting",
            "alternate": "https://m.gofun88.in/betting-exchange/football-betting",
            "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites",
            "openGraph": {
                "title": "Football Bet App: Enjoy Sports Betting Online | Download Now | Fun88",
                "description": "Kick-off your betting journey with the best betting app for Football. Download the Fun88 Football bet app and win big with the best betting offers.",
                "url": "https://www.gofun88.in/betting-exchange/football-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "tennisBetting": {
            "title": "Tennis Betting in India | Bet on Tennis Matches Online",
            "description": "Tennis betting offers an opportunity to bet on tennis matches online. Get the best tennis odds on fun88. Available for all tournaments with better payouts. ",
            "canonical": "https://www.gofun88.in/betting-exchange/tennis-betting",
            "alternate": "https://m.gofun88.in/betting-exchange/tennis-betting",
            "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites",
            "openGraph": {
                "title": "Tennis Betting in India | Bet on Tennis Matches Online",
                "description": "Tennis betting offers an opportunity to bet on tennis matches online. Get the best tennis odds on fun88. Available for all tournaments with better payouts. ",
                "url": "https://www.gofun88.in/betting-exchange/tennis-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "volleyballBetting": {
            "title": "Volleyball Betting in India | Bet on Volleyball Matches Online",
            "description": "Volleyball betting is a popular form of sports wagering that allows betting on Volleyball matches online. Get the best volleyball odds on fun88.",
            "canonical": "https://www.gofun88.in/betting-exchange/volleyball-betting",
            "alternate": "https://m.gofun88.in/betting-exchange/volleyball-betting",
            "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites",
            "openGraph": {
                "title": "Volleyball Betting in India | Bet on Volleyball Matches Online",
                "description": "Volleyball betting is a popular form of sports wagering that allows betting on Volleyball matches online. Get the best volleyball odds on fun88.",
                "url": "https://www.gofun88.in/betting-exchange/volleyball-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "promotions": {
            "title": "Claim Casino Bonus: Avail 388% Welcome & Free Sign-Up Offers",
            "description": "Discover top casino and sports betting bonuses in India! Grab exclusive casino signup and first-time deposit bonuses for an unbeatable gaming journey.",
            "canonical": "https://www.gofun88.in/promotions",
            "alternate": "https://m.gofun88.in/promotions",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Claim Casino Bonus: Avail 388% Welcome & Free Sign-Up Offers",
                "description": "Discover top casino and sports betting bonuses in India! Grab exclusive casino signup and first-time deposit bonuses for an unbeatable gaming journey.",
                "url": "https://www.gofun88.in/promotions",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "specialMarket": {
            "title": "Special Markets on fun88",
            "description": "Special Markets on fun88",
            "canonical": "https://www.gofun88.in/specialMarket",
            "alternate": "https://m.gofun88.in/specialMarket",
            "openGraph": {
                "title": "Special Markets on fun88",
                "description": "Special Markets on fun88",
                "url": "https://www.gofun88.in/specialMarket",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSports": {
            "title": "Bet on Virtual Cricket Online with Fun88",
            "description": "Bet on virtual Cricket online with Fun88! Enjoy realistic gameplay and seamless betting. Join now for an immersive virtual Cricket experience!",
            "canonical": "https://www.gofun88.in/virtualSports/vci",
            "alternate": "https://m.gofun88.in/virtualSports/vci",
            "openGraph": {
                "title": "Bet on Virtual Cricket Online with Fun88",
                "description": "Bet on virtual Cricket online with Fun88! Enjoy realistic gameplay and seamless betting. Join now for an immersive virtual Cricket experience!",
                "url": "https://www.gofun88.in/virtualSports/vci",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsBL": {
            "title": "Basketball Betting | Virtual Basketball Game on fun88",
            "description": "Basketball betting reaches new heights on fun88. Elevate your betting game and learn about the thrill of virtual basketball betting.",
            "canonical": "https://www.gofun88.in/virtualSports/vbl",
            "alternate": "https://m.gofun88.in/virtualSports/vbl",
            "openGraph": {
                "title": "Basketball Betting | Virtual Basketball Game on fun88",
                "description": "Basketball betting reaches new heights on fun88. Elevate your betting game and learn about the thrill of virtual basketball betting.",
                "url": "https://www.gofun88.in/virtualSports/vbl",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsFB": {
            "title": "Virtual Bundesliga Betting | fun88 Virtual Football Betting",
            "description": "Virtual Bundesliga betting is trendiest on fun88. Bet on Virtual Bundesliga matches and unleash your passion for football betting.",
            "canonical": "https://www.gofun88.in/virtualSports/vfb",
            "alternate": "https://m.gofun88.in/virtualSports/vfb",
            "openGraph": {
                "title": "Virtual Bundesliga Betting | fun88 Virtual Football Betting",
                "description": "Virtual Bundesliga betting is trendiest on fun88. Bet on Virtual Bundesliga matches and unleash your passion for football betting.",
                "url": "https://www.gofun88.in/virtualSports/vfb",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsFEL": {
            "title": "Virtual EPL Betting: English Premier League Betting on fun88",
            "description": "EPL betting offers excitement and a plunge into the virtual world of football. Learn more about the English Premier League betting on fun88. ",
            "canonical": "https://www.gofun88.in/virtualSports/vfel",
            "alternate": "https://m.gofun88.in/virtualSports/vfel",
            "openGraph": {
                "title": "Virtual EPL Betting: English Premier League Betting on fun88",
                "description": "EPL betting offers excitement and a plunge into the virtual world of football. Learn more about the English Premier League betting on fun88. ",
                "url": "https://www.gofun88.in/virtualSports/vfel",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsFEC": {
            "title": "European Cup Betting And Virtual Sports | Big Rewards on fun88",
            "description": "European Cup betting is based on the Euro League. Get the best virtual betting odds for the Euro Cup on fun88. Find out more on live Euro Cup betting",
            "canonical": "https://www.gofun88.in/virtualSports/vfec",
            "alternate": "https://m.gofun88.in/virtualSports/vfec",
            "openGraph": {
                "title": "European Cup Betting And Virtual Sports | Big Rewards on fun88",
                "description": "European Cup betting is based on the Euro League. Get the best virtual betting odds for the Euro Cup on fun88. Find out more on live Euro Cup betting",
                "url": "https://www.gofun88.in/virtualSports/vfec",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsVTI": {
            "title": "Elevate Your Game with Virtual Tennis Betting ID",
            "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
            "canonical": "https://www.gofun88.in/virtualSports/vti",
            "alternate": "https://www.gofun88.in/virtualSports/vti",
            "openGraph": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "url": "https://www.gofun88.in/virtualSports/vti",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "virtualSportsWC": {
            "title": "Virtual World Cup Betting | How to Make Informed Bets?",
            "description": "Virtual World Cup Betting helps you immerse yourself in a virtual football universe. Bet on your favourite teams and win big on World Cup betting.",
            "canonical": "https://www.gofun88.in/virtualSports/vfwc",
            "alternate": "https://www.gofun88.in/virtualSports/vfwc",
            "openGraph": {
                "title": "Virtual World Cup Betting | How to Make Informed Bets?",
                "description": "Virtual World Cup Betting helps you immerse yourself in a virtual football universe. Bet on your favourite teams and win big on World Cup betting.",
                "url": "https://www.gofun88.in/virtualSports/vfwc",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "slotGames": {
            "title": "Play Online Slots Jili Casino and Win a Jackpot 🎰 with Fun88",
            "description": "Play Slots Game online in India for real money ➡️ Browse the list of the best slots machine by Jili. Join now at Fun88 & let the games begin!",
            "canonical": "https://www.gofun88.in/slots",
            "alternate": "https://m.gofun88.in/slots",
            "keywords": "slot games for real money, casino slot games",
            "openGraph": {
                "title": "Play Online Slots Jili Casino and Win a Jackpot 🎰 with Fun88",
                "description": "Play Slots Game online in India for real money ➡️ Browse the list of the best slots machine by Jili. Join now at Fun88 & let the games begin!",
                "url": "https://www.gofun88.in/slots",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "aviator": {
            "title": "Play Aviator Game Online and Earn Real Money at Fun88",
            "description": "Ready for an 🚀 Aviator game online experience? Fly through thrilling missions in ✈️ Spribe Plane Game with fair gameplay, winning strategies and device compatibility. ",
            "canonical": "https://www.gofun88.in/instant-games/spribe/aviator",
            "alternate": "https://m.gofun88.in/instant-games/spribe/aviator",
            "keywords": "Aviator game, Aviator online game ",
            "openGraph": {
                "title": "Play Aviator Game Online and Earn Real Money at Fun88",
                "description": "Ready for an 🚀 Aviator game online experience? Fly through thrilling missions in ✈️ Spribe Plane Game with fair gameplay, winning strategies and device compatibility. ",
                "url": "https://www.gofun88.in/instant-games/spribe/aviator",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "cashshow": {
            "title": "Cash Show Game | Live Casino Online Games on fun88",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fun88.",
            "canonical": "https://www.gofun88.in/instant-games/cashshow",
            "alternate": "https://m.gofun88.in/instant-games/cashshow",
            "keywords": "Cash Show game, Cash Show online game ",
            "openGraph": {
                "title": "Cash Show Game | Live Casino Online Games on fun88",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fun88.",
                "url": "https://www.gofun88.in/instant-games/cashshow",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "jetx": {
            "title": "Play JetX Game Online at fun88 - Win Real Money Online",
            "description": "Jetx Online Game for Real Money is now available on fun88! Play Jetx Game now & win exciting rewards. Instant withdrawal & all the betting options. ",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft/jetx",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft/jetx",
            "keywords": "Jet X game, Jet X online game ",
            "openGraph": {
                "title": "Play JetX Game Online at fun88 - Win Real Money Online",
                "description": "Jetx Online Game for Real Money is now available on fun88! Play Jetx Game now & win exciting rewards. Instant withdrawal & all the betting options. ",
                "url": "https://www.gofun88.in/instant-games/smartsoft/jetx",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "rockpaperscissors": {
            "title": "Play Rock Paper Sissors Online at fun88 - Earn Big Rewards",
            "description": "Rock Paper Scissors online casino game is now available on fun88. The online version is upgraded and enhanced to offer faster gameplay and smoother interface. ",
            "canonical": "https://www.gofun88.in/instant-games/stp/rock-paper-scissors",
            "alternate": "https://www.gofun88.in/instant-games/stp/rock-paper-scissors",
            "keywords": "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online",
            "openGraph": {
                "title": "Play Rock Paper Sissors Online at fun88 - Earn Big Rewards",
                "description": "Rock Paper Scissors online casino game is now available on fun88. The online version is upgraded and enhanced to offer faster gameplay and smoother interface. ",
                "url": "https://www.gofun88.in/instant-games/stp/rock-paper-scissors",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "plinkox": {
            "title": "Play PlinkoX casino games- Top Instant Games on fun88",
            "description": "Play Plinkox Casino game online on fun88. PlinkoX online game provides higher payout and smoother gameplay. Play for real money online. Get the best experience. ",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft/plinkox",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft/plinkox",
            "keywords": "Plinkox game, Plinkox online game ",
            "openGraph": {
                "title": "Play PlinkoX casino games- Top Instant Games on fun88",
                "description": "Play Plinkox Casino game online on fun88. PlinkoX online game provides higher payout and smoother gameplay. Play for real money online. Get the best experience. ",
                "url": "https://www.gofun88.in/instant-games/smartsoft/plinkox",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "footballx": {
            "title": "Play FootballX Crash Game Online - Play Instant Game",
            "description": "Footballx casino game offers quality gameplay among all instant games. Play online footballx game for real money. Get 24x7 customer support and faster withdrawal.",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft/footballx",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft/footballx",
            "keywords": "Football X game, Football X online game ",
            "openGraph": {
                "title": "Play FootballX Crash Game Online - Play Instant Game",
                "description": "Footballx casino game offers quality gameplay among all instant games. Play online footballx game for real money. Get 24x7 customer support and faster withdrawal.",
                "url": "https://www.gofun88.in/instant-games/smartsoft/footballx",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "balloon": {
            "title": "Balloon crash games- Play for real money",
            "description": "Balloon casino game is one of the popular online instant games on fun88. Play Balloon for real money & win exciting rewards instantly. ",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft/balloon",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft/balloon",
            "keywords": "Balloon game, Balloon online game ",
            "openGraph": {
                "title": "Balloon crash games- Play for real money",
                "description": "Balloon casino game is one of the popular online instant games on fun88. Play Balloon for real money & win exciting rewards instantly. ",
                "url": "https://www.gofun88.in/instant-games/smartsoft/balloon",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "mines": {
            "title": "Play Mines Game Online By Spribe & Win Real Money at Fun88",
            "description": "Play the Mines game online by Spribe at Fun88 and get a chance to win real money by strategically discovering hidden treasures.",
            "canonical": "https://www.gofun88.in/instant-games/spribe/mines",
            "alternate": "https://m.gofun88.in/instant-games/spribe/mines",
            "keywords": "Mines game, Online mines game",
            "openGraph": {
                "title": "Play Mines Game Online By Spribe & Win Real Money at Fun88",
                "description": "Play the Mines game online by Spribe at Fun88 and get a chance to win real money by strategically discovering hidden treasures.",
                "url": "https://www.gofun88.in/instant-games/spribe/mines",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "macludo": {
            "title": "Macludo Casino Games Online - Play & Win 24/7",
            "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
            "canonical": "https://www.gofun88.in/live-casino/macludo",
            "alternate": "https://m.gofun88.in/live-casino/macludo",
            "keywords": "Ludo Game, Ludo game Online",
            "openGraph": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "url": "https://www.gofun88.in/instant-games/macludo",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "crazytime": {
            "title": "Crazy Time Live Casino Game by Evolution: Bet on Fun88",
            "description": "Discover the excitement of Crazy Time casino game at Fun88. Sign up today and enter the world of live crazy fun to win real money!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/crazy-time",
            "alternate": "https://m.gofun88.in/live-casino/evolution/crazy-time",
            "keywords": "Crazytime Game, Crazytime game Online",
            "openGraph": {
                "title": "Crazy Time Live Casino Game by Evolution: Bet on Fun88",
                "description": "Discover the excitement of Crazy Time casino game at Fun88. Sign up today and enter the world of live crazy fun to win real money!",
                "url": "https://www.gofun88.in/live-casino/evolution/crazy-time",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "welcomeBonus": {
            "title": "Get 388% massive bonus on your 1st deposit | Fun88 India",
            "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!",
            "canonical": "https://www.gofun88.in/promotions/welcome-bonus",
            "alternate": "https://m.gofun88.in/promotions/welcome-bonus",
            "keywords": "Welcome Bonus",
            "openGraph": {
                "title": "Get 388% massive bonus on your 1st deposit | Fun88 India",
                "description": "Unbelievable 388% BONUS on the first deposit. Don't miss this massive bonus. Only on Fun88 Official India. CLAIM YOUR BONUS NOW!",
                "url": "https://www.gofun88.in/promotions/welcome-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "premiumSportsbook": {
            "title": "Bet on B2B Sports Betting: Trusted Source for Sports Wagering",
            "description": "Enjoy the excitement of B2B online betting in India with Fun88! Bet on your favourite B2B teams with ease and win big with Fun88.",
            "canonical": "https://www.gofun88.in/premium-sportsbook",
            "alternate": "https://m.gofun88.in/premium-sportsbook",
            "keywords": "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India",
            "openGraph": {
                "title": "Bet on B2B Sports Betting: Trusted Source for Sports Wagering",
                "description": "Enjoy the excitement of B2B online betting in India with Fun88! Bet on your favourite B2B teams with ease and win big with Fun88.",
                "url": "https://www.gofun88.in/premium-sportsbook",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "refillBonus": {
            "title": "Redeposit Bonus up to 5% | Fun88 promotions India",
            "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit",
            "canonical": "https://www.gofun88.in/promotions/refill-bonus",
            "alternate": "https://m.gofun88.in/promotions/refill-bonus",
            "keywords": "Refill bonus",
            "openGraph": {
                "title": "Redeposit Bonus up to 5% | Fun88 promotions India",
                "description": "Earn up to 5% bonus based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5% every time you deposit",
                "url": "https://www.gofun88.in/promotions/refill-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "cashbackBonus": {
            "title": "Cashback up to 5% on your Losses | Fun88 Official India",
            "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%.",
            "canonical": "https://www.gofun88.in/promotions/cashback-bonus",
            "alternate": "https://m.gofun88.in/promotions/cashback-bonus",
            "keywords": "Cashback Bonus",
            "openGraph": {
                "title": "Cashback up to 5% on your Losses | Fun88 Official India",
                "description": "Worry is over! Get instant cashback on your weekly losses. Enjoy the benefits based on your membership tier: Blue: 2%, Silver: 3%, Gold: 4%, Platinum: 5%.",
                "url": "https://www.gofun88.in/promotions/cashback-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "referralBonus": {
            "title": "Refer Your Friend & Get 24%- FREE Bonus | Fun88",
            "description": "Want to earn 24% free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
            "canonical": "https://www.gofun88.in/promotions/referral-bonus",
            "alternate": "https://m.gofun88.in/promotions/referral-bonus",
            "keywords": "Referral Bonus",
            "openGraph": {
                "title": "Refer Your Friend & Get 24%/- FREE Bonus | Fun88",
                "description": "Want to earn 24% free bonus? Just share your Fun88 Referral Code or QR Code with your friend(s) and ask them to join Fun88 immediately. T&C apply.",
                "url": "https://www.gofun88.in/promotions/referral-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "termsandconditions": {
            "title": "Terms And Conditions About Betting & Gambling | Fun88 India",
            "description": "Before joining Fun88 India, make sure you read our terms and conditions carefully. Check out the detailed terms and conditions about betting, payments, promotions, bonuses, and more.",
            "canonical": "https://www.gofun88.in/terms-conditions",
            "alternate": "https://m.gofun88.in/about/terms-conditions",
            "keywords": "Terms & Conditions",
            "openGraph": {
                "title": "Terms And Conditions About Betting & Gambling | Fun88 India",
                "description": "Before joining Fun88 India, make sure you read our terms and conditions carefully. Check out the detailed terms and conditions about betting, payments, promotions, bonuses, and more.",
                "url": "https://www.gofun88.in/terms-conditions",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "responsibleGambling": {
            "title": "Learn All About Wagering & Responsible Gaming | Fun88 India",
            "description": "Read the Fun88 India’s responsible gaming section to learn how to gamble responsibly with features such as time outs, deposit limits, and self-exclusions. Know more about the negative effects of gambling and wagering, online and offline.",
            "canonical": "https://www.gofun88.in/responsible-gambling",
            "alternate": "https://m.gofun88.in/about/responsible-gambling",
            "keywords": "Responsible Gambling",
            "openGraph": {
                "title": "Learn All About Wagering & Responsible Gaming | Fun88 India",
                "description": "Read the Fun88 India’s responsible gaming section to learn how to gamble responsibly with features such as time outs, deposit limits, and self-exclusions. Know more about the negative effects of gambling and wagering, online and offline.",
                "url": "https://www.gofun88.in/responsible-gambling",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "privacyPolicy": {
            "title": "Learn Everything About Our Privacy Policy | Fun88 India",
            "description": "At Fun88 India, we are dedicated to keeping your details safe and secure at all times. Check out our Privacy Policy page for more information.",
            "canonical": "https://www.gofun88.in/privacy-policy",
            "alternate": "https://m.gofun88.in/about/privacy-policy",
            "keywords": "privacy policy",
            "openGraph": {
                "title": "Learn Everything About Our Privacy Policy | Fun88 India",
                "description": "At Fun88 India, we are dedicated to keeping your details safe and secure at all times. Check out our Privacy Policy page for more information.",
                "url": "https://www.gofun88.in/privacy-policy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "kycPolicy": {
            "title": "KYC Policy of FUN88 | Find the KYC Requirements & Process",
            "description": " Find the detailed KYC policy of FUN88. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
            "canonical": "https://www.gofun88.in/kyc-policy",
            "alternate": "https://m.gofun88.in/about/kyc-policy",
            "keywords": "KYC Policy",
            "openGraph": {
                "title": "KYC Policy of FUN88 | Find the KYC Requirements & Process",
                "description": " Find the detailed KYC policy of FUN88. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
                "url": "https://www.gofun88.in/kyc-policy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "exclusionPolicy": {
            "title": "Self-Exclusion Policy of FUN88 | What & How",
            "description": "Find what is the Self-Exclusion Policy of FUN88. Get detailed information of the entire process of self-exclusion.",
            "canonical": "https://www.gofun88.in/exclusion-policy",
            "alternate": "https://m.gofun88.in/about/self-exclusion-policy",
            "keywords": "exclusion policy ",
            "openGraph": {
                "title": "Self-Exclusion Policy of FUN88 | What & How",
                "description": "Find what is the Self-Exclusion Policy of FUN88. Get detailed information of the entire process of self-exclusion.",
                "url": "https://www.gofun88.in/exclusion-policy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "amlPolicy": {
            "title": "AML Policy of FUN88 | Anti-Money Laundering Policy",
            "description": " Find the complete Anti-Money Laundering policy of FUN88. Get the details of compliance procedures.",
            "canonical": "https://www.gofun88.in/aml-policy",
            "alternate": "https://m.gofun88.in/about/aml-policy",
            "keywords": "AML Policy",
            "openGraph": {
                "title": "AML Policy of FUN88 | Anti-Money Laundering Policy",
                "description": " Find the complete Anti-Money Laundering policy of FUN88. Get the details of compliance procedures.",
                "url": "https://www.gofun88.in/aml-policy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "aboutus": {
            "title": "About Us | Online Gambling Company in India | Fun88 India",
            "description": "Learn all about Fun88 India, one of the country’s biggest and fastest growing online gambling companies. Check out the About Us page today!",
            "canonical": "https://www.gofun88.in/about-us",
            "alternate": "https://m.gofun88.in/about/about-us",
            "keywords": "About us, About fun88",
            "openGraph": {
                "title": "About Us | Online Gambling Company in India | Fun88 India",
                "description": "Learn all about Fun88 India, one of the country’s biggest and fastest growing online gambling companies. Check out the About Us page today!",
                "url": "https://www.gofun88.in/aml-policy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "SPRIBE": {
            "title": "Spribe - Leading Dealer of Online Instant Games",
            "description": "Discover the best dealer of instant games with Spribe. Enjoy exciting online gaming at Fun88 for an immersive experience.",
            "canonical": "https://www.gofun88.in/instant-games/spribe",
            "alternate": "https://m.gofun88.in/instant-games/spribe",
            "keywords": "spribe casino games",
            "openGraph": {
                "title": "Spribe - Leading Dealer of Online Instant Games",
                "description": "Discover the best dealer of instant games with Spribe. Enjoy exciting online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/instant-games/spribe",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "aviatrix": {
            "title": "Aviatrix Game: Play at fun88 & Win Real Money Online",
            "description": "fun88 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
            "canonical": "https://www.gofun88.in/instant-games/aviatrix",
            "alternate": "https://m.gofun88.in/instant-games/aviatrix",
            "keywords": "Aviatrix casino games",
            "openGraph": {
                "title": "Aviatrix Game: Play at fun88 & Win Real Money Online",
                "description": "fun88 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
                "url": "https://www.gofun88.in/instant-games/aviatrix",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "7MOJOS": {
            "title": "7Mojos Games | Explore the top games at the best platform",
            "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
            "canonical": "https://www.gofun88.in/live-casino/7mojos",
            "alternate": "https://m.gofun88.in/live-casino/7mojos",
            "keywords": "7Mojos Games",
            "openGraph": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "url": "https://www.gofun88.in/live-casino/7mojos",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "supernowa": {
            "title": "Play Supernowa Casino Games on FUN88",
            "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on FUN88.",
            "canonical": "https://www.gofun88.in/live-casino/supernowa",
            "alternate": "https://m.gofun88.in/live-casino/supernowa",
            "keywords": "Supernowa Casino Games,Supernowa gaming, Supernowa casino",
            "openGraph": {
                "title": "Play Supernowa Casino Games on FUN88",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on FUN88.",
                "url": "https://www.gofun88.in/live-casino/supernowa",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "pragmatic": {
            "title": "Pragmatic Casino Game on fun88 | Get 388% Welcome Bonus",
            "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
            "canonical": "https://www.gofun88.in/slots/pragmatic-play",
            "alternate": "https://m.gofun88.in/slots/pragmatic-play",
            "keywords": "Pragmatic casino, pragmatic games",
            "openGraph": {
                "title": "Pragmatic Casino Game on fun88 | Get 388% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "url": "https://www.gofun88.in/slots/pragmatic-play",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "superspade": {
            "title": "Play Super Spade Live Casino Games at Fun88",
            "description": "Get ready for non-stop fun with Fun88's Super Spade live casino games. Join us now and enjoy the thrill of gaming!",
            "canonical": "https://www.gofun88.in/live-casino/super-spade",
            "alternate": "https://m.gofun88.in/live-casino/super-spade",
            "keywords": "super spade games,play super spade casino games, super spade casino",
            "openGraph": {
                "title": "Play Super Spade Live Casino Games at Fun88",
                "description": "Get ready for non-stop fun with Fun88's Super Spade live casino games. Join us now and enjoy the thrill of gaming!",
                "url": "https://www.gofun88.in/live-casino/super-spade",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "tvbet": {
            "title": "Play TVBET Casino Games Online | Real Money Games",
            "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about FUN88 TVBET Games.",
            "canonical": "https://www.gofun88.in/live-casino/tvbet",
            "alternate": "https://m.gofun88.in/live-casino/tvbet",
            "keywords": "TVBET casino games, FUN88 TVBET games",
            "openGraph": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about FUN88 TVBET Games.",
                "url": "https://www.gofun88.in/live-casino/tvbet",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "netent": {
            "title": "NetEnt - Leading Provider of Online Slot Games",
            "description": "Discover top slot games by NetEnt. Enjoy thrilling online gaming at Fun88 for an immersive experience.",
            "canonical": "https://www.gofun88.in/slots/netent",
            "alternate": "https://m.gofun88.in/slots/netent",
            "keywords": "netent games",
            "openGraph": {
                "title": "NetEnt - Leading Provider of Online Slot Games",
                "description": "Discover top slot games by NetEnt. Enjoy thrilling online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/slots/netent",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "betgames": {
            "title": "Betgames - Leading Online TV Games Dealer",
            "description": "Discover the best online dealer TV games with Betgames. Enjoy exciting online gaming at Fun88 for an immersive experience.",
            "canonical": "https://www.gofun88.in/live-casino/betgames",
            "alternate": "https://m.gofun88.in/live-casino/betgames",
            "keywords": "betgames,FUN88 BetGames",
            "openGraph": {
                "title": "Betgames - Leading Online TV Games Dealer",
                "description": "Discover the best online dealer TV games with Betgames. Enjoy exciting online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/live-casino/betgames",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "sportsbook": {
            "title": "BTi Sports Betting: Trusted Source for Sports Wagering",
            "description": "Enjoy the excitement of BTi online betting in India with Fun88! Bet on your favourite BTi teams with ease and win big with Fun88.",
            "canonical": "https://www.gofun88.in/sportsbook",
            "alternate": "https://m.gofun88.in/sportsbook",
            "keywords": "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India",
            "openGraph": {
                "title": "BTi Sports Betting: Trusted Source for Sports Wagering",
                "description": "Enjoy the excitement of BTi online betting in India with Fun88! Bet on your favourite BTi teams with ease and win big with Fun88.",
                "url": "https://www.gofun88.in/sportsbook",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "appDownload": {
            "title": "Best Betting App For Cricket, Football, & Other Sports In India | Fun88",
            "description": "Fun88 is an ideal online betting (satta) app for punters willing to bet on cricket, football, kabbadi, etc. in India. Download Fun88 to win prizes and rewards!",
            "canonical": "https://www.gofun88.in/app-download",
            "alternate": "https://m.gofun88.in/app-download",
            "keywords": "Yolo App Download, Yolo Android App",
            "openGraph": {
                "title": "Best Betting App For Cricket, Football, & Other Sports In India | Fun88",
                "description": "Fun88 is an ideal online betting (satta) app for punters willing to bet on cricket, football, kabbadi, etc. in India. Download Fun88 to win prizes and rewards!",
                "url": "https://www.gofun88.in/app-download",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "signup": {
            "title": "Fun88 India Registration | Sign Up Today!",
            "description": "Sign up on Fun88 official website, the best online sports betting market, an action-packed online casino, and a host of big promotions and bonuses. Enjoy more such features with Fun88 online registration.",
            "canonical": "https://www.gofun88.in/signup",
            "alternate": "https://m.gofun88.in/signup",
            "keywords": "Yolo User Registration",
            "openGraph": {
                "title": "Fun88 India Registration | Sign Up Today!",
                "description": "Sign up on Fun88 official website, the best online sports betting market, an action-packed online casino, and a host of big promotions and bonuses. Enjoy more such features with Fun88 online registration.",
                "url": "https://www.gofun88.in/signup",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "faq": {
            "title": "Frequently Asked Questions (FAQ) On Online Betting & Gambling | Fun88",
            "description": "Visit Fun88's FAQ page, where we'll help you if you have any doubts about online betting and gambling. Read about how to sign up or open an account, deposit and withdraw money, etc.",
            "canonical": "https://www.gofun88.in/faq",
            "alternate": "https://m.gofun88.in/faq",
            "openGraph": {
                "title": "Frequently Asked Questions (FAQ) On Online Betting & Gambling | Fun88",
                "description": "Visit Fun88's FAQ page, where we'll help you if you have any doubts about online betting and gambling. Read about how to sign up or open an account, deposit and withdraw money, etc.",
                "url": "https://www.gofun88.in/faq",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "affiliate": {
            "title": "Best Affiliate Program in India for Online Sports Betting | Fun88",
            "description": "Learn more about the Fun88 India affiliate program, including the ways you can join online sports betting and other gambling contests like live casino, the rewards you can earn, the network you can develop, and so on! Fun88 offers one of the best affiliate marketing programs in India.",
            "canonical": "https://www.gofun88.in/affiliate",
            "alternate": "https://m.gofun88.in/affiliate",
            "openGraph": {
                "title": "Best Affiliate Program in India for Online Sports Betting | Fun88",
                "description": "Learn more about the Fun88 India affiliate program, including the ways you can join online sports betting and other gambling contests like live casino, the rewards you can earn, the network you can develop, and so on! Fun88 offers one of the best affiliate marketing programs in India.",
                "url": "https://www.gofun88.in/affiliate",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "loyalty": {
            "title": "Fun88 VIP Club",
            "description": "At Fun88 we believe that all the time you spend online should be rewarding and entertaining. We will treat you like royalty. What we want to do is offer you the best VIP service.",
            "canonical": "https://www.gofun88.in/loyalty",
            "alternate": "https://m.gofun88.in/loyalty",
            "openGraph": {
                "title": "Fun88 VIP Club",
                "description": "At Fun88 we believe that all the time you spend online should be rewarding and entertaining. We will treat you like royalty. What we want to do is offer you the best VIP service.",
                "url": "https://www.gofun88.in/loyalty",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "login": {
            "title": "Fun88 Login - Online Sports Betting and Casino",
            "description": "Log in to your Fun88 account or register for a new one to enjoy a wide range of online sports betting and casino games. Join the excitement today!",
            "canonical": "https://www.gofun88.in/login",
            "alternate": "https://m.gofun88.in/login",
            "keywords": "Yolo Login",
            "openGraph": {
                "title": "Fun88 Login - Online Sports Betting and Casino",
                "description": "Log in to your Fun88 account or register for a new one to enjoy a wide range of online sports betting and casino games. Join the excitement today!",
                "url": "https://www.gofun88.in/login",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "forgotPassword": {
            "title": "Forgot Password - Reset Your Fun88 Betting and Casino Account",
            "description": "Forget your Fun88 account password? No worries! Reset your account password securely and regain access to online sports betting and casino games. Regain control now!",
            "canonical": "https://www.gofun88.in/forgot-password",
            "alternate": "https://m.gofun88.in/forgot-password",
            "keywords": "",
            "openGraph": {
                "title": "Forgot Password - Reset Your Fun88 Betting and Casino Account",
                "description": "Forget your Fun88 account password? No worries! Reset your account password securely and regain access to online sports betting and casino games. Regain control now!",
                "url": "https://www.gofun88.in/forgot-password",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "instantGames": {
            "title": "Play Instant Games Online and Win Real Cash Prizes at Fun88",
            "description": "Play instant card games like Aviator, Mini Roulette, Dice & More in India! Try your best in instant games and compete with your friends!",
            "canonical": "https://www.gofun88.in/instant-games",
            "alternate": "https://m.gofun88.in/instant-games",
            "keywords": "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites ",
            "openGraph": {
                "title": "Play Instant Games Online and Win Real Cash Prizes at Fun88",
                "description": "Play instant card games like Aviator, Mini Roulette, Dice & More in India! Try your best in instant games and compete with your friends!",
                "url": "https://www.gofun88.in/instant-games",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "majorLeagueCricketBetting": {
            "title": "MLC Betting in India | Major League Cricket Betting Online",
            "description": "MLC Betting is available on fun88! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
            "canonical": "https://www.gofun88.in/sports/tournaments/major-league-cricket-betting",
            "alternate": "https://m.gofun88.in/sports/tournaments/major-league-cricket-betting",
            "keywords": "Major League Cricket Betting Online, MLC Betting in India",
            "openGraph": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on fun88! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "url": "https://www.gofun88.in/sports/tournaments/major-league-cricket-betting",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "dailyDepositBonus": {
            "title": "Refill bonus | Best Casino Refill Bonus on fun88",
            "description": "fun88 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fun88.",
            "canonical": "https://www.gofun88.in/daily-deposit-bonus",
            "alternate": "https://m.gofun88.in/daily-deposit-bonus",
            "keywords": "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites ",
            "openGraph": {
                "title": "Refill bonus | Best Casino Refill Bonus on fun88",
                "description": "fun88 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fun88.",
                "url": "https://www.gofun88.in/daily-deposit-bonus",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "popular-games": {
            "title": "Best Casino Games | Online Casino Real Money on FUN88",
            "description": "Best casino games on FUN88 provide the best online gambling experience. Choose any of the top casino games & play online casino real money. Get free demo!",
            "canonical": "https://www.gofun88.in/popular-games",
            "alternate": "https://m.gofun88.in/popular-games",
            "keywords": "Best Casino Games Popular Casino Games, online casino real money",
            "openGraph": {
                "title": "Best Casino Games | Online Casino Real Money on FUN88",
                "description": "Best casino games on FUN88 provide the best online gambling experience. Choose any of the top casino games & play online casino real money. Get free demo!",
                "url": "https://www.gofun88.in/popular-games",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "evolution": {
            "title": "Discover Evolution's Live Casino Games Online in India | Fun88",
            "description": "Level up your gaming experience with Evolution's live casino. Experience real-time games, interactive dealers & immersive excitement. Don't miss out, join now!",
            "canonical": "https://www.gofun88.in/live-casino/evolution",
            "alternate": "https://m.gofun88.in/live-casino/evolution",
            "keywords": "Evolution Gaming, Evolution Gaming Casino",
            "openGraph": {
                "title": "Discover Evolution's Live Casino Games Online in India | Fun88",
                "description": "Level up your gaming experience with Evolution's live casino. Experience real-time games, interactive dealers & immersive excitement. Don't miss out, join now!",
                "url": "https://www.gofun88.in/live-casino/evolution",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },

        "spribe": {
            "title": "Spribe - Leading Dealer of Online Instant Games",
            "description": "Discover the best dealer of instant games with Spribe. Enjoy exciting online gaming at Fun88 for an immersive experience.",
            "canonical": "https://www.gofun88.in/instant-games/spribe",
            "alternate": "https://m.gofun88.in/instant-games/spribe",
            "keywords": "Spribe Games,fun88 Spribe games,Yolo Spribe games,Spribe live casino games",
            "openGraph": {
                "title": "Spribe - Leading Dealer of Online Instant Games",
                "description": "Discover the best dealer of instant games with Spribe. Enjoy exciting online gaming at Fun88 for an immersive experience.",
                "url": "https://www.gofun88.in/instant-games/spribe",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "cricketx": {
            "title": "Play CricketX Game Online - Play Instant Game for Real Cash",
            "description": "Cricketx casino game offers quality gameplay among all instant games. Play online Cricketx game for real money. Best combination of sports & casino!",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft/cricketx",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft/cricketx",
            "keywords": "Cricketx Games,fun88 Cricketx games,Yolo Cricketx games,Cricketx casino games",
            "openGraph": {
                "title": "Play CricketX Game Online - Play Instant Game for Real Cash",
                "description": "Cricketx casino game offers quality gameplay among all instant games. Play online Cricketx game for real money. Best combination of sports & casino!",
                "url": "https://www.gofun88.in/instant-games/smartsoft/cricketx",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "ezugi": {
            "title": "Play Ezugi Live Casino Games at Fun88",
            "description": "Join Fun88 to enjoy live casino games by Ezugi Games. Dive into the action and win big with our thrilling games! Play Now!",
            "canonical": "https://www.gofun88.in/live-casino/ezugi",
            "alternate": "https://m.gofun88.in/live-casino/ezugi",
            "keywords": "Ezugi Live Casino,Ezugi, Ezugi Gaming, FUN88 Ezugi Live Casino",
            "openGraph": {
                "title": "Play Ezugi Live Casino Games at Fun88",
                "description": "Join Fun88 to enjoy live casino games by Ezugi Games. Dive into the action and win big with our thrilling games! Play Now!",
                "url": "https://www.gofun88.in/live-casino/ezugi",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "sexygaming": {
            "title": "Play Sexy Gaming Live Casino Games at Fun88",
            "description": "Experience the thrill of live casino games by Sexy Gaming at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
            "canonical": "https://www.gofun88.in/live-casino/sexy-gaming",
            "alternate": "https://m.gofun88.in/live-casino/sexy-gaming",
            "keywords": "sexy gaming casino,sexy gaming casino games, FUN88 sexy gaming casino games, FUN88 sexy gaming live casino game",
            "openGraph": {
                "title": "Play Sexy Gaming Live Casino Games at Fun88",
                "description": "Experience the thrill of live casino games by Sexy Gaming at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
                "url": "https://www.gofun88.in/live-casino/sexy-gaming",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "monopolylive": {
            "title": "Play MONOPOLY Live Casino Game by Evolution Gaming at Fun88",
            "description": "Experience the fun of MONOPOLY Live Casino Game by Evolution Gaming. Play now and win real money with live casino games at Fun88.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/monopoly-live",
            "alternate": "https://m.gofun88.in/live-casino/evolution/monopoly-live",
            "keywords": "MONOPOLY LIVE",
            "openGraph": {
                "title": "Play MONOPOLY Live Casino Game by Evolution Gaming at Fun88",
                "description": "Experience the fun of MONOPOLY Live Casino Game by Evolution Gaming. Play now and win real money with live casino games at Fun88.",
                "url": "https://www.gofun88.in/live-casino/evolution/monopoly-live",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "funkytime": {
            "title": "Play Funky Time Live Casino Game by Evolution Gaming at Fun88",
            "description": "Play Funky Time exciting live casino game show by Evolution with Epic multiplier real money prize. Bet today and win big rewards! ",
            "canonical": "https://www.gofun88.in/live-casino/evolution/funky-time",
            "alternate": "https://m.gofun88.in/live-casino/evolution/funky-time",
            "keywords": "funkytime",
            "openGraph": {
                "title": "Play Funky Time Live Casino Game by Evolution Gaming at Fun88",
                "description": "Play Funky Time exciting live casino game show by Evolution with Epic multiplier real money prize. Bet today and win big rewards! ",
                "url": "https://www.gofun88.in/live-casino/evolution/funky-time",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "lightningdice": {
            "title": "Play Lightning Dice Casino Game by Evolution Gaming | Fun88",
            "description": "Experience the thrill of a live casino game with Lightning Dice by Evolution Gaming at Fun88! Play casino online and win big with Lightning Dice.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/lightning-dice",
            "alternate": "https://m.gofun88.in/live-casino/evolution/lightning-dice",
            "keywords": "Lightning Dice",
            "openGraph": {
                "title": "Play Lightning Dice Casino Game by Evolution Gaming | Fun88",
                "description": "Experience the thrill of a live casino game with Lightning Dice by Evolution Gaming at Fun88! Play casino online and win big with Lightning Dice.",
                "url": "https://www.gofun88.in/live-casino/evolution/lightning-dice",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "macaw": {
            "title": "Macaw Games | Top Macaw Casino Online Games on FUN88",
            "description": "Macaw games are thrilling and are available online on FUN88. Learn more about the popular Macaw casino online games.",
            "canonical": "https://www.gofun88.in/live-casino/macaw",
            "alternate": "https://m.gofun88.in/live-casino/macaw",
            "keywords": "macaw games, macaw casino online",
            "openGraph": {
                "title": "Macaw Games | Top Macaw Casino Online Games on FUN88",
                "description": "Macaw games are thrilling and are available online on FUN88. Learn more about the popular Macaw casino online games.",
                "url": "https://www.gofun88.in/live-casino/macaw",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "ag": {
            "title": "Play Amazing Gaming Live Casino Games at Fun88",
            "description": "Experience the thrill of live casino games by Amazing Gaming at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
            "canonical": "https://www.gofun88.in/live-casino/ag",
            "alternate": "https://m.gofun88.in/live-casino/ag",
            "keywords": "Amazing Gaming",
            "openGraph": {
                "title": "Play Amazing Gaming Live Casino Games at Fun88",
                "description": "Experience the thrill of live casino games by Amazing Gaming at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
                "url": "https://www.gofun88.in/live-casino/ag",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "asiaCupBonanza": {
            "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
            "description": "Online cricket betting on fun88 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
            "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fun88 online cricket betting, fun88 cricket betting odds, fun88 cricket betting, cricket betting sites, online cricket satta bazar",
            "canonical": "https://www.gofun88.in/asia-cup-bonanza",
            "alternate": "https://m.gofun88.in/asia-cup-bonanza",
            "openGraph": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on fun88 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "url": "https://www.gofun88.in/asia-cup-bonanza",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },

        "bingo": {
            "title": "Play Online Bingo Games on FUN88 Casino with real money",
            "description": "Online Bingo games on FUN88 allow you to play and place bets with INR payments. All types of Bingo games are available. Play now!",
            "canonical": "https://www.gofun88.in/live-casino/bingo",
            "alternate": "https://m.gofun88.in/live-casino/bingo",
            "keywords": "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Play Online Bingo Games on FUN88 Casino with real money",
                "description": "Online Bingo games on FUN88 allow you to play and place bets with INR payments. All types of Bingo games are available. Play now!",
                "url": "https://www.gofun88.in/live-casino/bingo",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "Funkytime": {
            "title": "Play Funky Time Casino Online Game at FUN88",
            "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on FUN88.",
            "canonical": "https://www.gofun88.in/live-casino/Funkytime",
            "alternate": "https://m.gofun88.in/live-casino/Funkytime",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Play Funky Time Casino Online Game at FUN88",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on FUN88.",
                "url": "https://www.gofun88.in/live-casino/Funkytime",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "evo": {
            "title": "Evo Casino Game on FUN88 | Get Upto 388%  Bonus",
            "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on FUN88",
            "canonical": "https://www.gofun88.in/live-casino/evo",
            "alternate": "https://m.gofun88.in/live-casino/evo",
            "keywords": "Evo Gaming, Evolution Gaming Casino, Evo Casino Game",
            "openGraph": {
                "title": "Evo Casino Game on FUN88 | Get Upto 388%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on FUN88",
                "url": "https://www.gofun88.in/live-casino/evo",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "nlc": {
            "title": "NLC Casino Game on FUN88 | 388% Signup  Bonus",
            "description": "Casino slot games are high on entertainment. Play slot games for real money on FUN88 and earn exciting rewards.",
            "canonical": "https://www.gofun88.in/slots/no-limit-city",
            "alternate": "https://m.gofun88.in/slots/no-limit-city",
            "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games",
            "openGraph": {
                "title": "NLC Casino Game on FUN88 | 388% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on FUN88 and earn exciting rewards.",
                "url": "https://www.gofun88.in/slots/no-limit-city",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "Funky Time": {
            "title": "Play Funky Time Live Casino Game by Evolution Gaming at Fun88",
            "description": "Play Funky Time exciting live casino game show by Evolution with Epic multiplier real money prize. Bet today and win big rewards! ",
            "canonical": "https://www.gofun88.in/live-casino/evolution/funky-time",
            "alternate": "https://m.gofun88.in/live-casino/evolution/funky-time",
            "keywords": "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Play Funky Time Live Casino Game by Evolution Gaming at Fun88",
                "description": "Play Funky Time exciting live casino game show by Evolution with Epic multiplier real money prize. Bet today and win big rewards! ",
                "url": "https://www.gofun88.in/live-casino/evolution/funky-time",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "First-Person-Blackjack": {
            "title": "Play Blackjack Live Casino Games | Free or Real Money",
            "description": "Play blackjack online on the most safest online casino. Register now at FUN88 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on FUN88, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
            "canonical": "https://www.gofun88.in/live-casino/first%20person%20blackjack",
            "alternate": "https://m.gofun88.in/live-casino/first%20person%20blackjack",
            "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games",
            "openGraph": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at FUN88 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on FUN88, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "url": "https://www.gofun88.in/live-casino/first%20person%20blackjack",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "teenpatti2020-2020": {
            "title": "Play Teenpatti Live Casino Games | Free or Real Money",
            "description": "Teenpatti 2020 is a popular casino card game on FUN88. Play this exciting card game for endless entertainment and big wins.",
            "canonical": "https://www.gofun88.in/live-casino/teenpatti%2020%2020",
            "alternate": "https://www.gofun88.in/live-casino/teenpatti%2020%2020",
            "keywords": "Teenpatti 2020",
            "openGraph": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti 2020 is a popular casino card game on FUN88. Play this exciting card game for endless entertainment and big wins.",
                "url": "https://www.gofun88.in/live-casino/teenpatti%2020%2020",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        //member dashboard pages
        "memberDashboard": {
            "canonical": "https://www.gofun88.in/member/profile",
            "alternate": "https://m.gofun88.in/member/profile",
            "openGraph": {
                "url": "https://www.gofun88.in/member/profile",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberProfileAccountInfo": {
            "canonical": "https://www.gofun88.in/member/profile/accountinfo",
            "alternate": "https://m.gofun88.in/member/profile/accountinfo",
            "openGraph": {
                "url": "https://www.gofun88.in/member/profile/accountinfo",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberProfileChangePassword": {
            "canonical": "https://www.gofun88.in/member/profile/password",
            "alternate": "https://m.gofun88.in/member/profile/password",
            "openGraph": {
                "url": "https://www.gofun88.in/member/profile/password",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberProfileBankDetails": {
            "canonical": "https://www.gofun88.in/member/profile/bankdetails",
            "alternate": "https://m.gofun88.in/member/profile/bankdetails",
            "openGraph": {
                "url": "https://www.gofun88.in/member/profile/bankdetails",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberProfileKYC": {
            "canonical": "https://www.gofun88.in/member/profile/kycpage",
            "alternate": "https://m.gofun88.in/member/profile/kycpage",
            "openGraph": {
                "url": "https://www.gofun88.in/member/profile/kycpage",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberFinanceDeposit": {
            "canonical": "https://www.gofun88.in/member/finance/deposit",
            "alternate": "https://m.gofun88.in/member/finance/deposit",
            "openGraph": {
                "url": "https://www.gofun88.in/member/finance/deposit",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberFinanceWithdrawal": {
            "canonical": "https://www.gofun88.in/member/finance/withdrawal",
            "alternate": "https://m.gofun88.in/member/finance/withdrawal",
            "openGraph": {
                "url": "https://www.gofun88.in/member/finance/withdrawal",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberFinanceHistory": {
            "canonical": "https://www.gofun88.in/member/finance/history",
            "alternate": "https://m.gofun88.in/member/finance/history",
            "openGraph": {
                "url": "https://www.gofun88.in/member/finance/history",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberFinanceTransfer": {
            "canonical": "https://www.gofun88.in/member/finance/transfer",
            "alternate": "https://m.gofun88.in/member/finance/transfer",
            "openGraph": {
                "url": "https://www.gofun88.in/member/finance/transfer",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberMyBets": {
            "canonical": "https://www.gofun88.in/member/betDetails/mybets",
            "alternate": "https://m.gofun88.in/member/betDetails/mybets",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/mybets",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberP&L": {
            "canonical": "https://www.gofun88.in/member/betDetails/profitandloss",
            "alternate": "https://m.gofun88.in/member/betDetails/profitandloss",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/profitandloss",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberCasinoStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/casinostatement",
            "alternate": "https://m.gofun88.in/member/betDetails/casinostatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/casinostatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberSportsbookStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/sportbookstatement",
            "alternate": "https://m.gofun88.in/member/betDetails/sportbookstatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/sportbookstatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberSTPStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/splitThePotStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/splitThePotStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/splitThePotStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberGalaxysStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/galaxyStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/galaxyStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/galaxyStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberAviatrixStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/aviatrixStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/aviatrixStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/aviatrixStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberEvoPlayStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/evoplayStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/evoplayStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/evoplayStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberJiliStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/jiliStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/jiliStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/jiliStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberTurboGamesStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/turbogamesStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/turbogamesStatement"
        },
        "memberIndiaLotteryStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/indiaLotteryStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/indiaLotteryStatement",
        },
        "memberAccountStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/accountStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/accountStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/accountStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberVirtualSportsStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/virtualSportsStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/virtualSportsStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/virtualSportsStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberTurnoverStatement": {
            "canonical": "https://www.gofun88.in/member/betDetails/turnoverStatement",
            "alternate": "https://m.gofun88.in/member/betDetails/turnoverStatement",
            "openGraph": {
                "url": "https://www.gofun88.in/member/betDetails/turnoverStatement",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberPrormotions": {
            "canonical": "https://www.gofun88.in/member/promotions",
            "alternate": "https://m.gofun88.in/member/promotions",
            "openGraph": {
                "url": "https://www.gofun88.in/member/promotions",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "memberMessages": {
            "canonical": "https://www.gofun88.in/member/message",
            "alternate": "https://m.gofun88.in/member/message",
            "openGraph": {
                "url": "https://www.gofun88.in/member/message",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "ninjacrash": {
            "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
            "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
            "canonical": "https://www.gofun88.in/instant-games/galaxsys/ninja-crash",
            "alternate": "https://m.gofun88.in/instant-games/galaxsys/ninja-crash",
            "keywords": "NinjaCrash game, Online NinjaCrash game",
            "openGraph": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "url": "https://www.gofun88.in/instant-games/galaxsys/ninja-crash",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "big-bash": {
            "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
            "description": "Play the most popular online casino games at fun88 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
            "canonical": "https://www.gofun88.in/promotions/big-bash",
            "alternate": "https://m.gofun88.in/promotions/big-bash",
            "keywords": "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site",
            "openGraph": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at fun88 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "url": "https://www.gofun88.in/promotions/big-bash",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },

        "smartsoft": {
            "title": "Smartsoft",
            "description": "Smartsoft game",
            "canonical": "https://www.gofun88.in/instant-games/smartsoft",
            "alternate": "https://m.gofun88.in/instant-games/smartsoft",
            "keywords": "",
            "openGraph": {
                "title": "Smartsoft",
                "description": "Smartsoft game",
                "url": "https://www.gofun88.in/instant-games/smartsoft",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "dice": {
            "title": "Play Dice Instant Game By Spribe | Fun88",
            "description": "Take your gaming to the next level with Dice by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
            "canonical": "https://www.gofun88.in/instant-games/spribe/dice",
            "alternate": "https://m.gofun88.in/instant-games/spribe/dice",
            "keywords": "",
            "openGraph": {
                "title": "Play Dice Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Dice by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/dice",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "goal": {
            "title": "Play Goal Instant Game By Spribe | Fun88",
            "description": "Take your gaming to the next level with Goal by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
            "canonical": "https://www.gofun88.in/instant-games/spribe/goal",
            "alternate": "https://m.gofun88.in/instant-games/spribe/goal",
            "keywords": "",
            "openGraph": {
                "title": "Play Goal Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Goal by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/goal",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "keno": {
            "title": "Play Live Keno Instant Game By Spribe | Fun88",
            "description": "Experience the thrill of instant games with Keno by Spribe Gaming at Fun88! Play Keno instant game and win big.",
            "canonical": "https://www.gofun88.in/instant-games/spribe/keno",
            "alternate": "https://m.gofun88.in/instant-games/spribe/keno",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Keno Instant Game By Spribe | Fun88",
                "description": "Experience the thrill of instant games with Keno by Spribe Gaming at Fun88! Play Keno instant game and win big.",
                "url": "https://www.gofun88.in/instant-games/spribe/keno",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "plinko": {
            "title": "Play Plinko Online - Pachinko Real Money Casino Game India",
            "description": "Plinko game online excitement awaits! Explore Pachinko, the real money casino game in India. Join the fun, play now, and elevate your gaming with thrilling wins!",
            "canonical": "https://www.gofun88.in/instant-games/spribe/plinko",
            "alternate": "https://m.gofun88.in/instant-games/spribe/plinko",
            "keywords": "",
            "openGraph": {
                "title": "Play Plinko Online - Pachinko Real Money Casino Game India",
                "description": "Plinko game online excitement awaits! Explore Pachinko, the real money casino game in India. Join the fun, play now, and elevate your gaming with thrilling wins!",
                "url": "https://www.gofun88.in/instant-games/spribe/plinko",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "andar-bahar": {
            "title": "Play Andar Bahar Games Online | Best Andar Bahar Sites In India | Fun88",
            "description": "Love playing Andar Bahar games online? Now enjoy Free online Andar Bahar or play online Andar Bahar with friends on Fun88. Register now to play Andar Bahar with real money!",
            "canonical": "https://www.gofun88.in/live-casino/andar-bahar",
            "alternate": "https://m.gofun88.in/live-casino/andar-bahar",
            "keywords": "",
            "openGraph": {
                "title": "Play Andar Bahar Games Online | Best Andar Bahar Sites In India | Fun88",
                "description": "Love playing Andar Bahar games online? Now enjoy Free online Andar Bahar or play online Andar Bahar with friends on Fun88. Register now to play Andar Bahar with real money!",
                "url": "https://www.gofun88.in/live-casino/andar-bahar",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "lucky-7": {
            "title": "Play Lucky 7 Online For Real Money by Bet Games | Fun88",
            "description": "Enjoy the excitement of a tv game with Lucky 7 by Bet games on Fun88! Play online and win big with Lucky 7. Have endless fun and entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/betgames/lucky-7",
            "alternate": "https://m.gofun88.in/live-casino/betgames/lucky-7",
            "keywords": "",
            "openGraph": {
                "title": "Play Lucky 7 Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Lucky 7 by Bet games on Fun88! Play online and win big with Lucky 7. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/lucky-7",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "skyward": {
            "title": "Play Skyward Game: Betgames Crash Adventure | Fun88",
            "description": "Experience the thrill of Skyward Game – an instant crash game by Betgames. Play for real money excitement at Fun88. Dive into the action now!",
            "canonical": "https://www.gofun88.in/live-casino/betgames/skyward",
            "alternate": "https://m.gofun88.in/live-casino/betgames/skyward",
            "keywords": "",
            "openGraph": {
                "title": "Play Skyward Game: Betgames Crash Adventure | Fun88",
                "description": "Experience the thrill of Skyward Game – an instant crash game by Betgames. Play for real money excitement at Fun88. Dive into the action now!",
                "url": "https://www.gofun88.in/live-casino/betgames/skyward",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "american-roulette": {
            "title": "American Roulette - Play Online Roulette by Evolution Gaming",
            "description": "Play American Roulette online with Evolution Gaming ✓ Experience best roulette action ✓ Sign-Up Not Required ✓ Game Demo Version Available ✓ Get 100% welcome bonuses.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/american-roulette",
            "alternate": "https://m.gofun88.in/live-casino/evolution/american-roulette",
            "keywords": "",
            "openGraph": {
                "title": "American Roulette - Play Online Roulette by Evolution Gaming",
                "description": "Play American Roulette online with Evolution Gaming ✓ Experience best roulette action ✓ Sign-Up Not Required ✓ Game Demo Version Available ✓ Get 100% welcome bonuses.",
                "url": "https://www.gofun88.in/live-casino/evolution/american-roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "dream-catcher": {
            "title": "Dream Catcher Game | Live Casino Evolution Game | Fun88",
            "description": "Play Live Dream Catcher at Fun88 with Evolution! Explore dream catcher rules, play guidelines, and win real money online. Start your winning journey now!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/dream-catcher",
            "alternate": "https://m.gofun88.in/live-casino/evolution/dream-catcher",
            "keywords": "",
            "openGraph": {
                "title": "Dream Catcher Game | Live Casino Evolution Game | Fun88",
                "description": "Play Live Dream Catcher at Fun88 with Evolution! Explore dream catcher rules, play guidelines, and win real money online. Start your winning journey now!",
                "url": "https://www.gofun88.in/live-casino/evolution/dream-catcher",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "hindi-lightning-roulette": {
            "title": "Play Online Hindi Lightning Roulette by Evolution at Fun88",
            "description": "Experience the excitement of playing Play Online Hindi Lightning Roulette by Evolution Gaming at Fun88. Immerse yourself in thrilling gameplay and win big rewards!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/hindi-lightning-roulette",
            "alternate": "https://m.gofun88.in/live-casino/evolution/hindi-lightning-roulette",
            "keywords": "",
            "openGraph": {
                "title": "Play Online Hindi Lightning Roulette by Evolution at Fun88",
                "description": "Experience the excitement of playing Play Online Hindi Lightning Roulette by Evolution Gaming at Fun88. Immerse yourself in thrilling gameplay and win big rewards!",
                "url": "https://www.gofun88.in/live-casino/evolution/hindi-lightning-roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "monopoly-big-baller": {
            "title": "Play MONOPOLY Big Baller Live Casino Game by Evolution",
            "description": "Join the action in MONOPOLY Big Baller by Evolution. Play live, roll the dice, and enjoy big wins and bonuses!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/monopoly-big-baller",
            "alternate": "https://m.gofun88.in/live-casino/evolution/monopoly-big-baller",
            "keywords": "",
            "openGraph": {
                "title": "Play MONOPOLY Big Baller Live Casino Game by Evolution",
                "description": "Join the action in MONOPOLY Big Baller by Evolution. Play live, roll the dice, and enjoy big wins and bonuses!",
                "url": "https://www.gofun88.in/live-casino/evolution/monopoly-big-baller",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "super-andar-bahar": {
            "title": "Play Super Andar Bahar Live Casino Game by Evolution Gaming",
            "description": "Experience the thrill of a live casino game with Super Andar Bahar by Evolution Gaming at Fun88! Play casino online and win big with Super Andar Bahar.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/super-andar-bahar",
            "alternate": "https://m.gofun88.in/live-casino/evolution/super-andar-bahar",
            "keywords": "",
            "openGraph": {
                "title": "Play Super Andar Bahar Live Casino Game by Evolution Gaming",
                "description": "Experience the thrill of a live casino game with Super Andar Bahar by Evolution Gaming at Fun88! Play casino online and win big with Super Andar Bahar.",
                "url": "https://www.gofun88.in/live-casino/evolution/super-andar-bahar",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "teen-patti": {
            "title": "Teen Patti Real Cash: Play 3 Patti Money Games Online",
            "description": "Play teen patti online game and win real cash daily. Choose rooms, compete with real 3 patti players and boost your popular card games earnings!",
            "canonical": "https://www.gofun88.in/live-casino/teen-patti",
            "alternate": "https://m.gofun88.in/live-casino/teen-patti",
            "keywords": "",
            "openGraph": {
                "title": "Teen Patti Real Cash: Play 3 Patti Money Games Online",
                "description": "Play teen patti online game and win real cash daily. Choose rooms, compete with real 3 patti players and boost your popular card games earnings!",
                "url": "https://www.gofun88.in/live-casino/teen-patti",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "xxxtreme-lightning-roulette": {
            "title": "Play XXXtreme Lightning Roulette Online Game by Evolution",
            "description": "Enter the world of XXXtreme Lightning Roulette by Evolution. Play online and win big with this amazing casino game.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette",
            "alternate": "https://m.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette",
            "keywords": "",
            "openGraph": {
                "title": "Play XXXtreme Lightning Roulette Online Game by Evolution",
                "description": "Enter the world of XXXtreme Lightning Roulette by Evolution. Play online and win big with this amazing casino game.",
                "url": "https://www.gofun88.in/live-casino/evolution/xxxtreme-lightning-roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "7-up-7-down": {
            "title": "Play 7 Up 7 Down Card Game By Kingmaker | Fun88",
            "description": "Feel the adrenaline rush of 7 Up 7 Down by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/7-up-7-down",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/7-up-7-down",
            "keywords": "",
            "openGraph": {
                "title": "Play 7 Up 7 Down Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of 7 Up 7 Down by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/7-up-7-down",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "bonus-dice": {
            "title": "Play Bonus Dice Card Game By Kingmaker | Fun88",
            "description": "Feel the adrenaline rush of Bonus Dice by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/bonus-dice",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/bonus-dice",
            "keywords": "",
            "openGraph": {
                "title": "Play Bonus Dice Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Bonus Dice by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/bonus-dice",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "coin-toss": {
            "title": "Play Coin Toss Card Game By Kingmaker | Fun88",
            "description": "Feel the adrenaline rush of Coin Toss by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/coin-toss",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/coin-toss",
            "keywords": "",
            "openGraph": {
                "title": "Play Coin Toss Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Coin Toss by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/coin-toss",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "color-game": {
            "title": "Play Color Game Card Game By Kingmaker | Fun88",
            "description": "Feel the adrenaline rush of Color Game by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/color-game",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/color-game",
            "keywords": "",
            "openGraph": {
                "title": "Play Color Game Card Game By Kingmaker | Fun88",
                "description": "Feel the adrenaline rush of Color Game by Kingmaker on Fun88! Play online, win big, and revel in the excitement and endless entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/color-game",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "triple-chance": {
            "title": "Play Triple Chance Card Game By Kingmaker | Fun88",
            "description": "Enjoy the excitement of a card game with Triple Chance by Kingmaker on Fun88! Play online and win big with Triple Chance. Have endless fun and entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/triple-chance",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/triple-chance",
            "keywords": "",
            "openGraph": {
                "title": "Play Triple Chance Card Game By Kingmaker | Fun88",
                "description": "Enjoy the excitement of a card game with Triple Chance by Kingmaker on Fun88! Play online and win big with Triple Chance. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/triple-chance",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "jili-slot": {
            "title": "Play Best JILI Slots Game Online at Fun88",
            "description": "Take your Jili Slot game to the next level with tricks, tips, and other important strategies that can help you win real money. Play jili slot games today.",
            "canonical": "https://www.gofun88.in/slots/jili-slot",
            "alternate": "https://m.gofun88.in/slots/jili-slot",
            "keywords": "",
            "openGraph": {
                "title": "Play Best JILI Slots Game Online at Fun88",
                "description": "Take your Jili Slot game to the next level with tricks, tips, and other important strategies that can help you win real money. Play jili slot games today.",
                "url": "https://www.gofun88.in/slots/jili-slot",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "crazy777": {
            "title": "Play Crazy777 JILI Slots Game Online by Fun88",
            "description": "Play Crazy777 by JILI, a fun slots game full of exciting features. Learn winning tips and start spinning to win real money today on Fun88!",
            "canonical": "https://www.gofun88.in/slots/jili-slot/crazy777",
            "alternate": "https://m.gofun88.in/slots/jili-slot/crazy777",
            "keywords": "",
            "openGraph": {
                "title": "Play Crazy777 JILI Slots Game Online by Fun88",
                "description": "Play Crazy777 by JILI, a fun slots game full of exciting features. Learn winning tips and start spinning to win real money today on Fun88!",
                "url": "https://www.gofun88.in/slots/jili-slot/crazy777",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "fortune-gems": {
            "title": "Play Fortune Gems Slots JILI Games Online by Fun88",
            "description": "Spin and win with Fortune Gems Slots from JILI Games at Fun88. Experience exciting features, big rewards, and endless fun.",
            "canonical": "https://www.gofun88.in/slots/jili-slot/fortune-gems",
            "alternate": "https://m.gofun88.in/slots/jili-slot/fortune-gems",
            "keywords": "",
            "openGraph": {
                "title": "Play Fortune Gems Slots JILI Games Online by Fun88",
                "description": "Spin and win with Fortune Gems Slots from JILI Games at Fun88. Experience exciting features, big rewards, and endless fun.",
                "url": "https://www.gofun88.in/slots/jili-slot/fortune-gems",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "money-coming": {
            "title": "Play Money Coming JILI Slot Game Online by Fun88",
            "description": "Play Money Coming Jili Slots at Fun88 & win real money. Enjoy exciting spins, big rewards, and top-notch gameplay. Start winning today!",
            "canonical": "https://www.gofun88.in/slots/jili-slot/money-coming",
            "alternate": "https://m.gofun88.in/slots/jili-slot/money-coming",
            "keywords": "",
            "openGraph": {
                "title": "Play Money Coming JILI Slot Game Online by Fun88",
                "description": "Play Money Coming Jili Slots at Fun88 & win real money. Enjoy exciting spins, big rewards, and top-notch gameplay. Start winning today!",
                "url": "https://www.gofun88.in/slots/jili-slot/money-coming",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },


        "kingmaker-andarbahar": {
            "title": "Play Andar Bahar Card Game By Kingmaker | Fun88",
            "description": "Enjoy the excitement of a card game with Andar Bahar by Kingmaker on Fun88! Play online and win big with Andar Bahar. Enjoy boundless fun and entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/andar-bahar",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/andar-bahar",
            "keywords": "",
            "openGraph": {
                "title": "Play Andar Bahar Card Game By Kingmaker | Fun88",
                "description": "Enjoy the excitement of a card game with Andar Bahar by Kingmaker on Fun88! Play online and win big with Andar Bahar. Enjoy boundless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/andar-bahar",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "jhandi-munda": {
            "title": "Play Jhandi Munda (Langur Burja) Card Game By Kingmidas",
            "description": "Play Jhandi Munda by Kingmidas, a thrilling card game of chance. Explore Langur Burja game rules, strategies, and start to win real money today!",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/jhandi-munda",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/jhandi-munda",
            "keywords": "",
            "openGraph": {
                "title": "Play Jhandi Munda (Langur Burja) Card Game By Kingmidas",
                "description": "Play Jhandi Munda by Kingmidas, a thrilling card game of chance. Explore Langur Burja game rules, strategies, and start to win real money today!",
                "url": "https://www.gofun88.in/live-casino/kingmaker/jhandi-munda",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "thai-fish-prawn-crab": {
            "title": "Play Thai Fish Prawn Crab Card Game By Kingmaker | Fun88",
            "description": "Take your gaming to the next level with Thai Fish Prawn Crab by Kingmaker on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
            "canonical": "https://www.gofun88.in/live-casino/kingmaker/thai-fish-prawn-crab",
            "alternate": "https://m.gofun88.in/live-casino/kingmaker/thai-fish-prawn-crab",
            "keywords": "",
            "openGraph": {
                "title": "Play Thai Fish Prawn Crab Card Game By Kingmaker | Fun88",
                "description": "Take your gaming to the next level with Thai Fish Prawn Crab by Kingmaker on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/live-casino/kingmaker/thai-fish-prawn-crab",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "hotline": {
            "title": "Play Hotline Instant Game By Spribe | Fun88",
            "description": "Take your gaming to the next level with Hotline by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
            "canonical": "https://www.gofun88.in/instant-games/spribe/hotline",
            "alternate": "https://m.gofun88.in/instant-games/spribe/hotline",
            "keywords": "",
            "openGraph": {
                "title": "Play Hotline Instant Game By Spribe | Fun88",
                "description": "Take your gaming to the next level with Hotline by Spribe on Fun88! Play now for your chance to win big and experience unlimited fun and excitement.",
                "url": "https://www.gofun88.in/instant-games/spribe/hotline",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "baccarat": {
            "title": "Play Live Baccarat Online By Evolution Gaming | Fun88",
            "description": "Play Live Baccarat Online by Evolution Gaming at Fun88: Experience the thrill of real-time gaming and win big with our exciting live casino!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/baccarat",
            "alternate": "https://m.gofun88.in/live-casino/evolution/baccarat",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Baccarat Online By Evolution Gaming | Fun88",
                "description": "Play Live Baccarat Online by Evolution Gaming at Fun88: Experience the thrill of real-time gaming and win big with our exciting live casino!",
                "url": "https://www.gofun88.in/live-casino/evolution/baccarat",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "blackjack": {
            "title": "Play Live Blackjack Game Online for Real Money by Evolution",
            "description": "Play Blackjack online for real money with Evolution's top-tier games. Enjoy live dealers and seamless gameplay. Start winning big and join now Fun88!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/blackjack",
            "alternate": "https://m.gofun88.in/live-casino/evolution/blackjack",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Blackjack Game Online for Real Money by Evolution",
                "description": "Play Blackjack online for real money with Evolution's top-tier games. Enjoy live dealers and seamless gameplay. Start winning big and join now Fun88!",
                "url": "https://www.gofun88.in/live-casino/evolution/blackjack",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "evodragonTiger": {
            "title": "Play Dragon Tiger Real Cash Game Online by Evolution at Fun88",
            "description": "Play dragon tiger casino game online for real money at Fun88 and experience the excitement of 🐲 dragon vs. tiger 🐯 showdown! Download the evolution game app now.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/dragon-tiger",
            "alternate": "https://m.gofun88.in/live-casino/evolution/dragon-tiger",
            "openGraph": {
                "title": "Play Dragon Tiger Real Cash Game Online by Evolution at Fun88",
                "description": "Play dragon tiger casino game online for real money at Fun88 and experience the excitement of 🐲 dragon vs. tiger 🐯 showdown! Download the evolution game app now.",
                "url": "https://www.gofun88.in/live-casino/evolution/dragon-tiger",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "emperor-sic-bo": {
            "title": "Play Emperor Sic Bo A Casino Game by Evolution Gaming | Fun88",
            "description": "Enjoy the excitement of Emperor Sic Bo, a thrilling casino game by Evolution Gaming. Play now and experience the ultimate gaming experience!",
            "canonical": "https://www.gofun88.in/live-casino/evolution/emperor-sic-bo",
            "alternate": "https://m.gofun88.in/live-casino/evolution/emperor-sic-bo",
            "openGraph": {
                "title": "Play Emperor Sic Bo A Casino Game by Evolution Gaming | Fun88",
                "description": "Enjoy the excitement of Emperor Sic Bo, a thrilling casino game by Evolution Gaming. Play now and experience the ultimate gaming experience!",
                "url": "https://www.gofun88.in/live-casino/evolution/emperor-sic-bo",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "evo-teenpatti": {
            "title": "Play Teen Patti Live Casino Game by Evolution Gaming | Fun88",
            "description": "Enjoy the excitement of a live casino with Teen Patti by Evolution Gaming on Fun88! Play online and win big with Teen Patti.",
            "canonical": "https://www.gofun88.in/live-casino/evolution/teen-patti",
            "alternate": "https://m.gofun88.in/live-casino/evolution/teen-patti",
            "openGraph": {
                "title": "Play Teen Patti Live Casino Game by Evolution Gaming | Fun88",
                "description": "Enjoy the excitement of a live casino with Teen Patti by Evolution Gaming on Fun88! Play online and win big with Teen Patti.",
                "url": "https://www.gofun88.in/live-casino/evolution/teen-patti",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "playtech": {
            "title": "Play Playtech Live Casino Games at Fun88",
            "description": "Experience the thrill of live casino games by Playtech at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
            "canonical": "https://www.gofun88.in/live-casino/playtech",
            "alternate": "https://m.gofun88.in/live-casino/playtech",
            "openGraph": {
                "title": "Play Playtech Live Casino Games at Fun88",
                "description": "Experience the thrill of live casino games by Playtech at Fun88! Amazing Gaming offers a wide selection of the best casino games. Play Now!",
                "url": "https://www.gofun88.in/live-casino/playtech",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "live-roulette": {
            "title": "Play Roulette Game Online and Win Real Money at Fun88",
            "description": "Get ready for an adventure with our thrilling roulette games. Join the fun and test your luck at the roulette wheel game and win real cash. Your jackpot awaits!",
            "canonical": "https://www.gofun88.in/live-casino/live-roulette",
            "alternate": "https://m.gofun88.in/live-casino/roulette",
            "keywords": "",
            "openGraph": {
                "title": "Play Roulette Game Online and Win Real Money at Fun88",
                "description": "Get ready for an adventure with our thrilling roulette games. Join the fun and test your luck at the roulette wheel game and win real cash. Your jackpot awaits!",
                "url": "https://www.gofun88.in/live-casino/live-roulette",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "extra-andar-bahar": {
            "title": "Play Extra Andar Bahar Live Casino By Sexy Gaming | Fun88",
            "description": "Experience the thrill of Extra Andar Bahar, a captivating live casino game by Sexy Gaming, available at Fun88. Play now and enjoy the ultimate gaming experience!",
            "canonical": "https://www.gofun88.in/live-casino/sexy-gaming/extra-andar-bahar",
            "alternate": "https://m.gofun88.in/live-casino/sexy-gaming/extra-andar-bahar",
            "openGraph": {
                "title": "Play Extra Andar Bahar Live Casino By Sexy Gaming | Fun88",
                "description": "Experience the thrill of Extra Andar Bahar, a captivating live casino game by Sexy Gaming, available at Fun88. Play now and enjoy the ultimate gaming experience!",
                "url": "https://www.gofun88.in/live-casino/sexy-gaming/extra-andar-bahar",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "sup-teenpatti2020": {
            "title": "Play Teen Patti 20-20 Live Casino By Super Spade | Fun88",
            "description": "Enjoy the excitement of a live casino with Teen Patti 20-20 by Super Spade on Fun88! Play online and win big with Teen Patti 20-20.",
            "canonical": "https://www.gofun88.in/live-casino/super-spade/teen-patti-20-20",
            "alternate": "https://m.gofun88.in/live-casino/super-spade/teen-patti-20-20",
            "openGraph": {
                "title": "Play Teen Patti 20-20 Live Casino By Super Spade | Fun88",
                "description": "Enjoy the excitement of a live casino with Teen Patti 20-20 by Super Spade on Fun88! Play online and win big with Teen Patti 20-20.",
                "url": "https://www.gofun88.in/live-casino/super-spade/teen-patti-20-20",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "dice-duel": {
            "title": "Play Dice Duel Online For Real Money by Bet Games | Fun88",
            "description": "Enjoy the excitement of a tv game with Dice Duel by Bet games on Fun88! Play online and win big with Dice Duel. Have endless fun and entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/betgames/dice-duel",
            "alternate": "https://m.gofun88.in/live-casino/betgames/dice-duel",
            "openGraph": {
                "title": "Play Dice Duel Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Dice Duel by Bet games on Fun88! Play online and win big with Dice Duel. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/dice-duel",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "satta-matka": {
            "title": "Play Satta Matka Live by Betgames for Real Money Online - Fun88",
            "description": "Explore the excitement Satta Matka Game with BetGames on Fun88. Engage in live gameplay, interactive fun, and aim for thrilling wins. Play now!",
            "canonical": "https://www.gofun88.in/live-casino/betgames/satta-matka",
            "alternate": "https://m.gofun88.in/live-casino/betgames/satta-matka",
            "openGraph": {
                "title": "Online Satta Game: Matka Play Online by BetGames & Win Cash",
                "description": "Experience the excitement of online Matka play with BetGames. Test your luck, win cash prizes, and enjoy thrilling online Satta games.",
                "url": "https://www.gofun88.in/live-casino/betgames/satta-matka",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "speedy-7": {
            "title": "Play Speedy 7 Online For Real Money by Bet Games | Fun88",
            "description": "Enjoy the excitement of a tv game with Speedy 7 by Bet games on Fun88! Play online and win big with Speedy 7. Have endless fun and entertainment.",
            "canonical": "https://www.gofun88.in/live-casino/betgames/speedy-7",
            "alternate": "https://m.gofun88.in/live-casino/betgames/speedy-7",
            "openGraph": {
                "title": "Play Speedy 7 Online For Real Money by Bet Games | Fun88",
                "description": "Enjoy the excitement of a tv game with Speedy 7 by Bet games on Fun88! Play online and win big with Speedy 7. Have endless fun and entertainment.",
                "url": "https://www.gofun88.in/live-casino/betgames/speedy-7",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        },
        "sponsorships": {
            "title": "Fun88 Sponsorships",
            "description": "Fun88 Sponsorships",
            "canonical": "https://www.gofun88.in/sponsors",
            "alternate": "https://m.gofun88.in/sponsors",
            "keywords": ""
        },
        "rummy": {
            "title": "Jili Rummy: Play Rummy Game Online and Win Real Cash",
            "description": "Play Rummy online for real money with Jili Games! Fun88 offers an instant 100% welcome bonus and reload bonuses for this classic rummy game.",
            "canonical": "https://www.gofun88.in/slots/jili-slot/rummy",
            "alternate": "https://m.gofun88.in/slots/jili-slot/rummy",
            "keywords": "",
            "openGraph": {
                "title": "Jili Rummy: Play Rummy Game Online and Win Real Cash",
                "description": "Play Rummy online for real money with Jili Games! Fun88 offers an instant 100% welcome bonus and reload bonuses for this classic rummy game.",
                "url": "https://www.gofun88.in/slots/jili-slot/rummy",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "images": [{"url":"https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"}]
            }
        },
        "jiliblackjack": {
            "title": "Play Live Blackjack Card Game By Jili | Fun88",
            "description": "Experience the thrill of a card game with Blackjack by Jili at Fun88! Play Blackjack card games online and win big.",
            "canonical": "https://www.gofun88.in/slots/jili-slot/blackjack",
            "alternate": "https://m.gofun88.in/slots/jili-slot/blackjack",
            "keywords": "",
            "openGraph": {
                "title": "Play Live Blackjack Card Game By Jili | Fun88",
                "description": "Experience the thrill of a card game with Blackjack by Jili at Fun88! Play Blackjack card games online and win big.",
                "url": "https://www.gofun88.in/slots/jili-slot/blackjack",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "images": [{"url":"https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"}]
            }
        },
        "instantskyward": {
            "title": "Play Skyward Game: Betgames Crash Adventure | Fun88",
            "description": "Experience the thrill of Skyward Game – an instant crash game by Betgames. Play for real money excitement at Fun88. Dive into the action now!",
            "canonical": "https://www.gofun88.in/instant-games/betgames/skyward",
            "alternate": "https://m.gofun88.in/instant-games/betgames/skyward",
            "keywords": "",
            "openGraph": {
                "title": "Play Skyward Game: Betgames Crash Adventure | Fun88",
                "description": "Experience the thrill of Skyward Game – an instant crash game by Betgames. Play for real money excitement at Fun88. Dive into the action now!",
                "url": "https://www.gofun88.in/instant-games/betgames/skyward",
                "locale": "en_US",
                "type": "website",
                "siteName": "Fun88",
                "image": "https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png"
            }
        }
    }


}

export default seoData;
